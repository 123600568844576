import PropTypes from 'prop-types'
import { canShowLogo } from 'services/utils'
import Logo from 'shared/Layout/Header/HeaderMiddleSection/Logo'
import LiveChat from '~/components/shared/LiveChat'
import styles from './CheckoutHeader.module.scss'
import Container from 'shared/Container'
import LiveChatData from '~/components/shared/LiveChat/LiveChatData'
import { getAndApplyPromoDiscount } from '~/services/orderService'
import { INSPECT_MODE } from 'components/containers/Checkout/CheckoutConstants'
import { useRouter } from 'next/router'
/*

 * @param {string} language
 * @param {object} settings
 * @param {object} details
 */

const CheckoutHeader = (props) => {
  const { language, details, settings, buyer, order = {} } = props
  const { enableClickToChat, isPunchOutEnable } = settings
  const showLogo = canShowLogo(settings)
  const logoUrl = showLogo > 1 ? details.customLogo : ''
  const price = getAndApplyPromoDiscount(order)
  const { query = {} } = useRouter()
  const { mode = '' } = query
  const disabledLogo = isPunchOutEnable && mode === INSPECT_MODE
  return (
    <header className={styles.headerMiddle}>
      <Container className="noPaddingXs">
        <div className={styles.headerMiddleBlock}>
          {!!showLogo && (
            <Logo
              language={language}
              logoUrl={logoUrl}
              disabledLogo={disabledLogo}
            />
          )}
          {enableClickToChat && (
            <>
              <LiveChatData
                buyer={buyer}
                details={details}
                language={language}
                price={price}
              />
              <LiveChat language={language} isCheckoutLiveChat={true} />
            </>
          )}
        </div>
      </Container>
    </header>
  )
}

// Props Validation
CheckoutHeader.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.object,
  details: PropTypes.object,
  buyer: PropTypes.object,
  order: PropTypes.object,
}

export default CheckoutHeader
