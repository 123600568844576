import { useState } from 'react'
import { FIELDS as ADDRESS_FIELDS } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import { FIELDS as COST_CENTER_FIELDS } from '~/components/shared/ManageAddresses/CostCenters/CostCentersConstants'

export const addressSearchFields = [
  [ADDRESS_FIELDS.COMPANY_NAME],
  [ADDRESS_FIELDS.ATTENTION],
  [ADDRESS_FIELDS.STREET_ADDRESS],
  [ADDRESS_FIELDS.BUILDING],
  [ADDRESS_FIELDS.CITY],
  [ADDRESS_FIELDS.PROVINCE],
  [ADDRESS_FIELDS.POSTAL_CODE],
]

export const costCenterFields = [
  [COST_CENTER_FIELDS.COST_CENTER_LABEL],
  [COST_CENTER_FIELDS.COST_CENTER_VALUE],
]

export function useSearch(list = [], fields = []) {
  const [searchInput, setSearchInput] = useState('')
  function onSearch(key) {
    const { target } = key
    const { value } = target
    setSearchInput(value)
  }
  const filterValue = searchInput.toLowerCase()
  const searchResult = list.filter((item = {}) => {
    const searchKeys = fields.map((i) => item[i])
    return searchKeys.some((data) => {
      return data && data.toLowerCase().includes(filterValue)
    })
  })

  return { searchResult, onSearch, setSearchInput, searchInput }
}

//TODO: We need to remove searchAddresses & use useSearch hook using in useAssignDeliveryAddresses.js
export function searchAddresses(addresses = [], searchKey, fields = []) {
  const filterValue = searchKey.toLowerCase()
  const searchResult = addresses.filter((address = {}) => {
    const fieldValues = fields.map((item) => address[item])
    return fieldValues.some((addressData) => {
      return addressData && addressData.toLowerCase().includes(filterValue)
    })
  })
  return searchResult
}
