import { useWasShownOnce } from 'components/shared/CustomHooks/useWasShownOnce'
import { WELCOME_TO_EWAY_MODAL_SHOWN } from 'components/shared/WelcomeToEwayModal/WelcomeToEwayModalConstants'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import AccountSelection from 'shared/AccountSelection'
import ProductQuickViewLoader from 'shared/ProductCard/ProductQuickView/ProductQuickViewLoader'
import { isLoggedIn } from 'shared/Utils/userUtils'
import { useToggler } from '~/components/shared/CustomHooks/useToggler'
import WelcomeToEwayModal from '~/components/shared/WelcomeToEwayModal'
import { useManageAddressesContext } from '~/context/ManageAddressesContext/ManageAddressesContext'
import { ENGLISH } from '~/redux/constants'
import { onAddToCartResponse } from '~/services/addToCartService'
import {
  QUICK_VIEW_CONTAINER_ID,
  QUICK_VIEW_MESSAGE_ID,
} from '../../Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { showApiNotifications } from '../../Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import { isAllowedOnPage } from './LayoutModalUtils'

/**
 * Controls all the modal that can be shown in the Layout component
 * @returns
 */
const LayoutModals = ({
  buyer = {},
  details = {},
  language = ENGLISH,
  order,
  pageName,
  settings = {},
  sessionId,
  renderAccountSelection,
  showWelcomeToEway,
  forceOpenWelcomeToEway,
  onClickContinueManageAddress,
  onClickContinuePostalCodeEntry,
  onCloseManagePostalCodeEntryModal,
  onCloseManageAddressModal,
}) => {
  const {
    isResolvedOrError: finishedLoadingAddresses,
    addressLevel,
    principalClientId,
    isResolved,
  } = useManageAddressesContext()

  const { pathname } = useRouter()
  const loggedIn = isLoggedIn(buyer)
  const { parentClientNo, clientNo } = details
  const { buyerId, clientId } = buyer
  const canShowWelcomeToEway =
    showWelcomeToEway &&
    finishedLoadingAddresses &&
    !forceOpenWelcomeToEway &&
    isAllowedOnPage(pathname) &&
    isResolved

  const canShowWelcomeToEwayFromLink =
    showWelcomeToEway && finishedLoadingAddresses && forceOpenWelcomeToEway

  const [triggerModalShown, setTriggerShown] = useState(false)
  const { open, isOpen: canBeShown, close: closeModal } = useToggler()
  const { wasShownOnce } = useWasShownOnce({
    cookieName: WELCOME_TO_EWAY_MODAL_SHOWN,
    setShownWhen: triggerModalShown,
  })

  useEffect(() => {
    if (
      !wasShownOnce &&
      (canShowWelcomeToEway || canShowWelcomeToEwayFromLink)
    ) {
      open()
    }
  }, [canShowWelcomeToEway, canShowWelcomeToEwayFromLink, open, wasShownOnce])

  function handleOnClickContinueManageAddress(e) {
    closeModalAndSetModalShown()
    onClickContinueManageAddress?.(e)
  }

  const handleAddressModalClose = (e) => {
    setTriggerShown(false)
    closeModal()
    onCloseManageAddressModal?.(e)
  }

  const handlePostalCodeEntryModalClose = (e) => {
    setTriggerShown(false)
    closeModal()
    onCloseManagePostalCodeEntryModal?.(e)
  }

  function handleOnClickPostalCodeEntry(...args) {
    const [, { isFormValid }] = args
    if (isFormValid) {
      closeModalAndSetModalShown()
      onClickContinuePostalCodeEntry?.(args)
    }
  }

  function closeModalAndSetModalShown() {
    closeModal()
    setTriggerShown(true)
  }

  const handleAddToCart = (cartData) => {
    onAddToCartResponse(cartData, (messages) => {
      showApiNotifications(messages, {
        containerId: QUICK_VIEW_CONTAINER_ID,
        id: QUICK_VIEW_MESSAGE_ID,
      }) // show it in product quick view alerts
    })
  }

  const isOpen = !wasShownOnce && canBeShown

  return (
    <>
      {renderAccountSelection ? (
        <AccountSelection
          language={language}
          buyer={buyer}
          order={order}
          pageName={pageName}
          settings={settings}
          sessionId={sessionId}
        />
      ) : null}
      {canShowWelcomeToEway || canShowWelcomeToEwayFromLink ? (
        <WelcomeToEwayModal
          open={isOpen || forceOpenWelcomeToEway}
          language={language}
          buyerId={buyerId}
          clientNo={clientNo}
          parentClientNo={parentClientNo}
          onClickContinueManageAddress={handleOnClickContinueManageAddress}
          onClickContinuePostalCodeEntry={handleOnClickPostalCodeEntry}
          onClosePostalCodeEntryModal={handlePostalCodeEntryModalClose}
          onCloseManageAddressModal={handleAddressModalClose}
          showCloseButton={forceOpenWelcomeToEway}
          clientId={clientId}
          addressLevel={addressLevel}
          principalClientId={principalClientId}
        />
      ) : null}
      <ProductQuickViewLoader
        language={language}
        isLoggedIn={loggedIn}
        settings={settings}
        pageName={pageName}
        exportQuickViewToWindow={true}
        addToCartHandler={handleAddToCart}
      />
    </>
  )
}

LayoutModals.propTypes = {
  buyer: PropTypes.shape({
    canManageDeliveryAddress: PropTypes.any,
    inviteeState: PropTypes.any,
  }),
  details: PropTypes.shape({
    isCustomerOnboardingAccount: PropTypes.any,
    parentClientNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clientNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  renderAccountSelection: PropTypes.bool,
  showWelcomeToEway: PropTypes.bool,
  forceOpenWelcomeToEway: PropTypes.bool,
  onClickContinueManageAddress: PropTypes.func,
  onClickContinuePostalCodeEntry: PropTypes.func,
  onCloseManagePostalCodeEntryModal: PropTypes.func,
  onCloseManageAddressModal: PropTypes.func,
  language: PropTypes.any,
  order: PropTypes.any,
  pageName: PropTypes.any,
  sessionId: PropTypes.any,
  settings: PropTypes.any,
}

export default LayoutModals
