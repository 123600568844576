import classNames from 'classnames'
import { isDev } from '~/config/env/environmentUtils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Button from '~/components/shared/Button'
import styles from './NavBarMenuButton.module.scss'

const NavBarMenuButton = forwardRef((props, ref) => {
  const { onClick, isOpen, text, ...rest } = props
  const buttonClasses = classNames(styles.button, {
    [styles.active]: isOpen,
  })

  return (
    <Button
      {...rest}
      ref={ref}
      text={text}
      aria-label={text}
      aria-expanded={isOpen ? 'true' : 'false'}
      onClick={onClick}
      className={buttonClasses}
      icon="carrot"
      variant="link"
    />
  )
})

if (isDev) {
  NavBarMenuButton.displayName = 'NavBarMenuButton'
}
export default NavBarMenuButton

NavBarMenuButton.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}
