import PropTypes from 'prop-types'
import Icon from 'shared/Icon'
import Text from 'shared/Text'
import messages from 'components/shared/LiveChat/messages'
import styles from './LiveChatButton.module.scss'
import classNames from 'classnames'
import { openLiveChat } from '../LiveChatUtils'

function LiveChatButton({ language, isCheckoutLiveChat }) {
  const liveChatWrapper = classNames(styles.liveChat, {
    [`${styles.checkoutLiveChat}`]: isCheckoutLiveChat,
  })
  return (
    <div className={liveChatWrapper}>
      <button onClick={openLiveChat}>
        <span className={styles.iconHeadphone}>
          <Icon variant="headphone" />
        </span>
        <div role="button" className={styles.hiddenSm}>
          <Text
            text={messages[language].liveChat}
            className={styles.liveChatText}
          />
        </div>
      </button>
    </div>
  )
}

LiveChatButton.propTypes = {
  language: PropTypes.string,
  isCheckoutLiveChat: PropTypes.bool,
}

export default LiveChatButton
