export default {
  en: {
    close: 'Close',
    shoppingLabel: 'Shopping',
    shoppingUrl: 'https://www.eway.ca/en',
    rebatesAndOffersLabel: 'Rebates & Offers',
    manageDeliveryAddresses: 'Manage Addresses',
    manageDeliveryAddressesUrl:
      'AccountManagement/Profile/ManageDeliveryAddresses',
    rebatesAndOffersUrl: 'Rebates/Rebates.aspx',
    flyersCataloguesLabel: 'Flyers',
    shoppingListsLabel: 'Shopping Lists',
    shoppingListsUrl: 'ShoppingList/ShoppingList/GetShoppingListList',
    ordersLabel: 'Orders',
    ordersUrl: 'https://www.eway.ca/en',
    groupOrdersLabel: 'Group Orders',
    submittedOrdersLabel: 'Submitted Orders',
    submittedOrdersUrl: 'Orders/Orders/OrderSummaries/1',
    backorderedItemsLabel: 'Backordered Items',
    backorderedItemsUrl: 'Orders/OrderList.aspx?OrderType=4',
    templatesLabel: 'Templates',
    inApprovalLabel: 'In Approval',
    inApprovalUrl: 'Orders/Orders/OrderSummaries/2',
    recentlyApprovedLabel: 'Recently Approved',
    recentlyApprovedUrl: 'Orders/Orders/OrderSummaries/3',
    approvedBackorderedItemsLabel: 'Approved Backordered Items',
    approvedBackorderedItemsUrl: 'Orders/OrderList.aspx?OrderType=5',
    specialordersLabel: 'Special Orders',
    specialordersUrl: 'Shopping/SpecialOrders/Form',
    returnsLabel: 'Returns',
    returnsUrl: 'Orders/Submitted/returns',
    myAccountLabel: 'My Account',
    myAccountUrl: 'https://www.eway.ca/en',
    editMyProfileLabel: 'Edit My Profile',
    editMyProfileUrl: 'edit-profile',
    changeMyPasswordLabel: 'Change My Password',
    changeMyPasswordUrl: 'AccountManagement/Profile/ChangePassword',
    adminLabel: 'Admin',
    adminUrl: 'AdminSite/Default.aspx',
    standingOfferProductsLabel: 'Standing Offer Products',
    offerProductsLabel: 'Offer Products',
    shipToLocationLabel: 'Ship to Location',
    findAccountLabel: 'Find Account',
    helpLabel: 'Help',
    cartLabel: 'Cart',
    cartMenuUrl: 'orders/cart/details',
    printOrderLabel: 'Print Order Form',
    printOrderUrl: 'OnlinePrintForm/OnlinePrintForm/GetFormData',
    accountManageLabel: 'Account Management Centre',
    accountManageUrl: 'AccountManagement/AccountManagementMenu.aspx',
    groupOrderMsg:
      'You are Participating in Group Order GROUP_ORDER, created by CREATED_BY. Need help placing Group Order?',
    groupOrderNote:
      'Please note that your Group Order access has reduced functionality from regular eway.ca users.',
    clickHereLabel: 'Click here',
    groupOrderLink:
      'https://www.eway.ca/Help/en/HelpCentre/Quick_Reference_Sheets/GroupOrder.pdf',
  },
  fr: {
    close: 'Fermer',
    shoppingLabel: 'Magasiner',
    shoppingUrl: 'https://www.eway.ca/en',
    rebatesAndOffersLabel: 'Rabais et offres',
    manageDeliveryAddresses: 'Gérer les adresses',
    manageDeliveryAddressesUrl:
      'AccountManagement/Profile/ManageDeliveryAddresses',
    rebatesAndOffersUrl: 'Rebates/Rebates.aspx',
    flyersCataloguesLabel: 'Circulaires',
    shoppingListsLabel: "Listes d'achat",
    shoppingListsUrl: 'ShoppingList/ShoppingList/GetShoppingListList',
    ordersLabel: 'Commandes',
    ordersUrl: 'https://www.eway.ca/en',
    groupOrdersLabel: 'Commandes de groupe',
    submittedOrdersLabel: 'Commandes soumises',
    submittedOrdersUrl: 'Orders/Orders/OrderSummaries/1',
    backorderedItemsLabel: 'Articles en rupture de stock',
    backorderedItemsUrl: 'Orders/OrderList.aspx?OrderType=4',
    templatesLabel: 'Brouillons',
    inApprovalLabel: 'En approbation',
    inApprovalUrl: 'Orders/Orders/OrderSummaries/2',
    recentlyApprovedLabel: 'Récemment approuvées',
    recentlyApprovedUrl: 'Orders/Orders/OrderSummaries/3',
    approvedBackorderedItemsLabel: 'Articles approuvés en rupture de stock',
    approvedBackorderedItemsUrl: 'Orders/OrderList.aspx?OrderType=5',
    specialordersLabel: 'Commandes Spéciales',
    specialordersUrl: 'Shopping/SpecialOrders/Form',
    returnsLabel: 'Retours',
    returnsUrl: 'Orders/Submitted/returns',
    myAccountLabel: 'Mon compte',
    myAccountUrl: 'https://www.eway.ca/en',
    editMyProfileLabel: 'Modifier mon profil',
    editMyProfileUrl: 'edit-profile',
    changeMyPasswordLabel: 'Changer mon mot de passe',
    changeMyPasswordUrl: 'AccountManagement/Profile/ChangePassword',
    adminLabel: 'Admin',
    adminUrl: 'AdminSite/Default.aspx',
    standingOfferProductsLabel: "Produits de l'offre à commandes",
    offerProductsLabel: 'Offre de produits',
    shipToLocationLabel: 'Emplacement du sous-compte',
    findAccountLabel: 'Trouver un compte',
    helpLabel: 'Aide',
    cartLabel: 'Panier',
    cartMenuUrl: 'orders/cart/details',
    printOrderLabel: 'Formulaire de commande d’impression',
    printOrderUrl: 'OnlinePrintForm/OnlinePrintForm/GetFormData',
    accountManageLabel: 'Centre de gestion des comptes',
    accountManageUrl: 'AccountManagement/AccountManagementMenu.aspx',
    groupOrderMsg: `Vous participez à la commande de groupe GROUP_ORDER, créée par CREATED_BY.
    Besoin d'aide pour placer une commande de groupe?`,
    groupOrderNote: `Veuillez noter que votre commande de groupe a des fonctions limitées en comparaison aux usagers réguliers d'eway.ca`,
    clickHereLabel: 'Cliquez ici',
    groupOrderLink:
      'https://www.eway.ca/Help/fr/HelpCentre/R_f_rence_rapide/CommandeDeGroupe.pdf',
  },
}
