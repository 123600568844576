import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMemo, useRef, useState } from 'react'
import {
  classMutationObserver,
  useKeyboardNavigation,
} from 'components/shared/CustomHooks/useKeyboardNavigation'
import { KEYS } from '../../CustomHooks/useKeyPressEvent'

import styles from '../ShipToComponent.module.scss'
import { accountsBy, findSelectedAccount } from '../ShipToComponentUtils'
import ShipToInput from './ShipToInput'
import ShipToList from './ShipToList'
import messages from '../messages'
import {
  pressDown,
  pressEnter,
  pressEscape,
  pressUp,
} from '~/services/utils/keyboardUtils'
function ShipToListContainer(props) {
  const shipToInputRef = useRef()
  const shipToListContainerRef = useRef()
  const {
    open,
    onClick,
    disabled,
    onKeyDown,
    onSelectItem,
    onFilterAccounts,
    accounts,
    block,
    size,
    id,
    inputFilter,
    hasTwoOrMoreAccounts,
    language,
  } = props

  const filteredAccounts = useMemo(() => {
    return accounts.filter(accountsBy(inputFilter))
  }, [accounts, inputFilter])
  const [selectedItemIndex, setSelectedItemIndex] = useState(() => {
    return null
  })

  useKeyboardNavigation({
    items: filteredAccounts,
    isActive: open,
    selectedItemIndex,
    setSelectedItemIndex,
    mutationObserver,
    observingElement: shipToListContainerRef.current,
    captureEvents,
    getKeyboardAction,
  })

  const handleFocusOnInput = () => {
    shipToInputRef.current.focus()
  }
  const handleShipToInputFocus = () => {
    setSelectedItemIndex(null)
  }
  const shipToContainerClasses = classNames(styles.selectCustomContainer, {
    [styles.open]: open,
  })

  const { isFavorite, label } = findSelectedAccount(accounts) || {}
  const comboAriaOwnId = 'ariaOwnId'
  const comboAriaLabelId = `selectCustomContainer-${id}`
  const ariaLabel = messages[language].shipToAccountLabel
  return (
    <div
      className={shipToContainerClasses}
      onKeyDown={onKeyDown}
      id={comboAriaLabelId}
      onClick={disabled ? null : onClick}
      tabIndex="0"
      role="combobox"
      aria-controls="shipToCombo"
      aria-expanded={open ? 'true' : 'false'}
      aria-haspopup="listbox"
      aria-owns={comboAriaOwnId}
      aria-label={ariaLabel}
    >
      <ShipToInput
        size={size}
        block={block}
        shipToInputRef={shipToInputRef}
        disabled={disabled}
        isFavorite={isFavorite}
        label={label}
        onFocus={handleShipToInputFocus}
        onFilterAccounts={onFilterAccounts}
        inputFilter={inputFilter}
        comboAriaOwnId={comboAriaOwnId}
        comboAriaLabelId={comboAriaLabelId}
      />
      {hasTwoOrMoreAccounts && (
        <DropDownCaret onClick={handleFocusOnInput} label={ariaLabel} />
      )}
      <ul
        ref={shipToListContainerRef}
        id={comboAriaOwnId}
        className={styles.selectCustomList}
        role="listbox"
        aria-labelledby={comboAriaLabelId}
      >
        <ShipToList
          accounts={filteredAccounts}
          open={open}
          onSelectItem={onSelectItem}
          selectedItemIndex={selectedItemIndex}
          itemSelectedClass={itemSelectedClass}
        />
      </ul>
    </div>
  )
}

export default ShipToListContainer
ShipToListContainer.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  block: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onSelectItem: PropTypes.func,
  onFilterAccounts: PropTypes.func,
  accounts: PropTypes.array,
  inputFilter: PropTypes.node,
  hasTwoOrMoreAccounts: PropTypes.bool,
  language: PropTypes.string,
}

function DropDownCaret({ onClick, label }) {
  return (
    <div
      onClick={onClick}
      className={`${styles.shipToArrowContainer}`}
      role="button"
      aria-label={label}
      tabIndex={-1}
    />
  )
}

DropDownCaret.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
}
const itemSelectedClass = styles.selected
const mutationObserver = classMutationObserver(itemSelectedClass)
const captureEvents = [KEYS.ARROW_DOWN, KEYS.ARROW_UP, KEYS.ESCAPE]
const getKeyboardAction = (e) => {
  const isNextSelection = pressDown(e)
  const isPreviousSelection = pressUp(e)
  const isExit = pressEscape(e)
  const isEnter = pressEnter(e)

  return {
    isNextSelection,
    isPreviousSelection,
    isExit,
    isEnter,
  }
}
