export default {
  en: {
    sessionTimeOutHeading: 'Sorry, your session has expired due to inactivity!',
    sessionTimeOutMessage:
      "You weren't really clicking around anymore so we logged you out, for your protection.  Please login to pick up where you left off.",
    okLabel: 'OK',
  },
  fr: {
    sessionTimeOutHeading:
      "Désolé, votre session s'est terminée en raison d'inactivité!",
    sessionTimeOutMessage:
      "Vous n'étiez plus vraiment en train de cliquer, alors nous vous avons déconnecté, pour votre protection. Veuillez vous connecter pour reprendre là où vous vous étiez.",
    okLabel: "D'accord",
  },
}
