import PropTypes from 'prop-types'
import { INSIDE_DATA_ID } from './LiveChatDataConstants'
import { getLiveHelpData } from './LiveChatDataUtils'
import styles from './LiveChatData.module.scss'

/**
 * To test the Live Chat Data
 * Go to: https://stp-sandbox-live.inside-graph.com
 * username: telmo.domingos@staples.com
 * password: Ewaystaples1
 *
 * To understand how to use it ask
 * "Theodore Eliadis" or "Telmo Domingo" to see it in action.
 * @param {*} param0
 */
function LiveChatData(props) {
  const { buyer, details, language, price } = props
  const insideData = getLiveHelpData(buyer, details, language)

  return (
    <>
      <div id="checkout-cart" className={styles.displayNone}>
        <span>{price}</span>
      </div>
      <input
        type="hidden"
        data-test="inside-data"
        // ⬇️ Do not change this 'ID' it needs to be "insidedata"
        // Or else, live chat data is not sent to our provider "PowerFront"
        id={INSIDE_DATA_ID}
        value={insideData}
      />
    </>
  )
}

LiveChatData.propTypes = {
  buyer: PropTypes.object,
  details: PropTypes.object,
  language: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default LiveChatData
