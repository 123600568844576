export const CATALOGUE_PRODUCT = 4
export const APPROVAL_RULE_ACTION = 2 /* ApprovalAction which can have {(Promotion = -2),(NoAction = -1), (CarbonCopy = 0),  (ToBeApproved = 1), (CannotOrder = 2)}*/

import { FIFTY_GREEN_HARD_STOP } from './summaryMessageService/summaryMessageServiceConstants'
import { isEmptyArray } from './utils'

/**
 * Name: checkCheckoutAvailability
 * Desc: check checkout availability for product list
 * @param {*} productList
 */
export const getCheckoutAvailabilityStatus = (
  productList = [],
  fiftyGreen,
  enableRecommendationProduct = false,
  cartTotal = 0,
  approvalRuleAction,
  minimumOrderAmount = 0
) => {
  let isCartEmpty = true
  if (productList && !isEmptyArray(productList)) {
    isCartEmpty = false
  }
  const isCartHasOnlyCatalogue =
    checkCartProductHasOnlyCatalogProduct(productList)
  const isFiftyGreen = checkFiftyGreenHardStop(
    fiftyGreen,
    enableRecommendationProduct,
    cartTotal,
    minimumOrderAmount
  )
  /* ApprovalAction which can have {(Promotion = -2),(NoAction = -1), (CarbonCopy = 0),  (ToBeApproved = 1), (CannotOrder = 2)}*/
  const isApprovalRuleAction = approvalRuleAction === APPROVAL_RULE_ACTION
  if (
    isCartEmpty ||
    isCartHasOnlyCatalogue ||
    isFiftyGreen ||
    isApprovalRuleAction
  ) {
    return true
  }
  return false
}

function checkCartProductHasOnlyCatalogProduct(productList) {
  let isCartHasOnlyCatalogue = true
  if (productList && !isEmptyArray(productList)) {
    productList.forEach((item) => {
      const orderProductType = item?.OrderProductType || item?.orderProductType
      if (orderProductType !== CATALOGUE_PRODUCT) {
        isCartHasOnlyCatalogue = false
        return isCartHasOnlyCatalogue
      }
    })
  }
  return isCartHasOnlyCatalogue
}

const checkFiftyGreenHardStop = (
  fiftyGreen,
  enableRecommendationProduct,
  cartTotal,
  minimumOrderAmount
) => {
  if (
    fiftyGreen === FIFTY_GREEN_HARD_STOP &&
    !enableRecommendationProduct &&
    cartTotal < minimumOrderAmount
  ) {
    return true
  }
  return false
}
