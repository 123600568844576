export const POSTAL_CODE = 'postalCode'
export const PROVINCE = 'province'
export const INIT_VALUES = {
  [POSTAL_CODE]: '',
  [PROVINCE]: '',
}
export const POSTAL_CODE_ENTRY_CODE = 2
export const SHIP_TO_SELECTION_CODE = 1
export const EXCEPTION_POSTAL_CODE_PREFIX = 'K1A'
export const PRE_DEFINE_PROVINCES_OPTIONS = [
  { value: 'on', name: 'ONTARIO' },
  { value: 'qc', name: 'QUEBEC' },
]
