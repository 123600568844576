import Head from 'next/head'
import { useEffect } from 'react'
import { pollValueExists } from '~/services/utils'
import { MUNCHKIN_SCRIPT_URL } from './MarketoTrackingConstants'
import { initMunchkin } from './MarketoTrackingUtils'

const MarketoTracking = () => {
  useEffect(() => {
    async function initMarketoTracking() {
      const isAvailable = await pollValueExists(window.Munchkin, 20000)
      isAvailable && initMunchkin()
    }
    initMarketoTracking()
  }, [])

  return (
    <Head>
      <script type="text/javascript" async src={MUNCHKIN_SCRIPT_URL} />
    </Head>
  )
}

MarketoTracking.propTypes = {}

export default MarketoTracking
