import PropTypes from 'prop-types'
import styles from './BannerImage.module.scss'
import Image from 'components/shared/Image'

/**
 * Name: BannerImg
 * Desc: render bottom banner image of the Navbar
 * @param {string} url
 */
const BannerImage = (props) => {
  return (
    <div className={styles.menuBanner}>
      <Image {...props} fluid />
    </div>
  )
}

// Props Validation
BannerImage.propTypes = {
  url: PropTypes.string,
}

export default BannerImage
