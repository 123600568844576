import PropTypes from 'prop-types'
import Star from 'shared/Star'
import { KEYS } from '~/components/shared/CustomHooks/useKeyPressEvent'
import styles from './ShipToItem.module.scss'

function ShipToItem({
  id,
  onSelectItem,
  isSelected,
  isFavorite,
  children,
  guidId,
  open,
  ...rest
}) {
  const handleOnClick = (e) => {
    onSelectItem({ clientNo: id, clientId: guidId, isFavorite }, e)
  }

  const handleItemSelectionKeyUp = (event) => {
    event.key === KEYS.ENTER &&
      onSelectItem({ clientNo: id, clientId: guidId, isFavorite }, event)
  }

  const aria = isSelected ? 'true' : 'false'
  return (
    <li
      data-test="ship-to-item"
      id={id}
      role="option"
      onClick={handleOnClick}
      onKeyUp={handleItemSelectionKeyUp}
      aria-selected={aria}
      {...rest}
      tabIndex={open ? 0 : -1}
    >
      <div className={styles.shipToItemContainer}>
        {isFavorite && <Star as="span" className={styles.starStyling} />}
        <span data-test="ship-to-list-item">{children}</span>
      </div>
    </li>
  )
}

ShipToItem.propTypes = {
  id: PropTypes.number,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  onSelectItem: PropTypes.func,
  isFavorite: PropTypes.bool,
  guidId: PropTypes.string,
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
export default ShipToItem
