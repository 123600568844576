import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import isEmpty from 'lodash/isEmpty'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { dataDogBrowserConfig } = publicRuntimeConfig
export function initializeDataDog() {
  /*  ---------------------------
  /*  Configuration for Real User Monitoring
  /*  ---------------------------
  /* Lines below configure the Real User Monitoring
  /* Configuration here: https://docs.datadoghq.com/real_user_monitoring/
  /* To see dashboard https://app.datadoghq.com/rum/application/2143a80b-b7dd-436f-ae23-76bc09545738/overview/browser?from_ts=1626898645211&to_ts=1626985045211&live=true
  /*/
  const sampleRate = Number(publicRuntimeConfig.dataDogSampleRate) || 100
  datadogRum.init({
    debug: true,
    applicationId: dataDogBrowserConfig.applicationId,
    clientToken: dataDogBrowserConfig.clientToken,
    site: 'datadoghq.com',
    service: 'nextjs-eway-ui',
    env: publicRuntimeConfig.dataDogEnv,
    version: publicRuntimeConfig.appVersion,
    sampleRate,
    trackInteractions: true,
    silentMultipleInit: true,
    useCrossSiteSessionCookie: true,
  })

  /*  ---------------------------
  /*  Configuration for Logging
  /*  ---------------------------
  /*  Configures any errors that occur on the browser, allows also the ability to use a custom logger
  /*  Configuration here: https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
  /*  https://app.datadoghq.com/logs?query=&cols=host%2Cservice&index=
  */
  datadogLogs.init({
    debug: true,
    clientToken: dataDogBrowserConfig.clientToken,
    site: 'datadoghq.com',
    service: 'nextjs-eway-ui',
    env: publicRuntimeConfig.dataDogEnv,
    version: publicRuntimeConfig.appVersion,
    forwardErrorsToLogs: true,
    sampleRate,
    silentMultipleInit: true,
    useCrossSiteSessionCookie: true,
  })
}

export function setDataDogUser(userProps = {}) {
  const { logonName, email, punchout } = userProps
  if (isEmpty(userProps)) {
    datadogRum.setUser({
      anonymous: true,
    })
    return
  }

  const user = {
    id: logonName,
    ...(!!email && { email }),
    ...(!!punchout && { punchout }),
    anonymous: false,
  }
  datadogRum.setUser(user)
}

export function trackDataDogEvent(eventName, eventData) {
  datadogLogs.logger.info(eventName, eventData)
}
