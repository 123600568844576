export default {
  en: {
    tableColumns: [
      'Mark as Favourite',
      'Account#',
      'Customer name',
      'Cost center',
      'Address',
      'Care of',
      'Select Account',
    ],
    tableColumnsWithoutSelect: [
      'Mark as Favourite',
      'Account#',
      'Customer name',
      'Cost center',
      'Address',
      'Care of',
    ],
    selectAccount: 'Select',
    noTableData: 'No records found.',
    accountSelection:
      'Your ship to account selection has been successfully updated.',
    accountMarkedFavorite: 'Account is marked favourite.',
    accountMarkedUnFavorite: 'Account has been unfavoured.',
  },
  fr: {
    tableColumns: [
      'MARQUER COMME FAVORI',
      'NO COMPTE#',
      'Nom du client',
      'CENTRE DE COÛT',
      'ADRESSE',
      'AU SOINS DE',
      'SÉLECTIONNER',
    ],
    tableColumnsWithoutSelect: [
      'MARQUER COMME FAVORI',
      'NO COMPTE#',
      'Nom du client',
      'CENTRE DE COÛT',
      'ADRESSE',
      'AU SOINS DE',
    ],
    selectAccount: 'SÉLECTIONNER',
    noTableData: 'Aucun Enregistrement Trouvé.',
    accountSelection:
      'Votre sélection de compte a été mise à jour avec succès.',
    accountMarkedFavorite: 'Le compte est marqué comme favori.',
    accountMarkedUnFavorite: 'Le compte est marqué comme non favori.',
  },
}
