import PropTypes from 'prop-types'
import { useState } from 'react'
import Button from 'shared/Button'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import FormField from 'shared/FormControl/FormField'
import Text from 'shared/Text'
import classNames from 'classnames'
import SignInMessage from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/SignInMessage'
import { getUsernameReminder } from 'api/userAuthOperations'
import messages from './messages'
import styles from './UserIDReminder.module.scss'
import { MESSAGE_TYPE, EMAIL_ID } from '../signInConstants'
/**
 * Name: UserIDReminder
 * Desc: Render user id reminder form
 * @param {string} language
 * @param {bool} isShowBorderBottom
 */

const UserIDReminder = ({ language, isShowBorderBottom, isFullSizeButton }) => {
  const [userIdReminderMsg, setUserIdReminderMsg] = useState({
    type: null,
    message: '',
  })
  const initValue = {
    [EMAIL_ID]: {
      value: '',
      required: messages[language].requiredUserIdMsg,
    },
  }
  const {
    handleChange,
    handleSubmit,
    errors,
    values = {},
    dirties,
  } = useFormControlled(initValue, language, {
    loadWhen: true,
  })
  const formEventHandler = (event) => {
    handleChange(event)
  }
  const { SUCCESS, ERROR } = MESSAGE_TYPE

  const handleSubmitForm = async (event) => {
    const { isFormValid } = handleSubmit(event)
    if (isFormValid) {
      const { email = '' } = values
      const { sentTo = '', status } = await getUsernameReminder(language, email)
      if (status !== 404) {
        setUserIdReminderMsg({
          type: SUCCESS,
          message: messages[language].userIdIsSentMsg + sentTo,
        })
      } else {
        setUserIdReminderMsg({
          type: ERROR,
          message: messages[language].invalidAddress,
        })
      }
    }
  }

  const mainBlockStyle = classNames(styles.mainBlock, {
    [styles.borderBottom]: isShowBorderBottom,
  })

  return (
    <div className={mainBlockStyle}>
      {userIdReminderMsg.type ? (
        <div className={styles.content}>
          <SignInMessage
            text={userIdReminderMsg.message}
            type={userIdReminderMsg.type}
          />
        </div>
      ) : null}
      {userIdReminderMsg.type !== 'success' ? (
        <form onSubmit={handleSubmitForm} id="userIdReminder" noValidate>
          <div className={styles.padding}>
            <FormField
              label={messages[language].enterEmailAddressLabel}
              name={EMAIL_ID}
              id="userIdReminder"
              onChange={formEventHandler}
              showError={errors.email && dirties.email}
              errorMessage={errors.email}
              aria-label={messages[language].enterEmailAddressLabel}
              aria-required="true"
            />
          </div>
          <Button
            text={messages[language].submitLabel}
            isFullWidth={isFullSizeButton}
            variant="primary"
          />
          <div className={styles.content}>
            <div className={styles.forgotContentWrapper}>
              <Text text={messages[language].noteLabel} />
            </div>
          </div>
        </form>
      ) : null}
    </div>
  )
}

// Proptype validation
UserIDReminder.propTypes = {
  language: PropTypes.string.isRequired,
  isShowBorderBottom: PropTypes.bool,
  isFullSizeButton: PropTypes.bool,
}
UserIDReminder.defaultProps = {
  isShowBorderBottom: true,
  isFullSizeButton: true,
}

export default UserIDReminder
