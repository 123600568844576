import PropTypes from 'prop-types'
import Box from '../Box'
import { forwardRef } from 'react'

const Form = forwardRef(function Form(props, ref) {
  const { children, onSubmit } = props
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit?.(e)
  }

  return (
    <Box ref={ref} as="form" {...props} onSubmit={handleSubmit} noValidate>
      {children}
      <button type="submit" hidden />
    </Box>
  )
})

Form.displayName = 'Form'

Form.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
}
export default Form
