import billingFieldMessages from 'components/shared/ManageAddresses/SaveAddressForm/BillingAddressFields/messages'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import Column from 'shared/Column'
import Dialog from 'shared/Dialog'
import DialogConfirmation from 'shared/Dialog/DialogConfirmation'
import FormField from 'shared/FormControl/FormField'
import { ADDRESS_FORMS } from 'shared/ManageAddresses/SaveAddressForm/BillingAddressFields/BillingAddressFieldsConstants'
import Row from 'shared/Row'
import { ICON_NAMES } from 'shared/Utils/constants'
import { mapKeysForApi } from '~/api/apiMappingUtils'
import { updatePrimaryDeliveryAddress } from '~/api/buyer'
import {
  ADDRESS_GROUPS,
  DELIVERY_ADDRESS_LOCK_API_END_POINT,
  TEMPORARY_DELIVERY_ADDRESS_ID,
} from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import {
  saveAddress,
  updateAddress,
} from '~/components/containers/ManageAddressesContainer/ManageAddressesContainerUtils'
import Box from '~/components/shared/Box'
import Button from '~/components/shared/Button'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import { useToggler } from '~/components/shared/CustomHooks/useToggler'
import { dynamicTranslation } from '~/components/shared/DynamicTranslation/DynamicTranslationUtils'
import Form from '~/components/shared/Form'
import Stack from '~/components/shared/Stack'
import Text from '~/components/shared/Text'
import { ENGLISH } from '~/redux/constants'
import { removeWhiteSpace } from '~/services/utils'
import { getAddressActions } from '../ManageAddressesUtils'
import { BillingAddressFields } from './BillingAddressFields/BillingAddressFields'
import messages from './messages'
import styles from './SaveAddressForm.module.scss'
import {
  ADDRESS_VISIBILITY,
  DEFAULT_BUYER_COUNT,
  FIELDS,
  LOCK_FLAG_API_MAPPING,
  PRIMARY_ADDRESS_ID,
  ADDRESS_TYPES_VIEW_LEVEL,
} from './SaveAddressFormConstants'
import {
  filterAddressLockedFields,
  hasDuplicateAddress,
  getSaveAddressFormInitialFields,
} from './SaveAddressFormUtils'

/**
 *Name: SaveAddressForm
 *Desc: Render SaveAddressForm
 * @param {string} language
 */

const SaveAddressForm = ({
  language = ENGLISH,
  buyerId,
  clientNo,
  onClickCancel,
  onAfterSubmit,
  isDisabledSetPrimaryField = false,
  isCheckedSetPrimaryField = false,
  address = {},
  selectBuyersInfo = {},
  addresses = [],
  billingAddresses = [],
  canCreateSharedAddress,
  canCreateGlobalAddress,
  addressLevel,
  principalClientId,
  addressGroup,
  fieldLockKeyName = DELIVERY_ADDRESS_LOCK_API_END_POINT,
  showSetPrimaryAddress = true,
  showLockButton = true,
  showUseAsBillingField = false,
  canFetchBillingAddresses,
  canFetchDeliveryAddresses,
  billingAddressLevel,
  onSaveAddress = () => {},
  skipSaveAddressApiAction = false,
}) => {
  const { id, canEditAddress = false } = address
  const translations = messages[language]
  const {
    isOpen: isSaving,
    open: startSaving,
    close: stopSaving,
  } = useToggler()
  const isEditMode = !!id
  const [lockedFields, setLockedFields] = useState(() =>
    isEditMode
      ? filterAddressLockedFields(address[fieldLockKeyName])
      : {
          [FIELDS.COMPANY_NAME]: true,
          [FIELDS.ATTENTION]: true,
          [FIELDS.BUILDING]: true,
        }
  )

  function handleFieldLockToggle(name) {
    const value = !lockedFields[name]
    setLockedFields({ ...lockedFields, [name]: value })
  }

  const {
    isOpen: isWarningModalOpen,
    open: openWarningModal,
    close: closeWarningModal,
  } = useToggler()

  const canShowUseAsBillingField =
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS &&
    !isEditMode &&
    showUseAsBillingField &&
    canFetchBillingAddresses &&
    canFetchDeliveryAddresses &&
    billingAddressLevel > ADDRESS_TYPES_VIEW_LEVEL.SELF

  const { required: requiredLabel, invalidPostalCode } = translations
  const options = {
    translations: {
      required: requiredLabel,
      invalidPostalCode,
    },
    canShowUseAsBillingField,
    isCheckedSetPrimaryField,
  }
  const saveAddressFormInitialFields = getSaveAddressFormInitialFields(
    address,
    options
  )
  const {
    values = {},
    errors = {},
    dirties = {},
    changed = {},
    required = {},
    handleChange,
    handleSubmit,
    triggerChange,
    setValues,
  } = useFormControlled(saveAddressFormInitialFields, language)

  function handleChangeUseAsBilling(event) {
    const { target: { name, checked } = {} } = event
    triggerChange(name, checked)
  }

  const {
    selectedBuyerCount = 0,
    checkedBuyers = [],
    openSelectedBuyerModal,
  } = selectBuyersInfo

  function handleManageAddressChange(e) {
    const { target: { name, value } = {} } = e
    setValues({
      [name]: value,
      [FIELDS.USE_AS_BILLING]: Number(value) === ADDRESS_VISIBILITY.PRIVATE,
    })
    // handleChange(e)
  }

  const canCheckDuplicateOnBillingAddress =
    canShowUseAsBillingField &&
    values[FIELDS.USE_AS_BILLING] &&
    billingAddresses?.length &&
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS

  const duplicateAddressRef = useRef({
    hasAddressDuplicate: false,
    hasDuplicateBillingAddress: false,
  })
  async function handleSaveAddress(e) {
    const { isFormValid } = handleSubmit(e)
    const { hasAddressDuplicate, hasDuplicateBillingAddress } =
      hasDuplicateAddress({
        addresses,
        billingAddresses,
        savingAddress: values,
        editAddress: address,
        canCheckDuplicateOnBillingAddress,
      })
    duplicateAddressRef.current = {
      hasAddressDuplicate,
      hasDuplicateBillingAddress,
    }
    if (isFormValid) {
      if (hasAddressDuplicate || hasDuplicateBillingAddress) {
        openWarningModal()
      } else {
        handleManageSaveAddressSubmit()
      }
    }
  }

  let shortDescMessage = ''
  const { hasAddressDuplicate = false, hasDuplicateBillingAddress = false } =
    duplicateAddressRef?.current
  if (canCheckDuplicateOnBillingAddress) {
    if (hasAddressDuplicate && !hasDuplicateBillingAddress) {
      shortDescMessage = translations.matchExistingDeliveryAddressMsg
    } else if (!hasAddressDuplicate && hasDuplicateBillingAddress) {
      shortDescMessage = translations.matchExistingBillingAddressMsg
    } else if (hasAddressDuplicate && hasDuplicateBillingAddress) {
      shortDescMessage = translations.matchExistingDeliveryBillingMsg
    }
  } else if (!canCheckDuplicateOnBillingAddress) {
    shortDescMessage =
      addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS
        ? translations.warningShortDescriptionDeliveryAddress
        : translations.warningShortDescriptionBillingAddress
  }

  async function handleWarning() {
    handleManageSaveAddressSubmit()
  }

  async function handleManageSaveAddressSubmit() {
    const savingAddress = {
      ...omit(values, FIELDS.IS_DEFAULT, FIELDS.USE_AS_BILLING),
      ...{ principalClientId },
    }
    const lockedFieldsPayload = mapKeysForApi({
      apiMapping: LOCK_FLAG_API_MAPPING,
      payload: lockedFields,
    })

    startSaving()
    const lockedFieldsInfo = showLockButton
      ? {
          [fieldLockKeyName]: lockedFieldsPayload,
        }
      : {}

    // if fields is changed from the initial state
    const isDefaultValue = !!values[FIELDS.IS_DEFAULT]
    savingAddress
    // SCE-6428 : When user does not have permission to add address
    // and no address is available to select then
    // skip calling save/update api and store in locate state
    if (!skipSaveAddressApiAction) {
      // if there is an id, update the address, if there isn't create a one
      const { isSuccess, address: savedAddress = {} } = id
        ? await updateAddress(
            {
              ...savingAddress,
              id,
            },
            checkedBuyers,
            lockedFieldsPayload,
            addressGroup
          )
        : await saveAddress(
            {
              buyerId,
              clientNo,
              ...savingAddress,
              ...lockedFieldsInfo,
            },
            checkedBuyers,
            addressGroup,
            values[FIELDS.USE_AS_BILLING]
          )

      stopSaving()
      const selectedBuyersList = checkedBuyers.map((itm) => {
        return { buyerId: itm }
      })
      const {
        visibility,
        buyerId: addressBuyerId,
        shippingContact = '',
        shippingAddress2 = '',
      } = savedAddress

      const mainActions = getAddressActions({
        addressLevel,
        addressBuyerId,
        buyerId,
        visibility,
        addressGroup,
      })
      const isDeliveryAddress = addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS
      if (changed[FIELDS.IS_DEFAULT] || isCheckedSetPrimaryField) {
        const addressId = isEditMode ? id : savedAddress.id
        const finalValue = isDefaultValue ? addressId : ''
        const key = isDeliveryAddress
          ? PRIMARY_ADDRESS_ID.DELIVERY
          : PRIMARY_ADDRESS_ID.BILLING
        await updatePrimaryDeliveryAddress(language, buyerId, finalValue, key)
      }

      const hasBillingValues = values[FIELDS.USE_AS_BILLING]
      if (!isEditMode && hasBillingValues && isDeliveryAddress) {
        onSaveAddress({
          isCheckedUseAsBilling: values[FIELDS.USE_AS_BILLING],
          savedAddress,
          billingAddresses,
          selectedBuyersList,
        })
      }

      const savedUpdatedAddress = isDeliveryAddress
        ? {
            ...savedAddress,
            finalDefaultBillingAddressId: '',
            assignedBillingIds: [],
            hasAssignment: false,
          }
        : savedAddress

      onAfterSubmit?.(
        isSuccess,
        {
          ...savedUpdatedAddress,
          ...mainActions,
          isDefault: isDefaultValue,
          selectedBuyersList,
          shippingContact,
          shippingAddress2,
          ...{ [fieldLockKeyName]: lockedFieldsPayload },
          addressGroup,
        },
        id
      )
    } else {
      const mainActions = getAddressActions({
        addressLevel,
        addressBuyerId: buyerId,
        buyerId,
        visibility: null,
        addressGroup,
      })
      onAfterSubmit?.(
        true,
        {
          ...savingAddress,
          ...mainActions,
          isDefault: false,
          selectedBuyersList: [],
          addressGroup,
          id: TEMPORARY_DELIVERY_ADDRESS_ID,
        },
        id
      )
    }
  }

  function getAddressTypeProps(addressTypeField) {
    return {
      id: `addressType${addressTypeField}`,
      name: FIELDS.ADDRESS_TYPE,
      label: translations[addressTypeField],
      value: addressTypeField,
      checked: parseInt(values.visibility) === addressTypeField,
      onChange: handleManageAddressChange,
      fieldLabel: null,
      type: 'radio',
      labelSize: 'labelMd',
      disabled: isSaving,
    }
  }
  const isSharedOrGlobalAddress =
    values[FIELDS.ADDRESS_TYPE] >= ADDRESS_VISIBILITY.SHARED

  const showLockIcon = isEditMode
    ? showLockButton && canEditAddress && isSharedOrGlobalAddress
    : showLockButton && isSharedOrGlobalAddress

  function getAddressLockFieldProps(name) {
    const { LOCK_ICON, UNLOCK_ICON } = ICON_NAMES
    const icon = lockedFields[name] ? LOCK_ICON : UNLOCK_ICON
    const text = lockedFields[name]
      ? translations.lockIconToolTip
      : translations.unLockIconToolTip
    return {
      onClick: handleFieldLockToggle,
      name,
      icon,
      text,
    }
  }

  function handleProvinceChange(value) {
    triggerChange(FIELDS.PROVINCE, value.toUpperCase())
  }

  function handleChangeIsPrimary(event) {
    const { target: { name, checked } = {} } = event
    triggerChange(name, checked)
  }

  const handleBlurPostalCode = ({ target }) => {
    const { value, name } = target
    const trimmedPostalCode = removeWhiteSpace(value)
    triggerChange(name, trimmedPostalCode)
  }

  const isCheckedIsPrimary =
    values[FIELDS.IS_DEFAULT] || isCheckedSetPrimaryField

  const showPostalCodeError =
    !!errors[FIELDS.POSTAL_CODE] && !!dirties[FIELDS.POSTAL_CODE]

  const showSelectBuyersButton =
    Number(values.visibility) === ADDRESS_VISIBILITY.SHARED

  const showAddressTypeFields = canCreateSharedAddress
  const showEditSelectionButton = !!checkedBuyers.length

  const selectedBuyerMsg =
    selectedBuyerCount > DEFAULT_BUYER_COUNT
      ? translations.selectedBuyers
      : translations.selectedBuyer

  const selectedBuyersMessage = showEditSelectionButton
    ? dynamicTranslation(selectedBuyerMsg, {
        '{NUMBER}': selectedBuyerCount,
      })
    : ''

  function handleCloseWarningModal() {
    closeWarningModal()
  }

  const primaryAddressLabel = billingFieldMessages[language][FIELDS.IS_DEFAULT]

  const useAsBillingLabel =
    billingFieldMessages[language][FIELDS.USE_AS_BILLING]

  const disabledPrimary = isDisabledSetPrimaryField || isSaving
  return (
    <Box pt="20px" pr="15px" pb="20px" pl="15px">
      <Form
        onSubmit={handleSaveAddress}
        id={`save-address-form`}
        data-test="save-address-form"
      >
        <BillingAddressFields
          disabled={isSaving}
          showLockIcons={showLockIcon}
          getAddressLockFieldProps={getAddressLockFieldProps}
          language={language}
          onProvinceChange={handleProvinceChange}
          onBlurPostalCode={handleBlurPostalCode}
          onChange={handleChange}
          translations={translations}
          showPostalCodeError={showPostalCodeError}
          required={required}
          values={values}
          dirties={dirties}
          errors={errors}
          id={ADDRESS_FORMS.SAVE_ADDRESS}
        />
        <Row>
          <Column variant="xsMb20">
            <Box mt="10px" mb="5px" pl="0px" className={styles.defaultAddress}>
              {showSetPrimaryAddress ? (
                <FormField
                  value={values[FIELDS.IS_DEFAULT] || ''}
                  name={FIELDS.IS_DEFAULT}
                  id={FIELDS.IS_DEFAULT}
                  notAllowed={disabledPrimary}
                  disabled={disabledPrimary}
                  aria-label={primaryAddressLabel}
                  aria-required={required[FIELDS.IS_DEFAULT]}
                  label={primaryAddressLabel}
                  onChange={handleChangeIsPrimary}
                  type="checkbox"
                  data-test="save-address-form-default-address"
                  checked={isCheckedIsPrimary}
                />
              ) : null}
            </Box>
          </Column>
        </Row>
        {canShowUseAsBillingField ? (
          <Row>
            <Column variant="xsMb20">
              <Box
                mt="10px"
                mb="5px"
                pl="0px"
                className={styles.defaultAddress}
              >
                <FormField
                  value={values[FIELDS.USE_AS_BILLING] || ''}
                  name={FIELDS.USE_AS_BILLING}
                  id={FIELDS.USE_AS_BILLING}
                  aria-label={useAsBillingLabel}
                  aria-required={required[FIELDS.USE_AS_BILLING]}
                  label={useAsBillingLabel}
                  onChange={handleChangeUseAsBilling}
                  type="checkbox"
                  data-test="save-address-form-use-as-billing"
                  checked={values[FIELDS.USE_AS_BILLING]}
                />
              </Box>
            </Column>
          </Row>
        ) : null}
        {showAddressTypeFields ? (
          <Row>
            <Column variant="xsMb20">
              <Box fontWeight="bold" data-test="address-type">
                {translations.addressTypeLabel}
              </Box>
              <Stack mt="10px" mb="5px" pl="0px">
                <Box pr="15px">
                  <FormField
                    {...getAddressTypeProps(ADDRESS_VISIBILITY.PRIVATE)}
                    data-test="save-address-form-private-address"
                  />
                  <Box pt="5px" pb="5px">
                    <Text
                      text={translations.privateDescription}
                      variant="smText"
                      color="secondary"
                      dataTest="private-address-description"
                    />
                  </Box>
                </Box>
                {canCreateSharedAddress ? (
                  <Box pr="15px">
                    <FormField
                      {...getAddressTypeProps(ADDRESS_VISIBILITY.SHARED)}
                      data-test="save-address-form-shared-address"
                    />
                    <Box pt="5px" pb="5px">
                      <Text
                        text={translations.sharedDescription}
                        variant="smText"
                        color="secondary"
                        dataTest="shared-address-description"
                      />
                    </Box>
                  </Box>
                ) : null}
                {canCreateGlobalAddress ? (
                  <Box pr="15px">
                    <FormField
                      {...getAddressTypeProps(ADDRESS_VISIBILITY.GLOBAL)}
                      data-test="save-address-form-global-address"
                    />
                    <Box pt="5px" pb="5px">
                      <Text
                        text={translations.globalDescription}
                        variant="smText"
                        color="secondary"
                        dataTest="global-address-description"
                      />
                    </Box>
                  </Box>
                ) : null}
              </Stack>
            </Column>
          </Row>
        ) : null}
        <Row mt="10px" className={styles.customRow}>
          {showSelectBuyersButton ? (
            <Column>
              <Box className={styles.selectBuyer}>
                {showEditSelectionButton ? (
                  <>
                    <Button
                      data-test="save-address-form-edit-buyers-button"
                      onClick={openSelectedBuyerModal}
                      disabled={isSaving}
                      type="button"
                      text={translations.editSelection}
                      variant="outline-primary"
                    />
                    <Box
                      as="span"
                      pl="10px"
                      className={styles.buyerMsg}
                      data-test="selected-buyer"
                    >
                      {selectedBuyersMessage}
                    </Box>
                  </>
                ) : (
                  <Button
                    data-test="save-address-form-select-buyers-button"
                    onClick={openSelectedBuyerModal}
                    disabled={isSaving}
                    type="button"
                    text={translations.selectBuyers}
                    variant="primary"
                  />
                )}
              </Box>
            </Column>
          ) : null}
        </Row>
      </Form>
      <Column>
        <Stack
          spacing="15px"
          justify="flex-end"
          className={styles.saveButtonSection}
        >
          <Button
            data-test="save-address-form-cancel-button"
            text={translations.cancel}
            onClick={onClickCancel}
            disabled={isSaving}
            variant="link"
            type="button"
            className={styles.cancelBtn}
            textDecoration={true}
          />
          <Button
            data-test="save-address-form-save-button"
            onClick={handleSaveAddress}
            loadingText={translations.savingText}
            isLoading={isSaving}
            type="submit"
            text={translations.save}
            variant="primary"
          />
        </Stack>
      </Column>
      <Dialog
        id="warning-address-modal"
        open={isWarningModalOpen}
        onHide={handleCloseWarningModal}
        variant="md"
        data-test="warning-address-modal"
        role="dialog"
        aria-label={messages[language].warning}
      >
        <DialogConfirmation
          onCancel={handleCloseWarningModal}
          onConfirm={handleWarning}
          headingText={messages[language].warning}
          descriptionShort={shortDescMessage}
          descriptionLong={messages[language].warningLongDescription}
          confirmationBtnText={messages[language].proceed}
          cancelText={messages[language].cancel}
          headingType="h3"
        />
      </Dialog>
    </Box>
  )
}

SaveAddressForm.propTypes = {
  buyerId: PropTypes.string,
  clientNo: PropTypes.number,
  isCheckedSetPrimaryField: PropTypes.bool,
  isDisabledSetPrimaryField: PropTypes.bool,
  language: PropTypes.string,
  onClickCancel: PropTypes.func,
  onAfterSubmit: PropTypes.func,
  address: PropTypes.object,
  selectBuyersInfo: PropTypes.object,
  addresses: PropTypes.array,
  canCreateSharedAddress: PropTypes.bool,
  canCreateGlobalAddress: PropTypes.bool,
  showSetPrimaryAddress: PropTypes.bool,
  addressLevel: PropTypes.number,
  principalClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addressGroup: PropTypes.string,
  fieldLockKeyName: PropTypes.string,
  showLockButton: PropTypes.bool,
  showUseAsBillingField: PropTypes.bool,
  canFetchBillingAddresses: PropTypes.bool,
  canFetchDeliveryAddresses: PropTypes.bool,
  billingAddressLevel: PropTypes.number,
  billingAddresses: PropTypes.array,
  onSaveAddress: PropTypes.func,
  skipSaveAddressApiAction: PropTypes.bool,
}

// PropType validation

export default SaveAddressForm
