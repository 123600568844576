import { get, update } from '~/api/buyer/buyerOperations'
import { logErrorInTestAndDev } from '~/services/logger'
import { SUCCESS_FALSE } from '../apiHelperConstants'
export async function updatePrimaryDeliveryAddress(
  language,
  buyerId,
  value,
  key
) {
  const payload = {
    reqParams: [
      {
        op: 'add',
        path: key,
        value,
      },
    ],
  }
  return await update(language, buyerId, payload)
}
export const getBuyer = async (id) => {
  if (!id) {
    logErrorInTestAndDev('Missing id cannot get buyer')
    return SUCCESS_FALSE
  }
  return await get(id)
}
