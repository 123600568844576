export default {
  en: {
    continueBtn: 'Continue',
    postalCodeDetailsMsg:
      ' To ensure that we provide you with the most accurate and up-to-date product availability, please enter the postal code for the shipping address that your next order will be delivered to.',
    postalCodeHelp:
      'Need help finding your postal code ? {{CLICK_HERE}} for postal code look-up',
    postalCodePlaceholder: 'Enter your postal code here',
    clickHere: 'Click here',
    postalCodeLookUpLink:
      'https://www.canadapost.ca/info/mc/personal/postalcode/fpc.jsf',
    province: 'Province',
    requiredMessage: 'This field is mandatory.',
    invalidPostalCode:
      'The postal code entered is invalid.  Please ensure the postal code is entered using one of the following formats:   A1A1A1 OR A1A 1A1 (where A is a letter and 1 is a digit).',
    close: 'Close',
  },
  fr: {
    continueBtn: 'Continuer',
    postalCodeDetailsMsg:
      "Pour nous assurer que nous vous fournissons la disponibilité des produits la plus précise et la plus à jour, veuillez entrer le code postal de l'adresse de livraison à laquelle votre prochaine commande sera livrée.",
    postalCodeHelp:
      "Besoin d'aide pour trouver votre code postal? {{CLICK_HERE}} pour la recherche de code postal.",
    postalCodePlaceholder: 'Entrez votre code postal ici.',
    clickHere: 'Cliquez ici',
    postalCodeLookUpLink:
      'https://www.canadapost.ca/info/cg/personnel/codepostal/trouvercodepostal.jsf ',
    province: 'Province',
    requiredMessage: 'Veuillez entrer une valeur.',
    invalidPostalCode:
      "Le code postal saisi n'est pas valide. Veuillez-vous assurer que le code postal est entré en utilisant l'un des formats suivants : A1A1A1 OU A1A 1A1 (où A est une lettre et 1 est un chiffre).",
    close: 'Fermer',
  },
}
