export const getUrlSearchParams = () => {
  if (process.browser && 'URLSearchParams' in window) {
    return new URLSearchParams(decodeURI(window.location.search))
  }
  return null
}

/**
 * Name: handleUrlChangeEvent
 * Desc: Detect url query param change event
 * @param {function} handlePopState
 */
export const handleUrlChangeEvent = (handlePopState) => {
  window.onpopstate = handlePopState
}
