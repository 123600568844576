export const FIELDS = {
  STREET_ADDRESS: 'streetAddress',
  COMPANY_NAME: 'companyName',
  CITY: 'city',
  PROVINCE: 'province',
  POSTAL_CODE: 'postalCode',
  BUILDING: 'building',
  ATTENTION: 'attention',
  DELIVERY_INSTRUCTION: 'DeliveryInstruction',
}

export const DELIVERY_ADDRESS_MAPPING = {
  [FIELDS.POSTAL_CODE]: 'postalCode',
  [FIELDS.COMPANY_NAME]: 'ShippingName',
  [FIELDS.ATTENTION]: 'ShippingContact',
  [FIELDS.STREET_ADDRESS]: 'ShippingAddress1',
  [FIELDS.BUILDING]: 'ShippingAddress2',
  [FIELDS.CITY]: 'City',
  [FIELDS.PROVINCE]: 'province',
  [FIELDS.DELIVERY_INSTRUCTION]: 'DeliveryInstruction',
}

export const BILLING_ADDRESS_MAPPING = {
  [FIELDS.POSTAL_CODE]: 'billingPostalCode',
  [FIELDS.COMPANY_NAME]: 'billingName',
  [FIELDS.ATTENTION]: 'billingContact',
  [FIELDS.STREET_ADDRESS]: 'billingAddress1',
  [FIELDS.BUILDING]: 'billingAddress2',
  [FIELDS.CITY]: 'billingCity',
  [FIELDS.PROVINCE]: 'billingProvince',
}

export const POPULATED_FIELDS_TO_BE_READ_ONLY = [
  FIELDS.STREET_ADDRESS,
  FIELDS.CITY,
  FIELDS.POSTAL_CODE,
  FIELDS.PROVINCE,
]

export const MODIFY_SHIPPING_ADDRESS_TYPE = {
  ENABLE_ALL: 0, //Can Modify everything
  DISABLE_ALL: 1, //Can modify nothing
  EXCEPT_ATTENTION: 2, //Can modify everything except Attention / Care Of
  EXCEPT_BUILDING: 3, //Can modify everything except address
  EXCEPT_CO_AND_BUILDING: 4, //Can modify everything except CareOf and Address 2
  ALLOW_All_EXCEPT_ATTENTION: 5, //Can only modify Care Of
}
export const OVERRIDE_BUYER = 'overrideBuyer'
export const OVERRIDE_READ_ONLY_TYPE = 'readOnly'
export const NO = 'no'
export const OVERRIDE_WHEN_BLANK = 'overrideWhenBlank'
export const NO_API = 0
export const OVERRIDE_BUYER_API = 1
export const OVERRIDE_WHEN_BLANK_API = 2
export const OVERRIDE_TYPE = {
  [NO_API]: NO,
  [OVERRIDE_BUYER_API]: OVERRIDE_BUYER,
  [OVERRIDE_WHEN_BLANK_API]: OVERRIDE_WHEN_BLANK,
}
