import PropTypes from 'prop-types'
import { useMemo } from 'react'
import {
  SupportMenuItem,
  SupportMenuItemContainer,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SupportMenuItem'
import MenuListItem from '~/components/shared/MenuList/MenuListItem'
import { ENGLISH } from '~/redux/constants'
import styles from './HelpMenu.module.scss'
import { getHelpMenus } from './HelpMenuUtils'
import messages from './messages'
import { HELP_MENU } from '../SupportMenuConstants'

/**
 * Name: HelpMenu
 * Desc: Render help menu
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {object} settings
 */

const HelpMenu = ({ language, settings }) => {
  const { showMenuHelpCenter } = settings
  const helpItems = useMemo(() => {
    return getHelpMenus(language, false, showMenuHelpCenter)
  }, [language, showMenuHelpCenter])

  return (
    <SupportMenuItemContainer menuName={HELP_MENU} enableMouseOut>
      <SupportMenuItem
        menuName={HELP_MENU}
        label={messages[language].helpLabel}
        icon="connect"
        color="black"
      />

      <div className={styles.helpList}>
        <ul>
          {helpItems.map((item) => {
            const url = item.link
            return (
              <MenuListItem
                key={item.linkText}
                href={url}
                text={item.linkText}
                data-test={item.dataTest}
              />
            )
          })}
        </ul>
      </div>
    </SupportMenuItemContainer>
  )
}

// Default Props
HelpMenu.defaultProps = {
  settings: {},
  language: ENGLISH,
}

// Props Validation
HelpMenu.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.object,
}

export default HelpMenu
