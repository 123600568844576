import { useCallback, useState } from 'react'
import useIsMounted from './useIsMounted'

const useStateCustom = (initState = undefined) => {
  const isMounted = useIsMounted()
  const [state, setState] = useState(initState)
  function stateUpdater(newState, mergeLastState = true) {
    if (isMounted) {
      if (isArrayOrFunction(newState)) {
        setState(newState)
      } else {
        setState((lastState) => {
          if (!mergeLastState) {
            return { ...newState }
          }
          return {
            ...lastState,
            ...newState,
          }
        })
      }
    }
  }
  const setStateFn = useCallback(stateUpdater, [isMounted])

  return [state, setStateFn]
}

export default useStateCustom

function isArrayOrFunction(newState) {
  return Array.isArray(newState) || typeof newState === 'function'
}
