import classNames from 'classnames'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useReducer, useRef } from 'react'
import { saveSearchOption } from 'services/searchServices'
import { isEscPress } from 'services/utils'
import useOutsideClickHandler from 'shared/CustomHooks/useOutsideClickHandler'
import TextInput from 'shared/FormControl/TextInput'
import { InputGroup } from 'shared/InputGroup'
import { getSearchParamsFromRouter } from '~/components/containers/BrowseProducts/BrowseProductsUtils'
import { ENGLISH } from '~/redux/constants'
import predictiveSearchService from '~/services/predictiveSearchService'
import { pressDown, pressUp } from '~/services/utils/keyboardUtils'
import AutoSuggestionList from './AutoSuggestionList'
import messages from './messages'
import styles from './Search.module.scss'
import {
  ALL_PRODUCTS_OFFER,
  ALL_PRODUCTS_TYPE,
  STANDARD_OFFER_PRODUCTS,
  STANDING_OFFER_PRODUCTS_TYPE,
} from './SearchConstants'
import {
  getDefaultPwgs,
  getSearchUrl,
  getSearchUrlWithCallingLocation,
} from './SearchUtils'

/**
 * Name: Search
 * Decs: Render header search section
 * @param {string} language
 * @param {bool} enablePwgscheckout
 * @param {object} router
 */
const Search = ({
  language,
  enablePwgscheckout,
  buyer = {},
  value = '',
  onKeyDown,
  showPwgsOptions,
}) => {
  const router = useRouter()
  const { buyerId, searchResultType = STANDING_OFFER_PRODUCTS_TYPE } = buyer
  const timeout = useRef()
  const {
    browsProdQuery: query,
    preSearchFilter,
    searchType: { isCategorySearch, isShoppingList, isPromoSearch },
  } = getSearchParamsFromRouter(router)
  const optionValue = preSearchFilter
    ? getDefaultPwgs(preSearchFilter)
    : searchResultType

  const isSearchType = isCategorySearch || isShoppingList || isPromoSearch
  const [state, setState] = useReducer(reducer, {
    autoSuggestIndex: 0,
    suggestions: [],
    searchInputValue: isSearchType ? '' : value,
    pwgsOption: enablePwgscheckout ? optionValue : null,
    showAutoSuggestion: false,
  })
  const {
    pwgsOption,
    searchInputValue,
    autoSuggestIndex,
    suggestions: autoSuggestResults,
  } = state
  const hasSuggestions = !!autoSuggestResults.length

  const searchRef = useRef(null)
  useOutsideClickHandler(searchRef, () => {
    setState({
      autoSuggestIndex: 0,
      showAutoSuggestion: false,
    })
  })

  async function loadSuggestions(inputValue) {
    const trimmedInput = inputValue.trim().toLowerCase()
    if (!trimmedInput) {
      setState({
        showAutoSuggestion: false,
        suggestions: [],
      })
      return
    }
    const words = await predictiveSearchService(trimmedInput, language)
    setState({
      showAutoSuggestion: true,
      suggestions: words,
    })
  }

  const handleOnSubmitOrClick = (e, item) => {
    e.preventDefault()
    setState({
      showAutoSuggestion: false,
    })
    if (item) {
      setState({
        searchInputValue: item,
      })
      const finalUrl = getSearchUrlWithCallingLocation(item, pwgsOption)
      window.location.assign(finalUrl)
    }
  }

  const handleIconClick = () => {
    if (searchInputValue) {
      setState({
        showAutoSuggestion: false,
      })
    }
    const finalUrl = getSearchUrlWithCallingLocation(
      searchInputValue,
      pwgsOption
    )
    window.location.assign(finalUrl)
  }

  const handleKeypress = (e) => {
    if (isEscPress(e)) {
      setState({ searchInputValue: '' })
    }
    onKeyDown?.(e)
    const { autoSuggestIndex, suggestions: autoSuggestResults } = state
    if (autoSuggestResults.length) {
      const greaterThanMaxSize =
        autoSuggestIndex >= autoSuggestResults.length - 1
      const smallerThanMinSize = autoSuggestIndex <= 0

      if (pressDown(e) && !greaterThanMaxSize) {
        incrementIndex()
      }

      if (pressUp(e) && !smallerThanMinSize) {
        decrementIndex()
      }
    }
  }

  const incrementIndex = () => {
    const index = autoSuggestIndex + 1
    if (hasSuggestions) {
      setState({
        autoSuggestIndex: index,
        searchInputValue: autoSuggestResults[index],
      })
    }
  }

  const decrementIndex = () => {
    const index = autoSuggestIndex - 1
    if (hasSuggestions) {
      setState({
        autoSuggestIndex: index,
        searchInputValue: autoSuggestResults[index],
      })
    }
  }

  const handleOnChange = ({ target }) => {
    const searchInputValue = target.value
    setState({
      showAutoSuggestion: hasSuggestions,
      searchInputValue,
    })
    delay(() => loadSuggestions(searchInputValue), 500)
  }

  const delay = (callback, timing) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      callback()
    }, timing)
  }

  const onMouseEnter = (index) => {
    if (index !== undefined) {
      setState({
        autoSuggestIndex: index,
        searchInputValue: autoSuggestResults[index],
      })
    }
  }

  const i18n = messages[language]
  const placeholder = showPwgsOptions ? i18n.pwgsPlaceholder : i18n.placeholder

  const handleOptionChange = async (e) => {
    const pwgsOption = e.target.value

    const { isSuccess } = await saveSearchOption(pwgsOption, buyerId)
    if (isSuccess) {
      setState({ pwgsOption })
      const finalUrl = getSearchUrl(query, pwgsOption, isCategorySearch)
      window.location.assign(finalUrl)
    }
  }
  return (
    <>
      <form
        onSubmit={handleOnSubmitOrClick}
        className={styles.searchFormStyling}
        ref={searchRef}
        id="searchForm"
      >
        {showPwgsOptions ? (
          <>
            <div className={`${styles.inputWrapper} ${styles.selectWrapper}`}>
              <InputGroup
                type="radio"
                tabIndex="0"
                className={styles.searchRadioButton}
                name="pwgsCategory"
                label={messages[language].allProd}
                id={ALL_PRODUCTS_OFFER}
                data-test="allProducts"
                onChange={handleOptionChange}
                value={ALL_PRODUCTS_TYPE}
                defaultChecked={state.pwgsOption === ALL_PRODUCTS_TYPE}
              />
            </div>
            <div className={`${styles.inputWrapper} ${styles.selectWrapper}`}>
              <InputGroup
                type="radio"
                tabIndex="0"
                className={styles.searchRadioButton}
                name="pwgsCategory"
                label={messages[language].standoffProd}
                id={STANDARD_OFFER_PRODUCTS}
                data-test="standardOfferProducts"
                onChange={handleOptionChange}
                value={STANDING_OFFER_PRODUCTS_TYPE}
                defaultChecked={
                  state.pwgsOption === STANDING_OFFER_PRODUCTS_TYPE
                }
              />
            </div>
          </>
        ) : null}
        <div
          className={classNames(
            styles.inputWrapper,
            styles.searchWrapper,
            styles.searchWrapperXs
          )}
        >
          <TextInput
            autoComplete="off"
            placeholder={placeholder}
            type="text"
            name="searchTxt"
            id="searchTxt"
            icon="search"
            iconDataTest="searchIcon"
            value={searchInputValue}
            onChange={handleOnChange} // typing
            onKeyDown={handleKeypress} // arrows ⬆️ ⬇️
            onIconClick={handleIconClick}
            data-test="searchTxt"
            buttonAriaLabel={messages[language].search}
          />

          {hasSuggestions &&
            !!searchInputValue.length &&
            state.showAutoSuggestion && (
              <AutoSuggestionList
                currentIndex={state.autoSuggestIndex}
                onItemClick={handleOnSubmitOrClick}
                suggestions={state.suggestions}
                onMouseEnter={onMouseEnter}
              />
            )}
        </div>
      </form>
    </>
  )
}

// Default Props
Search.defaultProps = {
  language: ENGLISH,
}
// Props Validation
Search.propTypes = {
  language: PropTypes.string,
  enablePwgscheckout: PropTypes.bool,
  showPwgsOptions: PropTypes.bool,
  onKeyDown: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buyer: PropTypes.object,
}

export default Search

function reducer(state, newState) {
  return { ...state, ...newState }
}
