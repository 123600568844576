import * as api from 'api'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { getHostUrl } from 'services/utils/configUtils'
import { useLoader } from 'shared/CustomHooks/useLoader'
import { BROWSE_PRODUCTS_KEYWORD_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'
import { getUrl, isHostedInOldEway } from '~/components/shared/EwayRouter'
import messages from './messages'
import { fetchPublicationAndBrandsAndDeals } from './NavBarApiUtils'
import { formatCategories } from './NavBarDesktop/NavBarDesktopUtils'
const HOST_URL = getHostUrl()

export function usePublicationAndBrandsMenusAndDeals({
  language,
  enablePublication = true,
}) {
  const { setPending, setResolved, setError, isLoading, isResolved, isError } =
    useLoader()
  const [menus, setMenus] = useState({
    publications: [],
    brands: [],
    deals: [],
  })

  useEffect(() => {
    async function initFetchMenus() {
      setPending()
      const { publications, brands, deals, isSuccess } =
        await fetchPublicationAndBrandsAndDeals(language)
      if (isSuccess) {
        setMenus({
          publications: enablePublication ? publications : [],
          brands,
          deals,
        })
        setResolved()
      } else {
        setError('error')
      }
    }
    initFetchMenus()
  }, [setPending, setResolved, setError, enablePublication, language])

  return {
    ...menus,
    isLoading,
    isResolved,
    isError,
  }
}

/**
 * Name: getTopBarListItems
 * Desc: This function is used for render the become a customer and flyers & Publication menus
 * @param {string} language
 * @param {bool} isLoggedIn
 */
export const getTopBarListItems = (language, isLoggedIn) => {
  const topNavBarListItems = isLoggedIn
    ? []
    : [
        {
          id: 2,
          linkText: messages[language].becameCustomer,
          link: messages[language].becameCustomerUrl,
          target: '_blank',
        },
      ]

  return topNavBarListItems
}

/**
 * Name: navBarService
 * Desc: business logics for navBar functionality
 */

/**
 * Name: fetchCategoryMenu
 * Desc: Fetch the category menu list from api
 * @param {*} language
 * @param {*} hideRestrictions
 * @param {*} searchFilter
 * @param {function} setMenuListFn
 */
export async function fetchCategoryMenu(
  language,
  hideRestrictions,
  searchFilter = ''
) {
  const category =
    (await api.getCategoryMenu(language, hideRestrictions, searchFilter)) || {}
  if (isEmpty(category)) {
    return []
  }
  const { Dimensions = [] } = category
  const menuList = Dimensions ? filterCategories(Dimensions) : []
  const formattedMenuList = formatCategories(menuList)
  return formattedMenuList
}

/**
 * Name: filterCategories
 * Desc: filter the api data and customize json according to menu display on Header
 * @param {*} data
 */

const filterCategories = (data = []) => {
  // Products menu
  const products = {
    level: 'root',
    Id: 'productsLabel',
    Name: 'Products',
    isBrowseCallingLocation: true, // for analytics
    Dimensions: data
      .filter((items) => !isNaN(items.Url))
      .map((item = {}) => {
        const { Dimensions = [] } = item
        return {
          ...item,
          level: '1',
          Url: getItemUrl(item, '1'),
          isBrowseCallingLocation: true, // for analytics
          Dimensions: Dimensions.map((subItem = {}) => {
            const { Dimensions = [] } = subItem
            return {
              ...subItem,
              level: '2',
              Url: getItemUrl(subItem),
              Dimensions: Dimensions?.map((subSubItem) => {
                return {
                  ...subSubItem,
                  level: '3',
                  Url: getItemUrl(subSubItem),
                }
              }),
            }
          }),
        }
      }),
  }
  // services and solutions menu
  const servicesSolutions = {
    level: 'root',
    Id: 'servicesSolutionLabel',
    Name: 'Services & Solutions',
    Dimensions: data
      .filter((items) => isNaN(items.Url))
      .map((item = {}) => {
        const { Dimensions = [] } = item
        const target =
          item.Name.search('VendorIntegrations') === -1 &&
          item.Name.search('Landing') === -1
            ? '_blank'
            : '_self'
        return {
          ...item,
          level: '1',
          target,
          Url: getItemUrl(item, '1'),
          Dimensions: Dimensions.map((subItem = {}) => {
            const { Dimensions = [] } = subItem
            return {
              ...subItem,
              level: '2',
              Url: getItemUrl(subItem),
              Dimensions: Dimensions.map((subSubItem) => {
                return {
                  ...subSubItem,
                  level: '3',
                  Url: getItemUrl(subSubItem),
                }
              }),
            }
          }),
        }
      }),
  }
  return [products, servicesSolutions]
}

/**
 * Name: getItemUrl
 * Desc: Method to make product category item url
 * @param {string} language
 * @param {string} HOST_URL
 * @param {object} item
 */
const getItemUrl = (item, level) => {
  if (level === '1' && item.ImageUrl && item.ImageName) {
    return `${HOST_URL}${item.ImageUrl}`
  }
  item.Url = !item.Url ? (item.Id !== '0' ? item.Id : '#') : item.Url
  const queryWord = isHostedInOldEway() ? 'keyword' : 'q'
  const formattedWord = encodeURIComponent(item?.Name)
  const keyword = `?${queryWord}=${formattedWord}`
  const route = getUrl(BROWSE_PRODUCTS_KEYWORD_ROUTE)
  const urlWithKeyword = `${route}${keyword}&categoryId=${item.Url}`
  return !isNaN(item.Url) ? urlWithKeyword : item.Url.replace('~', HOST_URL)
}
