import DynamicTranslation from '~/components/shared/DynamicTranslation'
import Text from '~/components/shared/Text'
import Box from '~/components/shared/Box'
import SearchBox from '~/components/shared/SearchBox'
import buyerModalStyles from 'shared/SelectBuyersModal/SelectBuyersModal.module.scss'
import PropTypes from 'prop-types'
import messages from './messages'

export function translateName(message, openedAddressName) {
  return (
    <DynamicTranslation
      text={message}
      replace={'{name}'}
      with={
        <Text
          fontWeight="strong"
          variant="lgText"
          fontFamily="md"
          text={openedAddressName}
        />
      }
    />
  )
}

export function orderByAssigned(assignmentIdsOrder = [], assignedIds = []) {
  const firstAddresses = assignmentIdsOrder.filter((id) =>
    assignedIds.includes(id)
  )
  const rest = assignmentIdsOrder.filter((id) => !assignedIds.includes(id))
  const idsOrderedByAssigned = [...firstAddresses, ...rest]
  return idsOrderedByAssigned
}

export const SearchBoxItem = ({ onSearch, language, type = '' }) => {
  const translation = messages[language]
  function handleSearch(event) {
    onSearch?.(event)
  }

  return (
    <>
      <Box className={buyerModalStyles.search}>
        <SearchBox
          data-test="filter-address-field"
          name="filterAddress"
          id={`${type}FilterAddress`}
          aria-label={translation.search}
          placeholder={translation.search}
          onChange={handleSearch}
        />
      </Box>
    </>
  )
}
SearchBoxItem.propTypes = {
  onSearch: PropTypes.func,
  language: PropTypes.string,
  type: PropTypes.string,
}
