import { useRef } from 'react'

const useIsFirstRender = () => {
  const isFirstRender = useRef(null)

  if (isFirstRender.current === true) {
    isFirstRender.current = false
  }

  if (isFirstRender.current === null) {
    isFirstRender.current = true
  }

  return isFirstRender.current
}

export default useIsFirstRender
