import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import useWindowDimensions from 'shared/CustomHooks/useWindowDimensions'
import { BREAK_POINTS } from 'shared/Utils/constants'
import { ENGLISH } from '~/redux/constants'
import NavBarMobile from './Mobile/HamburgerNavBar'
import styles from './NavBar.module.scss'
import NavBarDesktop from './NavBarDesktop'
import NavBarDesktopSkeleton from './NavBarDesktop/NavBarDesktopSkeleton/NavBarDesktopSkeleton.js'
import {
  fetchCategoryMenu,
  getTopBarListItems,
  usePublicationAndBrandsMenusAndDeals,
} from './NavBarUtils'

/**
 * Name: NavBar
 * Desc: Render the Nav bar menu
 * @param {string} language
 * @param {bool} isLoggedIn
 * @param {bool} showRebates
 * @param {bool} enablePublication
 * @param {string} hideRestrictions
 * @param {string} searchFilter
 * @param {bool} enablePwgsCheckout
 */
const NavBar = ({
  language = ENGLISH,
  isLoggedIn = false,
  enablePublication,
  hideRestrictions,
  enablePwgsCheckout,
  dealsMenuItems = [],
  showDealsFlyers = true,
}) => {
  const { width } = useWindowDimensions()
  const [menuList, setMenuList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const topNavBarItems = getTopBarListItems(language, isLoggedIn)
  const {
    publications = [],
    brands = [],
    deals = [],
  } = usePublicationAndBrandsMenusAndDeals({
    language,
    enablePublication,
  })
  useEffect(() => {
    async function initFetchMenu() {
      setIsLoading(true)
      const categories = await fetchCategoryMenu(language, hideRestrictions)
      setIsLoading(false)
      setMenuList(categories)
    }
    initFetchMenu()
  }, [hideRestrictions, language])

  const hasPublicationsMenuItems = !!publications.length
  const hasBrandsMenuItems = !!brands.length

  const finalDealsMenuItems = showDealsFlyers
    ? [...deals, ...dealsMenuItems]
    : dealsMenuItems
  const hasDealsMenuItems = !!finalDealsMenuItems.length
  const isDesktop = width > BREAK_POINTS.sm
  return (
    <div className={styles.navbar}>
      {isLoading ? (
        <NavBarDesktopSkeleton />
      ) : isDesktop ? (
        <NavBarDesktop
          showDeals={hasDealsMenuItems}
          showPublications={hasPublicationsMenuItems}
          showBrands={hasBrandsMenuItems}
          deals={finalDealsMenuItems}
          publications={publications}
          brands={brands}
          language={language}
          categories={menuList}
          topNavBarItems={topNavBarItems}
        />
      ) : (
        <NavBarMobile
          dealsMenuItems={finalDealsMenuItems}
          publicationsMenuItems={publications}
          brandsMenuItems={brands}
          categoryItem={menuList}
          topNavBarItems={topNavBarItems}
          language={language}
          enablePwgsCheckout={enablePwgsCheckout}
        />
      )}
    </div>
  )
}

// Currently set as we are not receiving data of settings after login. We can remove it later on.
NavBar.defaultProps = {
  enablePublication: true,
}

// Props Validation
NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  enablePublication: PropTypes.bool,
  hideRestrictions: PropTypes.string,
  language: PropTypes.string,
  enablePwgsCheckout: PropTypes.bool,
  dealsMenuItems: PropTypes.array,
  showDealsFlyers: PropTypes.bool,
}

export default memo(NavBar)
