import { getAccounts } from 'api'
import { fetchClientInformation } from 'api/client/clientApiUtils'
import isBoolean from 'lodash/isBoolean'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { LOCAL_STORAGES } from 'services/constants'
import {
  addSelected,
  clearSelected,
  createAccountFromAccountInfo,
  findSelectedIndex,
  hasOnlyFavoriteAccounts,
  initializeAccounts,
  orderAccounts,
  showAccountLink,
  showDropdown,
} from '~/components/shared/ShipToComponent/ShipToComponentUtils'
import { isDev } from '~/config/env/environmentUtils'
import { updateUserAccountAndReload } from '~/services/accountService'
import { getLocalStorage } from '~/services/storageManager'

const ShipToContext = createContext({ accounts: [] })
const { Provider, Consumer } = ShipToContext
const accountSelectionModal = getLocalStorage(
  LOCAL_STORAGES.ACCOUNT_SELECTION_MODAL
)

export function ShipToContextProvider({ children, ...rest }) {
  const [state, setState] = useState({
    accounts: [],
    details: rest.details,
    showAccountSelectionModal: !!accountSelectionModal,
    count: 0,
    isDisabledAccountSelection: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { buyerId, sessionId, clientNo, isGroupOrder } = rest
  const {
    accounts,
    showAccountSelectionModal,
    count,
    details,
    isDisabledAccountSelection,
  } = state

  const hasTwoOrMoreAccounts = accounts.length > 1
  const props = {
    showAccountSearchLink: rest.showAccountSearchLink,
    showAccountSelection: rest.showAccountSelection,
    enableFavoriteShipTo: rest.enableFavoriteShipTo,
    showShipToSearchFlag: rest.showShipToSearchFlag,
  }
  const showAccountSearchLink = showAccountLink(props, count)

  useEffect(() => {
    async function initContext() {
      if (!accounts.length && buyerId) {
        setIsLoading(true)
        const data = (await getAccounts(buyerId, sessionId)) || {}
        const hasOnlyFavorites = hasOnlyFavoriteAccounts(data.shipToList)
        const hasFavorites = checkHasSomeFavorites(data.shipToList)
        const accounts = initializeAccounts(
          data.shipToList,
          hasOnlyFavorites,
          clientNo
        )
        const selectedIndex = findSelectedIndex(accounts, clientNo)
        setIsLoading(false)
        setState({
          details,
          hasFavorites,
          showAccountSelectionModal,
          readOnly: isGroupOrder,
          accounts,
          selectedIndex,
          clientNo,
          showStarBesides: hasOnlyFavorites,
          count: data.count,
          isDisabledAccountSelection,
        })
      }
    }
    initContext()
  }, [
    accounts.length,
    buyerId,
    clientNo,
    details,
    isDisabledAccountSelection,
    isGroupOrder,
    sessionId,
    showAccountSelectionModal,
  ])

  /**
   * Sets the ShipToContext to readOnly mode
   * @param {bool} value
   */
  const setIsReadOnly = (value) => {
    const valueIsBoolean = isBoolean(value)
    if (valueIsBoolean) {
      setState((state) => {
        return { ...state, readOnly: value }
      })
    }
    if (isDev() && !valueIsBoolean) {
      throw Error(
        `setIsReadyOnly expects a boolean true, or false: ${typeof value} was passed`
      )
    }
  }

  /**
   * Uses the clientNo, clientId, and is favorite to set the account on the actual page.
   * @param {Object} values
   * @param {Object} values.clientNo - The clientNo
   * @param {Object} values.clientId - The clientId
   * @param {Object} values.isFavorite - If the account is part of the buyer's favorites
   * @param {Object} options - The options, can be set to reload : false to prevent the default page reload
   * @param {Object} options.reload - reload the page by default, set to false not to reload the page.
   */
  const selectShipToAccountAndReload = async (
    values,
    options = { reload: true }
  ) => {
    const {
      clientNo: selectedClientNo,
      clientId: selectedAccountGuid,
      isFavorite,
    } = values
    const details = await fetchClientInformation(selectedAccountGuid)

    const fetchedAccount = createAccountFromAccountInfo(details, isFavorite)
    const updatedAccounts = state.accounts.map((account) => {
      return account.clientNo === fetchedAccount.clientNo
        ? fetchedAccount
        : account
    })
    const accounts = orderAccounts([...updatedAccounts])
    const newAccounts = accounts
      .map(clearSelected)
      .map((account) => addSelected(account, selectedClientNo))

    const newState = {
      ...state,
      accounts: newAccounts,
    }
    const selectedIndex = findSelectedIndex(newAccounts, selectedClientNo)
    setState({
      ...newState,
      details,
      selectedIndex,
      hasChanged: state.clientNo !== selectedClientNo,
      clientNo: selectedClientNo,
    })

    updateUserAccountAndReload(selectedClientNo, clientNo, [], options)
  }

  const onMarkAccountAsFavorite = () => {
    setState({
      ...state,
      accounts: [],
    })
  }

  const setShowAccountSelection = (
    showAccountSelectionModalStatus,
    isDisabledAccountSelectionStatus = false
  ) => {
    setState({
      ...state,
      showAccountSelectionModal: showAccountSelectionModalStatus,
      isDisabledAccountSelection: isDisabledAccountSelectionStatus,
    })
  }

  const givenState = {
    ...state,
    isLoading,
    showDropdown: showDropdown(state.accounts, props),
    hasTwoOrMoreAccounts,
    showAccountSearchLink,
    selectShipToAccountAndReload,
    setIsReadOnly,
    onMarkAccountAsFavorite,
    setShowAccountSelection,
  }
  return <Provider value={givenState}>{children}</Provider>
}

ShipToContextProvider.propTypes = {
  children: PropTypes.node,
  buyerId: PropTypes.string,
}

export const checkHasSomeFavorites = (accountList = []) => {
  return accountList.some((account) => account.isFavorite)
}

export function useShipToContext() {
  return useContext(ShipToContext)
}

export { Consumer as ShipToConsumer }
export default ShipToContext
