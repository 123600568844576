export const getNextFocusableElement = (selectedItem, length) => {
  const lastElementIndex = length - 1
  const firstElementIndex = 0
  return selectedItem < lastElementIndex ? selectedItem + 1 : firstElementIndex
}

export const getPreviousFocusableElement = (selectedItem, length) => {
  const lastElementIndex = length - 1
  return selectedItem > 0 ? selectedItem - 1 : lastElementIndex
}
