export default {
  en: {
    shiptoLocation: 'Ship to location',
    liveChat: 'Livechat',
    messages: 'Messages',
    findAccountLabel: 'Find Account',
    deliverTo: 'Deliver to:',
    changePostalCode: 'Change postal code',
  },
  fr: {
    shiptoLocation: "Expédier à l'emplacement",
    liveChat: 'chat en direct',
    messages: 'Messages',
    findAccountLabel: 'Trouver un compte',
    deliverTo: 'Livrer à:',
    changePostalCode: 'Changer le code postal',
  },
}
