import EwayRouter, { getUrl } from 'shared/EwayRouter'
import {
  EDIT_PROFILE_ROUTE,
  FLYERS_ROUTE,
  REBATES_OFFERS_ROUTE,
  SHOPPING_LIST_ROUTE,
  SUBMITTED_ORDERS_ROUTE,
  APPROVAL_ORDERS_ROUTE,
  TEMPLATES_SUMMARY_ROUTE,
  RECENT_APPROVAL_ORDERS_ROUTE,
  GROUP_ORDERS,
} from 'shared/EwayRouter/EwayRouterConstants'
import { isLoggedIn } from 'shared/Utils/userUtils'
import { getHelpMenus } from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/HelpMenu/HelpMenuUtils'
import { ENGLISH } from '~/redux/constants'
import {
  ACCOUNT,
  ACCOUNT_MANAGEMENT,
  ADMIN,
  APPROVER,
  BACK_ORDER,
  CART_SUB_MENU,
  CHANGE_PASSWORD,
  EDIT_MY_PROFILE,
  FLYERS_CATALOGUES,
  GROUP_ORDER,
  HELP_MENU,
  MANAGE_ADDRESSES,
  MANAGE_USERS,
  ORDERS,
  PRINT_ORDER,
  REBATES_AND_OFFERS,
  RETURNS_URL,
  SHOPPING,
  SHOPPING_LIST,
  SPECIAL_ORDER,
  SUBMITTED_ORDER,
  TEMPLATES,
} from '~/services/accountServiceConstants'
import { isGroupOrderFromBuyer } from 'services/utils'
import { getHostUrl } from 'services/utils/configUtils'
import { ADDRESS_TYPES_VIEW_LEVEL } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'

const HOST_URL = getHostUrl()
/**
 * getAccountMenus
 * Gets a list of account menus based on some params
 * @param {array} messages - pass in an array for translations
 * @param {string} language - default to english
 * @param {object} settings - object settings from eway
 * @param {buyer} buyer - buyer object from eway
 * @param {details} details - account details from alpha object
 * @returns
 */
export const getAccountMenus = ({
  messages,
  language = ENGLISH,
  settings = {},
  buyer = {},
  details = {},
}) => {
  const editProfileUrl = EwayRouter.getUrl(EDIT_PROFILE_ROUTE)
  const groupOrder = isGroupOrderFromBuyer(buyer)
  const loggedIn = isLoggedIn(buyer)
  const translations = messages[language]
  const accountMenus = [
    {
      linkText: translations.shoppingLabel,
      id: SHOPPING,
      link: '#',
      value: SHOPPING,
      subLinks: [
        {
          target: '_blank',
          linkText: messages[language].cartLabel,
          id: CART_SUB_MENU,
          link: `${HOST_URL}/${language}/${messages[language].cartMenuUrl}`,
          value: CART_SUB_MENU,
        },
        {
          target: '_blank',
          linkText: messages[language].rebatesAndOffersLabel,
          id: REBATES_AND_OFFERS,
          link: getUrl(REBATES_OFFERS_ROUTE),
          value: REBATES_AND_OFFERS,
        },
        {
          target: '_blank',
          linkText: messages[language].shoppingListsLabel,
          id: SHOPPING_LIST,
          link: getUrl(SHOPPING_LIST_ROUTE),
          value: SHOPPING_LIST,
        },
        {
          target: '_blank',
          linkText: messages[language].flyersCataloguesLabel,
          id: FLYERS_CATALOGUES,
          link: getUrl(FLYERS_ROUTE),
          value: FLYERS_CATALOGUES,
        },
      ],
    },
    {
      linkText: messages[language].helpLabel,
      id: HELP_MENU,
      link: `${HOST_URL}/${language}/orders/cart/details`,
      value: HELP_MENU,
      subLinks: [
        ...getHelpMenus(
          language,
          loggedIn,
          groupOrder,
          settings.showMenuHelpCenter
        ),
      ],
    },
    {
      id: ORDERS,
      linkText: messages[language].ordersLabel,
      link: '#',
      value: ORDERS,
      subLinks: [
        {
          target: '_blank',
          linkText: messages[language].groupOrdersLabel,
          id: GROUP_ORDER,
          link: getUrl(GROUP_ORDERS),
          value: GROUP_ORDER,
        },
        {
          target: '_blank',
          linkText: messages[language].submittedOrdersLabel,
          id: SUBMITTED_ORDER,
          link: getUrl(SUBMITTED_ORDERS_ROUTE),
          value: SUBMITTED_ORDER,
        },
        {
          target: '_blank',
          linkText: messages[language].backorderedItemsLabel,
          id: BACK_ORDER,
          link: `${HOST_URL}/${messages[language].backorderedItemsUrl}`,
          value: BACK_ORDER,
        },
        {
          target: '_blank',
          linkText: messages[language].templatesLabel,
          id: TEMPLATES,
          link: getUrl(TEMPLATES_SUMMARY_ROUTE),
          value: TEMPLATES,
        },

        {
          target: '_blank',
          linkText: messages[language].inApprovalLabel,
          id: APPROVER,
          link: getUrl(APPROVAL_ORDERS_ROUTE),

          value: APPROVER,
        },
        {
          target: '_blank',
          linkText: messages[language].recentlyApprovedLabel,
          id: APPROVER,
          link: getUrl(RECENT_APPROVAL_ORDERS_ROUTE),
          value: APPROVER,
        },
        {
          target: '_blank',
          linkText: messages[language].approvedBackorderedItemsLabel,
          id: APPROVER,
          link: `${HOST_URL}/${messages[language].approvedBackorderedItemsUrl}`,
          value: APPROVER,
        },
        {
          target: '_blank',
          linkText: messages[language].specialordersLabel,
          id: SPECIAL_ORDER,
          link: `${HOST_URL}/${language}/${messages[language].specialordersUrl}`,
          value: SPECIAL_ORDER,
        },
        {
          target: '_blank',
          linkText: messages[language].printOrderLabel,
          id: PRINT_ORDER,
          link: `${HOST_URL}/${language}/${messages[language].printOrderUrl}`,
          value: PRINT_ORDER,
        },
        {
          target: '_blank',
          linkText: messages[language].returnsLabel,
          id: RETURNS_URL,
          link: `${HOST_URL}/${language}/${messages[language].returnsUrl}`,
          value: RETURNS_URL,
        },
      ],
    },
    {
      id: ACCOUNT,
      linkText: messages[language].myAccountLabel,
      link: '#',
      value: ACCOUNT,
      subLinks: [
        {
          target: '_blank',
          linkText: messages[language].editMyProfileLabel,
          id: EDIT_MY_PROFILE,
          link: editProfileUrl,
          value: EDIT_MY_PROFILE,
        },
        {
          target: '_blank',
          linkText: messages[language].changeMyPasswordLabel,
          id: CHANGE_PASSWORD,
          link: `${HOST_URL}/${language}/${messages[language].changeMyPasswordUrl}`,
          value: CHANGE_PASSWORD,
        },
        {
          target: '_blank',
          linkText: messages[language].accountManageLabel,
          id: ACCOUNT_MANAGEMENT,
          link: `${HOST_URL}/${messages[language].accountManageUrl}`,
          value: ACCOUNT_MANAGEMENT,
        },
        {
          linkText: messages[language].manageDeliveryAddresses,
          id: MANAGE_ADDRESSES,
          link: `${HOST_URL}/${language}/${messages[language].manageDeliveryAddressesUrl}`,
          value: MANAGE_ADDRESSES,
        },
      ],
    },
    {
      target: '_blank',
      linkText: messages[language].adminLabel,
      id: ADMIN,
      link: `${HOST_URL}/${messages[language].adminUrl}`,
      value: ADMIN,
      subLinks: [],
    },
  ]

  const menus = filterAccountMenus({
    accountMenus,
    settings,
    buyer,
    groupOrder,
    details,
  })
  return menus
}

function filterAccountMenus({
  accountMenus,
  settings,
  buyer,
  groupOrder,
  details,
}) {
  const manageCostCenter =
    buyer.canManageCostCenter &&
    buyer.costCenterLevel > ADDRESS_TYPES_VIEW_LEVEL.SELF
  const filteredMenus = accountMenus.filter((accountMenu) => {
    if (removeAccountMenu(accountMenu, settings, groupOrder)) {
      return false
    }
    if (removeHelpMenu(accountMenu, groupOrder)) {
      return false
    }
    if (removeAdminMenu(accountMenu, buyer, groupOrder)) {
      return false
    }
    if (removeOrderMenu(accountMenu, settings, groupOrder)) {
      return false
    }
    accountMenu.subLinks = accountMenu.subLinks.filter(function (item) {
      if (
        item.value === REBATES_AND_OFFERS &&
        (groupOrder || !settings.showMenuCoupons)
      ) {
        return false
      }

      if (item.value === FLYERS_CATALOGUES && !details.hasFlyerPriceFlag) {
        return false
      }

      if (item.value === APPROVER) {
        if (settings.showMenuOrderSubmitted && buyer?.isApprover) {
          return true
        }
        return false
      }
      if (
        item.value === GROUP_ORDER &&
        !(!buyer.hasSessionBasketFlag && settings.enableGroupOrdering)
      ) {
        return false
      }

      if (item.value === SUBMITTED_ORDER) {
        if (!settings.showMenuOrderSubmitted) {
          return false
        }
        if (buyer.hideSubmittedAndBoorders) {
          return false
        }
        return true
      }
      if (item.value === BACK_ORDER && buyer.hideSubmittedAndBoorders) {
        return false
      }

      if (item.value === ACCOUNT_MANAGEMENT) {
        if (
          MANAGE_USERS.includes(buyer.canManageUser) ||
          buyer.canManageCustomPrompts ||
          buyer.canViewReport
        ) {
          return true
        }
        return false
      }
      if (item.value === MANAGE_ADDRESSES) {
        if (
          buyer.canManageDeliveryAddress ||
          buyer.canManageBillingAddress ||
          manageCostCenter
        ) {
          return true
        }
        return false
      }

      if (item.value === PRINT_ORDER) {
        if (buyer.printOrderForm && settings.printOrderForm) {
          return true
        }
        return false
      }

      if (item.value === TEMPLATES && !settings.saveShowOrderTemplate) {
        return false
      }

      if (item.value === SPECIAL_ORDER && !settings.showMenuOrderSpecial) {
        return false
      }
      if (item.value === RETURNS_URL && !settings?.showMenuOrderReturns) {
        return false
      }

      if (item.value === EDIT_MY_PROFILE && !settings?.showMenuProfileModify) {
        return false
      }

      if (
        item.value === CHANGE_PASSWORD &&
        !(buyer?.canChangePasswordFlag && settings?.showProfileChangePwd)
      ) {
        return false
      }

      if (item.value === SHOPPING_LIST && !settings?.showShoppingListsMenu) {
        return false
      }

      return true
    })
    return accountMenu
  })
  return filteredMenus
}

function removeAccountMenu(accountMenu, settings, groupOrder) {
  return (
    accountMenu.value === ACCOUNT && (groupOrder || !settings?.showMenuProfile)
  )
}

function removeAdminMenu(accountMenu, buyer, groupOrder) {
  return accountMenu.value === ADMIN && (groupOrder || !buyer?.isAdminFlag)
}

function removeOrderMenu(accountMenu, settings, groupOrder) {
  return (
    accountMenu.value === ORDERS && (groupOrder || !settings?.showMenuOrder)
  )
}

function removeHelpMenu(accountMenu, groupOrder) {
  return accountMenu.value === HELP_MENU && !groupOrder
}
