import PropTypes from 'prop-types'
import styles from './TabList.module.scss'
import Tab from '../Tab'
import { Children, cloneElement } from 'react'
import { toCamelCase } from 'services/utils/utils'

/**
 *Name: TabList
 *Desc: Render TabList
 * @param {array} tabHeadingList
 * @param {func} clickOnTab
 * @param {string || number} activeTabId
 */

const TabList = ({
  tabHeadingList,
  clickOnTab,
  activeTabId,
  children: tabs,
}) => {
  const handleTabClick = (id, index) => {
    clickOnTab(id, index)
  }
  // When use with children, tabList is 0 based
  const children = Children.map(tabs, (tab, index) => {
    return cloneElement(tab, {
      ...tab.props,
      active: index === activeTabId,
    })
  })

  return (
    <div className={styles.tabListWrapper} role="tablist">
      {/* Recommended way to use tabs ⬇️ */}
      {children}

      {/* TODO: DEPRECATE ⬇️ */}
      {/* Called to disappear in favor off a full composable tab component ⬇️ */}
      {tabHeadingList.map((listItem, index) => (
        <Tab
          key={listItem.id}
          id={listItem.id}
          active={listItem.id === activeTabId}
          onClick={() => handleTabClick(listItem.id, index)}
          data-test={toCamelCase(listItem.text)}
        >
          {listItem.text}
        </Tab>
      ))}
    </div>
  )
}

// Default Props
TabList.defaultProps = {
  tabHeadingList: [],
}

// Props Validation
TabList.propTypes = {
  tabHeadingList: PropTypes.array,
  children: PropTypes.node,
  clickOnTab: PropTypes.func,
  activeTabId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TabList
