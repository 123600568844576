import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { useSupportMenuContext } from '../SupportMenuUtils'
import styles from './SupportMenuItem.module.scss'
import classNames from 'classnames'
import MenuItemContent from './MenuItemContent'

function SupportMenuItem({ label, icon, menuName, color, children = null }) {
  const { activeMenu, setCurrentActiveMenu, closeMenu } =
    useSupportMenuContext()
  const isOpen = activeMenu === menuName
  const handleToggleMenu = () => {
    activeMenu ? closeMenu() : setCurrentActiveMenu(menuName)
  }
  const handleMouseEnter = () => {
    setCurrentActiveMenu(menuName)
  }

  const colorClass = color === 'black' ? styles.veryDarkGrey : styles.white
  const colorClasses = classNames(styles.headerButtons, colorClass)

  return (
    <div className={styles.menuContent}>
      <Button
        variant="link"
        data-test={label}
        onMouseEnter={handleMouseEnter}
        onClick={handleToggleMenu}
        className={colorClasses}
        aria-label={label}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        {children || (
          <MenuItemContent label={label} icon={icon} color={color} />
        )}
      </Button>
    </div>
  )
}

// Props Validation
SupportMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  menuName: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
}

export default SupportMenuItem
