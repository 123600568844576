export const ADDRESS_TABS = {
  DELIVERY_TAB_ID: 0,
  BILLING_TAB_ID: 1,
  COST_CENTER_TAB_ID: 2,
}

export const DELIVERY_ADDRESS = 'delivery'
export const BILLING_ADDRESS = 'billing'
export const COST_CENTER = 'costCenter'

export const ADDRESS_GROUPS = {
  DELIVERY_ADDRESS,
  BILLING_ADDRESS,
  COST_CENTER,
}

export const DELIVERY_ADDRESS_LOCK_API_END_POINT = 'deliveryAddressLockingFlags'
export const BILLING_ADDRESS_LOCK_API_END_POINT = 'billingAddressLockingFlags'
export const TEMPORARY_DELIVERY_ADDRESS_ID = '0'
