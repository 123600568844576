import { ENGLISH } from '@redux/constants'
import { getApiUrl } from 'services/utils/configUtils'
import {
  checkArgsEmpty,
  generateHeadersWithLanguage,
  sendRequest,
} from '../apiHelper'
import { RETRY_PARAMS } from '../apiHelperConstants'
const API_URL = getApiUrl()

async function updateBuyer(args) {
  const [language = ENGLISH, buyerId, checkoutReqParams] = args
  const { reqParams } = checkoutReqParams
  const url = `${API_URL}/buyers/${buyerId}`
  const options = {
    method: 'PATCH',
    body: JSON.stringify(reqParams),
    headers: generateHeadersWithLanguage(language),
    retry: RETRY_PARAMS,
  }
  return sendRequest(url, options)
}

export const update = checkArgsEmpty(updateBuyer)

const buildAndFetchBuyer = async (args) => {
  const [buyerId] = args
  const buyerUrl = `${API_URL}/buyers/${buyerId}`
  return await sendRequest(buyerUrl)
}
export const get = checkArgsEmpty(buildAndFetchBuyer)
