import { getOldApiUrl } from 'services/utils/configUtils'
import { generateHeadersWithLanguage, sendRequest } from '~/api/apiHelper'
import {
  getSearchByProductList,
  getSearchOrCategoryProducts,
} from '~/api/productOperations'
import { getSearchUrl } from '~/api/productOperations/productOperationsUtils'
import {
  SEARCH_TYPES,
  RES_TRACKING_ID,
  SEARCH_API_TYPE,
} from '~/components/containers/BrowseProducts/BrowseProductsConstants'
import { ENGLISH } from '~/redux/constants'
import { getCookie } from '~/services/cookieManager'

const OLD_API_URL = getOldApiUrl()

export async function getProductsResultBySearchOrCategory({
  language,
  kws = SEARCH_API_TYPE.NOT_KEYWORD, // soon to be deprecated and replaced wi KEYWORD
  pageNumber,
  optionsFilter = SEARCH_TYPES.DEFAULT_FILTER,
  sortBy = '',
  guestLocation = '',
  enablePwgscheckout = 2,
  keyword = '',
  categoriesIds = '',
  showHideRestrictions = SEARCH_TYPES.DEFAULT_RESTRICTION,
  searchType,
  finderType,
  sort = '',
}) {
  // 0 should be used when doing a keyword search, 10 should be used doing a search by category
  const sgsType = searchType.isCategorySearch ? SGS_TYPES.OLD : SGS_TYPES.NONE
  const trackingId = getTrackingId() // Contained in Cookie (RES_TRACKINGID)

  const body = {
    Br_uid: getCookie('_br_uid_2') || '', //This value is stored in a cookie (_br_uid_2)
    Ids: categoriesIds, //ID of the category when doing a search by category
    Keyword: keyword, //The keyword to search for (entered in the search bar)
    PreSearchFilter: enablePwgscheckout, // 2 by default; If user is PWGS: 0 = All, 1 = StandingOffers
    SgsType: sgsType,
    SearchRefinementHistory: '', //No idea, keep it empty
    ShowHideRestrictions: showHideRestrictions, //2 by default; 0 = Hide, 1 = Show; Used to show or hide restrictions, taken from the cookie (UserInfo:ShowHideRestrictions=[0|1])
    Spk: '', //No idea, keep it empty
    TrackingId: trackingId,
    VisitorId: trackingId,
    finderType,
    sort: !sortBy ? sort : '',
  }
  const url = getSearchUrl({
    kws, // defines the keyword search or category search
    pageNumber,
    optionsFilter,
    sortBy,
    guestLocation,
  })
  return await getSearchOrCategoryProducts(url, body, language)
}

export async function getInkAndTonerProductDetails({
  language,
  pageNumber,
  optionsFilter,
  sortBy = '',
  guestLocation = '',
  productList = [],
  keyword = '',
  showHideRestrictions = SEARCH_TYPES.DEFAULT_RESTRICTION,
}) {
  const trackingId = getTrackingId() //Contained in Cookie (RES_TRACKINGID)
  const body = {
    Keyword: keyword, //The keyword to search for (entered in the search bar)
    ProductList: productList,
    ShowHideRestrictions: showHideRestrictions, //2 by default; 0 = Hide, 1 = Show; Used to show or hide restrictions, taken from the cookie (UserInfo:ShowHideRestrictions=[0|1])
    TrackingId: trackingId,
  }
  const result = await getSearchByProductList(
    language,
    pageNumber,
    optionsFilter,
    sortBy,
    guestLocation,
    body
  )
  return result
}

export const getPromotionProductDetails = async ({
  language = ENGLISH,
  pageNumber,
  optionsFilter = SEARCH_TYPES.DEFAULT_FILTER,
  sortBy = '',
  enablePwgscheckout = 2,
  keyword = '',
  categoriesIds = '',
  showHideRestrictions = SEARCH_TYPES.DEFAULT_RESTRICTION,
  searchType,
  finderType,
  sort = '',
  promoId = '',
}) => {
  // 0 should be used when doing a keyword search, 10 should be used doing a search by category
  const sgsType = searchType.isCategorySearch ? SGS_TYPES.OLD : SGS_TYPES.NONE
  const trackingId = getTrackingId() // Contained in Cookie (RES_TRACKINGID)

  const body = {
    Br_uid: getCookie('_br_uid_2') || '', //This value is stored in a cookie (_br_uid_2)
    Ids: categoriesIds, //ID of the category when doing a search by category
    Keyword: keyword, //The keyword to search for (entered in the search bar)
    PreSearchFilter: enablePwgscheckout, // 2 by default; If user is PWGS: 0 = All, 1 = StandingOffers
    SgsType: sgsType,
    SearchRefinementHistory: '', //No idea, keep it empty
    ShowHideRestrictions: '0', //showHideRestrictions, //2 by default; 0 = Hide, 1 = Show; Used to show or hide restrictions, taken from the cookie (UserInfo:ShowHideRestrictions=[0|1])
    Spk: '', //No idea, keep it empty
    TrackingId: trackingId,
    VisitorId: trackingId,
    finderType,
    sort: !sortBy ? sort : '',
  }

  const url = `${OLD_API_URL}/api/searchByPromotionId/${promoId}?pageno=${pageNumber}&filter=${optionsFilter}&sort=NONE&showhide=${showHideRestrictions}`

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...generateHeadersWithLanguage(language),
    },
  }
  return sendRequest(url, options)
}

function getTrackingId() {
  return getCookie(RES_TRACKING_ID) || ''
}

const SGS_TYPES = {
  NONE: 0,
  ENDECA: 1,
  BLOOMREACH: 3,
  OLD: 10,
}
