import { useRef } from 'react'
import Box from 'components/shared/Box'
import PropTypes from 'prop-types'
import Button from 'components/shared/Button'
import messages from '../AddressBox/messages'
import styles from './AddressActionButtons.module.scss'
import { ENGLISH } from '~/redux/constants'
import { ACTION } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import Tooltip from 'shared/Tooltip'
import ReactTooltip from 'react-tooltip'
import useOutsideClickHandler from 'shared/CustomHooks/useOutsideClickHandler'

/**
 * Name: AddressActionButtons
 * Desc: Render AddressActionButtons
 * @param {string} language
 */

const AddressActionButtons = ({
  language = ENGLISH,
  showToggleActionButton = false,
  showDeleteButton = false,
  showAssignButton = false,
  canEditAddress = false,
  canDeleteAddress = false,
  isAssignedToDeliveryAddress = false,
  showSetAsDefaultBillingAddressButton = false,
  id,
  onEditAddressClick,
  onDeleteAddressClick,
  onClickAssignAddress,
  openDefaultBillingAssignerModal,
  showMakePrimaryButton,
  onPrimaryAddressClick,
}) => {
  const tooltipOpenerRef = useRef(null)
  const translations = messages[language]

  useOutsideClickHandler(tooltipOpenerRef, () => {
    ReactTooltip.hide(tooltipOpenerRef.current)
  })

  function handleEditAddress(event) {
    event.stopPropagation()
    onEditAddressClick(id)
  }
  function handleDeleteAddress() {
    return onDeleteAddressClick(id)
  }

  function handleAssignAddress(e) {
    onClickAssignAddress(e, id)
  }

  function handleOpenDefaultBillingAssignerModal(e) {
    openDefaultBillingAssignerModal(e, id)
  }
  function handlePrimaryAddress() {
    return onPrimaryAddressClick(id)
  }
  const { editDelivery, assignDelivery } = translations
  const assignLabel = isAssignedToDeliveryAddress
    ? editDelivery
    : assignDelivery

  return (
    <Box>
      {showToggleActionButton ? (
        <Box>
          <Button
            className={styles.toggleButton}
            variant="link"
            border="none"
            data-test="toggle-action-button"
            data-tip
            data-event="click"
            ref={tooltipOpenerRef}
            data-for={`flyoutToggler${id}`}
          >
            ...
          </Button>
          <Tooltip
            id={`flyoutToggler${id}`}
            place="bottom"
            clickable
            className={styles.tooltipStyle}
          >
            <Box
              as="div"
              className={styles.editDeleteButton}
              data-test="edit-delete-button"
            >
              <Button
                text={translations.edit}
                aria-label={translations.edit}
                data-test="edit-address-button"
                onClick={handleEditAddress}
                pl="5px"
                variant="link"
                border="none"
                id={`${ACTION.EDIT}-delivery-address-${id}`}
                disabled={!canEditAddress}
              />
              {showAssignButton ? (
                <Button
                  text={assignLabel}
                  data-test="assign-address-button"
                  onClick={handleAssignAddress}
                  aria-label={assignLabel}
                  variant="link"
                  border="none"
                  className={styles.assignButton}
                />
              ) : null}
              {showMakePrimaryButton ? (
                <Button
                  text={translations.makePrimary}
                  variant="link"
                  border="none"
                  className={styles.setDefaultBillingAddressButton}
                  data-test="make-primary-address-button"
                  aria-label={translations.makePrimary}
                  onClick={handlePrimaryAddress}
                />
              ) : null}
              {showSetAsDefaultBillingAddressButton ? (
                <Button
                  text={translations.setDefaultBillingAddress}
                  data-test="set-default-billing-address"
                  onClick={handleOpenDefaultBillingAssignerModal}
                  aria-label={translations.setDefaultBillingAddress}
                  variant="link"
                  border="none"
                  className={styles.setDefaultBillingAddressButton}
                />
              ) : null}
              {showDeleteButton ? (
                <Button
                  text={translations.delete}
                  data-test="delete-address-button"
                  aria-label={translations.delete}
                  onClick={handleDeleteAddress}
                  color="red"
                  variant="link"
                  border="none"
                  className={styles.deleteButton}
                  disabled={!canDeleteAddress}
                />
              ) : null}
            </Box>
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  )
}
// PropType validation
AddressActionButtons.propTypes = {
  language: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onEditAddressClick: PropTypes.func,
  onDeleteAddressClick: PropTypes.func,
  onClickAssignAddress: PropTypes.func,
  openDefaultBillingAssignerModal: PropTypes.func,
  showAssignButton: PropTypes.bool,
  showToggleActionButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showSetAsDefaultBillingAddressButton: PropTypes.bool,
  isAssignedToDeliveryAddress: PropTypes.bool,
  canEditAddress: PropTypes.bool,
  canDeleteAddress: PropTypes.bool,
  showMakePrimaryButton: PropTypes.bool,
  onPrimaryAddressClick: PropTypes.func,
}
export { AddressActionButtons }
