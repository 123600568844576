export const FIFTY_GREEN_EXEMPT = 0
export const FIFTY_GREEN_NOT_ENROLLED = 1
export const FIFTY_GREEN = 2
export const GREEN_HARD_STOP = 3
export const DELIVERY_ZONE_FEE = 1 //enable delivery zone , surcharges apply
export const PRE_CHECKOUT_MESSAGE_ID = 'precheckout-add-to-cart-success'
export const HIDE_RECOMMENDED_PRODUCT_POPUP = -1
export const CHECKOUT_NOTIFICATION_ID = 'checkout-notification-id'
export const INSPECT_MODE = 'inspect'
export const DELIVERY_DETAIL_FORMS = {
  SERVICE_OPTIONS: 'SERVICE_OPTIONS',
  PRE_ASSEMBLY: 'PRE_ASSEMBLY',
  ADDRESS: 'ADDRESS',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  DELIVERY_OPTIONS: 'DELIVERY_OPTIONS',
}

export const REVIEW_CONFIRM_FORMS = {
  ORDER_INFO: 'ORDER_INFO',
  PAYMENT_OPTION: 'PAYMENT_OPTION',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  CUSTOM_INFO: 'CUSTOM_INFO',
  APPROVERS: 'APPROVERS',
}
