export const SEARCH_KEY_FIELD = 'searchKey'
export const SEARCH_TYPE_FIELD = 'searchType'
export const SEARCH_ON_FIELD = 'searchOn'
export const SORT_TYPE_FIELD = 'sortType'
export const SORT_BY_FIELD = 'sortBy'
export const FAVORITE_ONLY_FIELD = 'favoriteOnly'
export const SEARCH_TYPES = {
  CONTAINS: 'CONTAINS',
  BEGINS_WITH: 'BEGINS_WITH',
}

export const SORT_TYPES = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
}

export const SEARCH_DEBOUNCE_WAIT = 400
