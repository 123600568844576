import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'

const DetailsContext = createContext({})
const { Provider } = DetailsContext
export function DetailsContextProvider({ children, details = {} }) {
  return <Provider value={details}>{children}</Provider>
}

DetailsContextProvider.propTypes = {
  children: PropTypes.node,
  details: PropTypes.object,
}

export function useDetailsContext() {
  return useContext(DetailsContext)
}

export default DetailsContext
