import { useRef, useState } from 'react'
import { useLoader } from '~/components/shared/CustomHooks/useLoader'
import { isEmptyArray } from '~/services/utils'
import { getBuyer } from '.'
import { getLogonName } from '..'
import { formatBuyer } from './buyerApiFormatUtils'

export function useFetchBuyer() {
  const { isLoading, isResolved, setPending, setResolved } = useLoader()
  const wasFetched = useRef(false)
  const [buyer, setBuyer] = useState({})

  async function triggerFetchBuyer(id, { useLogonName = false } = {}) {
    if (wasFetched.current) {
      // Don't allow multiple fetches
      return
    }
    setPending()
    wasFetched.current = true
    if (useLogonName) {
      const [buyerLogonInfo = {}] = await getLogonName(id)
      if (!isEmptyArray(buyerLogonInfo)) {
        formatAndSetBuyer(buyerLogonInfo)
      }
    } else {
      const data = await getBuyer(id)
      formatAndSetBuyer(data)
    }
    setResolved()
  }

  function formatAndSetBuyer(buyer = {}) {
    const formattedData = formatBuyer(buyer)
    setBuyer(formattedData)
  }

  return { buyer, isLoading, isResolved, triggerFetchBuyer }
}
