import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { defaultToEmptyString } from '~/components/containers/Checkout/CheckoutAddress/CheckoutAddressUtils'
import FormField from '~/components/shared/FormControl/FormField'
import Icon from '~/components/shared/Icon'
import styles from '~/components/shared/ManageAddresses/ManageAddressList/ManageAddressList.module.scss'
import Text from '~/components/shared/Text'
import Stack from '../Stack'
import {
  ADDRESS_VISIBILITY,
  API_MAPPING_TYPE,
} from './SaveAddressForm/SaveAddressFormConstants'
import { getAddressPermissions } from './SaveAddressForm/SaveAddressFormUtils'

export const getIconVariant = (type) => {
  switch (type) {
    case ADDRESS_VISIBILITY.PRIVATE:
      return 'accountRegular'
    case ADDRESS_VISIBILITY.SHARED:
      return 'multipleUser'
    case ADDRESS_VISIBILITY.GLOBAL:
      return 'Global'
    default:
      return 'accountRegular'
  }
}

export function getAddressActions(...args) {
  return pick(getAddressPermissions(...args), [
    'canEditAddress',
    'canAssignAddress',
    'canDeleteAddress',
    'canMakePrimaryAddress',
  ])
}

export function mapApiKeyToFormKeyAddressesResponse(
  addresses = [],
  addressGroup
) {
  const filteredAddresses = addresses.map((address) => {
    return mapAddressApiKeyWithFormKey(address, addressGroup)
  })
  return filteredAddresses
}

export function mapAddressApiKeyWithFormKey(address, addressGroup) {
  return replaceKeys(address, API_MAPPING_TYPE[addressGroup])
}

export function replaceKeys(object = {}, map = {}) {
  const mapEntries = Object.entries(map)
  const allValuesToOmit = mapEntries.map(([, values]) => values).flat()
  const allKeys = Object.keys(object)
  const objectRest = omit(object, allValuesToOmit)
  const all = mapEntries
    .map(([newKey, values]) => {
      const foundKey = allKeys.find((key) => values.includes(key))
      if (foundKey) {
        return [newKey, object[foundKey]]
      }
      return []
    })
    .filter((a) => a.length)
  const renamedProps = Object.fromEntries(all)
  return { ...renamedProps, ...objectRest }
}

export const getAssignmentTableRows = ({
  disabled,
  addresses = [],
  assignedIds = [],
  translations,
  onChange,
  fieldType = 'checkbox',
}) => {
  return addresses.map((item = {}) => {
    const address = defaultToEmptyString(item)
    const { visibility, id } = item
    const { building, city, province, postalCode, streetAddress } = address
    const { attention, companyName } = address
    const fullAddress = [building, streetAddress, city, province, postalCode]
      .filter(Boolean)
      .join(', ')

    const map = {
      [ADDRESS_VISIBILITY.PRIVATE]: translations.privateHeading,
      [ADDRESS_VISIBILITY.SHARED]: translations.sharedHeading,
      [ADDRESS_VISIBILITY.GLOBAL]: translations.globalHeading,
    }

    const visibilityHeading = map[visibility]
    const variant = getIconVariant(item.visibility)
    return [
      <FormField
        checked={assignedIds.includes(id)}
        key={0}
        type={fieldType}
        data-test="select-address-row-field"
        value={id}
        id={id}
        name="assign-address-radio[]"
        aria-label={translations.selectAddresses}
        space="m0"
        disabled={disabled}
        onChange={onChange}
      />,
      <TableText key={1} text={companyName} />,
      <Stack key={2} spacing="10px" align="center">
        <Icon
          variant={variant}
          data-test="address-visibility-icon"
          cursor="default"
        />
        <TableText text={visibilityHeading} />
      </Stack>,
      <TableText key={3} text={fullAddress} />,
      <TableText key={4} text={attention} />,
    ]
  })
}

function TableText({ ...rest }) {
  return (
    <Text
      className={styles.tableCell}
      variant="mdText"
      color="secondary"
      {...rest}
    />
  )
}
