export default {
  en: {
    close: 'Close',
    continueBtn: 'Continue',
    failed: `Quelque chose c'est mal passé. Merci d'essayer plus tard.`,
    deliverySavedAddresses: 'Saved delivery addresses:',
    billingSavedAddresses: 'Saved billing addresses:',
    deliveryAddressHeading:
      'Select from a previously saved delivery address or add a new delivery address',
    billingAddressHeading:
      'Select from a previously saved billing address or add a new billing address',
    search: 'Search',
    noAddressesFoundMsg: 'No matches found. Please adjust your search entry.',
    gridViewLabel: 'Card View',
    tableViewLabel: 'List View',
  },
  fr: {
    close: 'Fermer',
    continueBtn: 'Continuer',
    failed: "Quelque chose c'est mal passé. Merci d'essayer plus tard.",
    deliverySavedAddresses: 'Adresses de livraison sauvegardées :',
    billingSavedAddresses: 'Adresses de facturation sauvegardées :',
    deliveryAddressHeading:
      'Sélectionnez une adresse de livraison précédemment enregistrée ou ajoutez une nouvelle adresse de livraison',
    billingAddressHeading:
      'Sélectionnez une adresse de facturation précédemment enregistrée ou ajoutez une nouvelle adresse de facturation',
    noAddressesFoundMsg:
      'Aucun résultat. Veuillez modifier votre entrée de recherche',
    search: 'Rechercher',
    gridViewLabel: 'Carte',
    tableViewLabel: 'Liste',
  },
}
