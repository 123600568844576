import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getHostUrl } from 'services/utils/configUtils'
import { LOGO_IMG_ID } from './LogoConstants'
import styles from './Logo.module.scss'
import messages from './messages'
import { ENGLISH } from '~/redux/constants'
import { getUrl } from 'shared/EwayRouter'
import { HOME_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'

const HOST_URL = getHostUrl()

/**
 *Name: Logo
 *Desc: Render Logo
 * @param {string} language
 * @param {string} logoUrl
 */
const Logo = ({ language = ENGLISH, logoUrl, disabledLogo = false }) => {
  const url = logoUrl
    ? `${HOST_URL}/Images/LogosClient/${language}/${logoUrl}`
    : `/static/header/logo-${language}.svg?v2`
  const logoStyle = classNames(`${styles.logo}`, {
    [`${styles.disabled}`]: disabledLogo,
  })

  const homeUrl = getUrl(HOME_ROUTE)

  return (
    <div className={logoStyle}>
      <a href={homeUrl} data-test="logo-img" id={LOGO_IMG_ID}>
        <img src={url} alt={messages[language].altTextLabel} />
      </a>
    </div>
  )
}

// Default Props
Logo.propTypes = {
  logoUrl: '',
}

// Props Validation
Logo.propTypes = {
  logoUrl: PropTypes.string,
  language: PropTypes.string.isRequired,
  disabledLogo: PropTypes.bool,
}

export default Logo
