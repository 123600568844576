import { checkArgsEmpty, sendRequest } from 'api/apiHelper'
import { getApiUrl } from 'services/utils/configUtils'
import { getAccounts } from '~/api'
import {
  FAVORITE_ONLY_FIELD,
  SEARCH_KEY_FIELD,
  SEARCH_ON_FIELD,
  SEARCH_TYPES,
  SEARCH_TYPE_FIELD,
  SORT_BY_FIELD,
  SORT_TYPES,
  SORT_TYPE_FIELD,
} from './FindAccountTabFilter/FindAccountTabFilterConstant'
import {
  getDefaultSortByFields,
  getOperatingItems,
} from './FindAccountTabFilter/FindAccountTabFilterUtils'

const API_URL = getApiUrl()

const multiFieldSorting = (
  list,
  sortByFields,
  sortType = SORT_TYPES.ASCENDING
) => {
  const sortedList = [...list].sort((a, b) => {
    const foundSorting = findByReturn(sortByFields, (sortByField) => {
      const firstItem =
        sortType === SORT_TYPES.ASCENDING ? a[sortByField] : b[sortByField]
      const secondItem =
        sortType === SORT_TYPES.ASCENDING ? b[sortByField] : a[sortByField]

      const singleFieldSortResult = sortItem(
        convertFieldByType(firstItem),
        convertFieldByType(secondItem)
      )
      return [1, -1].includes(singleFieldSortResult) ? singleFieldSortResult : 0
    })

    return foundSorting || 0
  })

  return sortedList
}

const convertFieldByType = (convertingField) => {
  return typeof convertingField === 'string'
    ? convertingField.toUpperCase()
    : convertingField
}

const findByReturn = (arrayItems, callback) => {
  for (const arrayItem of arrayItems) {
    const result = callback(arrayItem)
    if (result) {
      return result
    }
  }

  return undefined
}

const sortItem = function (a, b) {
  if (a < b) {
    return typeof a === 'boolean' ? 1 : -1
  }
  if (a > b) {
    return typeof a === 'boolean' ? -1 : 1
  }

  return 0
}

const searchByFields = function ({
  [SEARCH_KEY_FIELD]: searchKey,
  [SEARCH_TYPE_FIELD]: searchType,
  [FAVORITE_ONLY_FIELD]: favoritesOnly,
  searchTextAgainstFields,
  items,
}) {
  const searchText = searchKey.toUpperCase()
  const filteredItems = [...items].filter((item) =>
    filterItem({
      item,
      favoritesOnly,
      searchTextAgainstFields,
      searchType,
      searchText,
    })
  )

  return filteredItems
}

const filterItem = ({
  item,
  favoritesOnly,
  searchTextAgainstFields,
  searchType,
  searchText,
}) => {
  if (favoritesOnly && item.isFavorite !== favoritesOnly) {
    return false
  }

  if (!searchText) {
    return true
  }

  const itemFound = searchTextAgainstFields.find((searchTextAgainstField) => {
    const itemField = String(item[searchTextAgainstField] || '').toUpperCase()
    return searchType === SEARCH_TYPES.CONTAINS
      ? itemField.includes(searchText)
      : itemField.startsWith(searchText)
  })

  return !!itemFound
}

export const getTableData = (filterMeta, items) => {
  const searchTextAgainstFields = filterMeta[SEARCH_ON_FIELD]
    ? [filterMeta[SEARCH_ON_FIELD]]
    : getOperatingItems()
  const filteredItems =
    filterMeta[SEARCH_KEY_FIELD] || filterMeta[FAVORITE_ONLY_FIELD]
      ? searchByFields({ ...filterMeta, items, searchTextAgainstFields })
      : items
  const sortByFields = filterMeta[SORT_BY_FIELD]
    ? [filterMeta[SORT_BY_FIELD]]
    : getDefaultSortByFields()
  const sortedItems = multiFieldSorting(
    filteredItems,
    sortByFields,
    filterMeta[SORT_TYPE_FIELD]
  )

  return sortedItems
}

async function buildFetchShipTos(args) {
  const result = await getAccounts(args)
  const { status } = result
  return new Promise((resolve, reject) => {
    if (status === 200) {
      const { shipToList } = result
      resolve([...shipToList])
    } else {
      reject([])
    }
  })
}

const buildSetFavoriteShipTo = async (args) => {
  const [language, buyerId, clientId, isFavorite] = args
  const url = `${API_URL}/buyers/${buyerId}/favorite-shiptos/${clientId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
    method: isFavorite ? 'DELETE' : 'POST',
    retry: {
      addPreviousMethodToUrl: true,
      as: 'POST',
    },
  }
  const result = await sendRequest(url, options)
  const { status } = result
  return new Promise((resolve, reject) => {
    if (status === 200) {
      resolve({ ...result })
    } else {
      reject({ ...result })
    }
  })
}

export const setFavoriteShipTo = checkArgsEmpty(buildSetFavoriteShipTo)

export const fetchShipTos = checkArgsEmpty(buildFetchShipTos)
