/**
 * Allows some of the modals to be shown.
 * Based on buyer settings can be found in eway management.
 * Buyer Interface Settings -> HomePage ->
 * Where values can be as follows.
 *
 * @param {*} pathname
 * @returns
 */
export function isAllowedOnPage(pathname) {
  const ALLOWED_PATHS = [
    '/shopping-lists', // Shopping Lists
    '/shopping-cart', // Cart
    '/orders/summaries/[orderType]', // Submitted Orders Summary, Approval Order Summary
    '/', // Home
    '/browse-products/[queryString]', // Search
    '/product-detail/[type]/[productId]', // Product Detail Page , not here by default added as a requirement from product owner
  ]
  return ALLOWED_PATHS.includes(pathname)
}
