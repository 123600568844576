import { getAcaciaApiUrl } from 'services/utils/configUtils'
import isEmpty from 'lodash/isEmpty'
import { sendRequest } from '~/api/apiHelper'
import {
  ADDRESS_GROUPS,
  DELIVERY_ADDRESS_LOCK_API_END_POINT,
} from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'

import { SUCCESS_FALSE } from '~/api/apiHelperConstants'

const ACACIA_API_URL = getAcaciaApiUrl()

export const URL_MAPPING = {
  [ADDRESS_GROUPS.DELIVERY_ADDRESS]: 'delivery-address',
  [ADDRESS_GROUPS.BILLING_ADDRESS]: 'billing-address',
  [ADDRESS_GROUPS.COST_CENTER]: 'cost-center',
}

export async function get(
  buyerId = '',
  principalClientId = '',
  addressLevel,
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS
) {
  const url = `${geAddressUrl(addressGroup)}/${buyerId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      principalClientId,
      currentVisibility: addressLevel,
    },
  }
  const data = await sendRequest(url, options)
  const { isSuccess } = data
  const result = isSuccess ? [...data] : []
  return {
    addresses: result || [],
    isSuccess,
  }
}

export function update(
  id = '',
  payload = [],
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS
) {
  const url = `${geAddressUrl(addressGroup)}/${id}`
  const options = {
    method: 'PATCH',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function create(
  payload,
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS,
  canUseAsBilling = false
) {
  const prefix = geAddressUrl(addressGroup)
  const url = canUseAsBilling
    ? `${prefix}?canUseAsBillingAddress=${canUseAsBilling}`
    : `${prefix}`

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  const result = sendRequest(url, options)
  return result
}

export function remove(id = '', addressGroup) {
  const url = `${geAddressUrl(addressGroup)}/${id}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }
  return sendRequest(url, options)
}

export const addSelectedBuyersIdOnAddress = async (
  addressId,
  buyerIds,
  addressGroup
) => {
  const url = `${geAddressUrl(addressGroup)}/${addressId}/selectedBuyers`
  const options = {
    method: 'POST',
    ...stringifyPayload(buyerIds),
  }
  const result = await sendRequest(url, options)
  return result
}

export function updateAddressLockedFlag(id = '', payload = [], addressGroup) {
  const apiEndPoint = DELIVERY_ADDRESS_LOCK_API_END_POINT
  const url = `${geAddressUrl(addressGroup)}/${id}/${apiEndPoint}`

  const options = {
    method: 'PATCH',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function fetchAssociatedBillingAddresses({
  deliveryAddressId = 0,
  buyerId = '',
  principalClientId,
}) {
  const url = `${ACACIA_API_URL}/delivery-address/${deliveryAddressId}/billing-addresses`

  return sendRequest(url, {
    headers: {
      'Content-Type': 'application/json',
      principalClientId,
      buyerId,
    },
  })
}

/**
 *
 * @param {Array} ids
 * @param {String|Number} addressId
 * @returns
 */
export function assignDeliveryAddress(addressId, ids) {
  if (isEmpty(ids)) {
    return SUCCESS_FALSE
  }
  const payload = ids.map((deliveryAddressId) => {
    return {
      DeliveryAddressId: deliveryAddressId,
      BillingAddressId: addressId,
    }
  })
  const url = `${ACACIA_API_URL}/address-assignment/${addressId}/selectedDeliveryAddress`
  const options = {
    method: 'POST',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function assignDefaultBillingAddress({
  deliveryAddressId,
  billingAddressId,
  buyerId,
  deliveryAddressLevel,
}) {
  if (
    (isEmpty(deliveryAddressId) || isEmpty(billingAddressId), isEmpty(buyerId))
  ) {
    return SUCCESS_FALSE
  }

  const payload = {
    DeliveryAddressId: deliveryAddressId,
    DefaultBillingAddressId: billingAddressId,
    BuyerId: buyerId,
  }
  const url = `${ACACIA_API_URL}/address-assignment/${deliveryAddressLevel}/UpdateDefaultBillingAddress`
  const options = {
    method: 'POST',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function unassignDeliveryAddress(addressId, ids) {
  if (isEmpty(ids)) {
    return SUCCESS_FALSE
  }
  const payload = ids.map((deliveryAddressId) => {
    return {
      DeliveryAddressId: deliveryAddressId,
      BillingAddressId: addressId,
    }
  })
  const url = `${ACACIA_API_URL}/address-assignment`
  const options = {
    method: 'DELETE',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

function geAddressUrl(type) {
  return `${ACACIA_API_URL}/${URL_MAPPING[type]}`
}

function stringifyPayload(payload) {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
}

export function deleteCostCenters(ids) {
  const url = `${ACACIA_API_URL}/cost-center`
  const options = {
    method: 'DELETE',
    ...stringifyPayload(ids),
  }
  return sendRequest(url, options)
}
export function shareCostCenters(payload) {
  const url = `${ACACIA_API_URL}/cost-center/selectedBuyers`
  const options = {
    method: 'POST',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

/**
 *
 * @param {Array} ids
 * @param {String|Number} addressId
 * @returns
 */
export function assignCostCenterDeliveryAddresses(addressId, ids) {
  if (isEmpty(ids)) {
    return SUCCESS_FALSE
  }
  const payload = ids.map((deliveryAddressId) => {
    return {
      DeliveryAddressId: deliveryAddressId,
    }
  })
  const url = `${ACACIA_API_URL}/address-assignment/costcenters/${addressId}/selectedDeliveryAddress`
  const options = {
    method: 'POST',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function unassignCostCenterDeliveryAddresses(addressId, ids) {
  if (isEmpty(ids)) {
    return SUCCESS_FALSE
  }
  const payload = ids.map((deliveryAddressId) => {
    return {
      DeliveryAddressId: deliveryAddressId,
      CostCenterId: addressId,
    }
  })
  const url = `${ACACIA_API_URL}/address-assignment/costcenter`
  const options = {
    method: 'DELETE',
    ...stringifyPayload(payload),
  }
  return sendRequest(url, options)
}

export function fetchAssociatedCostCenters({
  deliveryAddressId = 0,
  buyerId = '',
  principalClientId = '',
}) {
  const url = `${ACACIA_API_URL}/cost-center/byDeliveryAddressId/${deliveryAddressId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
      principalClientId,
      buyerId,
    },
  }

  return sendRequest(url, options)
}
