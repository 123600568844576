import Head from 'next/head'
import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import { checkBloomReachAvailable } from '~/services/thirdPartyAvailability'
import { isBrowser } from '~/services/utils/runtimeUtils'
import {
  bloomReachAnalyticsStatus,
  sendCachedAnalytics,
} from './BloomReachAnalyticsUtils'

/**
 * Name: BloomReachAnalytics
 * Desc: Render BloomReachAnalytics
 * @param {bool} enableTracking
 */

const BloomReachAnalytics = ({ enableTracking }) => {
  useEffect(() => {
    async function checkAvailable() {
      bloomReachAnalyticsStatus(enableTracking)
      if (enableTracking) {
        const ready = await checkBloomReachAvailable()
        ready && sendCachedAnalytics()
      }
    }
    checkAvailable()
  }, [enableTracking])

  return canRenderAnalytics(enableTracking) ? (
    <Head>
      <script
        type="text/javascript"
        src="//cdns.brsrvr.com/v1/br-trk-5292.js"
      />
    </Head>
  ) : null
}

// Default Props
BloomReachAnalytics.defaultProps = {
  enableTracking: true, // Unless told otherwise, always send tracking
}

BloomReachAnalytics.propTypes = {
  enableTracking: PropTypes.bool,
}

export default memo(BloomReachAnalytics)

const canRenderAnalytics = (enableTracking) => {
  return isBrowser() && enableTracking
}
