import { ENGLISH } from '@redux/constants'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import Icon from 'shared/Icon'
import Tooltip from 'shared/Tooltip'
import { useShipToContext } from '~/context/ShipToContext'
import { pressDown, pressEscape } from '~/services/utils/keyboardUtils'
import useOutsideClickHandler from '../CustomHooks/useOutsideClickHandler'
import messages from './messages'
import styles from './ShipToComponent.module.scss'
import ShipToComponentSkeleton from './ShipToComponentSkeleton'
import ShipToComponentStar from './ShipToComponentStar'
import { accountsBy } from './ShipToComponentUtils'
import ShipToFindAccount from './ShipToFindAccount'
import ShipToListContainer from './ShipToListContainer'

function ShipToComponent(props) {
  const {
    addBorder,
    block = false,
    id = 'headerTop',
    language = ENGLISH,
    showShipToLabel = true,
    reloadPageOnItemSelect = true,
    size = null,
    showAccountSearchLink: showAccountSearchLinkFromProps = true,
  } = props
  const {
    accounts = [],
    isLoading,
    showDropdown,
    showAccountSearchLink,
    showStarBesides,
    clientNo,
    readOnly,
    hasTwoOrMoreAccounts,
    selectShipToAccountAndReload,
  } = useShipToContext()

  // props have precedence over context
  const canShowFindAccount =
    showAccountSearchLink && showAccountSearchLinkFromProps

  const hasAccountsAndReady = !isLoading && !!accounts.length
  const [state, setState] = useState(() => {
    return {
      open: false,
      inputFilter: '',
    }
  })
  useEffect(() => {
    if (hasAccountsAndReady) {
      // Set the selected index when component is ready
      setState((current) => ({ ...current, selectedId: clientNo }))
    }
  }, [clientNo, hasAccountsAndReady])
  const shipToComponentRef = useRef(null)

  const handleFilterAccountList = (e) => {
    const selectedId = getFirstItemInListIfSmaller(e)
    setState({
      ...state,
      inputFilter: e.target.value,
      selectedId,
      open: true,
    })
  }

  const getFirstItemInListIfSmaller = (e) => {
    const { accounts = [] } = props
    const { selectedId } = state
    const filteredList = accounts.filter(accountsBy(e.target.value))
    const listIsSmaller = filteredList.length < accounts.length
    // if list is smaller when filtering, go to first item
    const [firstItem] = accounts
    return listIsSmaller ? firstItem.clientNo : selectedId
  }

  const handleSelectItem = async (item) => {
    const options = {
      reload: reloadPageOnItemSelect,
    }
    selectShipToAccountAndReload(item, options)
    hide()
  }

  const handleKeyDown = (e) => {
    const { open } = state
    if (!open && pressDown(e)) {
      e.stopPropagation()
      show()
    }

    if (open && pressEscape(e)) {
      e.stopPropagation()
      hide()
    }
  }

  const show = () => {
    setState({
      ...state,
      open: true,
    })
  }

  const hide = () => {
    setState({
      ...state,
      open: false,
    })
  }

  useOutsideClickHandler(shipToComponentRef, hide)

  const toggleOpenShipToComponent = () => {
    setState({ ...state, open: !state.open })
  }

  const { shipToAccountLabel, tooltipText } = messages[language]
  const shipToDropDownClasses = classNames(styles.shipToSelectionContainer, {
    [styles.fullWidth]: block,
  })
  const shipToClasses = classNames(styles.shipToComponent, {
    [styles.shipToDisabled]: readOnly,
    [styles.withBorder]: addBorder && !isLoading,
    [styles.large]: size === 'lg',
  })

  return (
    <div className={shipToClasses} ref={shipToComponentRef}>
      {hasAccountsAndReady ? (
        <>
          <div className={styles.groupOrderTooltip}>
            {showShipToLabel && (
              <div
                className={styles.shipToSelectionLabel}
                id="ship-to-element"
                data-test="ship-to-account-label"
              >
                {shipToAccountLabel}
              </div>
            )}

            {showDropdown && hasTwoOrMoreAccounts && readOnly && (
              <>
                <QuestionMark language={language} show={readOnly} />
                <Tooltip
                  id="star-tooltip"
                  className={styles.tooltipStyling}
                  place="bottom"
                  offset={{ right: 140, top: 10 }}
                  effect="solid"
                  style={{ width: '290px' }}
                >
                  {tooltipText}
                </Tooltip>
              </>
            )}
          </div>
          {showDropdown && (
            <div className={shipToDropDownClasses}>
              {showStarBesides && (
                <ShipToComponentStar
                  data-tip
                  focusable="false"
                  data-for="star-tooltip"
                  className={styles.starContainer}
                />
              )}
              <ShipToListContainer
                hasTwoOrMoreAccounts={hasTwoOrMoreAccounts}
                block={block}
                size={size}
                id={id}
                clientNo={clientNo}
                disabled={readOnly}
                accounts={accounts}
                onFilterAccounts={handleFilterAccountList}
                onSelectItem={handleSelectItem}
                onKeyDown={handleKeyDown}
                onClick={toggleOpenShipToComponent}
                open={state.open}
                inputFilter={state.inputFilter}
                selectedId={state.selectedId}
                language={language}
              />
            </div>
          )}
          {canShowFindAccount && (
            <ShipToFindAccount language={language} readOnly={readOnly} />
          )}
        </>
      ) : (
        <ShipToComponentSkeleton />
      )}
    </div>
  )
}

ShipToComponent.propTypes = {
  addBorder: PropTypes.bool,
  accounts: PropTypes.array,
  block: PropTypes.bool,
  id: PropTypes.string,
  language: PropTypes.string,
  reloadPageOnItemSelect: PropTypes.bool,
  showAccountSearchLink: PropTypes.bool,
  showShipToLabel: PropTypes.bool,
  size: PropTypes.string,
}

export default ShipToComponent

function QuestionMark({ show, language }) {
  const { questionMarkText } = messages[language]
  return (
    show && (
      <>
        <div className={styles.questionIcon}>
          <Icon variant="question" data-tip data-for="question-tooltip" />
        </div>
        <Tooltip
          id="question-tooltip"
          className={styles.tooltipStyling}
          place="bottom"
          offset={{
            right: 140,
            top: 10,
          }}
          effect="solid"
          style={{
            width: '290px',
          }}
        >
          {questionMarkText}
        </Tooltip>
      </>
    )
  )
}

QuestionMark.propTypes = {
  language: PropTypes.string,
  show: PropTypes.bool,
}
