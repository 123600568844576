import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import { useToggler } from '~/components/shared/CustomHooks/useToggler'
import NavBarMegaMenu from './NavBarMegaMenu'
import NavBarMenuButton from './NavBarMenuButton'
import { useRef } from 'react'
import { defaultGetSecondaryMenusFocusableElements } from './NavBarMegaMenu/NavBarMegaMenuKeyboardAccessible'

function NavBarDesktopMenu({
  children,
  relativeCenter,
  buttonText,
  getSecondaryMenusFocusableElements = defaultGetSecondaryMenusFocusableElements,
  ...rest
}) {
  const { buttonRef, isOpen, handleClickOutside, toggle, close } =
    useNavBarToggler()
  const finalRest = {
    ...rest,
    ...(relativeCenter && { position: 'relative' }),
  }

  return (
    <ListItem {...finalRest}>
      <NavBarMenuButton
        text={buttonText}
        isOpen={isOpen}
        onClick={toggle}
        ref={buttonRef}
      />

      <NavBarMegaMenu
        isOpen={isOpen}
        relativeCenter={relativeCenter}
        onClickOutside={handleClickOutside}
        close={close}
        buttonRef={buttonRef}
        getSecondaryMenusFocusableElements={getSecondaryMenusFocusableElements}
      >
        {children}
      </NavBarMegaMenu>
    </ListItem>
  )
}

NavBarDesktopMenu.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  relativeCenter: PropTypes.bool,
  isMegaMenu: PropTypes.bool,
  getSecondaryMenusFocusableElements: PropTypes.func,
}
export default NavBarDesktopMenu

function useNavBarToggler() {
  const buttonRef = useRef()
  const { isOpen, toggle, close } = useToggler()

  const handleClickOutside = (e) => {
    if (e.target !== buttonRef.current) {
      close()
    }
  }
  return { isOpen, buttonRef, close, toggle, handleClickOutside }
}
