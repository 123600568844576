import { FIELDS } from '../SaveAddressFormConstants'
export default {
  en: {
    postalCodeTooltip: `Please ensure the postal code is entered using one of the following formats:   A1A1A1 OR A1A 1A1 (where A is a letter and 1 is a digit).`,
    [FIELDS.COMPANY_NAME]: `Company/Individual Name`,
    [FIELDS.BUILDING]: 'Building/Floor/Suite/Room',
    [FIELDS.ATTENTION]: 'Attention',
    [FIELDS.CITY]: 'City',
    [FIELDS.STREET_ADDRESS]: 'Street Address',
    [FIELDS.PROVINCE]: 'Province',
    [FIELDS.POSTAL_CODE]: 'Postal Code',
    [FIELDS.IS_DEFAULT]: 'Set as primary address',
    [FIELDS.USE_AS_BILLING]: 'Use this address as a Billing Address',
  },
  fr: {
    postalCodeTooltip: `Veuillez-vous assurer que le code postal est entré en utilisant l'un des formats suivants : A1A1A1 OU A1A 1A1 (où A est une lettre et 1 est un chiffre).`,
    [FIELDS.COMPANY_NAME]: `Compagnie/Nom de la personne`,
    [FIELDS.BUILDING]: 'Édifice/Étage/Suite/Bureau',
    [FIELDS.ATTENTION]: 'Attention',
    [FIELDS.CITY]: 'Ville',
    [FIELDS.STREET_ADDRESS]: 'Adresse civique',
    [FIELDS.PROVINCE]: 'Province',
    [FIELDS.POSTAL_CODE]: 'Code postal',
    [FIELDS.IS_DEFAULT]: 'Définir comme adresse principale',
    [FIELDS.USE_AS_BILLING]:
      'Utiliser cette adresse comme adresse de facturation',
  },
}
