import PropTypes from 'prop-types'
import { memo } from 'react'
import Button from 'shared/Button'
import Dialog from 'shared/Dialog'
import Text from 'shared/Text'
import { ENGLISH } from '~/redux/constants'
import messages from './messages'
import styles from './SessionTimeOutModal.module.scss'
import { handleOk, useIsIdle } from './SessionTimeOutModalUtils'
// Default expiry time
const MILLISECOND = 1000
const SECONDS = 60
const MINUTES = 60
const ONE_HOUR = MILLISECOND * SECONDS * MINUTES
/**
 * Name: SessionTimeOutModal
 * Desc: Popup will display when session is expired.
 * @param {string} language
 * @param {number} timeout
 */
const SessionTimeOutModal = ({ language, timeout }) => {
  const { isInactive } = useIsIdle(timeout)

  return (
    <Dialog open={isInactive} variant="md" id="sessionTimeOutModal">
      <div className={styles.dialogHeaderBg}></div>
      <div className={styles.dialogContentWrapper}>
        <div className={styles.dialogHeader}>
          <Text
            text={messages[language].sessionTimeOutHeading}
            variant="xxlgText"
            color="black"
          />
        </div>
        <div className={styles.dialogContant}>
          <Text
            text={messages[language].sessionTimeOutMessage}
            variant="xxlgText"
            color="black"
          />
        </div>
        <div className={styles.dialogFooterWrapper}>
          <Button
            text={messages[language].okLabel}
            onClick={handleOk}
            variant="primary"
          />
        </div>
      </div>
    </Dialog>
  )
}

// Default Props
SessionTimeOutModal.defaultProps = {
  timeout: ONE_HOUR,
  language: ENGLISH,
}

// props validation
SessionTimeOutModal.propTypes = {
  language: PropTypes.string,
  timeout: PropTypes.number,
}

export default memo(SessionTimeOutModal)
