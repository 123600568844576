import { pressDown, pressEscape, pressUp } from '~/services/utils/keyboardUtils'
import { KEYS } from '../useKeyPressEvent'

export const getDefaultKeyboardAction = (e) => {
  const { key, shiftKey } = e
  const isTab = key === KEYS.TAB
  const isNextSelection = pressDown(e) || (isTab && !shiftKey)
  const isPreviousSelection = pressUp(e) || (isTab && shiftKey)
  const rightPress = key === KEYS.ARROW_RIGHT
  const leftPress = key === KEYS.ARROW_LEFT
  const isExit = pressEscape(e)

  return {
    isNextSelection,
    isPreviousSelection,
    rightPress,
    leftPress,
    isExit,
    isTab,
  }
}
// Alias name
export const getKeyboardAction = getDefaultKeyboardAction

export const classMutationObserver = (selectedClassName) => {
  return function (event) {
    event.forEach((mutation) => {
      const { target: selectedElement = {} } = mutation
      selectedElement &&
        selectedElement?.classList?.contains(selectedClassName) &&
        selectedElement?.focus()
    })
  }
}
