/**
 * Name: SearchServices
 * Desc: business logics for search functionality
 */
import { ENGLISH } from '@redux/constants'
import { getHostUrl } from 'services/utils/configUtils'
import {
  getSearchByKeywordResult,
  updateSearchType,
} from '~/api/searchOperations'
import { getSearchParamsFromRouter } from '~/components/containers/BrowseProducts/BrowseProductsUtils'
import { getCookie } from './cookieManager'
import { openInNewTab } from './utils'

const HOST_URL = getHostUrl()
const CATEGORY_ID_DIFFERENCE = 1000000
/**
 * Name: fetchSearchResultByKeyword
 * @param {*} language
 * @param {*} keyword
 * @param {*} preSearchFilter
 */
export async function fetchSearchResultByKeyword(
  language,
  keyword,
  preSearchFilter,
  hideRestrictions
) {
  const reqParams = {
    Br_Uid: getCookie('_br_uid_2'),
    Ids: '',
    Keyword: keyword,
    PreSearchFilter: preSearchFilter, //2 by default; If user is PWGS: 0 = All, 1 = StadingOffers
    SGSType: 0, // Need disucssion
    SearchRefinementHistory: '',
    ShowHideRestrictions: hideRestrictions, //2 by default; 0 = Hide, 1 = Show; Used to show or hide restrictions, taken from the cookie (UserInfo:ShowHideRestrictions=[0|1])
    Spk: '',
    TrackingId: getCookie('RES_TRACKINGID'),
    VisitorId: getCookie('RES_TRACKINGID'),
  }
  const result = await getSearchByKeywordResult(language, reqParams)
  return result
}

/**
 * Name: onChangeLanguageManageCategoryId
 * Desc: (SCE-2246) Below function is used for mapping the category id for English and french as per the discussion with Maxime Theriault.
 * EN and FR both have different category id on Mega Menu
 */
export const onChangeLanguageManageCategoryId = (language, router) => {
  const { browsProdQuery, searchType } = getSearchParamsFromRouter(router)
  const { isCategorySearch } = searchType
  if (isCategorySearch) {
    const [categoryId] = browsProdQuery.split('?')
    if (categoryId) {
      let catId = Number(categoryId)
      if (language === ENGLISH) {
        catId -= CATEGORY_ID_DIFFERENCE
        catId
      } else {
        catId += CATEGORY_ID_DIFFERENCE
      }

      window.location.assign(
        `${HOST_URL}/${language}/Shopping/Search/Category/${catId}${
          window.location.search || ''
        }`
      )
    }
  }
}
export const bloomReachSearchRedirection = (
  router,
  redirectUrl,
  isExterneUrl
) => {
  const { searchType } = getSearchParamsFromRouter(router)
  const { isKeywordSearch } = searchType
  if (isKeywordSearch) {
    if (isExterneUrl) {
      openInNewTab(redirectUrl)
    } else {
      window.location.assign(redirectUrl)
    }
  }
}

function isSuccess(status) {
  return status === 200
}
export const saveSearchOption = async (option, buyerId) => {
  const { status: StatusCode } = await updateSearchType(option, buyerId)

  return {
    isSuccess: isSuccess(StatusCode),
    status: StatusCode,
    errorType: 'invalidSearchType',
  }
}
