import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useCallback, useReducer } from 'react'
import Button from 'shared/Button'
import HamburgerChildCategory from '../HamburgerChildCategory'
import HamburgerMenuCategoryList from '../HamburgerMenuCategoryList'
import styles from './HamburgerNavBar.module.scss'
import {
  hamburgerInitialState,
  HAMBURGER_NAV,
} from './HamburgerNavBarConstants'
const { SET_MENU_ACTIVE, SET_MENU_LIST_ACTIVE, SET_CHILD_MENU_ITEM } =
  HAMBURGER_NAV
/**
 * Name:HamburgerNavBar
 * Desc: Render category list
 * @param {string} language
 * @param {array} categoryItem
 * @param {array} topNavBarItems
 * @param {bool} enablePwgsCheckout
 */

const HamburgerNavBar = ({
  language,
  categoryItem,
  topNavBarItems,
  enablePwgsCheckout,
  dealsMenuItems = [],
  publicationsMenuItems = [],
  brandsMenuItems = [],
}) => {
  const [state, dispatch] = useReducer(hamburgerReducer, hamburgerInitialState)
  const { isMenuActive, isShowMainList, childMenuItem } = state

  const setMenuIconStatus = useCallback(() => {
    dispatch({ type: SET_MENU_ACTIVE, payload: !isMenuActive })
    dispatch({ type: SET_MENU_LIST_ACTIVE, payload: !isMenuActive })
  }, [isMenuActive])

  const switchMenuListHandler = (showMainList, items = {}) => {
    dispatch({ type: SET_MENU_LIST_ACTIVE, payload: showMainList })
    dispatch({ type: SET_CHILD_MENU_ITEM, payload: { ...items } })
  }
  const classes = classNames(styles.menuIcon, {
    [`${styles.menuIconWithPwgs}`]: enablePwgsCheckout,
  })

  return (
    <>
      <div className={classes}>
        <Button
          className={styles.icon}
          onClick={setMenuIconStatus}
          aria-label="menuButton"
          icon={isMenuActive ? 'close' : 'listView'}
        />
      </div>
      {isMenuActive && (
        <div className={styles.hamburgerNavbar}>
          {isShowMainList && (
            <HamburgerMenuCategoryList
              dealsMenuItems={dealsMenuItems}
              publicationsMenuItems={publicationsMenuItems}
              brandsMenuItems={brandsMenuItems}
              language={language}
              mainCategoryItem={categoryItem}
              topNavBarItems={topNavBarItems}
              switchMenuListHandler={switchMenuListHandler}
            />
          )}
          {!isShowMainList && (
            <HamburgerChildCategory
              language={language}
              childCategoryItem={childMenuItem}
              topNavBarItems={topNavBarItems}
              switchMenuListHandler={switchMenuListHandler}
            />
          )}
        </div>
      )}
    </>
  )
}

// Props Validation
HamburgerNavBar.propTypes = {
  language: PropTypes.string.isRequired,
  categoryItem: PropTypes.array.isRequired,
  topNavBarItems: PropTypes.array.isRequired,
  enablePwgsCheckout: PropTypes.bool,
  dealsMenuItems: PropTypes.array,
  publicationsMenuItems: PropTypes.array,
  brandsMenuItems: PropTypes.array,
}

export default HamburgerNavBar

function hamburgerReducer(state, action) {
  switch (action.type) {
    case HAMBURGER_NAV.SET_MENU_ACTIVE:
      return { ...state, isMenuActive: action.payload }
    case HAMBURGER_NAV.SET_MENU_LIST_ACTIVE:
      return { ...state, isShowMainList: action.payload }
    case HAMBURGER_NAV.SET_CHILD_MENU_ITEM:
      return { ...state, childMenuItem: action.payload }
  }
  return state
}
