import PropTypes from 'prop-types'
import { Component } from 'react'
import { eventManager } from 'services/eventsManager'
import { HIDE_NOTIFICATION_ALL } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import ErrorBoundaryPage from 'shared/ErrorBoundaryPage'
import { logError } from '~/services/telemetaryData/loggerService'
import styles from './LayoutContainer.module.scss'

class LayoutContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidUpdate = () => {
    if (this.state.hasError) {
      eventManager.emit(HIDE_NOTIFICATION_ALL)
    }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    const { enableErrorReporting, isLoggedIn } = this.props
    logError(error, isLoggedIn, enableErrorReporting)
  }

  render() {
    const children = this.state.hasError ? (
      <div className={styles.container}>
        <ErrorBoundaryPage language={this.props.language} />
      </div>
    ) : (
      this.props.children
    )

    return <div className={styles.content}>{children}</div>
  }
}

// props validation
LayoutContainer.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string,
  enableErrorReporting: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
}

export default LayoutContainer
