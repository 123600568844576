import PropTypes from 'prop-types'
import FormField from 'shared/FormControl/FormField'
import { useShipToContext } from '~/context/ShipToContext/ShipToContext'
import { ENGLISH } from '~/redux/constants'
import Button from '../../Button'
import Column from '../../Column'
import DynamicTranslation from '../../DynamicTranslation'
import QuestionTooltip from '../../QuestionTooltip'
import Row from '../../Row'
import ShipToComponent from '../../ShipToComponent/ShipToComponent'
import Text from '../../Text'
import messages from './messages'
import styles from './ShipToSelectionTab.module.scss'
import ShipToSelectionTabSkeleton from './ShipToSelectionTabSkeleton'
export default function ShipToSelectionTab({
  details = {},
  buyer = {},
  language,
  onCheckboxChange,
  onClose,
  isChecked,
  onClickFindAccount,
}) {
  const {
    clientNo,
    shippingName,
    shippingAddress1,
    shippingAddress3,
    shippingPostalCode,
    costCenter,
  } = details
  const shipToProps = useShipToContext()
  const { hasTwoOrMoreAccounts, isLoading } = shipToProps
  const descriptionText = hasTwoOrMoreAccounts
    ? messages[language].changeShipToMultiple
    : messages[language].changeShipToSingle

  const linkAccountLink = (
    <DynamicTranslation
      text={descriptionText}
      replace="{link}"
      with={
        <Button
          data-test="find-account-link-button"
          variant="redLink"
          onClick={onClickFindAccount}
          text={messages[language].findAccount}
          color="primary"
        />
      }
    />
  )

  const showShipToComponent = !buyer.isInGroupOrder && hasTwoOrMoreAccounts
  return (
    <>
      <div className={styles.shipToSelection}>
        <Row>
          <Column>
            <Text className={styles.mb10} variant="mdText" as="div">
              {messages[language].youAreShoppingOn}&nbsp;:
            </Text>
            <Text
              className={`${styles.mb20} ${styles.fontMedium} ${styles.xLargeFont}`}
              variant="xxlgText"
              fontWeight="strong"
              text={`${clientNo} - ${shippingName}`}
              as="div"
            />
            <Text
              className={styles.mb5}
              variant="mdText"
              fontWeight="strong"
              text={shippingName}
              as="div"
            />
            <Text
              className={styles.mb5}
              variant="mdText"
              fontWeight="strong"
              text={shippingAddress1}
              as="div"
            />
            <Text
              className={styles.mb5}
              variant="mdText"
              fontWeight="strong"
              text={`${shippingAddress3}, ${shippingPostalCode}`}
              as="div"
            />
            <Text
              variant="mdText"
              fontWeight="strong"
              text={`${messages[language].costCenter} : ${shippingName}`}
              as="div"
            />
          </Column>
          <Column>
            {isLoading ? (
              <ShipToSelectionTabSkeleton />
            ) : (
              <>
                <Text
                  variant="mdText"
                  dataTest="ship-to-description-text"
                  text={linkAccountLink}
                  as="div"
                  className={`${styles.mb10} ${styles.changeShipToLabel}`}
                />
                {showShipToComponent && (
                  <div
                    className={styles.mb20}
                    data-test="ship-to-dropdown-in-ship-to-selection-tab"
                  >
                    <ShipToComponent
                      reloadPageOnItemSelect={false}
                      block
                      addBorder={true}
                      size="lg"
                      costCenter={costCenter}
                      language={language}
                      showShipToLabel={false}
                      {...shipToProps}
                      showAccountSearchLink={false}
                    />
                  </div>
                )}
              </>
            )}
            <Text
              className={`${styles.fontMedium} ${styles.whyAmI}`}
              as="div"
              variant="mdText"
            >
              <QuestionTooltip
                text={messages[language].tooltipText}
                offset={{ right: 150 }}
                place="bottom"
                id="ship-to-selection-question-tooltip"
              >
                {messages[language].why}
              </QuestionTooltip>
            </Text>
          </Column>
        </Row>
        <div className={styles.bottomSection}>
          <FormField
            label={messages[language].doNotShow}
            type="checkbox"
            name="doNotShowFuturSessions"
            checked={isChecked}
            onChange={onCheckboxChange}
            id="doNotShowFuturSessions"
            data-test="doNotShowFuturSessions"
          />
          <Button
            text={messages[language].close}
            data-test="close-button"
            variant="primary"
            onClick={onClose}
            className={styles.bottomButton}
          />
        </div>
      </div>
    </>
  )
}

ShipToSelectionTab.propTypes = {
  buyer: PropTypes.object,
  details: PropTypes.object,
  language: PropTypes.string,
  onClose: PropTypes.func,
  settings: PropTypes.object,
  isChecked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onClickFindAccount: PropTypes.func,
}

// Default Props
ShipToSelectionTab.defaultProps = {
  language: ENGLISH,
}
