import PropTypes from 'prop-types'
import Icon from 'shared/Icon'
import styles from './MenuItemContent.module.scss'

const MenuItemContent = ({ label, icon, color, children }) => {
  const colorClass = color === 'black' ? styles.veryDarkGrey : styles.white
  return (
    <>
      <span className={styles.menuIcons}>
        <Icon variant={icon} className={colorClass} />
        {children}
      </span>

      <div className={styles.menuText} data-test={label}>
        {label}
      </div>
    </>
  )
}

// Props Validation
MenuItemContent.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
}

export default MenuItemContent
