const HOST_URL = getHostUrl()
import uniqueId from 'lodash/uniqueId'
import { getHostUrl } from 'services/utils/configUtils'
import {
  addCategoryQueryString,
  encodeValues,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'

import { purifyHtmlString } from '~/services/utils'
const PRODUCTS = 'productsLabel'
export function formatCategories(categories = []) {
  return categories.map((category) => {
    const isProducts = category.Id === PRODUCTS

    // Formats the sub categories, the first level
    const data = formatNavBarSubCategoryData(category.Dimensions, isProducts)
    // Formats the categories, the first level
    const Dimensions = category.Dimensions.map((item) => {
      item.level /*?*/
      const rawImageName = item.ImageName ?? ''
      const image = rawImageName.replace('~', '')
      // SCE-5449 :  Host url only added to ImageName starting with ~
      const imageUrl = rawImageName.includes('~')
        ? `${HOST_URL}${image}`
        : image

      return {
        ...item,
        id: uniqueId(),
        ImageName: imageUrl,
      }
    })
    return {
      ...category,
      Dimensions,
      id: uniqueId(),
      data,
    }
  })
}

export function formatNavBarSubCategoryData(
  data = [],
  isBrowseCallingLocation = false
) {
  return data.map((item, index) => {
    const hasItems = !!item.Dimensions.length
    const linkText = purifyHtmlString(item.Name)
    const dataIndex = hasItems ? index : -1

    const values = encodeValues([item.Name])
    const link = `${addCategoryQueryString(
      item.Url,
      values,
      isBrowseCallingLocation
    )}`
    return {
      id: uniqueId(),
      link,
      target: item.target || '_self',
      dataIndex,
      linkText,
    }
  })
}
