import {
  isCheckoutPage,
  isShoppingCartPage,
} from 'components/shared/Utils/pageUtils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { removeLocalStorage } from 'services/storageManager'
import { WARNING } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import Button from 'shared/Button'
import { showNotification } from '~/components/shared/Alerts'
import { ENGLISH } from '~/redux/constants'
import useStateCustom from '../CustomHooks/useStateCustom'
import { checkoutButtonAdobeAnalytics } from './CheckoutButtonAnalyticsUtils'
import { validateCustomPromptFields } from './CheckoutButtonUtils'
import { HAS_CUSTOM_PROMPT_ERRORS } from './constant'
import messages from './messages'
import { useSettingsContext } from '~/context/SettingsContext'
import { useCustomPromptsContext } from '../ProductCard/ProductCardBody/ProductContent/CustomPromptContainer/CustomPromptContext'

/**
 * CheckoutButton component for render checkout button
/*
 * @param {string} text
 * @param {string} language
 * @param {bool} isCheckoutDisable
 * @param {string} pageName
 * @param {bool} showCustomPromptDetail
 * @param {object} order
 * @returns element
 */

const CheckoutButton = ({
  text,
  language = ENGLISH,
  isCheckoutDisable,
  pageName,
  onFinish,
  order = {},
  showCustomPromptDetail,
  showCustomerOnboardingWarning,
}) => {
  const { isPunchOutUser } = useSettingsContext()
  const { runCustomPromptsValidations } = useCustomPromptsContext()
  const [isDoneAsyncActions, setIsDoneAsyncActions] = useStateCustom(false)
  const handleCheckoutAction = async () => {
    if (showCustomerOnboardingWarning) {
      const warningMsg = messages[language].cobWarningMsg
      showNotification[WARNING](warningMsg, { toast: true })
      return
    }
    checkoutButtonAdobeAnalytics(order, isPunchOutUser)
    removeLocalStorage(HAS_CUSTOM_PROMPT_ERRORS)
    if (!showCustomPromptDetail && isCheckoutPage(pageName)) {
      setIsDoneAsyncActions(true)
    } else {
      if (isShoppingCartPage(pageName)) {
        runCustomPromptsValidations()
      }
      const { status } = await validateCustomPromptFields(language)
      if (status === 200) {
        setIsDoneAsyncActions(true)
      }
    }
  }

  useEffect(() => {
    if (isDoneAsyncActions) {
      onFinish?.()
    }
  }, [isDoneAsyncActions, onFinish])

  return (
    <Button
      variant="primary"
      text={text || messages[language].checkoutLabel}
      disabled={isCheckoutDisable}
      onClick={handleCheckoutAction}
      data-test="CheckoutBtn"
    />
  )
}
CheckoutButton.defaultProps = {
  showCustomPromptDetail: true,
}
CheckoutButton.propTypes = {
  text: PropTypes.string,
  language: PropTypes.string,
  pageName: PropTypes.string,
  isCheckoutDisable: PropTypes.bool,
  showCustomerOnboardingWarning: PropTypes.bool,
  onFinish: PropTypes.func,
  order: PropTypes.object,
  showCustomPromptDetail: PropTypes.bool,
}

export default CheckoutButton
