import { ENGLISH } from '@redux/constants'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getLiveHelpUrl } from 'services/utils/configUtils'
import LiveChatButton from './LiveChatButton'
import { checkChatServiceAvailable } from 'services/thirdPartyAvailability'
const LIVE_HELP_URL = getLiveHelpUrl()

/**
 * Name: LiveChat
 * Desc: To be implement logic of live chat in header top section
 * @param {string} language
 * @param {bool} isCheckoutLiveChat
 */

const LiveChat = ({ language, isCheckoutLiveChat }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    async function initLiveChat() {
      const isAvailable = await checkChatServiceAvailable()
      isAvailable && setShow(true)
    }
    initLiveChat()
  }, [])

  return LIVE_HELP_URL ? (
    <>
      <Head>
        <script type="text/javascript" async src={LIVE_HELP_URL} />
      </Head>
      {show ? (
        <div>
          <LiveChatButton
            language={language}
            isCheckoutLiveChat={isCheckoutLiveChat}
          />
        </div>
      ) : null}
    </>
  ) : null
}

LiveChat.defaultProps = {
  language: ENGLISH,
  isCheckoutLiveChat: false,
}

LiveChat.propTypes = {
  language: PropTypes.string.isRequired,
  isCheckoutLiveChat: PropTypes.bool,
}

export default LiveChat

/**
 * Controls the language sent to PowerFront (the live chat)
 * Also gives additional data such as firstName, lastName, etc.
 * @param {*} param0
 */
