export default {
  en: {
    shipToSelection: 'Ship to Selection',
    findAccount: 'Find Account',
  },
  fr: {
    shipToSelection: 'Sélection de compte',
    findAccount: 'Trouver un compte',
  },
}
