import PropTypes from 'prop-types'
import { useNavBarContext } from '../NavBarMegaMenuUtils'
import styles from 'components/shared/Layout/Header/NavBar/NavBarDesktop/NavBarDesktopMenu/NavBarMegaMenu/NavBarMegaMenu.module.scss'

export function NavBarSecondaryMegaMenu({ children }) {
  const { secondaryPanelListRef } = useNavBarContext()
  return (
    <div ref={secondaryPanelListRef} className={styles.secondaryDefaults}>
      {children}
    </div>
  )
}

NavBarSecondaryMegaMenu.propTypes = {
  children: PropTypes.node,
}
