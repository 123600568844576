import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { useManageAddresses } from 'shared/ManageAddresses/useManageAddresses'
import { updateSession } from '~/api/facultyCenter/facultyCenterOperations'
import { COOKIES } from '~/services/constants'
import { getCookie } from '~/services/cookieManager'
import { somethingWentWrong } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import { getAddressById } from './ManageAddressesContextUtils'
import { getPrincipalClientId } from '~/components/containers/ManageAddressesContainer/ManageAddressesContainerUtils'
import { useEffectOnce } from 'components/shared/CustomHooks/useEffectOnce'
import { TEMPORARY_DELIVERY_ADDRESS_ID } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
const ManageAddressesContext = createContext({ accounts: [] })
const { Provider, Consumer } = ManageAddressesContext

/**
 * This context should be small, and stay small,
 * Please do not add any business logic that "shows" or hides "things" in components
 */
export function ManageAddressesContextProvider({ children, ...props }) {
  const {
    selectedDeliveryAddressId: initialValue,
    language,
    principalClientNo,
  } = props
  const [principalClientId, setPrincipleClientID] = useState()
  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] =
    useState(initialValue)

  function updateDeliveryAddressId(addressId) {
    setSelectedAddress(addressId)
  }

  useEffectOnce(async () => {
    const data = await getPrincipalClientId(principalClientNo)
    if (data.isSuccess) {
      setPrincipleClientID(data.principleClientShipToId)
    }
  })

  const state = useManageAddresses({
    principalClientId,
    ...props,
  })

  const { addresses = [], hasNoAddresses = true, primaryAddress } = state
  addresses.sort((a, b) =>
    a.isDefault === true ? -1 : b.isDefault === true ? 1 : 0
  )

  const selectedDeliveryAddress = getAddressById(
    addresses,
    selectedDeliveryAddressId
  )

  async function setSelectedAddress(id) {
    setSelectedDeliveryAddressId(id)
    // not update session when selected temporay delivery address
    if (id !== TEMPORARY_DELIVERY_ADDRESS_ID) {
      try {
        await updateSession(getCookie(COOKIES.SESSION_ID), [
          { op: 'replace', path: 'DeliveryAddressId', value: id },
        ])
      } catch (_) {
        somethingWentWrong(language, { toast: true })
      }
    }
  }

  // Sets the default address id
  useEffect(() => {
    if (!hasNoAddresses && !selectedDeliveryAddressId) {
      const fallBackId = addresses.length === 1 ? addresses[0]?.id : undefined
      const id = primaryAddress.id || fallBackId
      setSelectedDeliveryAddressId(id)
    }
  }, [addresses, hasNoAddresses, primaryAddress.id, selectedDeliveryAddressId])

  return (
    <Provider
      value={{
        ...state,
        principalClientId,
        isSameAsInitialValue: selectedDeliveryAddressId === initialValue,
        selectedDeliveryAddressId,
        selectedDeliveryAddress,
        updateDeliveryAddressId,
        setSelectedAddress,
      }}
    >
      {children}
    </Provider>
  )
}

ManageAddressesContextProvider.propTypes = {
  buyerId: PropTypes.string,
  children: PropTypes.node,
  language: PropTypes.any,
  allowFetch: PropTypes.bool,
  primaryDeliveryAddressId: PropTypes.number,
  selectedDeliveryAddressId: PropTypes.number,
  addressLevel: PropTypes.number,
  principalClientNo: PropTypes.number,
}

export function useManageAddressesContext() {
  return useContext(ManageAddressesContext)
}

export { Consumer as ManageAddressConsumer }
export default ManageAddressesContext
