import pick from 'lodash/pick'
export function getApiMappingValuesByFields(API_MAPPING, value) {
  return pick(API_MAPPING, Object.values(value))
}

export function reverseKeys(obj) {
  return Object.fromEntries(Object.entries(obj).map((a) => a.reverse()))
}

/**
 * @param {object} options - Options object that takes a with and from key
 * @param {object} options.with - Create the map of values that should be replaced
 * @param {object} options.from - The API payload you need to pass in
 * @returns {object} - Returns a new payload with the replaced keys based on the mapping
 */
export function mapKeysForApi({
  apiMapping: API_MAPPING = {},
  payload = {},
  options = {},
} = {}) {
  const { includeEmptyValues = false } = options
  const callback = includeEmptyValues ? mapKeysWithEmptyValues : mapAllKeys
  const replacedValues = callback(API_MAPPING, payload)
  const finalResult = Object.fromEntries(replacedValues)
  return finalResult
}

function mapKeysWithEmptyValues(mapping, payload) {
  return Object.entries(mapping).map((entry) => {
    const [key, value] = entry
    const finalValue = payload[key]
    return [value, finalValue || '']
  })
}

function mapAllKeys(mapping, payload) {
  const apiMappingKeys = Object.keys(mapping)
  return Object.entries(payload).map((entry) => {
    const [key, value] = entry
    const finalValue = apiMappingKeys.includes(key)
      ? [mapping[key], value]
      : [key, value]
    return finalValue
  })
}
