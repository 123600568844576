import PropTypes from 'prop-types'
import Heading from 'shared/Heading'
import Box from 'shared/Box'
import Text from 'shared/Text'
import { ENGLISH } from '~/redux/constants'
import AccountMenu from '../AccountMenu'
import messages from './messages'
import styles from './UserDetails.module.scss'

/**
 *Name: UserDetails
 *Desc: Render user details
 * @param {string} language
 * @param {string} firstName
 * @param {string} costCenter
 * @param {number} shipToNumber
 * @param {string}  createdBy
 * @param {number}  groupOrderId
 * @param {bool}  isGroupOrder
 * @param {object} buyer
 * @param {object} settings
 */

const UserDetails = ({
  firstName,
  costCenter,
  shipToNumber,
  language,
  createdBy,
  groupOrderId,
  isGroupOrder,
  buyer,
  settings,
  details,
  isMenuActive,
}) => {
  const hasShipToNumber = !!shipToNumber
  const { helloLabel } = messages[language]

  return (
    <div className={styles.accountContent}>
      <div className={styles.accountContentHeader}>
        <Heading text={`${helloLabel} ${firstName}!`} as="h5" color="primary" />
        {hasShipToNumber && !isGroupOrder && (
          <Box display="flex" align="center">
            <Text
              color="secondary"
              variant="mdText"
              text={costCenter}
              className={styles.shipNumberText}
            />
            <Text
              color="secondary"
              variant="mdText"
              text={`(${shipToNumber})`}
              data-test="ship-to-in-account"
            />
          </Box>
        )}
      </div>
      <AccountMenu
        language={language}
        buyer={buyer}
        settings={settings}
        groupOrderId={groupOrderId}
        createdBy={createdBy}
        isGroupOrder={isGroupOrder}
        details={details}
        isMenuActive={isMenuActive}
      />
    </div>
  )
}

// Default Props
UserDetails.defaultProps = {
  language: ENGLISH,
  firstName: '',
  costCenter: '',
  createdBy: '',
  groupOrderId: 0,
  isGroupOrder: false,
}

// Props Validation
UserDetails.propTypes = {
  language: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  costCenter: PropTypes.string.isRequired,
  shipToNumber: PropTypes.number,
  createdBy: PropTypes.string,
  groupOrderId: PropTypes.number,
  isGroupOrder: PropTypes.bool,
  buyer: PropTypes.object,
  settings: PropTypes.object,
  details: PropTypes.object,
  isMenuActive: PropTypes.bool,
}

export default UserDetails
