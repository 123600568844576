import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import FormField from 'shared/FormControl/FormField'
import Text from 'shared/Text'
import classNames from 'classnames'
import { useState } from 'react'
import { getPasswordReminder } from 'api/userAuthOperations'
import SignInMessage from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/SignInMessage'
import messages from './messages'
import styles from './PasswordReminder.module.scss'
import { MESSAGE_TYPE, USER_ID } from '../signInConstants'
/**
 * Name: PasswordReminder
 * Desc: Render password reminder form
 * @param {string} language
 * @param {bool} isShowBorderBottom
 * @param {bool} isFullSizeButton
 */
const PasswordReminder = ({
  language,
  isShowBorderBottom,
  isFullSizeButton,
}) => {
  const [passwordReminderMsg, setPasswordReminderMsg] = useState({
    type: null,
    message: '',
  })
  const initValue = {
    [USER_ID]: {
      value: '',
      required: messages[language].requiredUserIdMsg,
    },
  }
  const {
    handleChange,
    handleSubmit,
    errors,
    values = {},
    dirties,
  } = useFormControlled(initValue, language, {
    loadWhen: true,
  })

  const formEventHandler = (event) => {
    handleChange(event)
  }
  const { SUCCESS, ERROR } = MESSAGE_TYPE
  const handleSubmitForm = async (event) => {
    const { isFormValid } = handleSubmit(event)
    if (isFormValid) {
      const { userId = '' } = values
      const { sentTo = '', status } = await getPasswordReminder(
        language,
        userId
      )
      if (status !== 404) {
        setPasswordReminderMsg({
          type: SUCCESS,
          message: messages[language].passwordIsSentMsg + sentTo,
        })
      } else {
        setPasswordReminderMsg({
          type: ERROR,
          message: messages[language].invalidUserId,
        })
      }
    }
  }

  const mainBlockStyle = classNames(styles.mainBlock, {
    [styles.borderBottom]: isShowBorderBottom,
  })
  return (
    <div className={mainBlockStyle}>
      {passwordReminderMsg.type ? (
        <div className={styles.content}>
          <SignInMessage
            text={passwordReminderMsg.message}
            type={passwordReminderMsg.type}
          />
        </div>
      ) : null}
      {passwordReminderMsg.type !== SUCCESS ? (
        <form onSubmit={handleSubmitForm} noValidate id="passwordReminder">
          <div className={styles.padding}>
            <FormField
              label={messages[language].enterUserIdLabel}
              name={USER_ID}
              id="passwordReminderUserId"
              onChange={formEventHandler}
              showError={errors.userId && dirties.userId}
              errorMessage={errors.userId}
              aria-label={messages[language].enterUserIdLabel}
              aria-required="true"
            />
          </div>
          <Button
            text={messages[language].submitLabel}
            variant="primary"
            data-test="Submit"
            isFullWidth={isFullSizeButton}
          />
          <div className={styles.content}>
            <Text text={messages[language].noteLabel} />
          </div>
        </form>
      ) : null}
    </div>
  )
}

// Proptype validation
PasswordReminder.propTypes = {
  language: PropTypes.string.isRequired,
  isShowBorderBottom: PropTypes.bool,
  isFullSizeButton: PropTypes.bool,
}
PasswordReminder.defaultProps = {
  isShowBorderBottom: true,
  isFullSizeButton: true,
}

export default PasswordReminder
