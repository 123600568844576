import { getApiUrl, getHostUrl } from 'services/utils/configUtils'
import { sendRequest } from '~/api/apiHelper'
const HOST_URL = getHostUrl()
const API_URL = getApiUrl()

export const getClientFromPostalCode = async ({
  parentClientNo,
  postalCode,
  province,
}) => {
  const url = `${HOST_URL}/api/octopus/get-client-from-postal-code`
  const result = await sendRequest(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ClientNo: parentClientNo, // for local dev use account with value 322580, and it
      PostalCode: postalCode.toUpperCase(),
      Province: province,
    },
  })
  return result
}

/**
 * /**
 * @param {string} sessionId sessionId as a guid
 * @param {Object} body Contains the payload as an array
 */
export const updateSession = async (sessionId, body) => {
  const url = `${API_URL}/buyers/session/${sessionId}`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  return await sendRequest(url, options)
}
