export default {
  en: {
    buttons: [
      {
        title: 'browse catalog',
        text: 'browse catalog',
        arialabel: 'browse catalog',
        datatest: 'browse catalog',
        variant: 'primary',
        link: 'orders/cart/details',
      },
      {
        title: 'Return to the homepage',
        text: 'Return to the homepage',
        arialabel: 'Return to the homepage',
        datatest: 'Return to the homepage',
        variant: 'primary',
        link: '',
      },
      {
        title: 'contact us',
        text: 'contact us',
        arialabel: 'contact us',
        datatest: 'contact us',
        variant: 'primary',
        link: '/en/Info/Help/ContactUs',
      },
    ],
    errorTitle: 'Sorry! There was a problem with this page',
    errorUnderTitle: 'We are currently experiencing an issue on our website',
  },
  fr: {
    buttons: [
      {
        title: `Consulter le catalogue`,
        text: `Consulter le catalogue`,
        arialabel: `Consulter le catalogue`,
        datatest: `Consulter le catalogue`,
        variant: 'primary',
        link: 'shopping-cart',
      },
      {
        title: `Retourner à la page d'accueil`,
        text: `Retourner à la page d'accueil`,
        arialabel: `Retourner à la page d'accueil`,
        datatest: `Retourner à la page d'accueil`,
        variant: 'primary',
        link: '',
      },
      {
        title: `Contactez-nous`,
        text: `Contactez-nous`,
        arialabel: `Contactez-nous`,
        datatest: `Contactez-nous`,
        variant: 'primary',
        link: '/fr/Info/Help/ContactUs',
      },
    ],
    errorTitle: `Désolé! Un problème est survenu avec cette page`,
    errorUnderTitle: `Nous éprouvons actuellement des difficultés avec notre site web`,
  },
}
