import { ENGLISH } from '@redux/constants'
import { INVITEE_TYPES } from 'components/shared/AccountSelection/AccountSelectionConstants'
import { SettingsContextProvider } from 'context/SettingsContext'
import { OrderContextProvider } from 'context/OrderContext'
import { DetailsContextProvider } from 'context/DetailsContext'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { COOKIES } from 'services/constants'
import BloomReachAnalytics from 'shared/BloomReachAnalytics'
import MarketoTracking from 'shared/MarketoTracking'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { isLoggedIn, recommendationsIsEnabled } from 'shared/Utils/userUtils'
import AdobeAnalytics from '~/components/shared/AdobeAnalytics'
import NavBar from '~/components/shared/Layout/Header/NavBar'
import { POSTAL_CODE_ENTRY_CODE } from '~/components/shared/PostalCodeEntryForm/PostalCodeEntryFormConstants'
import { ManageAddressesContextProvider } from '~/context/ManageAddressesContext'
import { ShipToContextProvider } from '~/context/ShipToContext/ShipToContext'
import pageMessages from '~/pageMessages/global.messages'
import { removeAutoLoginParams } from '~/redux/autoLoginService/autoLoginServiceUtils'
import { getCookie, removeCookie } from '~/services/cookieManager'
import { AlertNotificationContainer } from '../Alerts'
import { useToggler } from '../CustomHooks/useToggler'
import ErrorBoundaryPage from '../ErrorBoundaryPage'
import { useConfigureEwayRouter } from '../EwayRouter'
import SegmentAnalytics from '../SegmentAnalytics'
import Footer from './Footer'
import Header from './Header'
import CheckoutHeader from './Header/CheckoutHeader'
import { getDealsItems } from './Header/NavBar/DealsMenuUtils'
import styles from './Layout.module.scss'
import { LAYOUT_TYPES } from './LayoutConstants'
import LayoutContainer from './LayoutContainer'
import LayoutModals from './LayoutModals'
import SessionTimeOutModal from './SessionTimeOutModal'
import { getPunchoutServiceFlag } from '~/services/utils/configUtils'
import { DEFAULT_PROVIDER } from '~/config/env/logProviders/logProvidersConfig'
import { MONITORING_COMPONENTS } from '../LogProviders/LogProviderConstants'
import getConfig from 'next/config'

const configs = getConfig().publicRuntimeConfig

const Layout = ({
  language = ENGLISH,
  buyer = {},
  settings = {},
  adobeAnalytics = {},
  details = {},
  setLanguage,
  order = {},
  children,
  hideRestrictions,
  pageTitle,
  sessionId,
  showErrorBoundary,
  ...props
}) => {
  const loggedIn = isLoggedIn(buyer)
  useConfigureEwayRouter(language)
  const { layoutType, pageName, selectedDeliveryAddressId } = props
  useEffect(() => {
    if (getCookie(COOKIES.AUTO_LOGIN_INITIATED) === 'true') {
      const url = removeAutoLoginParams(window.location.href)
      history.replaceState(null, '', url)
      removeCookie(COOKIES.AUTO_LOGIN_INITIATED)
    }
  }, [])

  const {
    firstName,
    showShipToSearchFlag,
    deliveryAddressLevel: addressLevel,
  } = buyer
  const { costCenter, principalClientNo } = details
  const dealsMenuItems = getDealsItems(
    loggedIn,
    props.hasPromotions,
    details,
    settings,
    language
  )
  const {
    enableSocialWidget,
    showMenuOrderReturns,
    showYourPriceFlag,
    enablePublication,
    enableBloomreachAnalyticsInstrumentation,
    enableWebAnalyticsInstrumentation: enableAdobeAnalytics = true,
    enableErrorReporting,
    showAccountSelection,
    enableFavoriteShipTo,
    showAccountSearchLink,
    enableMarketoTrackingPixel = true,
    enableShipToSelection,
  } = settings

  const {
    close,
    open: triggerOpenWelcomeToEwayModal,
    isOpen: forceOpenWelcomeToEway,
  } = useToggler()

  const {
    canManageDeliveryAddress,
    viewShipToSelectionPage,
    inviteeState = INVITEE_TYPES.DEFAULT,
  } = buyer

  const isLoggedInNormalUser =
    loggedIn &&
    inviteeState === INVITEE_TYPES.DEFAULT &&
    !details.isCustomerOnboardingAccount

  const postalCodeEntryEnabled =
    enableShipToSelection === POSTAL_CODE_ENTRY_CODE

  const canUsePostalCodeToManageAddresses = postalCodeEntryEnabled

  const canShowWelcomeToEway =
    isLoggedInNormalUser && canUsePostalCodeToManageAddresses

  const showPostalCodeChooserButton =
    loggedIn && canUsePostalCodeToManageAddresses && viewShipToSelectionPage

  const renderAccountSelection =
    isLoggedInNormalUser && !canManageDeliveryAddress && !postalCodeEntryEnabled

  const showShipToComponent =
    renderAccountSelection &&
    !canManageDeliveryAddress &&
    !postalCodeEntryEnabled

  const isMiniLayout = checkIsMiniLayout(layoutType)
  const { siteTitle } = pageMessages[language]
  const title = pageTitle || siteTitle
  const enablePunchoutService = getPunchoutServiceFlag()
  const { isPunchOutEnable } = settings
  const { isUsingInternalCheckout, canUseB2bCartResponse = false } = details
  const isPunchOutUser = isPunchOutEnable && !isUsingInternalCheckout
  const isSingleSignOnUser =
    isPunchOutEnable && Boolean(isUsingInternalCheckout)
  const isB2bPunchOut =
    isPunchOutEnable && canUseB2bCartResponse && enablePunchoutService
  const punchoutSettings = {
    isPunchOutUser,
    isSingleSignOnUser,
    isB2bPunchOut,
  }
  const mergedSettings = {
    isPunchOutUser: isPunchOutEnable && !isUsingInternalCheckout,
    isSingleSignOnUser: isPunchOutEnable && isUsingInternalCheckout,
    isB2bPunchOut:
      isPunchOutEnable && canUseB2bCartResponse && enablePunchoutService,
    canViewRecommendations: recommendationsIsEnabled(settings),
    ...settings,
  }
  const { hasFlyerPriceFlag: showDealsFlyers = true } = details

  const MonitoringComponent =
    MONITORING_COMPONENTS[configs.logProviderName || DEFAULT_PROVIDER]
  const component = MonitoringComponent ? (
    <MonitoringComponent buyer={buyer} punchoutSettings={punchoutSettings} />
  ) : null

  return (
    <div className={styles.flex}>
      <Head>
        <title>{title}</title>
      </Head>
      <OrderContextProvider order={order}>
        <DetailsContextProvider details={details}>
          <SettingsContextProvider settings={mergedSettings}>
            <ManageAddressesContextProvider
              buyerId={buyer.buyerId}
              language={language}
              primaryAddressId={buyer.primaryDeliveryAddressId}
              allowFetch={canManageDeliveryAddress}
              addressLevel={addressLevel}
              principalClientNo={principalClientNo}
              selectedDeliveryAddressId={selectedDeliveryAddressId}
            >
              <ShipToContextProvider
                details={details}
                isGroupOrder={order?.isGroupOrder}
                buyerId={buyer.buyerId}
                sessionId={sessionId}
                clientNo={details?.clientNo}
                showAccountSearchLink={showAccountSearchLink}
                showAccountSelection={showAccountSelection}
                enableFavoriteShipTo={enableFavoriteShipTo}
                showShipToSearchFlag={showShipToSearchFlag}
              >
                {isMiniLayout ? (
                  <CheckoutHeader
                    language={language}
                    details={details}
                    order={order}
                    buyer={buyer}
                    settings={settings}
                  />
                ) : (
                  <Header
                    language={language}
                    isLoggedIn={loggedIn}
                    setLanguage={setLanguage}
                    firstName={firstName}
                    costCenter={costCenter}
                    showYourPriceFlag={showYourPriceFlag}
                    order={order}
                    buyer={buyer}
                    sessionId={sessionId}
                    settings={settings}
                    details={details}
                    onClickPostalCodeChooserButton={
                      triggerOpenWelcomeToEwayModal
                    }
                    showSelectedPostalCode={showPostalCodeChooserButton}
                    showShipToComponent={showShipToComponent}
                    {...props}
                  />
                )}
                <main role="main" className={styles.mainStyle}>
                  {!isMiniLayout && (
                    <div className={styles.navBarWrapper}>
                      <div className={styles.container}>
                        <NavBar
                          language={language}
                          dealsMenuItems={dealsMenuItems}
                          isLoggedIn={loggedIn}
                          enablePublication={enablePublication}
                          hideRestrictions={hideRestrictions}
                          enablePwgsCheckout={settings?.enablePwgscheckout}
                          showDealsFlyers={showDealsFlyers}
                        />
                      </div>
                    </div>
                  )}
                  <AlertNotificationContainer />
                  <LayoutContainer
                    language={language}
                    enableErrorReporting={enableErrorReporting}
                    isLoggedIn={loggedIn}
                  >
                    {showErrorBoundary ? (
                      <ErrorBoundaryPage language={language} />
                    ) : (
                      children
                    )}
                    {loggedIn ? (
                      <SessionTimeOutModal language={language} />
                    ) : null}
                  </LayoutContainer>

                  <LayoutModals
                    buyer={buyer}
                    details={details}
                    enableShipToSelection={enableShipToSelection}
                    renderAccountSelection={renderAccountSelection}
                    forceOpenWelcomeToEway={forceOpenWelcomeToEway}
                    onClickContinueManageAddress={close}
                    onClickContinuePostalCodeEntry={close}
                    onCloseManagePostalCodeEntryModal={close}
                    onCloseManageAddressModal={close}
                    showWelcomeToEway={canShowWelcomeToEway}
                    language={language}
                    order={order}
                    pageName={pageName}
                    settings={settings}
                    sessionId={sessionId}
                  />
                </main>
              </ShipToContextProvider>
            </ManageAddressesContextProvider>
            <div className={styles.spacer} />
            <Footer
              language={language}
              isLoggedIn={loggedIn}
              isReturnItemEnabled={showMenuOrderReturns}
              customCopyrightContent={details?.customCopyrightContent}
              enableSocialWidget={enableSocialWidget}
              isMinimal={isMiniLayout}
              pageName={pageName}
            />
            {/*
             * DO NOT ADD CONDITIONS TO DISABLE THE ADOBE ANALYTICS TRACKING UNLESS TOLD OTHERWISE ⬇️
             * The only reason why this component should be disabled is if analytics are disabled from a settings / accounts level
             * This components generate the page view tracking for ADOBE.
             * To add more values go see -> generateAnalytics, in initialize.js to understand how it's built
             */}
            {enableAdobeAnalytics && (
              <div>
                <AdobeAnalytics
                  values={adobeAnalytics}
                  sendAnalyticsManually={props.sendAdobeAnalyticsManually}
                />
              </div>
            )}
            <SegmentAnalytics
              disabled={!enableAdobeAnalytics}
              identifyUser={loggedIn}
              buyer={buyer}
            />
            <BloomReachAnalytics
              enableTracking={enableBloomreachAnalyticsInstrumentation}
            />
            {enableMarketoTrackingPixel && pageName !== PAGE_NAMES.SEARCH && (
              <MarketoTracking />
            )}
          </SettingsContextProvider>
        </DetailsContextProvider>
      </OrderContextProvider>
      {component}
    </div>
  )
}

Layout.defaultProps = {
  language: ENGLISH,
  buyer: {},
  settings: {},
  analytics: {},
  details: {},
  order: {},
  showErrorBoundary: false,
  layoutType: LAYOUT_TYPES.DEFAULT,
}

// props validation
Layout.propTypes = {
  pageName: PropTypes.string,
  language: PropTypes.string.isRequired,
  showErrorBoundary: PropTypes.bool,
  buyer: PropTypes.object.isRequired,
  hasPromotions: PropTypes.bool,
  settings: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setLanguage: PropTypes.func,
  order: PropTypes.object,
  sessionId: PropTypes.string,
  pageTitle: PropTypes.string,
  hideRestrictions: PropTypes.string,
  adobeAnalytics: PropTypes.object,
  children: PropTypes.node,
  sendAdobeAnalyticsManually: PropTypes.bool,
  selectedDeliveryAddressId: PropTypes.number,
  layoutType: PropTypes.string,
}

const checkIsMiniLayout = (layoutType) => {
  return layoutType === LAYOUT_TYPES.MINI_LAYOUT
}

export default Layout
