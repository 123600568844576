import omit from 'lodash/omit'
import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import {
  ADDRESS_TYPES_VIEW_LEVEL,
  ADDRESS_VISIBILITY,
  FIELDS,
  DEFAULT_OPTION_TYPE,
} from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import { postalCodeRegex } from '~/services/utils/regexUtils'

const { COMPANY_NAME, ATTENTION, BUILDING } = FIELDS

export function filterAddressLockedFields(fieldsLockFlag = {}) {
  const {
    isNameLocked = true,
    isAttentionLocked = true,
    isBuildingLocked = true,
  } = fieldsLockFlag
  const fieldsLockedValue = {
    [COMPANY_NAME]: isNameLocked,
    [ATTENTION]: isAttentionLocked,
    [BUILDING]: isBuildingLocked,
  }
  return fieldsLockedValue
}

export function handleNewAddressState(addresses = [], address, id = '') {
  const listOfAddresses = address.isDefault
    ? addresses.map((address) => omit(address, FIELDS.IS_DEFAULT))
    : addresses

  const newAddresses =
    id !== ''
      ? listOfAddresses.map((item) => {
          return item.id === id ? { ...item, ...address } : item
        })
      : [address, ...listOfAddresses]
  return newAddresses
}

const hasAddressEditPermission = ({
  visibility,
  addressPermissionLevel,
  isPrivateAddressOwner,
}) => {
  if (addressPermissionLevel > ADDRESS_TYPES_VIEW_LEVEL.SELF) {
    if (addressPermissionLevel === ADDRESS_TYPES_VIEW_LEVEL.GLOBAL) {
      return visibility === ADDRESS_VISIBILITY.GLOBAL || isPrivateAddressOwner
    } else if (
      addressPermissionLevel === ADDRESS_TYPES_VIEW_LEVEL.SHARED &&
      isPrivateAddressOwner
    ) {
      return visibility <= ADDRESS_VISIBILITY.SHARED
    } else if (
      addressPermissionLevel === ADDRESS_TYPES_VIEW_LEVEL.PRIVATE &&
      isPrivateAddressOwner
    ) {
      return visibility === ADDRESS_VISIBILITY.PRIVATE
    }
  }
  return false
}

export function getAddressPermissions({
  addressLevel,
  addressBuyerId = '',
  buyerId = '',
  visibility = null,
  addressGroup,
}) {
  const isAddressOwner =
    addressBuyerId && buyerId ? addressBuyerId.trim() === buyerId.trim() : true

  const canManageAddresses = addressLevel > ADDRESS_TYPES_VIEW_LEVEL.SELF
  const isPrivateAddressOwner = isAddressOwner && canManageAddresses

  const canManageGlobalAddresses =
    addressLevel === ADDRESS_TYPES_VIEW_LEVEL.GLOBAL

  const canEditAndDeleteAddress = hasAddressEditPermission({
    visibility,
    addressPermissionLevel: addressLevel,
    isPrivateAddressOwner,
  })

  const canCreateSharedAddress = addressLevel >= ADDRESS_TYPES_VIEW_LEVEL.SHARED
  const canCreateGlobalAddress = addressLevel >= ADDRESS_TYPES_VIEW_LEVEL.GLOBAL
  const canMakePrimaryAddress = isAddressOwner
  const { isBillingType } = getAddressGroup(addressGroup)

  return {
    canAddAddress: canManageAddresses,
    canEditAddress: canEditAndDeleteAddress,
    canDeleteAddress: canEditAndDeleteAddress,
    canAssignAddress:
      isBillingType &&
      visibility === ADDRESS_VISIBILITY.GLOBAL &&
      canManageGlobalAddresses,
    canCreateSharedAddress,
    canCreateGlobalAddress,
    canMakePrimaryAddress,
  }
}

export function getAddressGroup(addressGroup) {
  const isBillingType = addressGroup === ADDRESS_GROUPS.BILLING_ADDRESS
  return { isBillingType }
}

export function hasDuplicateAddress({
  addresses = [],
  billingAddresses = [],
  savingAddress,
  editAddress = '',
  canCheckDuplicateOnBillingAddress = false,
}) {
  const {
    attention,
    building,
    city: savingAddressCity,
    companyName,
    postalCode,
    province: savingAddressProvince,
    streetAddress,
  } = savingAddress

  const hasAddressDuplicate = addresses
    ?.filter((item) => item.id !== editAddress?.id)
    ?.some((address) => {
      const addressValues = [
        [address[FIELDS.COMPANY_NAME], companyName],
        [address[FIELDS.ATTENTION], attention],
        [address[FIELDS.STREET_ADDRESS], streetAddress],
        [address[FIELDS.BUILDING], building],
        [address[FIELDS.CITY], savingAddressCity],
        [address[FIELDS.PROVINCE], savingAddressProvince],
        [address[FIELDS.POSTAL_CODE], postalCode],
      ]

      return addressValues
        .map(([addressPart1, addressPart2]) => [
          trimToLowerCase(addressPart1),
          trimToLowerCase(addressPart2),
        ])
        .every(([addressPart1, addressPart2]) => addressPart1 === addressPart2)
    })

  const hasDuplicateBillingAddress =
    canCheckDuplicateOnBillingAddress &&
    billingAddresses
      ?.filter((item) => item.id !== editAddress?.id)
      ?.some((address) => {
        const addressValues = [
          [address[FIELDS.COMPANY_NAME], companyName],
          [address[FIELDS.ATTENTION], attention],
          [address[FIELDS.STREET_ADDRESS], streetAddress],
          [address[FIELDS.BUILDING], building],
          [address[FIELDS.CITY], savingAddressCity],
          [address[FIELDS.PROVINCE], savingAddressProvince],
          [address[FIELDS.POSTAL_CODE], postalCode],
        ]

        return (
          addressValues
            .map(([addressPart1, addressPart2]) => [
              trimToLowerCase(addressPart1),
              trimToLowerCase(addressPart2),
            ])
            .every(
              ([addressPart1, addressPart2]) => addressPart1 === addressPart2
            ) || false
        )
      })

  return { hasAddressDuplicate, hasDuplicateBillingAddress }
}

export function getSaveAddressFormInitialFields(address = {}, options = {}) {
  const {
    canShowUseAsBillingField = false,
    isCheckedSetPrimaryField = false,
    translations = {},
  } = options
  const { required: requiredLabel, invalidPostalCode } = translations
  return {
    [FIELDS.STREET_ADDRESS]: {
      value: address[FIELDS.STREET_ADDRESS] || '',
      required: requiredLabel,
      maximumLength: 25,
    },
    [FIELDS.COMPANY_NAME]: {
      value: address[FIELDS.COMPANY_NAME] || '',
      required: requiredLabel,
    },
    [FIELDS.CITY]: {
      value: address[FIELDS.CITY] || '',
      required: requiredLabel,
    },
    [FIELDS.PROVINCE]: {
      value: address[FIELDS.PROVINCE] || '',
      required: requiredLabel,
    },
    [FIELDS.IS_DEFAULT]: {
      value: isCheckedSetPrimaryField || address?.isDefault || false,
    },
    [FIELDS.BUILDING]: {
      value: address[FIELDS.BUILDING] || '',
    },
    [FIELDS.ATTENTION]: {
      value: address[FIELDS.ATTENTION] || '',
    },

    [FIELDS.POSTAL_CODE]: {
      value: address[FIELDS.POSTAL_CODE] || '',
      required: requiredLabel,
      pattern: {
        value: postalCodeRegex,
        message: invalidPostalCode,
      },
    },
    [FIELDS.ADDRESS_TYPE]: {
      value: address?.visibility || DEFAULT_OPTION_TYPE,
      required: requiredLabel,
    },
    ...(canShowUseAsBillingField && {
      [FIELDS.USE_AS_BILLING]: {
        value:
          address[FIELDS.USE_AS_BILLING] ||
          Number(address?.visibility || DEFAULT_OPTION_TYPE) ===
            ADDRESS_VISIBILITY.PRIVATE,
      },
    }),
  }
}

function trimToLowerCase(value = '') {
  return value.toLowerCase()?.trim()
}
