import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const SupportMenuContext = createContext()
const { Provider } = SupportMenuContext

function useSupportMenuContext() {
  return useContext(SupportMenuContext) || {}
}

function SupportMenuProvider({ children }) {
  const [activeMenu, setActiveMenu] = useState(null)
  const setCurrentActiveMenu = (menu) => {
    setActiveMenu(menu)
  }
  const closeMenu = () => {
    setActiveMenu(null)
  }
  return (
    <Provider value={{ activeMenu, setCurrentActiveMenu, closeMenu }}>
      {children}
    </Provider>
  )
}

// Props Validation
SupportMenuProvider.propTypes = {
  children: PropTypes.node,
}

export { useSupportMenuContext, SupportMenuProvider }
