import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ENGLISH, FRENCH } from '@redux/constants'
import { InputGroup } from 'shared/InputGroup'
import styles from './LanguageSwitcher.module.scss'
import i18n from './messages'
import Label from '~/components/shared/Label'

/**
 * Name: LanguageSwitcher
 * Desc: Used for changing language
 * @param {string} language
 * @param {string} buyerId
 * @param {func} onLanguageChange
 */
const LanguageSwitcher = ({ language, buyerId, onLanguageChange }) => {
  const handleLanguageSelect = useCallback(
    ({ target }) => {
      const { value } = target
      onLanguageChange(buyerId, value)
    },
    [buyerId, onLanguageChange]
  )
  const messages = i18n[language]

  return (
    <div className={styles.rightMenu}>
      <Label htmlFor="language" className={styles.visuallyHidden}>
        {messages.changeLanguage}
      </Label>
      <InputGroup
        id="language"
        type="select"
        name="language"
        selectedValue={language}
        onChange={handleLanguageSelect}
        dataTest="language"
      >
        <option value={ENGLISH} aria-label={messages.english}>
          {messages.en}
        </option>
        <option value={FRENCH} aria-label={messages.french}>
          {messages.fr}
        </option>
      </InputGroup>
    </div>
  )
}

// PropType Validation
LanguageSwitcher.propTypes = {
  language: PropTypes.string.isRequired,
  buyerId: PropTypes.string,
  onLanguageChange: PropTypes.func,
}

export default LanguageSwitcher
