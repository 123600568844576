export const FIFTY_GREEN_EXEMPT = 0
export const FIFTY_GREEN_NOT_ENROLLED = 1
export const FIFTY_GREEN_ENROLLED = 2
export const FIFTY_GREEN_HARD_STOP = 3
export const ZERO_HANDLING_FEE = 0
export const ZERO_DELIVERY_FEE = 0
export const IS_DELIVERY_ZONE = 1
export const NOT_ENROLLED = 'notEnrolled'
export const ENROLLED = 'enrolled'
export const DELIVERY_AREA_SURCHARGE = 'deliveryAreaSurcharge'
export const DELIVERY_AND_HANDLING_FEE = 'deliveryAndHandlingFee'
export const PRODUCT_HANDLING_FEES = 'productHandlingFees'
export const HARD_STOP = 'hardStop'
export const MIN_AMOUNT_ACHIEVED = 'minAmountAchieved'
export const NOT_ENROLLED_CHANGE_MSG = 'notEnrolledChange'
