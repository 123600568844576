import { MAX_NUMBER_OF_ACCOUNTS } from 'api/constants'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import { HAS_ONE_SHIP_TO_ACCESS } from './ShipToConstants'

export const ANIMATION_TIME = 300

export const scrollAfter300ms = (currentElement, container) => {
  const timeout = setTimeout(() => {
    scrollToElements(currentElement, container)
    clearTimeout(timeout)
  }, ANIMATION_TIME)
}

const scrollToElements = (currentElement, container) => {
  if (!currentElement) {
    return
  }
  const elem = currentElement
  const elementTop = elem.offsetTop
  const elementHeight = elem.offsetHeight
  const center = elementTop - elementHeight - 20
  const duration = 200
  scrollTo(container, center, duration)
}

function scrollTo(element, to, duration) {
  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  const animateScroll = function () {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val

    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}
/**
 * easeIn animation
 */
const easeInOutQuad = (currentTime, startValue, change, duration) => {
  currentTime /= duration / 2
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + startValue
  }
  currentTime--
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + startValue
}

export function initializeAccounts(
  fetchedAccounts = [],
  hasOnlyFavorites,
  clientNo
) {
  const accounts = !fetchedAccounts.hasError
    ? // orders the ship tos
      orderAccounts(fetchedAccounts)
        // ads the missing label
        .map(addLabel)
        // ads the selected state
        .map((a) => addSelected(a, clientNo))
    : []
  // strip favorites if there are only favorites
  const formattedAccounts = hasOnlyFavorites
    ? accounts.map(stripIsFavoriteFromAccounts)
    : accounts
  return formattedAccounts
}

export function orderAccounts(fetchedAccounts) {
  return orderBy(fetchedAccounts, ['isFavorite', 'label'], ['desc'])
}

export function addSelected(account, selectedClientNo) {
  return account.clientNo === selectedClientNo
    ? { isSelected: true, ...account }
    : account
}

export function clearSelected(element) {
  return omit(element, 'isSelected')
}

export function addLabel(account) {
  const { label, clientNo } = account
  return {
    ...account,
    label: createLabel(label, clientNo),
  }
}

export function createLabel(costCenter, clientNo) {
  return `${costCenter || ''} ${clientNo ? `(${clientNo})` : ''}`.trim()
}

export function hasOnlyFavoriteAccounts(accounts = []) {
  return accounts.every((a) => a.isFavorite === true)
}

function stripIsFavoriteFromAccounts(account) {
  return omit(account, 'isFavorite')
}

export function showDropdown(accounts = [], props) {
  const { enableFavoriteShipTo = true, showAccountSelection } = props
  const showShipTo = hasLessThan50Accounts(accounts) && showAccountSelection
  // Weird Logic to clarify with team, and Product Owner ⬇️
  return showShipTo || enableFavoriteShipTo || weirdCondition(props)
}

function hasLessThan50Accounts(accounts) {
  return accounts.length < MAX_NUMBER_OF_ACCOUNTS
}

// This logic is terribly weird, and needs to be shown to our Product Owner
// In order to Clarify, what does what
function weirdCondition(props) {
  return (
    !props.showAccountSearchLink &&
    props.showShipToSearchFlag &&
    props.showAccountSelection &&
    !props.enableFavoriteShipTo
  )
}

export function showAccountLink(props, count = 0) {
  const { showShipToSearchFlag, showAccountSearchLink } = props
  // if length === 1 the need to hide find Account link, user has only one ship-to access
  const hasOnlyOneShipToAccess = count === HAS_ONE_SHIP_TO_ACCESS
  return (
    !!showAccountSearchLink && !!showShipToSearchFlag && !hasOnlyOneShipToAccess
  )
}

const isSelected = (account, clientNo) => account.clientNo === clientNo

export function findSelectedIndex(accounts, clientNo) {
  return accounts.findIndex((a) => isSelected(a, clientNo))
}

export const findSelectedAccount = (accounts = []) =>
  accounts.find((a) => a.isSelected)

export function accountsBy(inputFilter) {
  return (account) =>
    account.label.toLowerCase().includes(inputFilter.toLowerCase())
}

export const createAccountFromAccountInfo = (details, isFavorite = false) => {
  const {
    clientId,
    clientNo,
    costCenter,
    shippingName,
    shippingAddress1: address1 = '',
    shippingAddress2: address2 = '',
    shippingAddress3: address3 = '',
    shippingPostalCode,
  } = details
  const account = {
    clientId,
    isFavorite,
    clientNo,
    label: costCenter,
    costCenter,
    shippingName,
    address1,
    address2,
    address3,
    shippingPostalCode,
  }
  const accountWithLabel = addLabel(account)
  return accountWithLabel
}
