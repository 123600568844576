import PropTypes from 'prop-types'
import { useRef } from 'react'
import {
  classMutationObserver,
  useKeyboardNavigation,
} from '~/components/shared/CustomHooks/useKeyboardNavigation'
import MenuList from '~/components/shared/MenuList/MenuList'
import { useSupportMenuContext } from '../../../SupportMenuUtils'
import styles from '../AccountMenu.module.scss'

const primaryAccountMenuMutationObserver = classMutationObserver(
  styles.linkActive
)
const AccountMenusPrimary = ({
  items,
  activeIndex,
  setActiveIndex,
  isMenuActive,
  onPrimaryActiveMenu,
}) => {
  const primaryAccountMenusRef = useRef(null)
  const handleMouseEnter = (index) => setActiveIndex(index)
  const { closeMenu } = useSupportMenuContext()

  useKeyboardNavigation({
    items,
    isActive: isMenuActive,
    selectedItemIndex: activeIndex,
    setSelectedItemIndex: setActiveIndex,
    mutationObserver: primaryAccountMenuMutationObserver,
    observingElement: primaryAccountMenusRef.current,
    horizontalArrowHandler: onPrimaryActiveMenu,
    exitHandler: closeMenu,
  })

  return (
    <MenuList
      ref={primaryAccountMenusRef}
      items={items}
      activeIndex={activeIndex}
      onMouseEnter={handleMouseEnter}
      activeItemClass={styles.linkActive}
    />
  )
}

AccountMenusPrimary.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  isMenuActive: PropTypes.bool,
  setActiveIndex: PropTypes.func,
  onPrimaryActiveMenu: PropTypes.func,
}

export default AccountMenusPrimary
