export default {
  en: {
    termsAndConditionsLabel: 'Terms & Conditions',
    termsAndConditionsUrl: '/termsAndConditions.aspx?Language=en-CA',
    privacyPolicyLabel: 'Privacy Notice',
    privacyPolicyUrl: '/Privacy.aspx?Language=en-CA',
    staplesBusinessAdvantageLabel: 'Staples Professional',
    staplesBusinessAdvantageUrl: 'https://www.staplesprofessional.ca/',
    copyrightMessage: `© Copyright ${new Date().getFullYear()} Staples Professional Inc. All rights reserved.`,
  },
  fr: {
    termsAndConditionsLabel: 'Termes et conditions',
    termsAndConditionsUrl: '/termsAndConditions.aspx?Language=fr-CA',
    privacyPolicyLabel: 'Avis de confidentialité',
    privacyPolicyUrl: '/Privacy.aspx?Language=fr-CA',
    staplesBusinessAdvantageLabel: 'Staples Professionnel',
    staplesBusinessAdvantageUrl: 'https://www.staplesprofessionnel.ca/',
    copyrightMessage: `© Droit d'auteur ${new Date().getFullYear()} Staples Professionnel Inc. Tous droits réservés.`,
  },
}
