export default {
  en: {
    checkoutLabel: 'Checkout',
    viewCartLabel: 'View cart',
  },
  fr: {
    checkoutLabel: 'Commander',
    viewCartLabel: 'Voir le panier',
  },
}
