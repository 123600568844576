import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import styles from './HamburgerMenuCategoryListItem.module.scss'

function HamburgerMenuCategoryListItem({ linkText, link, onClick }) {
  return (
    <ListItem className={styles.item}>
      <a href={link} onClick={onClick}>
        {linkText}
      </a>
    </ListItem>
  )
}

HamburgerMenuCategoryListItem.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.node,
  onClick: PropTypes.func,
}

export default HamburgerMenuCategoryListItem
