import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import Container from '~/components/shared/Container'
import EwayLink from '~/components/shared/EwayLink'
import styles from './FooterCopyright.module.scss'
import { getCopyrightText } from './FooterCopyrightUtils'
import messages from './messages'
import {
  TERMS_CONDITIONS,
  PRIVACY_NOTICE,
} from 'shared/EwayRouter/EwayRouterConstants'
import { getUrl } from 'shared/EwayRouter'

/**
 *Name: FooterCopyright
 *Desc: Render footer copyright
 * @param {*} language
 * @param customCopyrightContent
 * @returns Element
 */
const FooterCopyright = ({ language, customCopyrightContent, pageTarget }) => {
  const copyrightText = getCopyrightText(language)
  const privacyNoticeUrl = getUrl(PRIVACY_NOTICE)
  const termsAndConditionsUrl = getUrl(TERMS_CONDITIONS)
  return (
    <div className={styles.footerCopyright} data-footer="checkoutFooter">
      <Container>
        <p>{customCopyrightContent || copyrightText}</p>
        <ul className={styles.list}>
          <ListItem>
            <EwayLink
              target={pageTarget ? '_blank' : '_self'}
              rel="noopener noreferrer"
              href={termsAndConditionsUrl}
              data-test="terms-condition-link"
            >
              {messages[language].termsAndConditionsLabel}
            </EwayLink>
          </ListItem>
          <ListItem>
            <EwayLink
              target="_self"
              rel="noopener noreferrer"
              href={privacyNoticeUrl}
              data-test="privacy-policy-link"
            >
              {messages[language].privacyPolicyLabel}
            </EwayLink>
          </ListItem>
          <ListItem>
            <EwayLink
              target="_blank"
              rel="noopener noreferrer"
              href={messages[language].staplesBusinessAdvantageUrl}
              data-test="sba-link"
            >
              {messages[language].staplesBusinessAdvantageLabel}
            </EwayLink>
          </ListItem>
        </ul>
      </Container>
    </div>
  )
}

// Props Validation
FooterCopyright.propTypes = {
  language: PropTypes.string.isRequired,
  customCopyrightContent: PropTypes.string,
  pageTarget: PropTypes.bool,
}

export default FooterCopyright
