import classNames from 'classnames'
import Box from 'components/shared/Box'
import Button from 'components/shared/Button'
import InfoLabel from 'components/shared/InfoLabel'
import Text from 'components/shared/Text'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { getIconVariant } from 'shared/ManageAddresses/ManageAddressesUtils'
import Tooltip from 'shared/Tooltip'
import { useFetchBuyer } from '~/api/buyer/useFetchBuyer'
import Card from '~/components/shared/Card'
import { dynamicTranslation } from '~/components/shared/DynamicTranslation/DynamicTranslationUtils'
import Flex from '~/components/shared/Flex'
import { ACTION } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import { ENGLISH } from '~/redux/constants'
import Icon from '../../../Icon'
import styles from './AddressBox.module.scss'
import { ACCOUNT_REGULAR, GLOBAL, MULTIPLE_USER } from './AddressBoxConstants'
import messages from './messages'

/**
 * Name: AddressBox
 * Desc: Render AddressBox
 * @param {string} language
 * @param {object} address
 */

const AddressBox = ({
  id,
  language = ENGLISH,
  address = {},
  className,
  onEditAddressClick,
  onPrimaryAddressClick,
  onDeleteAddressClick,
  onSelectAddressClick,
  onClickAssignAddress,
  openDefaultBillingAssignerModal,
  showPrimaryButton = false,
  showToggleActionButton = false,
  showDeleteButton = false,
  showAssignButton = false,
  isSharedFromOtherBuyer = false,
  canEditAddress = false,
  canDeleteAddress = false,
  isAssignedToDeliveryAddress = false,
  showPrimaryLabel = false,
  isSelected,
  showSetAsDefaultBillingAddressButton = false,
  hasNoDeliveryAddresses = false,
  ...rest
}) => {
  const {
    companyName,
    streetAddress,
    attention,
    building,
    city,
    province,
    postalCode,
    visibility,
  } = address
  const addressBoxClasses = classNames(className)
  const translations = messages[language]
  const [flyoutToggler, setFlyoutToggler] = useState(false)
  const {
    triggerFetchBuyer,
    isLoading,
    buyer: { fullName },
  } = useFetchBuyer()

  const replaceMap = {
    '{fullName}': fullName,
  }
  const sharedContentWithName = isSharedFromOtherBuyer
    ? dynamicTranslation(translations.customSharedContent, replaceMap)
    : ''

  const tooltipSharedText =
    isSharedFromOtherBuyer === true
      ? sharedContentWithName
      : translations.sharedContent

  async function handleMouseEnter() {
    if (isSharedFromOtherBuyer) {
      triggerFetchBuyer(address.buyerId)
    }
  }

  function handleActionsToggler(event) {
    event.stopPropagation()
    setFlyoutToggler(!flyoutToggler)
  }

  function handleFocusOut() {
    flyoutToggler && setFlyoutToggler(!flyoutToggler)
  }

  function handleEditAddress(event) {
    event.stopPropagation()
    onEditAddressClick(id)
  }

  function handlePrimaryAddress() {
    return onPrimaryAddressClick(id)
  }

  function handleSelectAddress() {
    return onSelectAddressClick?.(id)
  }

  function handleDeleteAddress() {
    return onDeleteAddressClick(id)
  }

  function handleAssignAddress(e) {
    onClickAssignAddress(e, id)
  }

  function handleOpenDefaultBillingAssignerModal(e) {
    openDefaultBillingAssignerModal(e, id)
  }
  const restAddress = `${city || ''} ${province || ''} ${postalCode || ''}`
  const variant = getIconVariant(visibility)
  const iconId = `addressTypeIcon-${id}`
  const { editDelivery, assignDelivery } = translations
  const assignLabel = isAssignedToDeliveryAddress
    ? editDelivery
    : assignDelivery

  return (
    <Flex
      minH="160px"
      direction="column"
      w="100%"
      p="6px"
      className={addressBoxClasses}
      {...rest}
      cursor={onSelectAddressClick ? 'pointer' : ''}
    >
      <Card
        p="15px"
        h="100%"
        display="flex"
        direction="column"
        borderColor={isSelected && 'green'}
        data-test="address-card"
        onClick={handleSelectAddress}
        className={styles.addressBoxCard}
        onMouseLeave={handleFocusOut}
      >
        <Flex
          className={styles.addressBoxHeader}
          justify="space-between"
          align="center"
        >
          <Text
            text={companyName || ''}
            className={styles.addressBoxTitle}
            color="secondary"
            data-test="company-individual-name"
            data-tip={companyName || ''}
            data-for="custom"
          />
          <Icon
            variant={variant}
            data-for={iconId}
            data-tip
            aria-describedby={iconId}
            data-test="address-icon"
            onMouseEnter={handleMouseEnter}
          >
            <Tooltip
              place="left"
              type="custom"
              background="primary"
              id={iconId}
              effect="solid"
              data-test="address-box-tooltip"
              className={styles.addressTooltipIcon}
              isLoading={isLoading}
            >
              {variant === ACCOUNT_REGULAR ? (
                <>
                  <Text text={translations.privateHeading} as="h6" />
                  <Text text={translations.privateContent} as="p" />
                </>
              ) : null}

              {variant === MULTIPLE_USER ? (
                <>
                  <Text text={translations.sharedHeading} as="h6" />
                  <Text
                    data-test="sharedContent"
                    text={tooltipSharedText}
                    as="p"
                  />
                </>
              ) : null}

              {variant === GLOBAL ? (
                <>
                  <Text text={translations.globalHeading} as="h6" />
                  <Text text={translations.globalContent} as="p" />
                </>
              ) : null}
            </Tooltip>
          </Icon>
        </Flex>
        <Box mt="10px">
          <Text
            text={streetAddress || ''}
            color="secondary"
            variant="mdText"
            as="div"
            className={styles.addressText}
          />
          <Text
            text={attention || ''}
            color="secondary"
            variant="mdText"
            as="div"
            className={styles.addressTextStyle}
          />
          <Text
            text={building || ''}
            color="secondary"
            variant="mdText"
            as="div"
            className={styles.addressTextStyle}
          />
          <Text
            text={restAddress}
            color="secondary"
            variant="mdText"
            as="div"
            className={styles.addressTextStyle}
          />
        </Box>
        <Flex mt="auto" pt="20px" className={styles.addressBoxButtonWrapper}>
          <Box>
            {showToggleActionButton ? (
              <Box minH="40px">
                <Button
                  onClick={handleActionsToggler}
                  className={styles.toggleEditDelBtn}
                  variant="link"
                  border="none"
                  data-test="toggle-action-button"
                >
                  ...
                </Button>
                {flyoutToggler ? (
                  <Box
                    as="div"
                    className={styles.editDeleteButton}
                    data-test="edit-delete-button"
                  >
                    <Button
                      text={translations.edit}
                      aria-label={translations.edit}
                      data-test="edit-address-button"
                      onClick={handleEditAddress}
                      pl="5px"
                      variant="link"
                      border="none"
                      id={`${ACTION.EDIT}-delivery-address-${id}`}
                      disabled={!canEditAddress}
                    />
                    {showAssignButton ? (
                      <Button
                        text={assignLabel}
                        data-test="assign-address-button"
                        onClick={handleAssignAddress}
                        aria-label={assignLabel}
                        variant="link"
                        border="none"
                        className={styles.assignButton}
                        disabled={hasNoDeliveryAddresses}
                      />
                    ) : null}

                    {showSetAsDefaultBillingAddressButton ? (
                      <Button
                        text={translations.setDefaultBillingAddress}
                        data-test="set-default-billing-address"
                        onClick={handleOpenDefaultBillingAssignerModal}
                        aria-label={translations.setDefaultBillingAddress}
                        variant="link"
                        border="none"
                        className={styles.setDefaultBillingAddressButton}
                      />
                    ) : null}
                    {showDeleteButton ? (
                      <Button
                        text={translations.delete}
                        data-test="delete-address-button"
                        aria-label={translations.delete}
                        onClick={handleDeleteAddress}
                        color="red"
                        variant="link"
                        border="none"
                        className={styles.deleteButton}
                        disabled={!canDeleteAddress}
                      />
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Box>
          {showPrimaryLabel ? (
            <InfoLabel
              variant="success"
              variantText="smText"
              className={styles.infoLabel}
              data-test="primary-selected"
            >
              <Icon variant="Primary" mr="3px"></Icon>
              <Text text={translations.primary} color="white" />
            </InfoLabel>
          ) : null}

          {showPrimaryButton ? (
            <Button
              type="button"
              title={translations.makePrimary}
              text={translations.makePrimary}
              variant="secondary"
              data-test="make-primary-address-button"
              className={styles.addressBoxButton}
              aria-label={translations.makePrimary}
              onClick={handlePrimaryAddress}
            />
          ) : null}
        </Flex>
      </Card>
      <Tooltip
        type="custom"
        id="custom"
        place="top"
        className={styles.orderDetailInfo}
      />
    </Flex>
  )
}

// PropType validation
AddressBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  language: PropTypes.string,
  address: PropTypes.object,
  className: PropTypes.string,
  onEditAddressClick: PropTypes.func,
  onPrimaryAddressClick: PropTypes.func,
  onDeleteAddressClick: PropTypes.func,
  onClickAssignAddress: PropTypes.func,
  openDefaultBillingAssignerModal: PropTypes.func,
  onSelectAddressClick: PropTypes.func,
  isSelected: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
  showPrimaryLabel: PropTypes.bool,
  showAssignButton: PropTypes.bool,
  showToggleActionButton: PropTypes.bool,
  isSharedFromOtherBuyer: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showSetAsDefaultBillingAddressButton: PropTypes.bool,
  isAssignedToDeliveryAddress: PropTypes.bool,
  canEditAddress: PropTypes.bool,
  canDeleteAddress: PropTypes.bool,
  hasNoDeliveryAddresses: PropTypes.bool,
}

export { AddressBox }
