import { useEffect, useState } from 'react'
import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
// TODO: shared component should not be dependent on the containers/ pages
import { getAddresses } from '~/components/containers/ManageAddressesContainer/ManageAddressesContainerUtils'
import { useLoader } from '~/components/shared/CustomHooks/useLoader'
import { getAddressPermissions } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormUtils'
import { getPrimaryAddress } from '~/context/ManageAddressesContext/ManageAddressesContextUtils'
import { ENGLISH } from '~/redux/constants'
import messages from '../messages'
import { formatAddresses } from './useManageAddressesUtils'
export function useManageAddresses({
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS,
  addressLevel,
  allowFetch = false,
  buyerId,
  defaultLoadingState,
  language = ENGLISH,
  primaryAddressId = '',
  principalClientId,
} = {}) {
  const {
    isLoading,
    setResolved,
    setPending,
    setError,
    isResolvedOrError,
    isError,
    isResolved,
  } = useLoader(defaultLoadingState)
  const [addresses, setAddresses] = useState([])
  const translations = messages[language]
  const hasNoAddresses = isResolved ? !addresses.length : true
  const hasAddressesAndResolved = !!addresses.length && isResolved
  const primaryAddress = getPrimaryAddress(addresses) || {}
  useEffect(() => {
    async function initGetAddresses() {
      setPending()
      const result = await getAddresses({
        buyerId,
        addressLevel,
        principalClientId,
        addressGroup,
      })
      const { addresses = [], isSuccess } = result

      if (isSuccess) {
        setResolved()
        const formattedAddresses = formatAddresses(
          addresses,
          addressGroup,
          primaryAddressId
        )
        setAddresses(formattedAddresses)
      } else {
        setError(translations.errorLoadingAddresses)
      }
    }
    allowFetch ? initGetAddresses() : setResolved()
  }, [
    primaryAddressId,
    buyerId,
    allowFetch,
    setError,
    setPending,
    setResolved,
    translations.errorLoadingAddresses,
    addressLevel,
    addressGroup,
    principalClientId,
  ])

  const { canAddAddress, canCreateSharedAddress, canCreateGlobalAddress } =
    getAddressPermissions({ addressLevel })

  return {
    canAddAddress,
    canCreateSharedAddress,
    canCreateGlobalAddress,
    addressLevel,
    hasNoAddresses,
    hasAddressesAndResolved,
    isResolvedOrError,
    addresses,
    primaryAddress,
    isLoading,
    isResolved,
    isError,
    setAddresses,
    addressGroup,
  }
}
