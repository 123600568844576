import {
  ALL_PRODUCTS,
  BEST_MATCH,
  CORE_PRODUCTS,
  DEFAULT_PAGINATION_NUMBER,
  FILTER_BY_KEY,
  INK_AND_TONER_SELECT_NAME_MAPPINGS,
  KEYWORD_KEY,
  OPTION_FILTERS_KEY,
  PAGE_COUNT_KEY,
  SORT_BY_KEY,
} from 'components/containers/BrowseProducts/constants'
import {
  CATEGORY_RECOMMENDATION_SEGMENT_LOCATION,
  SEARCH_RECOMMENDATION_SEGMENT_LOCATION,
} from 'components/shared/SegmentAnalytics/SegmentAnalyticsConstants'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import uniq from 'lodash/uniq'
import browseProductsPageMessages from 'pageMessages/browseProducts.messages'
import { getHostUrl } from 'services/utils/configUtils'
import { getUrlSearchParams } from 'services/utils/urlUtils'
import {
  getShoppingProductDetails,
  formatProductDetail,
} from '~/api/productOperations'
import {
  BOX_QUERY,
  INK_AND_TONER_EN_FINDER,
  INK_AND_TONER_FR_FINDER,
  SEARCH_API_TYPE,
  SEARCH_TYPES,
} from '~/components/containers/BrowseProducts/BrowseProductsConstants'
import filterMessages from '~/components/containers/BrowseProducts/messages'
import { updateAndSendTrackingWith } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import { PROMO_BANNER_ANALYTICS_VALUES } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsValuesGenerator/Search/AdobeAnalyticsSearchConstants'
import { sendBloomReachPageAnalytics } from '~/components/shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import { LIST_VIEW } from '~/components/shared/ProductCard/ProductCardConstants'
import { getFinderType } from '~/components/containers/BrowseProducts/getFinderType'
import { ENGLISH } from '~/redux/constants'
import { logErrorToConsole } from '~/services/logger'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '~/services/storageManager'
import { getItemUrl } from '~/services/utils'
import {
  getInkAndTonerProductDetails,
  getProductsResultBySearchOrCategory,
  getPromotionProductDetails,
} from './BrowseProductsApiUtils'

const {
  CATEGORY,
  KEYWORD,
  SHOPPING,
  PROMO,
  INK_AND_TONER,
  BROWSE_SEARCH_HYBRID,
} = lowerCaseValues(SEARCH_TYPES)
const SEARCH_TYPES_BOOLEAN = {
  isCategorySearch: CATEGORY,
  isKeywordSearch: KEYWORD,
  isShoppingList: SHOPPING,
  isPromoSearch: PROMO,
  isInkToner: INK_AND_TONER,
}

const HOST_URL = getHostUrl()
export async function getBrowseProductResults(args) {
  const {
    language = ENGLISH,
    router,
    pageNumber = DEFAULT_PAGINATION_NUMBER,
    optionsFilter,
    sortBy,
    showHideRestrictions,
    categoriesIds: ids = [],
    guestLocation,
    isClearAll = false,
    sort,
    keyWord = '',
  } = makeKeywordUnsensitive(args) || {}
  const {
    browsProdQuery,
    preSearchFilter,
    searchType,
    productCodeList = '',
    isInkAndTonerFinder,
    finderType,
  } = getSearchParamsFromRouter(router)
  const query = keyWord || browsProdQuery
  const {
    isCategorySearch,
    isKeywordSearch,
    isPromoSearch,
    isShoppingList,
    isInkAndTonerSearch,
  } = searchType
  const isCategoryOrKeywordSearch = isCategorySearch || isKeywordSearch
  let result = {}
  result.productCodeList = []
  if (isCategoryOrKeywordSearch || isInkAndTonerFinder) {
    const categoriesIds = getCategoryIds({
      ids,
      isCategorySearch,
      isClearAll,
      reqBrowsProdParam: query,
      isInkAndTonerFinder,
    })
    const keyword = getKeyword({
      isKeywordSearch,
      query,
      isInkAndTonerFinder,
      categoriesIds,
      language,
    })
    const kws = getKws(isInkAndTonerFinder, categoriesIds, keyword)
    result =
      (await getProductsResultBySearchOrCategory({
        kws,
        language,
        pageNumber,
        optionsFilter,
        sortBy,
        guestLocation,
        enablePwgscheckout: preSearchFilter,
        keyword,
        categoriesIds,
        showHideRestrictions,
        searchType,
        finderType,
        sort,
      })) || {}
    result.productCodeList =
      result?.Records?.map((product) => product.ProductCode) || []

    // BloomReach Analytics
    const bloomReachData = {}
    bloomReachData.q = keyword
    sendBloomReachPageAnalytics(result, bloomReachData)
  } else if (isPromoSearch) {
    const categoriesIds = getCategoryIds({
      ids,
      isCategorySearch,
      isClearAll,
      reqBrowsProdParam: query,
      isInkAndTonerFinder,
    })
    const keyword = getKeyword({
      isKeywordSearch,
      query,
      isInkAndTonerFinder,
      categoriesIds,
      language,
    })

    result = await getPromotionProductDetails({
      language,
      pageNumber,
      optionsFilter,
      sortBy,
      enablePwgscheckout: preSearchFilter,
      keyword,
      categoriesIds,
      showHideRestrictions,
      searchType,
      finderType,
      sort,
      promoId: query,
    })
    result.Records = formatProductDetail(result.Records, language)
    updateAndSendTrackingWith(PROMO_BANNER_ANALYTICS_VALUES)
  } else if (isShoppingList) {
    result = await getShoppingProductDetails(
      language,
      query,
      pageNumber,
      optionsFilter,
      sortBy
    )
  } else if (isInkAndTonerSearch) {
    const productList = getProductList(productCodeList)
    result = await getInkAndTonerProductDetails({
      language,
      keyword: query,
      pageNumber,
      optionsFilter,
      sortBy,
      productList,
      showHideRestrictions,
    })
  }
  result.shoppingLists = []
  if (result?.AvailableShoppingLists) {
    result.shoppingLists = getFormattedShoppingListData(
      result.AvailableShoppingLists
    )
  }
  // if the viewType === InkAndToner
  result.isShowFilterSections = isCategoryOrKeywordSearch
  result.breadcrumbs = []

  result.searchPageType = getPreSearchFilter(searchType)
  if (result?.SectionSearchs?.SectionList) {
    const filterData = filterProductSearchPageData(
      result.SectionSearchs.SectionList
    )
    result.SectionSearchs.SectionList = filterData.sectionList
    result.breadcrumbs = filterData.breadcrumbs
  }
  if (!isEmpty(result.breadcrumbs)) {
    result.breadcrumbs = getDimensionsBreadcrumbs(
      result.breadcrumbs,
      language,
      HOST_URL
    )
  }
  result.Records = result.Records || []

  // Assign default value to left filter section if no data available
  const { SectionSearchs = {} } = result || {}
  const { SectionList = [] } = SectionSearchs
  if (result.Records.length === 0 || isEmpty(SectionList)) {
    const defaultSectionSearch = getDefaultValueOfSearchSections(language)
    result.SectionSearchs = defaultSectionSearch
  }

  return result
}

/*
 * Name: getDefaultValues
 * Desc: Takes the selected values from search, formats them in a readable way
 * For tha app to consume them
 * @param: {object} filterValues
 * @param: {array} sectionList
 */
const RATING = 'RATING'
const REVIEW_RAT = 'REVIEWRAT' //For EN and FR id is same
export const getSelectedCategories = (filterValues = {}, sectionList = []) => {
  const [urlLink] = filterValues.categoriesIds
  const dimensions = sectionList.map((item) => {
    const itemDimensions = item?.Dimensions || []
    return item.Id === REVIEW_RAT
      ? itemDimensions.map((item) => ({ ...item, type: RATING }))
      : itemDimensions
  })

  const allCategories = [].concat.apply([], dimensions)

  const selectedCategories = allCategories.filter((c) => c?.IsSelected)

  // Known API issue: the "brands" array has no uniqueId
  // Other known API issue: When you select more than 3 brands in sidebar
  // The unique Id isn't unique anymore

  // Therefore we use the name as a unique ID for now
  const results = selectedCategories
    .map(({ Name: name, type = '' }) => ({
      name,
      urlLink,
      id: kebabCase(name), // ⬅️ as kebab-case
      type,
    }))
    .reverse()
  return results
}

/**
 * Name: getPathWithQueryStringParameter
 * Desc:return query params
 * @param {object} filterValue
 */
export const getPathWithQueryStringParameter = (filterValue) => {
  const searchParams = getUrlSearchParams()
  const queryParams = getQueryStringArray(filterValue)
  if (searchParams && queryParams.length) {
    queryParams.forEach(({ key, value }) => {
      searchParams.set(key, value)
    })

    const path = window.location.pathname + '?' + searchParams.toString()
    return path
  }
}
/**
 * Name: decodeQueryParams
 * Desc: get all the query params from url
 * @param {object} filterValue
 */
export const decodeQueryParams = (filterValues) => {
  const searchParams = getUrlSearchParams()
  const result = { ...filterValues }
  if (searchParams) {
    if (!searchParams.get(PAGE_COUNT_KEY)) {
      getPathWithQueryStringParameter(result)
    }
    result['pageNumber'] = searchParams.get(PAGE_COUNT_KEY) || result.pageNumber
    const sortBy = searchParams.get(SORT_BY_KEY)
    const categoriesIds = searchParams.get(FILTER_BY_KEY)
    const optionsFilter = searchParams.get(OPTION_FILTERS_KEY)
    result['sortBy'] = sortBy || ''

    result['categoriesIds'] = categoriesIds ? [categoriesIds] : []
    if (optionsFilter) {
      result['optionsFilter'] = isNaN(optionsFilter)
        ? optionsFilter
        : Number(optionsFilter)
    }

    return result
  }
  return result
}

/**
 * Name: getQueryStringArray
 * Desc: set all filter into url
 * @param {number} pageNumber
 * @param {string} sortBy
 * @param {string} optionsFilter
 * @param {array} categoriesIds
 */
export const getQueryStringArray = ({
  pageNumber = 1,
  sortBy = '',
  optionsFilter,
  categoriesIds = [],
  keyWord = '',
} = {}) => {
  const PARAMS_TO_FILTER_WHEN_EMPTY = [SORT_BY_KEY, KEYWORD_KEY]
  const queryArray = [
    { key: PAGE_COUNT_KEY, value: pageNumber },
    {
      key: SORT_BY_KEY,
      value: !sortBy || sortBy === '0' ? '' : sortBy,
    },
    {
      key: FILTER_BY_KEY,
      value:
        categoriesIds.length && Array.isArray(categoriesIds)
          ? categoriesIds[categoriesIds.length - 1]
          : categoriesIds || '',
    },
    { key: OPTION_FILTERS_KEY, value: optionsFilter },
    { key: KEYWORD_KEY, value: keyWord },
  ].filter((e) => {
    const result = PARAMS_TO_FILTER_WHEN_EMPTY.includes(e.key) && !e.value
    return !result
  })

  return queryArray
}

function getKws(isInkAndTonerFinder, categoriesIds, keyword) {
  if (isInkAndTonerFinder) {
    if (categoriesIds) {
      return SEARCH_API_TYPE.NOT_KEYWORD
    } else if (keyword) {
      return SEARCH_API_TYPE.KEYWORD
    }
  }
  return SEARCH_API_TYPE.NOT_KEYWORD // default
}

function getKeyword({
  isKeywordSearch,
  query,
  isInkAndTonerFinder,
  categoriesIds,
  language,
}) {
  if (isInkAndTonerFinder) {
    // If there are no categories, return the query, else, return finder type
    const inkAndTonnerFinder =
      language === ENGLISH ? INK_AND_TONER_EN_FINDER : INK_AND_TONER_FR_FINDER
    return !categoriesIds ? query : inkAndTonnerFinder
  } else if (isKeywordSearch) {
    return query
  }
  return ''
}

export function getViewTypeFromLocalStorage() {
  const view = getLocalStorage('viewType') || LIST_VIEW
  return view
}

export function setViewTypeToLocalStorage(viewType) {
  setLocalStorage('viewType', viewType)
}

export function getSelectedProductsFromLocalStorage() {
  return JSON.parse(getLocalStorage('selectedProducts')) || []
}
export function setBrowseProductUrlToLocalStorage() {
  setLocalStorage('browseProductUrl', window.location.href)
}

export function removeSelectedProductsFromLocalStorage() {
  removeLocalStorage('selectedProducts')
}

export const getPageInfo = (
  { queryString: q = '' } = {},
  isBoxRequest = false,
  isInkAndTonerFinderRequest = false,
  callingLocation
) => {
  const query = q.toLowerCase()
  const isHybridSearchPage =
    query === KEYWORD && callingLocation === BROWSE_SEARCH_HYBRID

  const isCategoryPage = query === CATEGORY
  const isKeyWordPage = query === KEYWORD
  const isShoppingListPage = query === SHOPPING
  const isPromotionPage = query === PROMO
  const isInkAndTonerPage = query === INK_AND_TONER
  const result = {
    isCategoryPage,
    isKeyWordPage,
    isShoppingListPage,
    isPromotionPage,
    isInkAndTonerPage,
    isKeyWordOrInkTonerPage: isKeyWordPage || isInkAndTonerPage,
    isBoxFinderView: isBoxRequest,
    isInkAndTonerFinder: isInkAndTonerFinderRequest,
    isHybridSearchPage,
  }
  return result
}

export const getSearchParamsFromRouter = ({ query = {} } = {}) => {
  const { queryString: searchType, q, preSearchFilter, productCodeList } = query
  const { DEFAULT_PRE_SEARCH } = SEARCH_TYPES
  const searchFilter = preSearchFilter || DEFAULT_PRE_SEARCH
  const browsProdQuery = q ? decodeURIComponent(q) : ''
  const type = getSearchType(searchType)
  return {
    ...getFinderType(query),
    browsProdQuery,
    searchFilter,
    queryString: searchType,
    preSearchFilter,
    searchType: type,
    productCodeList,
  }
}

function getSearchType(searchType = '') {
  const type = searchType.toLowerCase()

  const { isInkToner, isKeywordSearch, ...rest } = getTruthyValuesByType(
    SEARCH_TYPES_BOOLEAN,
    type
  )
  const isInkAndTonerPage = isInkToner,
    isInkAndTonerSearch = isInkToner
  return {
    ...rest,
    isKeywordSearch,
    isKeyWordPage: isKeywordSearch,
    isInkAndTonerPage,
    isInkAndTonerSearch,
    isKeyWordOrInkTonerPage: isKeywordSearch || isInkAndTonerPage,
  }
}

export const getFilterOptions = (language, settings, requestKey) => {
  const filterOptionsData = [
    {
      value: 3,
      name: filterMessages[language].allProducts,
      filterName: ALL_PRODUCTS,
    },
    {
      value: 1,
      name: filterMessages[language].coreProducts,
      filterName: CORE_PRODUCTS,
    },
    {
      value: 2,
      name: filterMessages[language].bestMatch,
      filterName: BEST_MATCH,
    },
  ]
  if (!settings?.showCoreFilter) {
    filterOptionsData.splice(
      filterOptionsData.findIndex((item) => item.filterName === CORE_PRODUCTS),
      1
    )
  }
  if (!settings?.showSourcebookProduct) {
    filterOptionsData.splice(
      filterOptionsData.findIndex((item) => item.filterName === BEST_MATCH),
      1
    )
  }

  if (!settings?.showCoreFilter && !settings?.showSourcebookProduct) {
    filterOptionsData.length = 0
  }
  if (SEARCH_TYPES.SHOPPING === requestKey.queryString) {
    filterOptionsData.length = 0
  }
  return filterOptionsData
}

function getTruthyValuesByType(MAP, type) {
  return Object.fromEntries(
    Object.entries(MAP).map(([key, value]) => [key, type === value])
  )
}

// SEARCH_CAT_KEY = 1 - For search by keyword and browse category // should be the default
// SEARCH_PROMO =  2 - Promotion
// SEARCH_SHOPPING_ID= 3 - Shopping List details page
export function getPreSearchFilter(searchType) {
  const singleSearchType = Object.keys(searchType).find((v) => searchType[v])
  const defaultValue = SEARCH_TYPES.SEARCH_CAT_KEY
  const PRE_SEARCH_NEW_FILTER = {
    isKeywordSearch: defaultValue,
    isCategorySearch: defaultValue,
    isPromoSearch: SEARCH_TYPES.SEARCH_PROMO,
    isShoppingList: SEARCH_TYPES.SEARCH_SHOPPING_ID,
    isInkAndTonerSearch: SEARCH_TYPES.INK_AND_TONER_AS_INT,
  }
  return PRE_SEARCH_NEW_FILTER[singleSearchType] || defaultValue
}

function getProductList(codes = '') {
  if (Array.isArray(codes)) {
    // Added because of https://jiraent.staples.com/browse/SCE-4801
    // TODO: Remove "isArray" if condition when vintage eway is retired.
    logErrorToConsole(
      'Array was passed as a value, check that router has a single string '
    )
    const [codeString] = uniq(codes)
    return codeString
  }
  const productCode = codes?.split('|')
  return productCode
}

function getCategoryIds({
  ids,
  isCategorySearch,
  isClearAll,
  reqBrowsProdParam,
  isInkAndTonerFinder,
}) {
  if (isInkAndTonerFinder) {
    return isEmpty(ids) ? '' : ids
  }
  const count = ids.length
  const trimmedIds = ids[count - 1]?.trim?.()
  const categories = isCategorySearch
    ? getCategorySearchCategories(
        count,
        trimmedIds,
        isClearAll,
        reqBrowsProdParam
      )
    : getKeywordCategories(ids, trimmedIds)
  return categories?.trim?.()
}

const getFormattedShoppingListData = (list) => {
  const shoppingList = []
  for (const key in list) {
    shoppingList.push({ name: list[key], value: key })
  }
  return shoppingList
}

const YOU_HAVE_SELECTED_EN = "YOU'VE SELECTED"
const YOU_HAVE_SELECTED_FR = 'ÉLÉMENTS CHOISIS'
const filterProductSearchPageData = (data = []) => {
  const filterData = { breadcrumbs: [], sectionList: [] }
  const parser = new DOMParser()
  data.forEach((item) => {
    if (
      item.Name.toUpperCase() === YOU_HAVE_SELECTED_EN.toUpperCase() ||
      item.Name.toUpperCase() === YOU_HAVE_SELECTED_FR
    ) {
      filterData.breadcrumbs.push(item)
    } else {
      item.Name = parser.parseFromString(
        item.Name,
        'text/html'
      ).body.textContent
      filterData.sectionList.push(item)
    }
  })
  return filterData
}
function getCategorySearchCategories(
  count,
  trimmedIds,
  isClearAll,
  reqBrowsProdParam
) {
  const value = isClearAll ? '' : reqBrowsProdParam
  const ids = count ? trimmedIds : value
  return ids || ''
}

function getKeywordCategories(ids, trimmedIds) {
  return ids.length ? trimmedIds : ''
}

export const getDefaultValueOfSearchSections = (language) => {
  const defaultSectionSearch = {
    SectionList: [
      {
        Dimensions: [],
        UrlLink: '',
        Id: '1000000',
        Name: filterMessages[language].sectionSearchDefValCat,
      },
      {
        Dimensions: [],
        UrlLink: '',
        Id: '3000000',
        Name: filterMessages[language].sectionSearchDefValBrand,
      },
      {
        Dimensions: [],
        UrlLink: '',
        Id: '5000000',
        Name: filterMessages[language].sectionSearchDefValRate,
      },
    ],
  }
  return defaultSectionSearch
}

function getDimensionsBreadcrumbs([breadcrumb], language, hostUrl) {
  if (!breadcrumb) {
    return []
  }
  const { Dimensions } = breadcrumb

  if (!Dimensions || isEmpty(Dimensions)) {
    return []
  }

  const breadcrumbs = []
  const returnDimensions = (dimensions) => {
    if (!dimensions || isEmpty(dimensions)) {
      return
    }

    Dimensions.map((item) => {
      const dimensionName = item.Name
      item.Dimensions.forEach((item) => (item.catType = dimensionName))
    })

    dimensions.forEach((dimension) => {
      const { Dimensions, Name, UrlRemoveFilter, Id, catType } = dimension
      if (Dimensions && !isEmpty(Dimensions)) {
        returnDimensions(Dimensions)
      } else {
        breadcrumbs.push({
          title: Name,
          link: getItemUrl(language, hostUrl, dimension),
          urlRemoveFilter: UrlRemoveFilter,
          id: Id,
          type: catType,
        })
      }
    })
  }

  returnDimensions(Dimensions)
  if (!isEmpty(breadcrumbs)) {
    breadcrumbs.unshift({
      title: filterMessages[language].home,
      link: hostUrl,
    })
  }
  return breadcrumbs
}

export function getFilteredSections(isLoggedIn, sections = [], ratingBoxName) {
  const byLoggedInOrNotRatingBox = ({ Name = '' }) => {
    const isRatingsBox = Name.toLowerCase() === ratingBoxName
    return (!isRatingsBox && !isLoggedIn) || isLoggedIn
  }

  const filteredSections = sections.filter(byLoggedInOrNotRatingBox)
  return filteredSections
}

export function getBrowseProductPageTitle(
  keyword,
  breadcrumbs = [],
  language = ENGLISH,
  searchType = {},
  isInkAndTonerFinder = false
) {
  const { isKeyWordOrInkTonerPage, isCategoryPage, isShoppingListPage } =
    searchType
  const translations = browseProductsPageMessages[language]
  const defaultTitle = `${translations.searchResultsFor}`
  const value = isInkAndTonerFinder
    ? ` ${translations.inkAndToner}`
    : isKeyWordOrInkTonerPage && keyword
    ? `${keyword}`
    : isCategoryPage && !!breadcrumbs
    ? `${parseHtmlEntities(breadcrumbs[breadcrumbs.length - 1]?.title)}`
    : isShoppingListPage
    ? `${translations.shoppingListDetails}`
    : defaultTitle
  return value
}

export function hasBoxRequest(query) {
  const lowerCasedBoxKey = BOX_QUERY.KEY.toLowerCase()
  const lowerCasedBoxValue = BOX_QUERY.VALUE.toLowerCase()

  const isBoxRequest = !!Object.entries(query).find(
    ([key = '', value = '']) => {
      const lowerCasedQueryKey = key.toLowerCase()
      const lowerCasedQueryValue = value?.toLowerCase?.()

      if (
        lowerCasedQueryKey === lowerCasedBoxKey &&
        lowerCasedQueryValue === lowerCasedBoxValue
      ) {
        return true
      }

      return false
    }
  )

  return isBoxRequest
}

export const formattedBoxFinderFacetId = (
  SectionList = [],
  facetAttribute,
  facetName,
  language
) => {
  const formattedParam = SectionList?.filter((item) => {
    return item?.Name === facetName
  }).map((data) => {
    return data?.Dimensions?.filter((name) => {
      return (
        name?.Name === `${facetAttribute}"` || name?.Name === facetAttribute
      )
    }).map((id) => {
      return id?.Id || ''
    })
  })
  const [firstElement = []] = formattedParam
  const [id = ''] = firstElement
  if (facetAttribute && !id) {
    return language === ENGLISH
      ? // eslint-disable-next-line no-useless-escape
        `${facetName}:${facetAttribute}\"`
      : `${capitalize(facetName)}:${facetAttribute}`
  }
  const [filteredFacet] = id.split(',')
  return filteredFacet || ''
}

export const getFormattedFacetId = (facetAttribute, facetName, language) => {
  if (facetAttribute) {
    if (language === ENGLISH) {
      // eslint-disable-next-line no-useless-escape
      return `${facetName}:${facetAttribute}\"`
    }
    return `${capitalize(facetName)}:${facetAttribute}`
  }
  return ''
}

export function getFormattedSizes(sizes) {
  const mutatingSizes = [...sizes]
  const formattedSizes = sizes
    .map(() => {
      const joinedSizes = mutatingSizes.join(',')
      mutatingSizes.pop()
      return joinedSizes
    })
    .reverse()
  return formattedSizes
}

export const getResetBoxFilteredValues = (filterValues, boxFilters) => {
  const { length, width, height } = boxFilters
  const { categoriesIds } = filterValues
  return categoriesIds?.map((ids) => {
    return ids
      .split(',')
      .filter((item) => {
        const [type, value] = item.split(':')
        return !(
          ([
            filterMessages['en'].boxLength,
            capitalize(filterMessages['en'].boxLength),
            capitalize(filterMessages['fr'].boxLength),
          ].includes(type.toString().trim()) &&
            (Number(value) === Number(length) || value === `${length}"`)) ||
          ([
            filterMessages['en'].boxWidth,
            capitalize(filterMessages['en'].boxWidth),
            capitalize(filterMessages['fr'].boxWidth),
          ].includes(type.toString().trim()) &&
            (Number(value) === Number(width) || value === `${width}"`)) ||
          ([
            filterMessages['en'].boxHeight,
            capitalize(filterMessages['en'].boxHeight),
            capitalize(filterMessages['fr'].boxHeight),
          ].includes(type.toString().trim()) &&
            (Number(value) === Number(height) || value === `${height}"`))
        )
      })
      .join(',')
  })
}

export const parseHtmlEntities = (str = '') => {
  return str?.replace(/&#([0-9]{1,3});/gi, function (_match, numStr) {
    const num = parseInt(numStr, 10) // read num as normal number
    return String.fromCharCode(num)
  })
}

export const getProductListId = ({ query = {} } = {}) => {
  const { CATEGORY, KEYWORD, SHOPPING, PROMO, INK_AND_TONER } = SEARCH_TYPES
  const { queryString = '', q, callingLocation, categoryId } = query
  const lowerCasedQuery = queryString.toLowerCase()
  if (lowerCasedQuery === KEYWORD.toLowerCase()) {
    if (callingLocation === BROWSE_SEARCH_HYBRID) {
      return categoryId
    }
    return 'search'
  } else if (lowerCasedQuery === CATEGORY.toLowerCase()) {
    return q
  } else if (lowerCasedQuery === SHOPPING.toLowerCase()) {
    return 'shopping list details'
  } else if (lowerCasedQuery === PROMO.toLowerCase()) {
    return 'SearchByPromotion'
  } else if (lowerCasedQuery === INK_AND_TONER.toLowerCase()) {
    return 'InkAndToner'
  }
  return ''
}

export const getRecommendationListId = ({ query = {} } = {}) => {
  const { CATEGORY, KEYWORD } = SEARCH_TYPES
  const { queryString = '', callingLocation } = query
  if (queryString.toLowerCase() === KEYWORD.toLowerCase()) {
    if (callingLocation === BROWSE_SEARCH_HYBRID) {
      return CATEGORY_RECOMMENDATION_SEGMENT_LOCATION
    }
    return SEARCH_RECOMMENDATION_SEGMENT_LOCATION
  } else if (queryString.toLowerCase() === CATEGORY.toLowerCase()) {
    return CATEGORY_RECOMMENDATION_SEGMENT_LOCATION
  }
  return ''
}

/**
 * Get the parameter of an object case unsensitive
 * @param {Object} object
 * @param {string} key
 * @return {any} value
 */
export function getParameterCaseInsensitive(object = {}, key = '') {
  return object[
    Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase?.())
  ]
}

export function constructInkAndTonerCategories({ brandValue, modelValue }) {
  const categoriesIds =
    brandValue && modelValue
      ? `${INK_AND_TONER_SELECT_NAME_MAPPINGS.BRAND_NAME}:${brandValue},${INK_AND_TONER_SELECT_NAME_MAPPINGS.MODEL_NAME}:${modelValue}`
      : ''

  return categoriesIds
}

export function destructureInkAndTonerCategories(categoriesIds = '') {
  return String(categoriesIds)
    .split(',')
    .map((catKeyVal) => {
      const [key = '', val = ''] = String(catKeyVal).split(':')
      return [key, val]
    })
    .reduce((aggregatedObj, [key, val]) => {
      const finalKey = String(key).trim()
      const finalVal = String(val).trim()
      return finalKey
        ? { ...aggregatedObj, [finalKey]: finalVal }
        : { ...aggregatedObj }
    }, {})
}

function makeKeywordUnsensitive(args = {}) {
  const value = getParameterCaseInsensitive(args, 'keyword')
  return {
    ...args,
    keyword: value,
    keyWord: value,
  }
}

// create function that lower case all values
function lowerCaseValues(obj = {}) {
  const newObj = {}
  for (const key in obj) {
    newObj[key] = obj[key]?.toLowerCase?.()
  }
  return newObj
}

// function getCategoryIds(ids, isCategorySearch, isClearAll, reqBrowsProdParam) {
//   const count = ids.length
//   const trimmedIds = ids[count - 1]?.trim()
//   const categories = isCategorySearch
//     ? getCategorySearchCategories(
//         count,
//         trimmedIds,
//         isClearAll,
//         reqBrowsProdParam
//       )
//     : getKeywordCategories(ids, trimmedIds)
//   return categories.trim()
// }
