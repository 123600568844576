export default {
  en: {
    itemsLabel: 'Items',
    itemLabel: 'Item',
    orderNumberLabel: 'Order Number',
  },
  fr: {
    itemsLabel: 'articles',
    itemLabel: 'article',
    orderNumberLabel: 'Numéro de commande',
  },
}
