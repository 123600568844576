import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'

import styles from './CartItemsList.module.scss'

/**
 * Name: CartItemsList
 * Desc: Render cart list
 * @param {array} items
 */
const CartItemsList = ({ items }) => {
  return (
    <div className={styles.itemList}>
      <ul>
        {items.map((item, index) => (
          <ListItem key={`${item.productId}-${index}`}>
            <span data-test={'product'}>{item.productId}</span>
            <p>
              <span data-test={'quantity'}>{item.quantity}</span>
            </p>
          </ListItem>
        ))}
      </ul>
    </div>
  )
}

// Props Validation
CartItemsList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default CartItemsList
