import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import { mapAddressApiKeyWithFormKey } from '~/components/shared/ManageAddresses/ManageAddressesUtils'
import {
  API_MAPPING_TYPE,
  DEFAULT_LOCKED_FIELDS_TRUE_VALUE,
} from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import { useSettingsContext } from '~/context/SettingsContext'
import { ENGLISH } from '~/redux/constants'
import { camelCaseKeys } from '~/services/utils'
import { isValidPostalCode, postalCodeRegex } from '~/services/utils/regexUtils'
import {
  FIELDS,
  OVERRIDE_BUYER,
  OVERRIDE_READ_ONLY_TYPE,
  OVERRIDE_TYPE,
  OVERRIDE_WHEN_BLANK,
} from './CheckoutAddressConstants'
import messages from './messages'

//TODO: there should be some way in useControlled form to remove this repetition
export function doAddressValidation(values, language) {
  const translations = messages[language]
  const newErrors = {}
  if (isEmpty(values[FIELDS.STREET_ADDRESS])) {
    newErrors[FIELDS.STREET_ADDRESS] = translations.requiredMsg
  }
  if (isEmpty(values[FIELDS.CITY])) {
    newErrors[FIELDS.CITY] = translations.requiredMsg
  }
  if (isEmpty(values[FIELDS.PROVINCE])) {
    newErrors[FIELDS.PROVINCE] = translations.requiredMsg
  }
  if (isEmpty(values[FIELDS.POSTAL_CODE])) {
    newErrors[FIELDS.POSTAL_CODE] = translations.requiredMsg
  }
  if (
    !isEmpty(values[FIELDS.POSTAL_CODE]) &&
    !isValidPostalCode(values[FIELDS.POSTAL_CODE])
  ) {
    newErrors[FIELDS.POSTAL_CODE] = translations.invalidPostalCode
  }
  const isValid = !Object.keys(newErrors).length
  return { newErrors, isValid }
}

const USE_ADDRESS_DEFAULT_OPTIONS = {
  useAttentionOverride: false,
}
export const useAddress = ({
  language = ENGLISH,
  address: addressFromProps,
  addressGroup,
  canManageDeliveryAddress = false,
  fullName,
  options = USE_ADDRESS_DEFAULT_OPTIONS,
}) => {
  const { useAttentionOverride } = options
  const camelCasedAddress = camelCaseKeys(addressFromProps)
  const translations = messages[language]
  const { requiredMsg: required, invalidPostalCode } = translations
  const address = defaultToEmptyString(
    mapAddressApiKeyWithFormKey(camelCasedAddress, addressGroup)
  )
  const [addressLockingFlags, setAddressLockingFlags] = useState(
    canManageDeliveryAddress ? DEFAULT_LOCKED_FIELDS_TRUE_VALUE : {}
  )
  const loadWhen = !isEmpty(camelCasedAddress)
  const form = useFormControlled(
    {
      [FIELDS.COMPANY_NAME]: {
        value: address.companyName || '',
        required,
      },
      [FIELDS.ATTENTION]: {
        value: address.attention || '',
      },
      [FIELDS.BUILDING]: {
        value: address.building || '',
      },
      [FIELDS.STREET_ADDRESS]: {
        value: address.streetAddress || '',
        required,
      },
      [FIELDS.CITY]: {
        value: address.city || '',
        required,
      },
      [FIELDS.PROVINCE]: {
        value: address.province || '',
        required,
      },
      [FIELDS.POSTAL_CODE]: {
        value: address.postalCode || '',
        required,
        pattern: {
          value: postalCodeRegex,
          message: invalidPostalCode,
        },
      },
    },
    language,
    {
      loadWhen,
    }
  )

  const { values, dirties } = form
  const { overrideAttention } = useSettingsContext()
  const attentionValue = values[FIELDS.ATTENTION]
  const attention = useAttentionOverride
    ? getAttentionValueFromSettings(
        overrideAttention,
        attentionValue,
        dirties,
        fullName
      )
    : attentionValue
  const data = {
    ...form,
    values: {
      ...values,
      [FIELDS.ATTENTION]: attention?.substring(0, 30) || '',
    },
  }
  return { ...data, addressLockingFlags, setAddressLockingFlags }
}

const defaultFieldsToUpdate = [
  FIELDS.POSTAL_CODE,
  FIELDS.COMPANY_NAME,
  FIELDS.ATTENTION,
  FIELDS.STREET_ADDRESS,
  FIELDS.BUILDING,
  FIELDS.CITY,
  FIELDS.PROVINCE,
]

function getAttentionValueFromSettings(
  overrideAttention,
  attention = '',
  dirties,
  fullName
) {
  const type = OVERRIDE_TYPE[overrideAttention] || OVERRIDE_READ_ONLY_TYPE
  const isOverrideBuyer = type === OVERRIDE_BUYER
  const isOverrideBlank =
    type === OVERRIDE_WHEN_BLANK &&
    attention.trim() === '' &&
    !dirties[FIELDS.ATTENTION]
  return isOverrideBuyer || isOverrideBlank ? fullName : attention
}

export function getAddressFieldsPayload(
  address,
  addressGroup,
  fieldsToUpdate = defaultFieldsToUpdate
) {
  const addressPathMapping = API_MAPPING_TYPE[addressGroup]
  const filteredAddress = fieldsToUpdate.map((addressFieldName) => {
    const path = addressPathMapping[addressFieldName]
    const value = address[addressFieldName] || ''
    return { op: 'add', path, value }
  })
  return filteredAddress
}

export function defaultToEmptyString(object = {}) {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, value || ''])
  )
}
