export default {
  en: {
    helpLabel: 'Help',

    helpContactUsLabel: 'Contact Us',
    helpContactUsUrl: '/en/Info/Help/ContactUs?from=Header',

    helpItemLabel: 'Help Centre',
    helpUrl: '/Help/HelpCentre.aspx?HelpCentreSection=0&Language=en-CA',

    helpRecallInformationLabel: 'Recall Information',
    helpRecallInformationUrl: '/Recalls/RecallInformation.aspx?Language=en-CA',
  },
  fr: {
    helpLabel: 'Aide',

    helpContactUsLabel: 'Contactez-nous',
    helpContactUsUrl: '/fr/Info/Help/ContactUs',

    helpItemLabel: "Centre d'aide",
    helpUrl: '/Help/HelpCentre.aspx?HelpCentreSection=0&Language=fr-CA',

    helpRecallInformationLabel: 'Informations de rappel',
    helpRecallInformationUrl: '/Recalls/RecallInformation.aspx?Language=fr-CA',
  },
}
