import ContentLoader from 'react-content-loader'

const ShipToSelectionTabSkeleton = () => {
  return (
    <ContentLoader width="100%" height="80px" uniqueKey="rectangle">
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="20px" />
      <rect x="0" y="40" rx="0" ry="0" width="100%" height="30px" />
    </ContentLoader>
  )
}

export default ShipToSelectionTabSkeleton
