import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'

const SettingsContext = createContext({})
const { Provider } = SettingsContext

export function SettingsContextProvider({ children, settings }) {
  return <Provider value={settings}>{children}</Provider>
}

SettingsContextProvider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
}

export function useSettingsContext() {
  return useContext(SettingsContext)
}

export default SettingsContext
