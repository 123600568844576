import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './InputWithLockIcon.module.scss'
import Flex from '~/components/shared/Flex'
import { noop } from '../Utils/userUtils'
import { SELECT } from 'shared/Utils/constants'
import classNames from 'classnames'
import Box from 'components/shared/Box'
import Text from 'components/shared/Text'
import Tooltip from 'shared/Tooltip'
/**
 * Name: InputWithIcon
 * Decs: Render InputWithIcon component
 * @param {string} name
 * @param {func} onClickSuffixIcon
 * @param {string} suffixIcon
 * @param {bool} showLockIcon
 * @returns Element
 */
export const InputWithLockIcon = ({
  name,
  onClick = noop,
  showLockIcon = true,
  children,
  type = 'text',
  icon,
  text = '',
}) => {
  const handleIconClick = (e) => {
    onClick(name, e)
  }

  const lockInputWrapper = classNames(styles.lockInputWrapper, {
    [styles.customSelectFiledWrapper]: type === SELECT,
  })

  const id = `${name}-icon-button`
  return (
    <Flex position="relative" className={lockInputWrapper}>
      {children}
      {showLockIcon ? (
        <Flex justify="center" align="center">
          <Box data-tip data-for={id} className={styles.iconButtonWrapper}>
            <Button
              role="button"
              icon={icon}
              onClick={handleIconClick}
              variant="link"
              type="button"
              data-test="field-lock-toggle-button"
              className={styles.iconButton}
              aria-describedby={name}
              aria-label={text}
            />
          </Box>
          {text ? (
            <Tooltip type="custom" id={id} place="left">
              <Box w="150px">
                <Text text={text} role="tooltip" color="secondary" />
              </Box>
            </Tooltip>
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  )
}

// Props Validation
InputWithLockIcon.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  showLockIcon: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
}
