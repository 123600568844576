import { getOldApiUrl } from 'services/utils/configUtils'
import { sendRequest } from '~/api/apiHelper'
const OLD_API_URL = getOldApiUrl()
export const getBuyersList = async (clientId) => {
  const url = `${OLD_API_URL}/api/buyer/availableBuyers/clients/${clientId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const result = await sendRequest(url, options)
  const isSuccess = result.isSuccess
  delete result.isSuccess
  delete result.isUnauthorized
  delete result.status
  const [allBuyerList] = result
  const { BuyersAvailable } = allBuyerList
  return {
    buyers: BuyersAvailable,
    isSuccess,
  }
}
