export default {
  en: {
    placeholder: 'Search for all your business needs',
    pwgsPlaceholder: 'Search products',
    standoffProd: 'Standing Offer Products',
    allProd: 'All Products',
    search: 'Search',
  },
  fr: {
    placeholder: 'Rechercher pour tous les besoins de votre entreprise',
    pwgsPlaceholder: 'Rechercher des produits',
    standoffProd: "Produits de l'offre à commandes",
    allProd: 'Tous les produits',
    search: 'Chercher',
  },
}
