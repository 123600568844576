import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { initializeDataDog, setDataDogUser } from './DataDogUtils'

function DataDogConfig({ buyer, punchoutSettings = {} }) {
  const { isPunchOutUser, isSingleSignOnUser, isB2bPunchOut } = punchoutSettings
  const punchoutTypes = {
    b2bPunchOut: isB2bPunchOut,
    punchout: isPunchOutUser,
    singleSignOn: isSingleSignOnUser,
  }
  const [punchout = ''] = Object.entries(punchoutTypes)
    .filter(([, value]) => value)
    .map(([key]) => key)

  useEffect(() => {
    initializeDataDog()
    setDataDogUser({
      ...buyer,
      punchout,
    })
  })
  return null
}

DataDogConfig.propTypes = {
  buyer: PropTypes.object,
  punchoutSettings: PropTypes.object,
}

export default DataDogConfig
