export default {
  en: {
    becomeAMemberLabel:
      'To use this feature, please sign in or become a customer',
    orderTotalLabel: 'Order total',
    moreItemsLabel: 'Your cart summary contains the last 5 items added.',
    cartLabel: 'Cart',
    emptyCartLabel: 'You haven’t added any items to your cart.',
  },
  fr: {
    becomeAMemberLabel:
      'Veuillez ouvrir une session ou devenez client pour utiliser cette fonctionnalité',
    orderTotalLabel: 'Total de la commande',
    moreItemsLabel:
      'Le sommaire de votre panier contient les 5 derniers articles ajoutés.',
    cartLabel: 'Panier',
    emptyCartLabel: `Vous n'avez ajouté aucun article à votre panier.`,
  },
}
