import Box from '../Box'
import Icon from '../Icon'
import Text from '../Text'
import Tooltip from '../Tooltip'
import PropTypes from 'prop-types'
import { handleTooltipShowHide } from '~/services/utils'
import styles from './QuestionTooltip.module.scss'

function QuestionTooltip({ children, text, id, offset, place, ...rest }) {
  return (
    <Box {...rest}>
      <Icon
        className={styles.icon}
        variant="question"
        data-tip
        tabIndex="0"
        onFocus={handleTooltipShowHide}
        onBlur={handleTooltipShowHide}
        data-for={id}
        role="img"
        aria-describedby={id}
        aria-label={text}
      />
      {children}
      <Tooltip
        id={id}
        place={place}
        effect="solid"
        className={styles.tooltip}
        offset={offset}
      >
        <Text role="tooltip" text={text} as="div" />
      </Tooltip>
    </Box>
  )
}

QuestionTooltip.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  text: PropTypes.node,
  place: PropTypes.string,
  offset: PropTypes.object,
}

export default QuestionTooltip
