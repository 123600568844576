import { useState } from 'react'
import {
  SHOW_USER_LOGIN,
  SHOW_USER_ID_REMINDER,
  SHOW_PASSWORD_REMINDER,
} from './signInConstants'
import messages from './messages'
export const useSignInForms = (language) => {
  const [currentFormState, setCurrentFormState] = useState(SHOW_USER_LOGIN)
  const { signInLabel, passwordReminderLabel, userIdReminderLabel } =
    messages[language]

  const formsLabel = [
    {
      title: signInLabel,
    },
    {
      title: passwordReminderLabel,
    },
    {
      title: userIdReminderLabel,
    },
  ]
  const activeFormTitle = formsLabel[currentFormState].title
  const showUserIdReminderForm = currentFormState === SHOW_USER_ID_REMINDER
  const showPasswordReminderForm = currentFormState === SHOW_PASSWORD_REMINDER
  const showUserLoginForm = currentFormState === SHOW_USER_LOGIN
  const showLoginLink = [
    SHOW_PASSWORD_REMINDER,
    SHOW_USER_ID_REMINDER,
  ].includes(currentFormState)

  return {
    currentFormState,
    setCurrentFormState,
    activeFormTitle,
    showUserIdReminderForm,
    showPasswordReminderForm,
    showUserLoginForm,
    showLoginLink,
  }
}
