export default {
  en: {
    manageAddressesSectionTitle: 'Manage Addresses',
    addNewAddressButton: 'Add new address',
    failed: 'Something went wrong please try again later.',
    featureMessage:
      'The address list feature is being worked on. Come back later! 😉',
    cancel: 'Cancel',
    delete: 'Delete',
    deleteAddress: 'Delete Address',
    confirmDeleteMsg: 'Are you sure you want to delete this address ?',
    loadingText: '...loading',
    confirmPrimaryAddressDeleteMsg:
      'You have selected to delete your primary address.  Deleting your primary address will result in another saved address being selected as your primary address.',
    deliveryAddress: 'Delivery Address',
    deliveryAddresses: 'Delivery addresses',
    billingAddress: 'Billing Address',
    billingAddresses: 'Billing addresses',
    costCenters: 'Cost Centers',
    address: 'Address',
    search: 'Search',
    assign: 'Assign',
    save: 'Save',
    name: 'Name',
    visibility: 'Visibility',
    attention: 'Attention',
    privateHeading: 'Private',
    sharedHeading: 'Shared',
    globalHeading: 'Global',
    assignSuccessMsg: 'Delivery addresses were successfully assigned.',
    assignModalMessage:
      'Select the delivery addresses that you want to assign to ”{name}” billing address.',
    assignModalMessageDetail:
      'By assigning one or more delivery addresses, this billing address ("{name}”) will only be allowed for the assigned delivery addresses.',
    noAddressesFoundMsg: 'No matches found. Please adjust your search entry.',
    selectAddresses: 'select addresses',
    gridViewLabel: 'Card View',
    tableViewLabel: 'List View',
  },
  fr: {
    manageAddressesSectionTitle: 'Gérer les adresses',
    addNewAddressButton: 'Ajouter une nouvelle adresse',
    failed: `Quelque chose c'est mal passé. Merci d'essayer plus tard.`,
    featureMessage: `La fonction de liste d'adresses est en cours de développement. Revenez plus tard! 😉 `,
    cancel: 'Annuler',
    delete: 'Supprimer',
    deleteAddress: "Supprimer l'adresse",
    confirmDeleteMsg: 'Voulez-vous vraiment supprimer cette adresse ?',
    loadingText: '...Chargement en cours',
    confirmPrimaryAddressDeleteMsg: `Vous avez choisi de supprimer votre adresse principale. La suppression de votre adresse principale entraînera la sélection d'une autre adresse enregistrée comme adresse principale.`,
    deliveryAddress: 'adresse de livraison',
    deliveryAddresses: 'Adresses de livraison',
    billingAddress: 'adresse de facturation',
    billingAddresses: 'Adresses de facturation',
    costCenters: 'Centres de coûts',
    address: 'adresse',
    search: 'Rechercher',
    assign: 'Assigner',
    save: 'Sauvegarder',
    name: 'Nom de',
    visibility: 'Visibilité',
    attention: 'Attention',
    privateHeading: 'Privée',
    sharedHeading: 'Partagée',
    globalHeading: 'Globale',
    noAddressesFoundMsg:
      'Aucun résultat. Veuillez modifier votre entrée de recherche',
    assignModalMessage: `Sélectionnez les adresses de livraison que vous souhaitez assigner à l'adresse de facturation ”{name}”.`,
    assignSuccessMsg:
      'Les adresses de livraison ont été assignées avec succès.',
    assignModalMessageDetail:
      'En assignant une ou plusieurs adresses de livraison, cette adresse de facturation ("{name}”) ne sera autorisée que pour les adresses de livraison assignées.',
    selectAddresses: 'Sélectionnez Adresses',
    gridViewLabel: 'Carte',
    tableViewLabel: 'Liste',
  },
}
