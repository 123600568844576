import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import { setPrimaryById } from '~/context/ManageAddressesContext/ManageAddressesContextUtils'
import { mapApiKeyToFormKeyAddressesResponse } from '../ManageAddressesUtils'

export function formatAddresses(
  addresses = [],
  addressGroup,
  primaryAddressId = ''
) {
  const filterType =
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS
      ? setPrimaryById(primaryAddressId) // ads the primary id to the address
      : (a) => a // returns the element as is

  const filteredAddresses = mapApiKeyToFormKeyAddressesResponse(
    addresses,
    addressGroup
  )
  return filteredAddresses.map(filterType)
}
