import ContentLoader from 'react-content-loader'

const ManageAddressesSkeleton = () => {
  return (
    <>
      <ContentLoader
        height={'450'}
        style={{ width: '33%' }}
        uniqueKey="manage-address-skeleton-1"
      >
        <rect x="0" y="20" rx="5" ry="5" width="100%" height="200" />
        <rect x="0" y="230" rx="0" ry="0" width="100%" height="200" />
      </ContentLoader>
      <ContentLoader
        height={'450'}
        style={{ width: '33%' }}
        uniqueKey="manage-address-skeleton-2"
      >
        <rect x="10" y="20" rx="5" ry="5" width="100%" height="200" />
        <rect x="10" y="230" rx="0" ry="0" width="100%" height="200" />
      </ContentLoader>
      <ContentLoader
        height={'450'}
        style={{ width: '33%' }}
        uniqueKey="manage-address-skeleton-3"
      >
        <rect x="10" y="20" rx="5" ry="5" width="100%" height="200" />
        <rect x="10" y="230" rx="0" ry="0" width="100%" height="200" />
      </ContentLoader>
    </>
  )
}

export default ManageAddressesSkeleton
