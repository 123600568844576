import PropTypes from 'prop-types'
import Button from 'shared/Button'
import ListItem from 'shared/ListItem'
import styles from './ContextMenu.module.scss'

/**
 *Name: ContextMenu
 *Desc: Render ContextMenu
 * @param {string} text
 * @param {string} icon
 * @param {string} variant
 * @param {array} dropDownList
 * @param {func} onChange
 * @param {string} name
 */

const ContextMenu = ({
  text,
  icon,
  variant,
  contextMenuList: list,
  onChange,
  name,
}) => {
  const syntheticEvent = {
    target: {
      name,
      value: '',
    },
  }
  return (
    <div className={styles.contextMenu}>
      <Button text={text} icon={icon} variant={variant} />
      <ul className={styles.contextMenuList}>
        {list.map((item, index) => {
          const dataTest = item.value ? `context-menu-${item.value}` : null
          return (
            <ListItem key={index}>
              <Button
                text={item.name}
                data-test={dataTest}
                onClick={() => {
                  syntheticEvent.target.value = item.value
                  onChange(syntheticEvent)
                }}
                variant="link"
              />
            </ListItem>
          )
        })}
      </ul>
    </div>
  )
}

// Props Validation
ContextMenu.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.string,
  contextMenuList: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
}

export default ContextMenu
