import { ADDRESS_VISIBILITY } from './SaveAddressFormConstants'
export default {
  en: {
    required: 'This field is required.',
    invalidPostalCode: `The format entered is invalid.`,
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    savingText: '...Saving',
    addDeliveryAddressTitle: 'Add Delivery Address',
    editDeliveryAddressTitle: 'Edit Delivery Address',
    addBillingAddressTitle: 'Add Billing Address',
    editBillingAddressTitle: 'Edit Billing Address',
    addressTypeLabel: 'Address Type',
    selectBuyers: 'Select buyers',
    editSelection: 'Edit Selection',
    selectedBuyer: '{NUMBER} buyer selected',
    selectedBuyers: '{NUMBER} buyers selected',
    [ADDRESS_VISIBILITY.PRIVATE]: 'Private',
    [ADDRESS_VISIBILITY.SHARED]: 'Shared',
    [ADDRESS_VISIBILITY.GLOBAL]: 'Global',
    proceed: 'Proceed',
    warning: 'Warning',
    warningShortDescriptionBillingAddress:
      'The billing address you are trying to save is an exact match with an address (card) that is already on your saved list of billing addresses. Saving these changes will result in a duplicate address being created.',
    warningShortDescriptionDeliveryAddress:
      'The delivery address you are trying to save is an exact match with an address (card) that is already on your saved list of delivery addresses. Saving these changes will result in a duplicate address being created.',
    warningLongDescription: 'Please confirm if you want to proceed.',
    privateDescription: 'Private addresses are visible to you only.',
    sharedDescription:
      'Shared addresses are visible to you and the buyers you have shared them with.',
    globalDescription:
      'Global addresses are visible to you and all buyers in your organization.',
    lockIconToolTip:
      'By default, address fields are locked so that buyers using this address cannot override the corresponding fields, however you have the ability to unlock this specific field. Simply click on the lock icon to unlock this field.',
    unLockIconToolTip:
      'Currently this field is unlocked so that buyers using this address can override it. If you want this field locked, simply click on the lock icon.',

    matchExistingBillingAddressMsg:
      'The billing address you are trying to add already exists. Confirming this addition will result in a duplicate address.',
    matchExistingDeliveryAddressMsg:
      'The delivery address you are trying to add already exists. Confirming this addition will result in a duplicate address.',
    matchExistingDeliveryBillingMsg:
      'The delivery & billing address you are trying to add already exists. Confirming this addition will result in a duplicate address.',
  },
  fr: {
    required: 'Ce champ est requis.',
    invalidPostalCode: 'Le format entré est invalide.',
    cancel: 'Annuler',
    close: 'Fermer',
    save: 'Sauvegarder',
    savingText: '...Enregistrement',
    addDeliveryAddressTitle: 'Ajouter une adresse de livraison',
    editDeliveryAddressTitle: "Modifier l'adresse de livraison",
    addBillingAddressTitle: 'Ajouter une adresse de facturation',
    editBillingAddressTitle: "Modifier l'adresse de facturation",
    addressTypeLabel: "type d'adresse",
    selectBuyers: 'Sélectionnez les acheteurs',
    editSelection: 'Modifier la sélection',
    selectedBuyer: '{NUMBER} acheteur sélectionné',
    selectedBuyers: '{NUMBER} acheteurs sélectionnés',
    [ADDRESS_VISIBILITY.PRIVATE]: 'Privée',
    [ADDRESS_VISIBILITY.SHARED]: 'Partagée',
    [ADDRESS_VISIBILITY.GLOBAL]: 'Globale',
    proceed: 'Continuer',
    warning: 'Avertissement',
    warningShortDescriptionBillingAddress:
      "L'adresse de facturation que vous essayez d'enregistrer correspond exactement à une adresse (carte) qui figure déjà sur votre liste d'adresses de facturation enregistrée. L'enregistrement de ces modifications entraînera la création d'une adresse de livraison en double.",
    warningShortDescriptionDeliveryAddress:
      "L'adresse que vous tentez de sauvegarder correspond exactement à une adresse (carte) déjà dans votre liste d'adresses sauvegardées. La sauvegarde de ces changements entraînera la création d'une adresse en double.",
    warningLongDescription: 'Veuillez confirmer si vous souhaitez continuer.',
    privateDescription: 'Les adresses privées ne sont visibles que par vous.',
    sharedDescription:
      'Les adresses partagées sont visibles pour vous et les acheteurs avec qui vous les avez partagées.',
    globalDescription:
      'Les adresses globales sont visibles pour vous et tous les acheteurs de votre organisation.',
    lockIconToolTip:
      "Par défaut, les champs d'adresse sont verrouillés afin que les acheteurs qui utilisent cette adresse ne puissent pas remplacer les champs correspondants, toutefois vous avez la possibilité de déverrouiller ce champ spécifique. Cliquez simplement sur l'icône de verrouillage pour le déverrouiller.",
    unLockIconToolTip:
      "Actuellement, ce champ est déverrouillé afin que les acheteurs utilisant cette adresse puissent la remplacer. Si vous souhaitez que ce champ soit verrouillé, cliquez simplement sur l'icône de verrouillage.",
    matchExistingBillingAddressMsg:
      'L’adresse de facturation que vous tentez d’ajouter existe déjà. La confirmation de cet ajout entraînera une adresse en double.',
    matchExistingDeliveryAddressMsg:
      'L’adresse de livraison que vous tentez d’ajouter existe déjà. La confirmation de cet ajout entraînera une adresse en double.',
    matchExistingDeliveryBillingMsg:
      'L’adresse de livraison et de facturation que vous tentez d’ajouter existe déjà. La confirmation de cet ajout entraînera une adresse en double.',
  },
}
