import * as colors from 'assets/scss/colors.module.scss'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Button from 'shared/Button'
import Flex from 'shared/Flex'
import Icon from 'shared/Icon'
import Text from 'shared/Text'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { HOME_PAGE_MESSAGE_ID } from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipCardMessages/MembershipCardMessagesConstants'
import Container from '~/components/shared/Container'
import EwayLink from '~/components/shared/EwayLink'
import Logout from '~/components/shared/Layout/Header/Logout'
import LiveChat from '~/components/shared/LiveChat'
import LiveChatData from '~/components/shared/LiveChat/LiveChatData'
import NoSSR from '~/components/shared/NoSSR'
import ShipToComponent from '~/components/shared/ShipToComponent'
import { getCookie } from '~/services/cookieManager'
import { getAndApplyPromoDiscount } from '~/services/orderService'
import styles from './HeaderTop.module.scss'
import LanguageSwitcher from './LanguageSwitcher'
import messages from './messages'
import { SAVED_POSTAL_CODE } from './HeaderTopConstants'

/**
 * Name: HeaderTop
 * Desc: To be implement logic of header top section
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {object} buyer
 * @param {func} setLanguage
 * @param {object} settings
 * @param {object} details
 */

const HeaderTop = ({
  language,
  isLoggedIn,
  buyer = {},
  setLanguage,
  settings,
  details,
  showShipToComponent,
  showSelectedPostalCode,
  onClickPostalCodeChooserButton,
  ...props
}) => {
  const {
    showLogOff,
    logoffAction,
    logoffUrl,
    enableClickToChat,
    displayEwayMessagingCenter,
  } = settings

  const [savedPostalCode, setSavedPostalCode] = useState()
  useEffect(() => {
    if (showSelectedPostalCode) {
      setSavedPostalCode(getCookie(SAVED_POSTAL_CODE))
    }
  }, [showSelectedPostalCode])

  const postalCodeText = savedPostalCode
    ? savedPostalCode?.toUpperCase()
    : messages[language].changePostalCode

  const { buyerId, canManageOwnProfileFlag, inviteeState } = buyer
  const { isCustomerOnboardingAccount } = details

  const showLanguageSwitcher =
    (isLoggedIn &&
      (canManageOwnProfileFlag ||
        inviteeState === 1 ||
        isCustomerOnboardingAccount)) ||
    !isLoggedIn

  const total = getAndApplyPromoDiscount(props.order)
  const liveChat = (
    <>
      <LiveChatData
        buyer={buyer}
        details={details}
        language={language}
        price={total}
      />
      <LiveChat language={language} />
    </>
  )

  return (
    <div className={styles.headerTop}>
      <Container>
        <div className={styles.headerTopBlock}>
          <div className={styles.leftSection}>
            {showLanguageSwitcher ? (
              <LanguageSwitcher
                language={language}
                isLoggedIn={isLoggedIn}
                buyerId={buyerId}
                onLanguageChange={setLanguage}
              />
            ) : null}
            {isLoggedIn && showLogOff ? (
              <Logout
                language={language}
                logoffAction={logoffAction}
                logoffUrl={logoffUrl}
              />
            ) : null}
          </div>

          <div className={styles.rightSection}>
            {isLoggedIn ? (
              <>
                <div className={styles.rightMenu}>
                  {displayEwayMessagingCenter &&
                    props.pageName === PAGE_NAMES.HOME && (
                      <EwayLink href={`#${HOME_PAGE_MESSAGE_ID}`}>
                        <Text
                          text={messages[language].messages}
                          color="primary"
                        />
                      </EwayLink>
                    )}
                </div>

                {enableClickToChat && liveChat}
              </>
            ) : (
              liveChat
            )}
          </div>

          <NoSSR>
            {showShipToComponent ? (
              <ShipToComponent id="headerTop" language={language} {...props} />
            ) : showSelectedPostalCode ? (
              <Flex
                align="center"
                borderRight={`1px solid ${colors.borderColor}`}
                data-test="postal-code-chooser"
              >
                <div className={styles.rightMenu}>
                  <Flex align="center">
                    <Icon
                      variant="location"
                      title="location"
                      className={styles.locationIcon}
                    />
                    {savedPostalCode ? (
                      <Text
                        text={messages[language].deliverTo}
                        className={styles.deliverToText}
                      />
                    ) : null}
                    <Button
                      variant="link"
                      onClick={onClickPostalCodeChooserButton}
                      text={postalCodeText}
                      className={styles.postalCodeLink}
                      data-test="postal-code-choose-button"
                    />
                  </Flex>
                </div>
              </Flex>
            ) : null}
          </NoSSR>
        </div>
      </Container>
    </div>
  )
}

// Default props
HeaderTop.defaultProps = {
  buyerId: '',
  settings: {},
}

// PropType Validation
HeaderTop.propTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  showShipToComponent: PropTypes.bool,
  buyer: PropTypes.object,
  setLanguage: PropTypes.func,
  settings: PropTypes.object,
  details: PropTypes.object,
  pageName: PropTypes.string,
  order: PropTypes.object,
  sessionId: PropTypes.string,
  showSelectedPostalCode: PropTypes.bool,
  onClickPostalCodeChooserButton: PropTypes.func,
  savedPostalCode: PropTypes.string,
}

export default HeaderTop
