import { doLogout } from 'components/shared/Layout/Header/Logout/LogoutUtils'
import { isDev } from '~/config/env/environmentUtils'
import { useEffect, useRef, useState } from 'react'

/**
 * useIsIdle
 * Custom hooks that checks the user is idle.
 * @param {number} timing The number of milliseconds before the isInactive flag is set to true
 */
export function useIsIdle(timing) {
  const [isInactive, setIsInactive] = useState(false)
  const timeRef = useRef()

  useEffect(() => {
    // on load, start the timer
    resetTimer()

    // register dom events
    document.addEventListener('mousemove', resetTimer)
    document.addEventListener('keypress', resetTimer)

    function resetTimer() {
      clearTimeout(timeRef.current)
      timeRef.current = setTimeout(() => {
        // Dont set user inactive in dev for better developer experience
        if (isDev()) {
          return
        }
        setIsInactive(true)
        cleanUpEvents()
      }, timing)
    }

    // unregister dom events
    function cleanUpEvents() {
      document.removeEventListener('mousemove', resetTimer)
      document.removeEventListener('keypress', resetTimer)
    }

    return () => {
      cleanUpEvents()
    }
  }, [timing])
  return { isInactive }
}

export const handleOk = () => {
  doLogout()

  window.location.assign('/')
}
