import { ADDRESS_VISIBILITY } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
export const FIELDS = {
  COST_CENTER_VALUE: 'value',
  COST_CENTER_LABEL: 'label',
  VISIBILITY: 'visibility',
}
export const DEFAULT_OPTION_TYPE = ADDRESS_VISIBILITY.GLOBAL
export const MAX_LENGTH_25 = 25
export const COST_CENTER_SINGLE_ROW_COLUMN = 5
export const ASC = 'asc'
export const DESC = 'desc'
export const CSV_EXT = 'csv'
export const DOWNLOAD_COST_CENTER_EN_CSV_FILE_URL =
  '/static/cost-center/cost-centers.csv'
export const DOWNLOAD_COST_CENTER_FR_CSV_FILE_URL =
  '/static/cost-center/cost-centers-fr.csv'
export const SORT_DESC = 'sortDescending'
export const SORT_ASC = 'sortAscending'
export const SORT_DEFAULT = 'sortDefault'
