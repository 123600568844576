import { ADDRESS_GROUPS } from 'components/containers/ManageAddressesContainer/ManageAddressConstants'
export const FIELDS = {
  STREET_ADDRESS: 'streetAddress', // required
  COMPANY_NAME: 'companyName', // required
  CITY: 'city', // required
  PROVINCE: 'province', // required
  POSTAL_CODE: 'postalCode', // required
  BUILDING: 'building',
  ATTENTION: 'attention',
  IS_DEFAULT: 'isDefault',
  ADDRESS_TYPE: 'visibility',
  SELECTED_BUYERS: 'selectedBuyersList',
  USE_AS_BILLING: 'canUseAsBillingAddress',
}

// When doing request outside the application, name changes to following

export const DELIVERY_API_MAPPING = {
  [FIELDS.POSTAL_CODE]: 'shippingPostalCode',
  [FIELDS.COMPANY_NAME]: 'shippingName',
  [FIELDS.ATTENTION]: 'shippingContact',
  [FIELDS.STREET_ADDRESS]: 'shippingAddress1',
  [FIELDS.BUILDING]: 'shippingAddress2',
  [FIELDS.ADDRESS_TYPE]: 'visibility',
  [FIELDS.CITY]: 'city',
  [FIELDS.PROVINCE]: 'province',
  [FIELDS.SELECTED_BUYERS]: 'deliveryAddressBuyer',
  clientNo: 'shipTo',
}

export const BILLING_API_MAPPING = {
  [FIELDS.POSTAL_CODE]: 'billingPostalCode',
  [FIELDS.COMPANY_NAME]: 'billingName',
  [FIELDS.ATTENTION]: 'billingContact',
  [FIELDS.STREET_ADDRESS]: 'billingAddress1',
  [FIELDS.BUILDING]: 'billingAddress2',
  [FIELDS.CITY]: 'billingCity',
  [FIELDS.PROVINCE]: 'billingProvince',
  [FIELDS.ADDRESS_TYPE]: 'visibility',
  [FIELDS.SELECTED_BUYERS]: 'billingAddressBuyer',
  clientNo: 'shipTo',
}

export const API_MAPPING_TYPE = {
  [ADDRESS_GROUPS.BILLING_ADDRESS]: BILLING_API_MAPPING,
  [ADDRESS_GROUPS.DELIVERY_ADDRESS]: DELIVERY_API_MAPPING,
}

export const LOCK_FLAG_API_MAPPING = {
  [FIELDS.COMPANY_NAME]: 'isNameLocked',
  [FIELDS.ATTENTION]: 'isAttentionLocked',
  [FIELDS.BUILDING]: 'isBuildingLocked',
}

export const ACTION = {
  ADD: 'add',
  EDIT: 'edit',
}

export const ADDRESS_TYPE = {
  BILLING_ADDRESS: 'BillingAddress',
  DELIVERY_ADDRESS: 'DeliveryAddress',
}

export const MAX_LENGTH_22 = 22
export const MAX_LENGTH_25 = 25
export const MAX_LENGTH_30 = 30
export const MAX_LENGTH_40 = 40
export const ADDRESS_VISIBILITY = {
  PRIVATE: 0,
  SHARED: 1,
  GLOBAL: 2,
}
export const ADDRESS_TYPES_VIEW_LEVEL = {
  SELF: 1,
  PRIVATE: 2,
  SHARED: 3,
  GLOBAL: 4,
}
export const DEFAULT_OPTION_TYPE = '0'

export const DEFAULT_LOCKED_FIELDS_TRUE_VALUE = {
  [LOCK_FLAG_API_MAPPING[FIELDS.COMPANY_NAME]]: true,
  [LOCK_FLAG_API_MAPPING[FIELDS.ATTENTION]]: true,
  [LOCK_FLAG_API_MAPPING[FIELDS.BUILDING]]: true,
}

export const DEFAULT_BUYER_COUNT = 1

export const PRIMARY_ADDRESS_ID = {
  DELIVERY: '/primaryDeliveryAddressId',
  BILLING: '/primaryBillingAddressId',
}
export const ASC = 'asc'
export const DESC = 'desc'
export const IS_CHECKED = 'isChecked'
export const CHECKED = 'checked'
export const UN_CHECKED = 'un-checked'
