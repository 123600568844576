import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { ACTION } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import {
  DELIVERY_ADDRESS,
  ADDRESS_GROUPS,
} from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import { ENGLISH } from '~/redux/constants'
import styles from './AddNewAddressButton.module.scss'
import messages from './messages'

/**
 * AddNewAddressButton component for render add address button button
 * @param {string} text
 * @param {func} onClick
 * @returns element
 */

export const AddNewAddressButton = ({
  language = ENGLISH,
  text,
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS,
  ...rest
}) => {
  const translation = messages[language]

  return (
    <Button
      variant="primary"
      text={
        text || addressGroup === DELIVERY_ADDRESS
          ? translation.addNewDeliveryAddress
          : translation.addNewBillingAddress
      }
      data-test="add-new-address-button"
      id={`${ACTION.ADD}-${addressGroup}`}
      className={styles.addressBtn}
      {...rest}
    />
  )
}
AddNewAddressButton.propTypes = {
  text: PropTypes.string,
  language: PropTypes.string,
  addressGroup: PropTypes.string,
}
