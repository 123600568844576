export default {
  en: {
    clickToGetStarted: `Click on Add new address to get started.`,
    deliveryNoAddresses:
      'Currently, you do not have any saved delivery addresses.',
    billingNoAddresses: `Currently, you do not have any saved billing addresses.`,
    noAddressesFoundMsg: 'No matches found. Please adjust your search entry.',
    search: 'Search',
    name: 'Name',
    type: 'Address Type',
    address: 'Address',
    attention: 'Attention',
    gridViewLabel: 'Grid View',
    tableViewLabel: 'List View',
  },
  fr: {
    clickToGetStarted: `Cliquez sur Ajouter une nouvelle adresse pour commencer.`,
    deliveryNoAddresses: `Actuellement, vous ne disposez d'aucune adresse de livraison enregistrée.`,
    billingNoAddresses: `Actuellement, vous ne disposez d'aucune adresse de facturation enregistrée.`,
    noAddressesFoundMsg:
      'Aucun résultat. Veuillez modifier votre entrée de recherche',
    search: 'Rechercher',
    name: 'Nom',
    type: "Type d'adresse",
    address: 'Adresse',
    attention: 'Attention',
    gridViewLabel: 'Grille',
    tableViewLabel: 'Liste',
  },
}
