export function getPrimaryAddress(addresses = []) {
  return addresses?.find((a) => a.isDefault) || {}
}

export function getAddressById(addresses = [], id) {
  return addresses.find((address) => address.id === id) || {}
}

export function setPrimaryById(id) {
  return (a) => (id === a.id ? { ...a, isDefault: true } : a)
}

//TODO: above functions, Probably should be moved into ManageAddressesUtils in shared/ManageAddressesUtils
