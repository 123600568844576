export default {
  en: {
    logout: 'Logout',
    closeSession: 'Close Session',
  },
  fr: {
    logout: 'Déconnexion',
    closeSession: 'Fermer la session',
  },
}
