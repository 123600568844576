import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { canShowLogo } from 'services/utils'
import useIsFirstRender from 'shared/CustomHooks/useIsFirstRender'
import { ENGLISH } from '~/redux/constants'
import { buildOrder } from '~/services/orderService'
import { getInkAndTonerFeatureFlag } from '~/services/utils/configUtils'
import HeaderMiddleSection from './HeaderMiddleSection'
import HeaderTop from './HeaderTop'
const ENABLE_NEW_INK_AND_TONER = getInkAndTonerFeatureFlag()

/**
 *Name: Header
 *Desc: Render header aggregator
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {func} setLanguage
 * @param {string} costCenter
 * @param {boolean} showLogo
 * @param {boolean} enableInkTonerFinder
 * @param {boolean} showYourPriceFlag
 * @param {string} order
 * @param {object} buyer
 * @param {object} settings
 * @param {object} details
 */

const Header = ({
  language = ENGLISH,
  isLoggedIn,
  setLanguage,
  showYourPriceFlag,
  order = {},
  buyer = {},
  settings = {},
  details = {},
  sessionId,
  onClickPostalCodeChooserButton,
  ...props
}) => {
  const isFirstRender = useIsFirstRender()
  const [orderInfo, setOrderInfo] = useState(order)

  useEffect(() => {
    async function initSetOrderInfo() {
      if (!isFirstRender) {
        const order =
          (await buildOrder({ buyerId: buyer.buyerId, sessionId })) || {}
        if (order.orderId && Object.keys(order).length) {
          setOrderInfo(order)
        }
      }
    }
    !isFirstRender && initSetOrderInfo()
  }, [buyer.buyerId, isFirstRender, sessionId])

  const { enableInkTonerFinder } = settings
  return (
    <header>
      <HeaderTop
        language={language}
        isLoggedIn={isLoggedIn}
        buyer={buyer}
        details={details}
        setLanguage={setLanguage}
        settings={settings}
        onClickPostalCodeChooserButton={onClickPostalCodeChooserButton}
        {...props}
        sessionId={sessionId}
        order={orderInfo}
      />

      <HeaderMiddleSection
        language={language}
        isLoggedIn={isLoggedIn}
        showLogo={canShowLogo(settings)}
        showYourPriceFlag={showYourPriceFlag}
        showNewFeatureInkAndToner={ENABLE_NEW_INK_AND_TONER}
        showInkAndTonerLink={
          !isLoggedIn || (isLoggedIn && !!enableInkTonerFinder)
        }
        buyer={buyer}
        settings={settings}
        details={details}
        sessionId={sessionId}
        {...props}
        order={orderInfo}
      />
    </header>
  )
}

HeaderTop.defaultProps = {
  session: {},
}

// PropType Validation
Header.propTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  setLanguage: PropTypes.func,
  costCenter: PropTypes.string,
  onClickPostalCodeChooserButton: PropTypes.func,
  showYourPriceFlag: PropTypes.bool,
  order: PropTypes.object,
  buyer: PropTypes.object,
  settings: PropTypes.object,
  details: PropTypes.object,
  sessionId: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    cartUpdateTimeStamp: state.state.cartUpdateTimeStamp,
  }
}

export default connect(mapStateToProps, null)(Header)
