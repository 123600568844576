import { ENGLISH } from '@redux/constants'
import PropTypes from 'prop-types'
import { memo, useCallback } from 'react'
import { FixedSizeList } from 'react-window'
import { LOCAL_STORAGES } from 'services/constants'
import Button from 'shared/Button'
import Checkbox from 'shared/FormControl/Checkbox'

import Table from 'shared/Table'
import TableRow from 'shared/Table/TableRow'
import { useShipToContext } from '~/context/ShipToContext/ShipToContext'
import { setLocalStorage } from '~/services/storageManager'
import { showAccountSelectionModalNotification } from '../../AccountSelectionUtils'
import styles from './FindAccountTable.module.scss'
import messages from './messages'
import TableSkeleton from './TableSkeleton'

/**
 *Name: FindAccountTable
 *Desc: Render FindAccountTable
 * @param {string} language
 * @param {array} tableData
 * @param {array} accountList
 * @param {function} handleMarkFavorite
 * @param {boolean} enableFavoriteShipTo
 * @param {boolean} isDisabledAccountSelection
 */

const FindAccountTable = ({
  language = ENGLISH,
  tableData,
  accountList,
  handleMarkFavorite,
  enableFavoriteShipTo,
  isDisabledAccountSelection,
}) => {
  const { selectShipToAccountAndReload } = useShipToContext()

  const RenderRow = useCallback(
    ({ index, style }) => {
      const tableRowData = tableData[index]
      const { clientId } = tableRowData

      return (
        <div style={{ ...style }} key={clientId}>
          <TableRow
            rowCells={getTableRow({
              language,
              item: tableRowData,
              handleMarkFavorite,
              enableFavoriteShipTo,
              selectShipToAccountAndReload,
              isDisabledAccountSelection,
            })}
            showToggleIcon={false}
            className={styles.findAccount}
          />
        </div>
      )
    },
    [
      enableFavoriteShipTo,
      isDisabledAccountSelection,
      handleMarkFavorite,
      language,
      selectShipToAccountAndReload,
      tableData,
    ]
  )

  if (tableData.length === 0) {
    return (
      <div className={styles.tableWrapper}>
        {accountList.length === 0 ? (
          <TableSkeleton />
        ) : (
          <div className={styles.noContent}>
            {messages[language].noTableData}
          </div>
        )}
      </div>
    )
  }

  const customBody = (
    <FixedSizeList height={270} itemCount={tableData.length} itemSize={80}>
      {RenderRow}
    </FixedSizeList>
  )
  const tableColumns = isDisabledAccountSelection
    ? messages[language].tableColumnsWithoutSelect
    : messages[language].tableColumns
  return (
    <div className={styles.findAccountTableWrapper}>
      <div className={styles.tableWrapper}>
        <Table
          language={language}
          columns={tableColumns}
          fontWeight="medium"
          variantText="mdText"
          color="secondary"
          data-test="accountTable"
          noHeaderBackground={false}
          customBody={customBody}
          className={styles.findAccount}
        />
      </div>
    </div>
  )
}

const getTableRow = ({
  language,
  item,
  handleMarkFavorite,
  enableFavoriteShipTo,
  isDisabledAccountSelection,
  selectShipToAccountAndReload,
}) => {
  const address1 = item.address1 || ''
  const address2 = item.address2 || ''
  const address3 = item.address3 || ''
  const postalCode = item.shippingPostalCode || ''
  const address = `${address1} ${address2} ${address3} ${postalCode}`
  const {
    clientId,
    clientNo,
    shippingName,
    isFavorite,
    costCenter,
    shippingContact,
  } = item
  const handleAccountSelection = () => {
    showAccountSelectionModalNotification({
      notificationMessage: messages[language].accountSelection,
    })
    setLocalStorage(LOCAL_STORAGES.ACCOUNT_SELECTION_MODAL, clientNo)
    selectShipToAccountAndReload({ clientNo, clientId, isFavorite })
  }

  const handleMarkAsFavorite = () => {
    if (enableFavoriteShipTo) {
      handleMarkFavorite(clientId, isFavorite)
      showAccountSelectionModalNotification({
        notificationMessage: isFavorite
          ? messages[language].accountMarkedUnFavorite
          : messages[language].accountMarkedFavorite,
      })
    }
  }

  const defaultTableBodyElement = [
    <Checkbox
      key={1}
      variant="star"
      onChange={handleMarkAsFavorite}
      className={styles.starIcon}
      checked={isFavorite}
      id={`${'ration-account-'}${clientNo}`}
      data-test="favorite-icon"
    />,
    clientNo,
    shippingName,
    costCenter || '',
    address,
    shippingContact || '',
  ]
  const dynamicTableBodyElement = [
    !isDisabledAccountSelection && (
      <Button
        key={2}
        variant="redLink"
        onClick={handleAccountSelection}
        text={messages[language].selectAccount}
        data-test="selectAccountBtn"
        className={styles.selectAccountBtn}
      />
    ),
  ].filter(Boolean)
  return [...defaultTableBodyElement, ...dynamicTableBodyElement]
}

FindAccountTable.defaultProps = {
  tableData: [],
}

// Props Validation
FindAccountTable.propTypes = {
  language: PropTypes.string,
  tableData: PropTypes.array,
  accountList: PropTypes.array,
  handleMarkFavorite: PropTypes.func,
  enableFavoriteShipTo: PropTypes.bool,
  isDisabledAccountSelection: PropTypes.bool,
}

export default memo(FindAccountTable)
