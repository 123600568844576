import {
  FIELDS,
  MAX_LENGTH_22,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
} from '../SaveAddressFormConstants'
import { POSTAL_CODE_MAX_LENGTH } from 'services/constants'

export const ADDRESS_FORMS = {
  BILLING_ADDRESS: 'billing',
  DELIVERY_ADDRESS: 'delivery',
  SAVE_ADDRESS: 'save-address-form',
}

const BILLING_ADDRESS_FIELD_VARIANTS = {
  [FIELDS.COMPANY_NAME]: {
    maxLength: MAX_LENGTH_30,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-company-individual-name`,
  },
  [FIELDS.ATTENTION]: {
    maxLength: MAX_LENGTH_30,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-attention`,
  },
  [FIELDS.STREET_ADDRESS]: {
    maxLength: MAX_LENGTH_25,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-street-address`,
  },
  [FIELDS.BUILDING]: {
    maxLength: MAX_LENGTH_25,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-building`,
  },
  [FIELDS.CITY]: {
    maxLength: MAX_LENGTH_22,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-city`,
  },
  [FIELDS.PROVINCE]: {
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-province`,
  },
  [FIELDS.POSTAL_CODE]: {
    maxLength: POSTAL_CODE_MAX_LENGTH,
    'data-test': `${ADDRESS_FORMS.BILLING_ADDRESS}-postal-code`,
  },
}

const SAVE_ADDRESS_FIELD_VARIANTS = {
  [FIELDS.COMPANY_NAME]: {
    maxLength: MAX_LENGTH_30,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-company-individual-name`,
  },
  [FIELDS.ATTENTION]: {
    maxLength: MAX_LENGTH_30,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-attention`,
  },
  [FIELDS.STREET_ADDRESS]: {
    maxLength: MAX_LENGTH_25,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-street-address`,
  },
  [FIELDS.BUILDING]: {
    maxLength: MAX_LENGTH_25,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-building-floor-suite-room`,
  },
  [FIELDS.CITY]: {
    maxLength: MAX_LENGTH_22,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-city`,
  },
  [FIELDS.PROVINCE]: {
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-province`,
  },
  [FIELDS.POSTAL_CODE]: {
    maxLength: POSTAL_CODE_MAX_LENGTH,
    'data-test': `${ADDRESS_FORMS.SAVE_ADDRESS}-postal-code`,
  },
}

export const ADDRESS_FORM_FIELD_VARIANTS = {
  [ADDRESS_FORMS.BILLING_ADDRESS]: BILLING_ADDRESS_FIELD_VARIANTS,
  [ADDRESS_FORMS.SAVE_ADDRESS]: SAVE_ADDRESS_FIELD_VARIANTS,
}

export const BILLING_ADDRESS_REQUIRED_FIELDS = {
  [FIELDS.COMPANY_NAME]: true,
  [FIELDS.STREET_ADDRESS]: true,
  [FIELDS.CITY]: true,
  [FIELDS.PROVINCE]: true,
  [FIELDS.POSTAL_CODE]: true,
}
