import last from 'lodash/last'
import { ENGLISH } from '~/redux/constants'
import { isEnglish } from '~/services/utils/languageUtils'
import { ENGLISH_QUEUE, FRENCH_QUEUE } from './LiveChatDataConstants'
export const getLiveHelpData = (
  buyer = {},
  details = {},
  language = ENGLISH
) => {
  const { shippingName = '', shippingAddress3 = '', clientNo = '' } = details
  const {
    email = '',
    telNumber = '',
    firstName = '',
    lastName = '',
    logonName = '',
  } = buyer

  const division = shippingAddress3.includes(',')
    ? last(shippingAddress3.split(',')).trim()
    : shippingAddress3

  const languageQueue = isEnglish(language) ? ENGLISH_QUEUE : FRENCH_QUEUE
  const liveChatData = `|${shippingName}|${division}|${email}|${languageQueue}|${telNumber}|${firstName}|${lastName}|${clientNo}|${logonName}`

  return liveChatData
}
