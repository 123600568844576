import PropTypes from 'prop-types'
import Button from 'shared/Button'
import Heading from 'shared/Heading'
import styles from './DialogConfirmation.module.scss'

export function DialogConfirmationHeading({
  headingType,
  headingText,
  hideCloseLink,
  onCancel,
  closeText,
  ...rest
}) {
  return (
    <div className={styles.dialogHeader} {...rest}>
      <Heading
        as={headingType}
        text={headingText}
        fontWeightNormal
        className={styles.headingType}
      />
      {hideCloseLink && (
        <Button variant="link" onClick={onCancel} text={closeText} />
      )}
    </div>
  )
}
DialogConfirmationHeading.propTypes = {
  headingText: PropTypes.any,
  onCancel: PropTypes.func,
  closeText: PropTypes.string,
  headingType: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hideCloseLink: PropTypes.bool,
}

DialogConfirmationHeading.defaultProps = {
  headingType: 'h4',
}
