import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Children, cloneElement, useEffect, useRef, useState } from 'react'
import { COOKIES } from 'services/constants'
import { getCookie, removeCookie, setCookie } from 'services/cookieManager'
import { handleSignInSuccess } from 'services/userLoginService'
import Button from 'shared/Button'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import FormField from 'shared/FormControl/FormField'
import Text from 'shared/Text'
import { userLogin } from '~/api/userAuthOperations'
import {
  SHOW_PASSWORD_REMINDER,
  SHOW_USER_ID_REMINDER,
  SIGN_IN_FORM_FIELDS,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/signInConstants'
import SignInMessage from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/SignInMessage'
import { ENGLISH } from '~/redux/constants'
import { useSupportMenuContext } from '../../SupportMenuUtils'
import messages from './messages'
import styles from './UserLogin.module.scss'
import { SIGN_IN } from '../../SupportMenuConstants'
import { clearSessionDependentData } from 'shared/Layout/Header/Logout/LogoutUtils'
import { removeSessionStorage } from '~/services/storageManager'
import { CLIENT_ID } from '~/components/containers/Home/constants'
const { USER_ID, PASSWORD } = SIGN_IN_FORM_FIELDS
/**
 * Name: UserLogin
 * Desc: Render login form in sign in panel
 * @param {string} language
 * @param {func} setCurrentState
 * @param {object} buyer
 * @param {object} settings
 * @param {string} pageName
 * @param {bool} isActive
 */

const UserLogin = ({
  setCurrentState,
  language = ENGLISH,
  pageName,
  isShowBorderBottom,
  isFullSizeButton,
  location,
  children,
  id = 'userLogin',
}) => {
  const { activeMenu } = useSupportMenuContext()
  const isActive = activeMenu === SIGN_IN
  const router = useRouter()
  const [signInErrorMessage, setSignInErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // TODO: Probably settings and buyer objects are no needed here.
  const userIdRef = useRef(null)
  const { requiredUserIdMsg, requiredPassMsg } = messages[language]
  const initValues = {
    [USER_ID]: {
      value: '',
      required: requiredUserIdMsg,
    },
    [PASSWORD]: {
      value: '',
      required: requiredPassMsg,
    },
    saved: {
      value: false,
    },
  }
  const {
    values,
    errors,
    setValues,
    dirties,
    handleChange,
    handleSubmit: handleSubmitLoginForm,
  } = useFormControlled(initValues, language)

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        userIdRef.current?.form[0]?.focus({ preventScroll: false })
      }, 100)
    }
  }, [isActive])

  useEffect(() => {
    const isEmptyFields = isEmpty(values[USER_ID]) && isEmpty(values[PASSWORD])
    if (isEmptyFields && !!getCookie(COOKIES.SAVED_USER_ID)) {
      setValues({
        userId: getCookie(COOKIES.LAST_USER_ID) || '',
        password: '',
        saved: !!getCookie(COOKIES.SAVED_USER_ID) || false,
      })
    }
  })

  const handleOnChange = (event) => {
    handleChange(event)
  }

  const handleOnChangeSaveUserId = (event) => {
    const saved = event.target.checked
    const { userId } = values
    setValues({ ...values, saved })
    // When user has un-checked to save user id
    if (saved && userId) {
      // When user checked to save user id
      setCookie(COOKIES.SAVED_USER_ID, true)
      setCookie(COOKIES.LAST_USER_ID, userId)
    } else {
      removeCookie(COOKIES.SAVED_USER_ID)
      removeCookie(COOKIES.LAST_USER_ID)
    }
  }
  const updateUserId = () => {
    const userId = getCookie(COOKIES.LAST_USER_ID) || ''
    if (!!userId && userId !== values.userId && values.saved) {
      handleOnChangeSaveUserId({ target: { checked: true } })
    }
  }

  const handleSetCurrentView = (e = {}) => {
    setCurrentState(Number(e.target?.dataset.view))
  }

  const handleSubmit = async (event) => {
    setSignInErrorMessage(null)
    const { isFormValid } = handleSubmitLoginForm(event)
    // If there are no errors, do the async stuff.
    if (!isFormValid) {
      return
    }
    setIsLoading(true)
    const { userId, password } = values
    const response = await userLogin(userId, password, language)
    const { isSuccess, redirectUrl, message } = response
    if (isSuccess) {
      updateUserId()
      clearSessionDependentData()
      removeSessionStorage(CLIENT_ID)
      handleSignInSuccess({
        redirectUrl,
        pageName,
        router,
      })
    } else {
      setIsLoading(false)
      const errorMessage = message || messages[language].failedMessage
      setSignInErrorMessage(errorMessage)
    }
  }
  const mainBlockStyle = classNames(styles.mainBlock, {
    [styles.borderBottom]: isShowBorderBottom,
  })

  const translations = messages[language]
  const childrenWithDependencies = provideDependenciesToChildren(children, {
    translations,
    isLoading,
    values,
    handleOnChange,
    errors,
    dirties,
    userIdRef,
    handleOnChangeSaveUserId,
    isFullSizeButton,
  })

  return (
    <div className={mainBlockStyle}>
      {signInErrorMessage ? (
        <SignInMessage text={signInErrorMessage} type="error" isHTML={true} />
      ) : null}
      <form onSubmit={handleSubmit} id={id} noValidate>
        {childrenWithDependencies}
      </form>

      <div className={styles.forgotContent}>
        <div className={styles.forgotContentWrapper}>
          <Text text={messages[language].forgotLabel} />
          <Button
            id={`${location}-${SHOW_USER_ID_REMINDER}`}
            variant="link"
            text={messages[language].userIdLabel}
            data-test="showUserReminderLink"
            data-view={SHOW_USER_ID_REMINDER}
            onClick={handleSetCurrentView}
          />
          <Text text={messages[language].orLabel} />
          <Button
            id={`${location}-${SHOW_PASSWORD_REMINDER}`}
            variant="link"
            text={`${messages[language].passwordLabel} ?`}
            data-test="forgotPasswordLink"
            data-view={SHOW_PASSWORD_REMINDER}
            onClick={handleSetCurrentView}
          />
        </div>
      </div>
    </div>
  )
}

// Props Validations
UserLogin.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentState: PropTypes.func.isRequired,
  pageName: PropTypes.string,
  location: PropTypes.string,
  id: PropTypes.string,
  isShowBorderBottom: PropTypes.bool,
  isFullSizeButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
}

UserLogin.defaultProps = {
  isShowBorderBottom: true,
  isFullSizeButton: true,
  isLocatedOnTopHeaderPosition: true,
}
export default UserLogin

export function UserLoginUserIdField({
  translations = {},
  isLoading = false,
  values = {},
  handleOnChange = function () {},
  errors = {},
  dirties = {},
  id,
  dataTest,
  userIdRef = {},
}) {
  return (
    <div className={styles.padding}>
      <FormField
        fieldLabel={translations.userIdLabel}
        labelSize="labelXs"
        name={USER_ID}
        id={id}
        disabled={isLoading}
        defaultValue={values.userId}
        onChange={handleOnChange}
        showError={!!(errors.userId && dirties.userId)}
        errorMessage={errors.userId}
        data-test={dataTest}
        textRef={userIdRef}
        inputAriaLabel={translations.userIdLabel}
        aria-required="true"
      />
    </div>
  )
}
UserLoginUserIdField.propTypes = {
  translations: PropTypes.object,
  isLoading: PropTypes.bool,
  values: PropTypes.object,
  handleOnChange: PropTypes.func,
  errors: PropTypes.object,
  dirties: PropTypes.object,
  id: PropTypes.string,
  dataTest: PropTypes.string,
  userIdRef: PropTypes.object,
}
export function UserLoginPasswordField({
  translations = {},
  isLoading = false,
  handleOnChange = function () {},
  errors = {},
  dirties = {},
  id,
  dataTest,
}) {
  return (
    <div className={styles.padding}>
      <FormField
        fieldLabel={translations.passwordLabel}
        labelSize="labelXs"
        name={PASSWORD}
        type="password"
        id={id}
        disabled={isLoading}
        showError={!!(errors.password && dirties.password)}
        errorMessage={errors.password}
        onChange={handleOnChange}
        data-test={dataTest}
        inputAriaLabel={translations.passwordLabel}
        aria-required="true"
      />
    </div>
  )
}
UserLoginPasswordField.propTypes = {
  translations: PropTypes.object,
  isLoading: PropTypes.bool,
  handleOnChange: PropTypes.func,
  errors: PropTypes.object,
  dirties: PropTypes.object,
  id: PropTypes.string,
  dataTest: PropTypes.string,
}

export function UserLoginSaveUserIdField({
  translations = {},
  values = {},
  handleOnChangeSaveUserId = function () {},
  errors = {},
  dirties = {},
  id,
  dataTest,
}) {
  return (
    <div className={styles.padding}>
      <FormField
        label={translations.saveUserIdAndPasswordLabel}
        type="checkbox"
        name="saved"
        disabled={!values.userId}
        onChange={handleOnChangeSaveUserId}
        id={id}
        showError={!!(errors.saved && dirties.saved)}
        errorMessage={errors.saved}
        checked={values.saved || false}
        data-test={dataTest}
        aria-label={translations.saveUserIdAndPasswordLabel}
      />
    </div>
  )
}
UserLoginSaveUserIdField.propTypes = {
  translations: PropTypes.object,
  values: PropTypes.object,
  handleOnChangeSaveUserId: PropTypes.func,
  errors: PropTypes.object,
  dirties: PropTypes.object,
  id: PropTypes.string,
  dataTest: PropTypes.string,
}

export function UserLoginSignInButton({
  translations = {},
  isFullSizeButton = false,
  isLoading = false,
  dataTest,
}) {
  return (
    <div>
      <Button
        text={translations.signInLabel}
        variant="primary"
        data-test={dataTest}
        isLoading={isLoading}
        isFullWidth={isFullSizeButton}
      />
    </div>
  )
}
UserLoginSignInButton.propTypes = {
  translations: PropTypes.object,
  isFullSizeButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
}

function provideDependenciesToChildren(children, dependencies) {
  const newChildren = Children.map(children, (e) => {
    return cloneElement(e, { ...e.props, ...dependencies })
  })
  return newChildren
}
