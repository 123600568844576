export default {
  en: {
    requiredUserIdMsg: 'Please enter a valid ID',
    failedMessage: 'Something went wrong please try again later.',
    requiredPassMsg: 'Please enter a valid password',
    signInLabel: 'Sign in',
    userIdLabel: 'User ID',
    passwordLabel: 'Password',
    saveUserIdAndPasswordLabel: 'Save User ID',
    forgotLabel: 'Forgot',
    orLabel: 'or',
    errorMsgForInactiveUser:
      'The User ID you are attempting to access is inactive. Please contact Customer Care at 1-877-272-2121.',
    maximumBadAttemptsMsg: `You have reached your maximum allowable login attempts. Your password has been automatically reset and has been sent to the email address associated with this User ID. Please use the information provided in the email to log into eway.ca.`,
    invalidUserMsg: `The User ID or Password that you entered could not be validated. Please confirm your login information and re-enter.`,
  },
  fr: {
    requiredUserIdMsg: "Veuillez entrer un nom d'utilisateur valide",
    requiredPassMsg: 'Veuillez entrer un mot de passe valide',
    failedMessage: `Quelque chose c'est mal passé. Merci d'essayer plus tard.`,
    signInLabel: 'Ouvrir une session',
    userIdLabel: "Nom d'utilisateur",
    passwordLabel: 'Mot de passe',
    saveUserIdAndPasswordLabel: "Sauvegarder le nom d'utilisateur",
    forgotLabel: 'Oublié votre',
    orLabel: 'ou',
    errorMsgForInactiveUser: `L'ID utilisateur auquel vous essayez d'accéder est inactif. Veuillez contacter le service à la clientèle au 1-877-272-2121.`,
    maximumBadAttemptsMsg: `Vous avez atteint le maximum de tentatives de connexions permis. Votre mot de passe a été automatiquement réinitialisé et envoyé à l'adresse de courriel associée avec ce nom d'utilisateur. Veuillez utilisez l'information incluses dans ce courriel pour vous connecter à eway.ca.`,
    invalidUserMsg: `L'ID utilisateur ou le mot de passe que vous avez entré n'a pas pu être validé. S'il vous plaît confirmer vos informations de connexion et entrez à nouveau.`,
  },
}
