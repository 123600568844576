import { checkArgsEmpty, sendRequest } from 'api/apiHelper'
import { getHostUrl, getOldApiUrl } from 'services/utils/configUtils'
import { isDev } from '~/config/env/environmentUtils'
import { ENGLISH } from '~/redux/constants'
import { fakeService } from './fakeService'
const OLD_API_URL = getOldApiUrl()
const HOST_URL = getHostUrl()
const debugPredictiveSearch = false

const defaultResults = []
/**
 * Gets the autocomplete word from the old API url
 * @param {*} keyword the word to search for
 * @param {*} language the language to expect
 */

const getAutoCompleteWords = async (args) => {
  const [keyword, language = ENGLISH] = args
  const url = `${OLD_API_URL}/Api/KeywordPredictiveSearch`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
    body: JSON.stringify({ keyword }),
  }

  const serviceToCall = debugPredictiveSearch ? fakeService : sendRequest
  const { predictionResults = defaultResults } = await serviceToCall(
    url,
    options
  ).catch((e) => handleException(e, url, options))

  return predictionResults
}

/**
 * Fake promise that returns a false result to mock behavior
 */

const predictiveSearchService = checkArgsEmpty(getAutoCompleteWords)

export default predictiveSearchService

/**
 * Code that handles the expcetion
 * @param {*} param0
 */
function handleException({ hasError }, url, options) {
  if (hasError && isDev()) {
    // eslint-disable-next-line no-console
    console.error(
      `Could not get predictive search on get predictive search on ${HOST_URL} with ${url} and ${JSON.stringify(
        options
      )}`
    )
  } else {
    // log it to the log provider if it is there
  }
  return defaultResults
}
