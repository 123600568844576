import { getHostUrl } from 'services/utils/configUtils'
import { getUrl } from 'shared/EwayRouter'
import { HELP_CENTRE } from 'shared/EwayRouter/EwayRouterConstants'
import messages from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/HelpMenu/messages'
const HOST_URL = getHostUrl()
export const getHelpMenus = (
  language,
  isGroupOrder = false,
  showMenuHelpCenter = true
) => {
  const {
    helpContactUsUrl,
    helpContactUsLabel,
    helpItemLabel,
    helpRecallInformationLabel,
    helpRecallInformationUrl,
  } = messages[language]
  const helpCentreUrl = getUrl(HELP_CENTRE)
  const contactUsUrl = `${HOST_URL}${helpContactUsUrl}`
  const helpRecallInformationPageUrl = `${HOST_URL}${helpRecallInformationUrl}`
  return [
    {
      id: 'helpContactUsLabel',
      linkText: helpContactUsLabel,
      link: contactUsUrl,
      dataTest: 'contact-us-url',
    },
    showMenuHelpCenter && {
      id: 'helpItemLabel',
      linkText: helpItemLabel,
      link: helpCentreUrl,
      dataTest: 'help-center-url',
    },
    !isGroupOrder && {
      id: 'helpRecallInformationLabel',
      linkText: helpRecallInformationLabel,
      link: helpRecallInformationPageUrl,
      dataTest: 'recall-information-url',
    },
  ].filter(Boolean)
}
