import classNames from 'classnames'
import PropTypes from 'prop-types'
import ShipToComponentStar from '../../ShipToComponentStar'
import styles from './ShipToInput.module.scss'

function ShipToInput({
  disabled,
  label,
  onFilterAccounts,
  inputFilter,
  isFavorite,
  shipToInputRef,
  block,
  size,
  comboAriaOwnId,
  comboAriaLabelId,
  onFocus,
}) {
  const classes = classNames(styles.inputFilterContainer, {
    [styles.isFavorite]: isFavorite,
  })
  const tabIndex = disabled ? -1 : 0
  const inputStyles = classNames(styles.filteringInput, {
    [styles.fullWidth]: block,
    [styles.large]: size,
  })
  return (
    <div className={classes}>
      {isFavorite && (
        <ShipToComponentStar
          focusable="false"
          className={styles.inputFilterStarContainer}
        />
      )}
      <input
        ref={shipToInputRef}
        data-test="current-selected-ship-to"
        placeholder={label}
        disabled={disabled}
        value={inputFilter}
        type="text"
        onFocus={onFocus}
        className={inputStyles}
        onChange={disabled ? null : onFilterAccounts}
        tabIndex={tabIndex}
        aria-autocomplete="both"
        aria-labelledby={comboAriaLabelId}
        aria-controls={comboAriaOwnId}
      />
    </div>
  )
}

export default ShipToInput

// Default Props
ShipToInput.defaultProps = {
  fontSize: null,
  onFocus: () => {},
}

// Props Validation
ShipToInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onFilterAccounts: PropTypes.func,
  inputFilter: PropTypes.string,
  isFavorite: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  shipToInputRef: PropTypes.object,
  comboAriaOwnId: PropTypes.string,
  comboAriaLabelId: PropTypes.string,
  onFocus: PropTypes.func,
}
