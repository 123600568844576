import { getUrl } from '../../EwayRouter'
import { getHostUrl } from 'services/utils/configUtils'
import { HELP_CENTRE } from 'shared/EwayRouter/EwayRouterConstants'
import messages from './messages'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { dynamicTranslation } from '../../DynamicTranslation/DynamicTranslationUtils'
const HOST_URL = getHostUrl()
/**
 * Returns and build the actual Footer Menu
 * @param {string} language gets the menu is proper language
 * @param {boolean} isLoggedIn checks if user is logged in
 * @param {boolean} isReturnItemEnabled that's a settings values that shows or hides the return item
 */
export const getListItems = (language, isLoggedIn, isReturnItemEnabled) => {
  const {
    contactUsUrl,
    contactUsLabel,
    helpCenterLabel,
    safetyDataSheetsLabel,
    recallInformationLabel,
    recallInformationUrl,
  } = messages[language]
  const helpCentreUrl = getUrl(HELP_CENTRE)
  const contactUsPageUrl = `${HOST_URL}${contactUsUrl}`
  const safetyDataPageUrl = dynamicTranslation(
    messages[language].safetyDataSheetsUrl,
    {
      '{lang}': language,
    }
  )
  const helpRecallInformationPageUrl = `${HOST_URL}${recallInformationUrl}`
  const customerServices = [
    {
      target: '_self',
      link: helpCentreUrl,
      linkText: helpCenterLabel,
      dataTest: 'help-center-url',
    },
    {
      target: '_self',
      link: contactUsPageUrl,
      linkText: contactUsLabel,
      dataTest: 'contact-us-url',
    },
    {
      target: '_self',
      link: helpRecallInformationPageUrl,
      linkText: recallInformationLabel,
      dataTest: 'recall-information-url',
    },
    {
      target: '_self',
      link: safetyDataPageUrl,
      linkText: safetyDataSheetsLabel,
      dataTest: 'safety-data-sheets-url',
    },
  ]

  const newCustomerItems = [
    {
      target: '_blank',
      link: messages[language].becomeACustomerUrl,
      linkText: messages[language].becomeACustomerLabel,
      dataTest: 'become-a-customer-url',
    },
    {
      target: '_blank',
      link: messages[language].govtCustomersUrl,
      linkText: messages[language].govtCustomersLabel,
      dataTest: 'govt-customers-url',
    },
    {
      target: '_blank',
      link: messages[language].membershipsUrl,
      linkText: messages[language].membershipsLabel,
      dataTest: 'memberships-url',
    },
  ]
  const joinAndNewCustomerSection = [
    {
      title: messages[language].joinStaplesTeamLabel,
      items: [
        {
          target: '_blank',
          link: messages[language].careersUrl,
          linkText: messages[language].careersLabel,
          dataTest: 'careers-url',
        },
      ],
    },
  ]
  if (isLoggedIn) {
    if (isReturnItemEnabled) {
      customerServices.splice(2, 0, {
        target: '_self',
        link: messages[language].returnItemUrl,
        linkText: messages[language].returnItemLabel,
        dataTest: 'return-item-url',
      })
    }
  } else {
    joinAndNewCustomerSection.unshift({
      title: messages[language].newCustomerLabel,
      items: newCustomerItems,
    })
  }
  return [
    {
      key: 1,
      items: [
        {
          title: messages[language].customerServiceLabel,
          items: customerServices,
        },
      ],
    },
    {
      key: 2,
      items: [
        {
          title: messages[language].corporateInfoLabel,
          items: [
            {
              target: '_blank',
              link: messages[language].staplesBusinessUrl,
              linkText: messages[language].staplesBusinessLabel,
              dataTest: 'staples-business-url',
            },
            {
              target: '_blank',
              link: messages[language].industriesServedUrl,
              linkText: messages[language].industriesServedLabel,
              dataTest: 'industries-served-url',
            },
            {
              target: '_blank',
              link: messages[language].blogUrl,
              linkText: messages[language].blogLabel,
              dataTest: 'blog-url',
            },
            {
              target: '_blank',
              link: messages[language].ourBrandsUrl,
              linkText: messages[language].ourBrandsLabel,
              dataTest: 'our-brands-url',
            },
          ],
        },
      ],
    },
    {
      key: 3,
      items: [
        {
          title: messages[language].staplesCorporateLabel,
          items: [
            {
              target: '_blank',
              link: messages[language].officeProductsUrl,
              linkText: messages[language].officeProductsLabel,
              dataTest: 'office-products-url',
            },
            {
              target: '_blank',
              link: messages[language].technologyProductsUrl,
              linkText: messages[language].technologyProductsLabel,
              dataTest: 'technology-products-url',
            },
            {
              target: '_blank',
              link: messages[language].furnitureUrl,
              linkText: messages[language].furnitureLabel,
              dataTest: 'furniture-url',
            },
            {
              target: '_blank',
              link: messages[language].facilitiesUrl,
              linkText: messages[language].facilitiesLabel,
              dataTest: 'facilities-url',
            },
            {
              target: '_blank',
              link: messages[language].promotionalProductsUrl,
              linkText: messages[language].promotionalProductsLabel,
              dataTest: 'promotional-products-url',
            },
          ],
        },
      ],
    },
    {
      key: 4,
      items: joinAndNewCustomerSection,
    },
  ]
}

export const getPageTarget = (pageName) => {
  return PAGE_NAMES.CHECKOUT === pageName || PAGE_NAMES.RETURNS === pageName
}
