import classNames from 'classnames'
import PropTypes from 'prop-types'
import Box from '~/components/shared/Box'
import styles from './NavBarMegaMenu.module.scss'
import { NavBarMegaMenuContextProvider } from './NavBarMegaMenuContext'
import { useNavBarContext } from './NavBarMegaMenuUtils'

/**
 * Name:NavBarSubCategory
 * Desc: Renders primary and secondary sub menu
 * @param {array} data
 * @param {function} changeLeftMenu
 * @param {object} navBarSubCategoryStyles
 */
/**
 * Shows a mega menu
 * @param {*} props
 */
export function NavBarMegaMenu(props) {
  const {
    isOpen,
    onClickOutside,
    getSecondaryMenusFocusableElements,
    close,
    buttonRef,
    children,
    ...rest
  } = props
  return (
    <NavBarMegaMenuContextProvider
      isOpen={isOpen}
      close={close}
      buttonRef={buttonRef}
      onClickOutside={onClickOutside}
      getSecondaryMenusFocusableElements={getSecondaryMenusFocusableElements}
    >
      <NavBarMegaMenuMain {...rest}>{children}</NavBarMegaMenuMain>
    </NavBarMegaMenuContextProvider>
  )
}

NavBarMegaMenu.propTypes = {
  children: PropTypes.node,
  buttonRef: PropTypes.object,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  onClickOutside: PropTypes.func,
  getSecondaryMenusFocusableElements: PropTypes.func,
}

export default NavBarMegaMenu

function NavBarMegaMenuMain({ children, relativeCenter, ...rest }) {
  const { navBarRef, isOpen } = useNavBarContext()
  const classes = classNames(styles.mainDefaults, {
    [styles.centerAndSetSmall]: relativeCenter,
    [styles.visible]: isOpen,
  })
  return (
    <Box {...rest} className={classes} ref={navBarRef}>
      {children}
    </Box>
  )
}

NavBarMegaMenuMain.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  relativeCenter: PropTypes.bool,
}
