import classNames from 'classnames'
import { Children, cloneElement } from 'react'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import Box from 'shared/Box'
import styles from './MountActiveElement.module.scss'
/**
 * Component that behaves like a Tab.
 * @deprecated - Please use `<Stepper /> Component` instead.
 * @returns
 */
export default function MountActiveElement({
  children,
  activeStepId,
  className,
  ...rest
}) {
  const elements = filterEmptyChildrenNode(children)
  return Children.map(elements, (child) => {
    if (child === null) {
      return null
    }
    const isActiveStep = child.props.id === activeStepId
    const showHideClass = classNames(styles.hide, {
      [styles.show]: isActiveStep,
      className,
    })
    const isElementComponent = isFunction(child.type) || isObject(child.type)
    const mergedProps = {
      ...child.props,
      ...(isElementComponent && { isActiveStep }),
    }

    return (
      <Box {...rest} className={showHideClass}>
        {cloneElement(child, { ...mergedProps })}
      </Box>
    )
  })
}

function filterEmptyChildrenNode(children) {
  return Children.toArray(children).filter(Boolean)
}
