import PropTypes from 'prop-types'
import { isGroupOrderFromBuyer } from 'services/utils'
import Account from './Account'
import CartPanel from './CartPanel'
import HelpMenu from './HelpMenu'
import SignIn from './SignIn'
import styles from './SupportMenu.module.scss'
import { ACCOUNT, CART, HELP_MENU, SIGN_IN } from './SupportMenuConstants'
import { SupportMenuProvider } from './SupportMenuUtils'

/**
 * Name: SupportMenu
 * Desc: Render support menus like account , help, cart
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {string} firstName
 * @param {string} costCenter
 * @param {array} shipToLocation
 * @param {boolean} showYourPriceFlag
 * @param {object} order
 * @param {string} hostUrl
 * @param {object} buyer
 * @param {object} settings
 * @param {object} details
 * @param {string} pageName
 */

const SupportMenu = ({
  language,
  isLoggedIn,
  firstName,
  costCenter,
  shipToLocation,
  showYourPriceFlag,
  order,
  hostUrl,
  buyer,
  settings,
  details,
  sessionId,
  pageName,
}) => {
  const groupOrder = isGroupOrderFromBuyer(buyer)
  const menuItems = [
    isLoggedIn
      ? {
          menuName: ACCOUNT,
          component: Account,
        }
      : {
          menuName: SIGN_IN,
          component: SignIn,
        },
    !groupOrder && {
      menuName: HELP_MENU,
      component: HelpMenu,
    },
    {
      menuName: CART,
      component: CartPanel,
    },
  ].filter(Boolean)

  return (
    <SupportMenuProvider>
      <ul className={styles.supportMenu}>
        {menuItems.map((data) => {
          const { menuName } = data
          return (
            <data.component
              key={menuName}
              language={language}
              isLoggedIn={isLoggedIn}
              firstName={firstName}
              costCenter={costCenter}
              shipToLocation={shipToLocation}
              showYourPriceFlag={showYourPriceFlag}
              order={order}
              hostUrl={hostUrl}
              buyer={buyer}
              sessionId={sessionId}
              settings={settings}
              details={details}
              pageName={pageName}
            />
          )
        })}
      </ul>
    </SupportMenuProvider>
  )
}

// Props Validation
SupportMenu.propTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  shipToLocation: PropTypes.array,
  firstName: PropTypes.string,
  costCenter: PropTypes.string,
  showYourPriceFlag: PropTypes.bool,
  order: PropTypes.object,
  hostUrl: PropTypes.string,
  buyer: PropTypes.object,
  settings: PropTypes.object,
  details: PropTypes.object,
  sessionId: PropTypes.string,
  pageName: PropTypes.string,
}

export default SupportMenu
