export default {
  en: {
    shoppingListDetails: 'Shopping List Details',
    searchResultsFor: 'Search Results for',
    inkAndToner: 'Ink & Toner',
  },
  fr: {
    shoppingListDetails: "Détails de la liste d'achat",
    searchResultsFor: 'Résultats de recherche pour',
    inkAndToner: 'Encre et toner',
  },
}
