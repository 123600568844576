import { buildOrder } from 'services/orderService'
import { getCheckoutAvailabilityStatus } from '~/services/checkoutAvailabilityService'

export const getOrderList = async (
  {
    buyerId,
    sessionId,
    fiftyGreen,
    enableRecommendationProduct,
    minimumOrderAmount,
  },
  setOrderInfo,
  setCheckoutDisable
) => {
  const order = await buildOrder({ buyerId, sessionId })
  if (order.orderId && Object.keys(order).length) {
    setOrderInfo(order)
    const {
      orderProducts: productList = [],
      total: cartTotal = 0,
      approvalActionType,
    } = order
    const isCheckoutDisable = getCheckoutAvailabilityStatus(
      productList,
      fiftyGreen,
      enableRecommendationProduct,
      cartTotal,
      approvalActionType,
      minimumOrderAmount
    )

    setCheckoutDisable(isCheckoutDisable)
  }
}
