import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { useSettingsContext } from '~/context/SettingsContext'
import { useManageAddressesContext } from '~/context/ManageAddressesContext'
import { ENGLISH } from '~/redux/constants'
import styles from './Logout.module.scss'
import { CLOSE_BROWSER } from './LogoutConstants'
import {
  clearSessionDependentData,
  doLogout,
  getLogoutUrl,
  closeAction,
} from './LogoutUtils'
import messages from './messages'
import { noop } from '~/components/shared/Utils/userUtils'

/**
 * Name: Logout
 * Desc: render logout button
 * @param {string} language
 * @param {number} logoffAction
 * @param {string} logoffUrl
 */

const Logout = ({ language = ENGLISH, logoffAction, logoffUrl }) => {
  const settings = useSettingsContext()
  const { setSelectedAddress = noop } = useManageAddressesContext()
  const { isPunchOutUser } = settings
  const logoutURL = getLogoutUrl(logoffAction, logoffUrl, language)
  const logoutHandlerCallBack = async () => {
    await setSelectedAddress('')
    if (isPunchOutUser) {
      // Let .net backend clear session do proper redirect
      clearSessionDependentData()
    } else {
      // Removing cookies on browser should logout user
      doLogout()
    }

    if (logoffAction === CLOSE_BROWSER) {
      closeAction()
      return
    }
    window.location.assign(logoutURL)
  }

  const { logout, closeSession } = messages[language]
  const logoutLabel = isPunchOutUser ? closeSession : logout

  return (
    <div className={styles.rightMenu}>
      <Button
        variant="link"
        text={logoutLabel}
        className={styles.logoutLink}
        onClick={logoutHandlerCallBack}
        data-test="Logout"
      />
    </div>
  )
}

// PropTypes validation
Logout.propTypes = {
  language: PropTypes.string,
  logoffAction: PropTypes.number,
  logoffUrl: PropTypes.string,
}

export default Logout
