export default {
  en: {
    clientNo: 'Acct. Number',
    costCenter: 'Cost Center',
    shippingName: 'Customer name',
    shippingContact: 'Care Of',
    address1: 'Address 1',
    address2: 'Address 2',
    address3: 'City',
    shippingPostalCode: 'Postal Code',
    lookInAllFields: 'Look in all fields',
    favoriteShipTo: 'Favourite Ship To',
    favoritesOnly: 'Favourites Only',
    contains: 'Contains',
    beginsWith: 'Begins With',
    ascending: 'Ascending',
    descending: 'Descending',
    sortBy: 'Sort by',
    searchInputPlaceholder: 'Enter keyword',
    showingRecordText: 'Showing ({{TOTAL_RECORD}}) accounts',
    search: 'Search',
  },
  fr: {
    clientNo: 'No de compte',
    costCenter: 'Centre de Coût',
    shippingName: 'Nom du client',
    shippingContact: 'Au soins de',
    address1: 'Adresse 1',
    address2: 'Adresse 2',
    address3: 'Ville',
    shippingPostalCode: 'Code postal',
    lookInAllFields: 'Regarder dans tous les champs',
    favoriteShipTo: 'Compte favori',
    favoritesOnly: 'Favoris seulement',
    contains: 'Contient',
    beginsWith: 'Commence par',
    ascending: 'Croissant',
    descending: 'Décroissant',
    sortBy: '	Trier par',
    searchInputPlaceholder: 'Mot-clé',
    showingRecordText: '({{TOTAL_RECORD}}) comptes affichés',
    search: 'Chercher',
  },
}
