export default {
  en: {
    productsLabel: 'Products',
    servicesSolutionLabel: 'Services & Solutions',
    close: 'Close',
    moreLink: 'More',
    lessLink: 'Less',
  },
  fr: {
    productsLabel: 'PRODUITS',
    servicesSolutionLabel: 'SERVICES ET SOLUTIONS',
    close: 'Fermer',
    moreLink: 'More',
    lessLink: 'Less',
  },
}
