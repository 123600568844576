import Star from 'shared/Star'
import PropTypes from 'prop-types'

const ShipToComponentStar = ({ focusable, className, ...props }) => {
  return (
    <div className={className} {...props}>
      <Star focusable={focusable} />
    </div>
  )
}

export default ShipToComponentStar

// Props Validation
ShipToComponentStar.propTypes = {
  className: PropTypes.string,
  props: PropTypes.object,
  focusable: PropTypes.string,
}
