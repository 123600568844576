import { useEffect } from 'react'

const defaultAttributeFilter = ['class']

export const useMutationObservation = function ({
  observingElement,
  observerHandler,
  attributeFilter = defaultAttributeFilter,
}) {
  useEffect(() => {
    let observer = null
    if (observingElement) {
      observer = new MutationObserver(observerHandler)
      observer.observe(observingElement, {
        attributes: true,
        attributeFilter,
        subtree: true,
        characterData: false,
      })
    }

    return () => {
      observer && observer?.disconnect()
    }
  }, [attributeFilter, observerHandler, observingElement])
}
