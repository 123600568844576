import PropTypes from 'prop-types'

import classNames from 'classnames'
import Button from 'shared/Button'
import { noop } from '../Utils/userUtils'
import styles from './SearchBox.module.scss'
import Input from '~/components/shared/FormControl/Input'

/**
 * Name: SearchBox
 * Decs: Render SearchBox component
 * @param {string} type
 * @param {string} name
 * @param {string} id
 * @param {string} icon
 * @param {string} placeholder
 * @param {string} iconDataTest
 * @param {string} buttonAriaLabel
 * @returns Element
 */
const SearchBox = ({
  type = 'text',
  icon = 'search',
  name,
  id,
  placeholder,
  onIconClick,
  btnProps,
  ...rest
}) => {
  const searchInputWrapperClasses = classNames(
    `${styles.inputWrapper} `,
    `${styles.searchWrapper} `,
    icon ? styles.inputFlex : styles.textInputWrapper
  )
  return (
    <div className={searchInputWrapperClasses}>
      <Input
        placeholder={placeholder || ''}
        type={type}
        name={name}
        id={id}
        {...rest}
      />
      {icon ? (
        <Button
          role="button"
          icon={icon}
          onClick={onIconClick}
          variant="link"
          data-test="search-button"
          aria-label="search-button" // TODO: needs to be fixed
          className={styles.iconButton}
          {...btnProps}
        />
      ) : null}
    </div>
  )
}

// Default Props
SearchBox.defaultProps = {
  onIconClick: noop,
}

// Props Validation
SearchBox.propTypes = {
  type: PropTypes.string,
  onIconClick: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  iconDataTest: PropTypes.string,
  btnProps: PropTypes.object,
}

export default SearchBox
