import PropTypes from 'prop-types'
import CheckoutButton from 'shared/CheckoutButton'
import EwayLink from '~/components/shared/EwayLink'
import styles from './CartFooter.module.scss'
import messages from './messages'

/**
 * Name: CartFooter
 * Desc: Render cart footer
 * @param {string} language
 * @param {number} productCount
 * @param {string} viewCartUrl
 * @param {string} pageName
 * @param {object} order
 */
const CartFooter = ({
  language,
  productCount,
  viewCartUrl,
  pageName,
  isCheckoutDisable,
  order,
  buyer = {},
  isCustomerOnboardingAccount,
}) => {
  const showCustomerOnboardingWarning =
    buyer.isWaitingForCoactivation && isCustomerOnboardingAccount
  return (
    <div className={styles.cartButton}>
      <div className={styles.checkOutButton} data-test="CheckoutBtnFooter">
        {productCount ? (
          <CheckoutButton
            language={language}
            isCheckoutDisable={isCheckoutDisable}
            showCustomerOnboardingWarning={showCustomerOnboardingWarning}
            pageName={pageName}
            order={order}
            buyer={buyer}
          />
        ) : null}
      </div>
      <div className={styles.viewButton}>
        <EwayLink
          href={viewCartUrl}
          text={messages[language].viewCartLabel}
          dataTest="ViewCartBtnFooter"
        />
      </div>
    </div>
  )
}

// Props Validation
CartFooter.propTypes = {
  language: PropTypes.string.isRequired,
  productCount: PropTypes.number,
  viewCartUrl: PropTypes.string,
  pageName: PropTypes.string,
  isCheckoutDisable: PropTypes.bool,
  order: PropTypes.object,
  buyer: PropTypes.object,
  isCustomerOnboardingAccount: PropTypes.bool,
}

export default CartFooter
