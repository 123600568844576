import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { getTextWithDynamicValue } from 'services/utils/languageUtils.js'
import ButtonPanel from 'shared/ButtonPanel'
import ContextMenu from 'shared/ContextMenu'
import { InputGroup } from 'shared/InputGroup'
import SearchBox from 'shared/SearchBox'
import Text from 'shared/Text'
import { CHECKBOX } from 'shared/Utils/constants'
import styles from './FindAccountTabFilter.module.scss'
import {
  FAVORITE_ONLY_FIELD,
  SEARCH_DEBOUNCE_WAIT,
  SEARCH_KEY_FIELD,
  SEARCH_ON_FIELD,
  SEARCH_TYPE_FIELD,
  SORT_BY_FIELD,
  SORT_TYPE_FIELD,
} from './FindAccountTabFilterConstant'
import {
  getFilterInitialValues,
  getSearchOnFields,
  getSearchTypeButtons,
  getSortingMenus,
  getSortTypeButtons,
} from './FindAccountTabFilterUtils'
import messages from './messages'

/**
 *Name: FindAccountTabFilter
 *Desc: Render FindAccountTabFilter
 * @param {string} language
 * @param {function} setFilterMeta
 * @param {number} totalShowingRecords
 */
const FindAccountTabFilter = ({
  language,
  setFilterMeta,
  totalShowingRecords,
}) => {
  const filterValues = useRef(getFilterInitialValues()).current

  const handleInputChange = (event) => {
    const {
      target,
      target: { name, value, type },
    } = event
    const fieldValue = type === CHECKBOX ? target.checked : value
    if (name) {
      filterValues[name] = fieldValue
    }
  }

  const handleChangeFilter = (event) => {
    event && handleInputChange(event)
    setFilterMeta({ ...filterValues })
  }

  const showingRecordText = getTextWithDynamicValue(
    messages[language].showingRecordText,
    {
      '{{TOTAL_RECORD}}': totalShowingRecords,
    }
  )

  const { searchButtonTypes, searchOnFields, sortTypeButtons, sortingMenus } =
    useMemo(() => {
      return {
        searchButtonTypes: getSearchTypeButtons(language),
        searchOnFields: getSearchOnFields(language),
        sortTypeButtons: getSortTypeButtons(language),
        sortingMenus: getSortingMenus(language),
      }
    }, [language])

  const handleOnChange = debounce(function (event) {
    handleChangeFilter(event)
  }, SEARCH_DEBOUNCE_WAIT)

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleChangeFilter(e)
    }
  }
  const handleOnChangeHandler = (event) => {
    event.persist()
    handleOnChange(event)
  }
  const btnProps = {
    'aria-label': messages[language].search,
  }
  return (
    <>
      <div className={styles.filterWrapper}>
        <div className={styles.filterTop}>
          <div className={styles.search}>
            <SearchBox
              id="searchId"
              name={SEARCH_KEY_FIELD}
              aria-label={SEARCH_KEY_FIELD}
              data-test="searchKey"
              onChange={handleOnChangeHandler}
              onIconClick={handleChangeFilter}
              placeholder={messages[language].searchInputPlaceholder}
              onKeyDown={handleOnKeyDown}
              btnProps={btnProps}
            />
          </div>

          <ButtonPanel
            onSelect={handleChangeFilter}
            panelName={SEARCH_TYPE_FIELD}
            data-test="searchTypeButton"
            activeButton={filterValues[SEARCH_TYPE_FIELD]}
            buttonList={searchButtonTypes}
          />
        </div>

        <div className={styles.filterBottom}>
          <div className={styles.filterBottomLeft}>
            <div className={styles.filterSelect}>
              <InputGroup
                options={searchOnFields}
                id={SEARCH_ON_FIELD}
                aria-label={SEARCH_ON_FIELD}
                type="select"
                variant="lg"
                name={SEARCH_ON_FIELD}
                data-test="lookUpField"
                onChange={handleChangeFilter}
                selectedValue={filterValues[SEARCH_ON_FIELD]}
              />
            </div>
          </div>
          <div className={styles.contextMenuWrapper}>
            <ContextMenu
              text={messages[language].sortBy}
              icon="sort"
              variant="link"
              name={SORT_BY_FIELD}
              onChange={handleChangeFilter}
              contextMenuList={sortingMenus}
            />
          </div>
          <div className={styles.btnFilterWrapper}>
            <ButtonPanel
              onSelect={handleChangeFilter}
              panelName={SORT_TYPE_FIELD}
              data-test="lookUpTypeButton"
              activeButton={filterValues[SORT_TYPE_FIELD]}
              buttonList={sortTypeButtons}
            />
          </div>
        </div>
      </div>

      <div className={styles.tableFiltering}>
        <InputGroup
          label={messages[language].favoritesOnly}
          data-test-label="shipto-favorites-checkbox"
          type="checkbox"
          name={FAVORITE_ONLY_FIELD}
          id={FAVORITE_ONLY_FIELD}
          space="categoryList"
          data-test="favoriteField"
          onChange={handleChangeFilter}
          defaultChecked={filterValues[FAVORITE_ONLY_FIELD]}
        />
        <Text text={showingRecordText} variant="mdText" color="secondary" />
      </div>
    </>
  )
}

// Props Validation
FindAccountTabFilter.propTypes = {
  language: PropTypes.string,
  setFilterMeta: PropTypes.func,
  totalShowingRecords: PropTypes.number,
}

export default FindAccountTabFilter
