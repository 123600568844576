import { useEffect, useState } from 'react'
import { useCookies } from '~/context/CookiesContext'
import { getCookie, setCookie } from '~/services/cookieManager'

export function useWasShownOnce({ cookieName = '', setShownWhen } = {}) {
  const allCookies = useCookies()
  const serverSideCookieWasShown = allCookies?.[cookieName] === 'true'
  if (!cookieName) {
    throw new Error('cookieName is required, but got falsy value')
  }
  const [wasShownOnce, setWasShownOnce] = useState(serverSideCookieWasShown)
  useEffect(() => {
    const browserCookieWasShown = getCookie(cookieName) === 'true'
    const wasAlreadyShown = browserCookieWasShown || serverSideCookieWasShown
    const canSetCookie = !wasAlreadyShown && setShownWhen
    if (canSetCookie) {
      setCookie(cookieName, 'true', {
        sameSite: 'none',
        secure: true,
      })
      return
    }
    setWasShownOnce(wasAlreadyShown)
  }, [cookieName, setWasShownOnce, setShownWhen, serverSideCookieWasShown])
  return { wasShownOnce }
}
