import { COOKIES } from 'services/constants'
import { getCookie, setCookie } from 'services/cookieManager'
import { useLoader } from '~/components/shared/CustomHooks/useLoader'
import { SAVED_POSTAL_CODE } from '~/components/shared/Layout/Header/HeaderTop/HeaderTopConstants'
import { isProduction } from '~/config/env/environmentUtils'
import { useShipToContext } from '~/context/ShipToContext'
import {
  getClientFromPostalCode,
  updateSession,
} from './facultyCenterOperations'

export async function submitPostalCodeForm(props) {
  try {
    const { status, clientNo } = await getClientFromPostalCode(props)
    return {
      isSuccess: status === 200,
      result: {
        clientNo,
        // Default values added for the view layer ⬇️
        clientId: '',
        isFavorite: false,
      },
    }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e, result: {} }
  }
}

/**
 * /**
 * @param {Object} args Contains the values and the language
 * @param {Object} args.values Contains the values for the request
 * @param {string} args.values.postalCode The canadian province
 * @param {string} args.values.province The canadian 2 letter postal code
 */
export const updateUsersPostalCodeAndProvince = async ({
  postalCode,
  province,
}) => {
  const body = [
    { op: 'replace', path: 'PostalCode', value: postalCode },
    { op: 'replace', path: 'Province', value: province },
  ]
  return await updateSession(getCookie(COOKIES.SESSION_ID), body)
}

/**
 * Get the right faculty center based on the province, postal code and parentClientNumber has a hook
 * @param {number} parent
 *
 * @param arguments Information about the language and fa
 * @param userInfo.name The name of the user.
 * @param userInfo.email The email of the user.
 */
export function useFacultyCenter({ parentClientNo }) {
  const { selectShipToAccountAndReload } = useShipToContext()
  const { setPending, isLoading, isError, setIdle, setError } = useLoader()
  async function startGetFacultyCenter(postalCode, province, options) {
    setPending()
    const { result, isSuccess } = await submitPostalCodeForm({
      parentClientNo,
      postalCode,
      province,
    })

    if (isSuccess) {
      // code for reset session
      const data = await updateUsersPostalCodeAndProvince({
        postalCode,
        province,
      })
      const { reload = true, savePostalCodeAsCookie = true } = options
      //change ship to account code and page reload
      if (savePostalCodeAsCookie) {
        setCookie(SAVED_POSTAL_CODE, postalCode, {
          sameSite: 'none',
          secure: true,
        })
      }
      // reload as the last operation
      if (reload) {
        selectShipToAccountAndReload(result)
      }
      setIdle()
      return { ...result, data, isSuccess }
    }
    setError('error')
    return { ...result, isSuccess }
  }
  /**
   * Updates the session of the user using a canadian postal code and province
   * @param {string} postalCode - Takes a canadian postal code
   * @param {string} province - Take a canadian province
   */
  function updateSessionAccount({ postalCode, province = '', options = {} }) {
    WARNING_IF_NO_CLIENT_NO(parentClientNo)
    WARNING_IF_MISSING_PROPS(postalCode, province)
    return startGetFacultyCenter(postalCode, province, options)
  }

  return {
    updateSessionAndReload: updateSessionAccount,
    isLoading,
    isError,
  }
}

function WARNING_IF_NO_CLIENT_NO(parentClientNo) {
  if (!parentClientNo && !isProduction()) {
    throw new Error(
      'You tried changing postal code without a "parentClientNo", please add in hook initialization'
    )
  }
}

function WARNING_IF_MISSING_PROPS(postalCode) {
  if (!postalCode && !isProduction()) {
    throw new Error('postal code or province is missing')
  }
}
