import ContentLoader from 'react-content-loader'
import styles from './ShipToComponentSkeleton.module.scss'

const ShipToComponentSkeleton = () => {
  return (
    <div className={styles.shipToComponentSkeleton}>
      <ContentLoader
        width="205px"
        height="20px"
        title="...Loading"
        uniqueKey="ship-to-component-skeleton"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    </div>
  )
}
export default ShipToComponentSkeleton
