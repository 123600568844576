export default {
  en: {
    enterUserIdLabel: 'Enter User ID',
    submitLabel: 'Submit',
    noteLabel:
      'Enter your User ID above and click on the submit button to proceed. A temporary password will be created and sent to the email address associated with this User ID.',
    invalidUserId: `Your information cannot be found or your account does not allow an automated password reset to be performed. Please contact the Customer Care at:
    1-877-272-2121`,
    requiredUserIdMsg: 'Please enter a valid User ID',
    passwordIsSentMsg: 'Your temporary password has been emailed to ',
  },
  fr: {
    enterUserIdLabel: "Entrez le nom d'utilisateur",
    submitLabel: 'Soumettre',
    noteLabel:
      "Entrez votre nom d'utilisateur ci-dessus et cliquez sur le bouton Envoyer pour continuer. Un mot de passe temporaire sera créé et envoyé à l'adresse courriel associée à ce nom d'utilisateur.",
    invalidUserId: `
    Votre information est introuvable ou votre compte ne permet pas d'effectuer une réinitialisation automatique du mot de passe. Veuillez contacter le service à la clientèle au : 1-877-272-2121`,
    requiredUserIdMsg: `Veuillez entrer un nom d'utilisateur valide`,
    passwordIsSentMsg: 'Votre mot de passe temporaire à été envoyé à ',
  },
}
