import PropTypes from 'prop-types'
import Box from '../../Box'
import { splitBoxProps } from '../../Box/BoxUtils'
import SectionTitle from '../../SectionTitle'
import messages from './messages'
import SaveAddressForm from './SaveAddressForm'
import {
  ADDRESS_TYPE,
  ACTION,
} from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'

function SaveAddressFormWithTitle({
  variant = ACTION.EDIT,
  language,
  type = ADDRESS_TYPE.DELIVERY_ADDRESS,
  ...props
}) {
  const translations = messages[language]
  const { boxProps, rest } = splitBoxProps(props)
  const title = translations[`${variant}${type}Title`]

  return (
    <>
      <SectionTitle title={title} />
      <Box p="0 40px" {...boxProps}>
        <SaveAddressForm language={language} {...rest} />
      </Box>
    </>
  )
}

SaveAddressFormWithTitle.propTypes = {
  buyerId: PropTypes.string,
  clientNo: PropTypes.number,
  editingAddress: PropTypes.object,
  handleAfterSubmit: PropTypes.any,
  handleCancelButtonClick: PropTypes.any,
  hasNoAddresses: PropTypes.any,
  language: PropTypes.any,
  title: PropTypes.any,
  variant: PropTypes.string,
  type: PropTypes.string,
}

export default SaveAddressFormWithTitle
