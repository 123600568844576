import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './HamburgerMenuCategoryListToggle.module.scss'

function HamburgerMenuCategoryListToggle({ onToggle, text, isOpen }) {
  return (
    <Button
      className={styles.hamburgerMenuCategoryListToggle}
      icon="carrot"
      text={text}
      onClick={onToggle}
      aria-expanded={isOpen ? 'true' : 'false'}
    />
  )
}

HamburgerMenuCategoryListToggle.propTypes = {
  onToggle: PropTypes.func,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default HamburgerMenuCategoryListToggle
