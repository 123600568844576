import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import Box from 'shared/Box'
import TrapFocus from 'shared/TrapFocus'
import useOutsideClickHandler from '~/components/shared/CustomHooks/useOutsideClickHandler'
import { pressEscape } from '~/services/utils/keyboardUtils'
import { useSupportMenuContext } from '../../SupportMenuUtils'
import styles from './SupportMenuItemContainer.module.scss'

/**
 * Name: SupportMenuItemContainer
 * Decs: Render support menu items
 * @param {object} data
 * @param {function} onMouseEnter
 */

const SupportMenuItemContainer = ({
  children,
  menuName,
  enableMouseOut = false,
}) => {
  const { activeMenu, closeMenu } = useSupportMenuContext()
  const isOpen = menuName === activeMenu
  const supportMenuItemRef = useRef(null)
  const closeCurrentMenu = () => {
    isOpen && closeMenu()
  }
  useOutsideClickHandler(supportMenuItemRef, closeCurrentMenu)

  const activeClass = classNames({
    [styles.active]: isOpen,
  })

  const handleExitMenu = (event) => {
    pressEscape(event) && closeMenu()
  }

  const mouseProps = enableMouseOut
    ? {
        onMouseLeave: closeCurrentMenu,
      }
    : {}

  return (
    <Box
      as="li"
      ref={supportMenuItemRef}
      className={activeClass}
      onKeyUp={handleExitMenu}
      {...mouseProps}
    >
      <TrapFocus className={styles.supportMenuItem} isActive={isOpen}>
        {children}
      </TrapFocus>
    </Box>
  )
}

// Props Validation
SupportMenuItemContainer.propTypes = {
  children: PropTypes.node,
  menuName: PropTypes.string,
  enableMouseOut: PropTypes.bool,
}

export default SupportMenuItemContainer
