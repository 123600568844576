import PropTypes from 'prop-types'
import { useState } from 'react'
import Flex from 'shared/Flex'
import Container from '~/components/shared/Container'
import { ENGLISH } from '~/redux/constants'
import styles from './Footer.module.scss'
import FooterConnect from './FooterConnect/FooterConnect'
import FooterCopyright from './FooterCopyright/FooterCopyright'
import { FooterItemList } from './FooterItemList'
import { getListItems, getPageTarget } from './FooterUtils'

/**
 *Name: Footer
 *Desc: Render footer aggregator
 * @param {*} language
 * @param {*} isLoggedIn
 * @param {*} isReturnItemEnabled
 * @param {*} customCopyrightContent
 * @returns Element
 */
const Footer = ({
  language,
  isLoggedIn = false,
  isReturnItemEnabled,
  customCopyrightContent,
  enableSocialWidget,
  isMinimal,
  pageName,
}) => {
  const listItems = getListItems(language, isLoggedIn, isReturnItemEnabled)
  const [activeItem, setActiveItem] = useState(null)

  const activeItemHandler = (activeItem) => {
    setActiveItem(activeItem)
  }

  const pageTarget = getPageTarget(pageName)

  return (
    <footer className={styles.footer}>
      {!isMinimal && (
        <>
          <div className={styles.footerWrapper}>
            <Container>
              <Flex className={styles.columnXs}>
                {listItems.map(({ items, key }) => {
                  return (
                    <div key={key} className={styles.flexItem}>
                      {items.map((item) => {
                        const isActive = activeItem === item.title
                        return (
                          <FooterItemList
                            target={item.target}
                            isActive={isActive}
                            key={item.title}
                            data={item}
                            onClick={activeItemHandler}
                            isLoggedIn={isLoggedIn}
                          />
                        )
                      })}
                    </div>
                  )
                })}
              </Flex>
            </Container>
          </div>
          <FooterConnect
            language={language}
            enableSocialWidget={enableSocialWidget}
            isLoggedIn={isLoggedIn}
          />
        </>
      )}
      <FooterCopyright
        language={language}
        customCopyrightContent={customCopyrightContent}
        pageTarget={pageTarget}
      />
    </footer>
  )
}

// Default Props
Footer.defaultProps = {
  isMinimal: false,
  language: ENGLISH,
}

// Props Validation
Footer.propTypes = {
  language: PropTypes.string.isRequired,
  isMinimal: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  isReturnItemEnabled: PropTypes.bool,
  enableSocialWidget: PropTypes.bool,
  customCopyrightContent: PropTypes.string,
  pageName: PropTypes.string,
}

export default Footer
