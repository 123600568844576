import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFetchBuyer } from '~/api/buyer/useFetchBuyer'
import {
  SupportMenuItem,
  SupportMenuItemContainer,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SupportMenuItem'
import { ENGLISH } from '~/redux/constants'
import { ACCOUNT } from '../SupportMenuConstants'
import { useSupportMenuContext } from '../SupportMenuUtils'
import styles from './Account.module.scss'
import messages from './messages'
import UserDetails from './UserDetails'
/**
 *Name: Account
 *Desc: Render header account panel
 * @param {string} language
 * @param {func} onCloseMenu
 * @param {object} buyer
 * @param {object} order
 * @param {object} settings
 * @param {object} details
 * @param {func} onMouseEnter
 */

const Account = ({
  language = ENGLISH,
  buyer = {},
  order,
  settings,
  details,
}) => {
  const { isInGroupOrder, createdBy } = buyer
  const { triggerFetchBuyer, buyer: logonInfo } = useFetchBuyer()
  const { activeMenu } = useSupportMenuContext()

  useEffect(() => {
    if (isInGroupOrder && buyer.createdBy) {
      triggerFetchBuyer(createdBy, { useLogonName: true })
    }
  })

  return (
    <SupportMenuItemContainer menuName={ACCOUNT}>
      <SupportMenuItem
        menuName={ACCOUNT}
        label={messages[language].accountLabel}
        icon="accountRegular"
        color="black"
      />

      <div className={styles.accountList}>
        <div className={styles.account}>
          <UserDetails
            firstName={buyer?.firstName}
            costCenter={order?.costCenter}
            shipToNumber={details?.clientNo}
            language={language}
            createdBy={logonInfo?.fullName}
            groupOrderId={buyer.groupOrderNumber}
            isGroupOrder={isInGroupOrder}
            buyer={buyer}
            settings={settings}
            details={details}
            isMenuActive={activeMenu === ACCOUNT}
          />
        </div>
      </div>
    </SupportMenuItemContainer>
  )
}

// Props Validation
Account.propTypes = {
  language: PropTypes.string,
  buyer: PropTypes.object,
  order: PropTypes.object,
  settings: PropTypes.object,
  details: PropTypes.object,
}

export default Account
