import { showNotification } from '~/components/shared/Alerts'
import EwayRouter from '~/components/shared/EwayRouter'
import { REBATES_OFFERS_ROUTE } from '~/components/shared/EwayRouter/EwayRouterConstants'
import { ENGLISH } from '~/redux/constants'
import { getHostUrl } from 'services/utils/configUtils'
const HOST_URL = getHostUrl()
import messages from './messages'
export function getDealsItems(
  isLoggedIn,
  hasPromotions,
  details = {},
  settings = {},
  language = ENGLISH
) {
  const { canReceivePromotions: showDealsPromotions = true } = details
  const {
    showMenuCoupons: showDealsRebates = true,
    showClearanceMenu = false,
  } = settings

  const showPromosMenu = isLoggedIn && hasPromotions && showDealsPromotions

  return [
    showPromosMenu && {
      id: 'promotion',
      linkText: messages[language].promotions,
      cursor: 'pointer',
      link: hasPromotions && '#promotions',
      onClick: () =>
        !hasPromotions &&
        showNotification.error(messages[language].noPromotions, {
          toast: true,
        }),
    },
    showDealsRebates && {
      id: 'rebatesOffers',
      linkText: messages[language].rebatesOffers,
      link: EwayRouter.getUrl(REBATES_OFFERS_ROUTE),
      image:
        language === ENGLISH
          ? '/static/rebates-offers/eway_deals_rebates_banner_E.png'
          : '/static/rebates-offers/eway_deals_rebates_banner_F.png',
    },
    showClearanceMenu && {
      id: 'clearance',
      linkText: messages[language].clearance,
      link: `${HOST_URL}/${language}${messages[language].clearanceUrl}`,
    },
  ].filter(Boolean)
}
