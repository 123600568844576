import classNames from 'classnames'
import PropTypes from 'prop-types'
import Container from 'shared/Container'
import EwayLink from '~/components/shared/EwayLink'
import styles from './HeaderMiddleSection.module.scss'
import Logo from './Logo'
import messages from './messages'
import Search from './Search'
import SupportMenu from './SupportMenu'
import { getHostUrl } from 'services/utils/configUtils'
import { getSearchUrl } from 'shared/Layout/Header/HeaderMiddleSection/Search/SearchUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import { INK_AND_TONER } from '~/components/shared/EwayRouter/EwayRouterConstants'
import { ENGLISH } from '~/redux/constants'
import { useRouter } from 'next/router'
const HOST_URL = getHostUrl()

/**
 *Name: HeaderMiddleSection
 *Desc: Render header middle section
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {number} showLogo
 * @param {array}  shipToLocation
 * @param {boolean} showYourPriceFlag
 * @param {object} order
 * @param {object} buyer
 * @param {object} settings
 * @param {object} details
 * @param {string} pageName
 */

const HeaderMiddleSection = ({
  language = ENGLISH,
  isLoggedIn,
  showLogo,
  shipToLocation,
  showYourPriceFlag,
  order,
  buyer = {},
  settings = {},
  details = {},
  sessionId,
  pageName,
  showInkAndTonerLink,
  showNewFeatureInkAndToner = false, // feature flag to remove when new ink toner enabled.
}) => {
  const router = useRouter() || {}
  const { costCenter } = details
  const { firstName } = buyer
  const { enablePwgscheckout } = settings
  const inkAndTonerLabel = messages[language].inkAndTonerLabel
  const inkAndTonerUrl = showNewFeatureInkAndToner
    ? getSearchUrl('', null, '', '', true)
    : getUrl(INK_AND_TONER)
  const logoUrl = showLogo > 1 ? details.customLogo : ''
  const customSearchWrapperClass = classNames(styles.dFlex, {
    [styles.searchWrappers]: enablePwgscheckout,
  })
  const customSearchWithButtonClass = classNames(styles.searchCenter, {
    [styles.searchWithButtons]: enablePwgscheckout,
  })

  return (
    <div className={styles.headerMiddle}>
      <Container className="noPaddingXs">
        <div className={styles.headerMiddleBlock}>
          <div className={styles.logoWrapper}>
            {!!showLogo && <Logo language={language} logoUrl={logoUrl} />}
          </div>
          <div className={customSearchWrapperClass}>
            <div className={customSearchWithButtonClass}>
              <div className={styles.inputFields}>
                <Search
                  language={language}
                  value={router?.query?.q}
                  enablePwgscheckout={enablePwgscheckout}
                  showPwgsOptions={isLoggedIn && enablePwgscheckout}
                  buyer={buyer}
                />
              </div>
              {showInkAndTonerLink ? (
                <div className={styles.inkToner}>
                  <EwayLink
                    href={inkAndTonerUrl}
                    icon="drop"
                    text={inkAndTonerLabel}
                    data-test="'header-ink-toner-img'"
                    className={styles.inkAndTonerLink}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <SupportMenu
            language={language}
            isLoggedIn={isLoggedIn}
            firstName={firstName}
            costCenter={costCenter}
            shipToLocation={shipToLocation}
            showYourPriceFlag={showYourPriceFlag}
            order={order}
            hostUrl={HOST_URL}
            buyer={buyer}
            sessionId={sessionId}
            settings={settings}
            details={details}
            pageName={pageName}
          />
        </div>
      </Container>
    </div>
  )
}

// Props Validation
HeaderMiddleSection.propTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  showLogo: PropTypes.number,
  shipToLocation: PropTypes.array,
  showYourPriceFlag: PropTypes.bool,
  showInkAndTonerLink: PropTypes.bool,
  order: PropTypes.object,
  buyer: PropTypes.object,
  settings: PropTypes.object,
  details: PropTypes.object,
  sessionId: PropTypes.string,
  pageName: PropTypes.string,
  showNewFeatureInkAndToner: PropTypes.bool,
}

export default HeaderMiddleSection
