import PropTypes from 'prop-types'
import { useState } from 'react'
import { purifyHtmlString } from 'services/utils'
import Button from 'shared/Button'
import ListItem from 'shared/ListItem'
import NavBarCategoryListItemShow from '../NavBarCategoryListItemShow'
import styles from './HamburgerMenuCategoryList.module.scss'
import HamburgerMenuCategoryListItem from './HamburgerMenuCategoryListItem/HamburgerMenuCategoryListItem'
import HamburgerMenuCategoryListToggle from './HamburgerMenuCategoryListToggle'
import messages from './messages'
import MobileMenuItemList from '../MobileMenuItemList'
import navBarMessages from '../../messages'

const SHOW_ITEMS = 5

/**
 * Name:HamburgerMenuCategoryList
 * Desc: Render main category list
 * @param {string} language
 * @param {array} mainCategoryItem
 * @param {array} topNavBarItems
 * @param {function} switchMenuListHandler
 */

const HamburgerMenuCategoryList = ({
  language,
  mainCategoryItem,
  topNavBarItems,
  switchMenuListHandler,
  showItems,
  dealsMenuItems = [],
  publicationsMenuItems = [],
  brandsMenuItems = [],
}) => {
  const [categories, setCategoryData] = useState(
    mainCategoryItem.map((item) => item)
  )

  const toggleMenu = (item) => {
    item.active = !item.active
    const newCat = categories.map((category) => {
      if (category.Id === item.Id) {
        return item
      }
      return category
    })
    setCategoryData(newCat)
  }
  const displayMoreItem = (dataId) => {
    setCategoryData(
      categories.map((item) => {
        if (dataId === item.Id) {
          item.more = !item.more
        }
        return item
      })
    )
  }

  const hasPublicationMenuItems = !!publicationsMenuItems.length
  const hasDealsMenuItems = !!dealsMenuItems.length
  const hasBrandsMenuItems = !!brandsMenuItems.length

  return (
    <ul className={styles.hamburgerMainSubCategoryWrapper}>
      {/* Renders the "Products" and "Services and solution" */}
      {categories.map((category) => {
        const isActiveClass = category.active ? styles.active : ''

        const handleToggleOpenMenu = () => {
          toggleMenu(category)
        }
        const text = category?.more
          ? messages[language].lessLink
          : messages[language].moreLink
        const handleDisplayMore = () => {
          displayMoreItem(category.Id)
        }
        const { Dimensions: items } = category
        const showDisplayMore = items.length > showItems

        return (
          <ListItem key={category.Id} className={isActiveClass}>
            <HamburgerMenuCategoryListToggle
              onToggle={handleToggleOpenMenu}
              text={messages[language][category.Id]}
              isOpen={category.active}
            />
            <NavBarCategoryListItemShow isOpen={category.active} as="ul">
              {items.map((item, idx) => {
                const handleShowSubMenuList = () => {
                  switchMenuListHandler(false, item)
                }
                return (!category?.more && idx < showItems) ||
                  category?.more ? (
                  <HamburgerMenuCategoryListItem
                    key={item.Name}
                    link={item.Url}
                    linkText={purifyHtmlString(item.Name)}
                    onClick={handleShowSubMenuList}
                  />
                ) : null
              })}
              {showDisplayMore && (
                <ListItem>
                  <Button
                    className={styles.showMoreBtn}
                    text={text}
                    onClick={handleDisplayMore}
                  />
                </ListItem>
              )}
            </NavBarCategoryListItemShow>
          </ListItem>
        )
      })}

      {/* Renders brands menu dropdown */}
      {hasBrandsMenuItems && (
        <MobileMenuItemList
          items={brandsMenuItems}
          heading={navBarMessages[language].brands}
        />
      )}

      {/* Renders deals menu dropdown */}
      {hasDealsMenuItems && (
        <MobileMenuItemList
          items={dealsMenuItems}
          heading={navBarMessages[language].deals}
        />
      )}

      {/* Renders publication menu dropdown */}
      {hasPublicationMenuItems && (
        <MobileMenuItemList
          items={publicationsMenuItems}
          heading={navBarMessages[language].flyerPub}
        />
      )}

      {/* Renders top level menus */}
      {topNavBarItems.map((data) => {
        return (
          <ListItem key={`menu-${data.id}`}>
            <a href={data.link}>{data.linkText}</a>
          </ListItem>
        )
      })}
    </ul>
  )
}

HamburgerMenuCategoryList.defaultProps = {
  showItems: SHOW_ITEMS,
}

// Props Validation
HamburgerMenuCategoryList.propTypes = {
  language: PropTypes.string.isRequired,
  mainCategoryItem: PropTypes.array.isRequired,
  topNavBarItems: PropTypes.array.isRequired,
  dealsMenuItems: PropTypes.array,
  publicationsMenuItems: PropTypes.array,
  switchMenuListHandler: PropTypes.func.isRequired,
  showItems: PropTypes.number,
  brandsMenuItems: PropTypes.array,
}

export default HamburgerMenuCategoryList
