import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { LOCAL_STORAGES } from 'services/constants'
import { isGroupOrderFromBuyer, isEscPress } from 'services/utils'
import AlertNotificationContainer from 'shared/Alerts/AlertNotificationContainer'
import { useShipToContext } from '~/context/ShipToContext/ShipToContext'
import { ENGLISH } from '~/redux/constants'
import { getLocalStorage, removeLocalStorage } from '~/services/storageManager'
import { ACCOUNT_SELECTION_MODAL_CONTAINER_ID } from '../Alerts/AlertNotificationUtils/AlertNotificationConstants'
import Dialog from '../Dialog'
import MountActiveElement from '../MountActiveElement'
import TabList from '../Tabs/TabList'
import styles from './AccountSelection.module.scss'

import {
  SHIP_TO_SELECTION_NO,
  INVITEE_TYPES,
} from './AccountSelectionConstants'

import {
  checkWasAlreadyShown,
  hideAccountSelectionModalNotification,
  setIsShown as setLocalStorageIsShown,
  showAccountSelectionModalNotification,
  updateUserNotToShowPopUp,
} from './AccountSelectionUtils'
import FindAccountTab from './FindAccountTab'
import findAccountTableMessages from './FindAccountTab/FindAccountTable/messages'
import messages from './messages'
import ShipToSelectionTab from './ShipToSelectionTab'
import { PAGE_NAMES } from 'shared/Utils/constants'

const SHIP_TO_SELECTION_ID = 1
const FIND_ACCOUNT_TAB_ID = 2

export default function AccountSelection({
  language,
  buyer,
  order,
  settings,
  forceShow,
  sessionId,
  pageName,
}) {
  const [activeTab, setActiveTab] = useState(SHIP_TO_SELECTION_ID)
  const {
    showAccountSelectionModal,
    setShowAccountSelection,
    hasChanged,
    details = {},
  } = useShipToContext()

  const tabHeadingList = [
    {
      id: SHIP_TO_SELECTION_ID,
      text: messages[language].shipToSelection,
    },
    {
      id: FIND_ACCOUNT_TAB_ID,
      text: messages[language].findAccount,
    },
  ]
  const { buyerId, viewShipToSelectionPage } = buyer

  // Opposite value is used because of the "do NOT" wording
  const [isChecked, setIsChecked] = useState(!viewShipToSelectionPage)
  const [open, setOpen] = useState(false)

  // To control popup from find account link click from the top header
  useEffect(() => {
    getLocalStorage(LOCAL_STORAGES.ACCOUNT_SELECTION_MODAL) &&
      showAccountSelectionModalNotification({
        notificationMessage:
          findAccountTableMessages[language].accountSelection,
      })
    removeLocalStorage(LOCAL_STORAGES.ACCOUNT_SELECTION_MODAL)
    showAccountSelectionModal
      ? setActiveTab(FIND_ACCOUNT_TAB_ID)
      : setActiveTab(SHIP_TO_SELECTION_ID)
  }, [language, showAccountSelectionModal])

  const preventAutoOpenDialogInContextOfSpecificPages = ![
    PAGE_NAMES.ACCOUNT_ACTIVATION,
    PAGE_NAMES.CHANGE_PASSWORD,
  ].includes(pageName)
  // page is shown based on those following business logic ⬇️
  const canViewDialogBox =
    preventAutoOpenDialogInContextOfSpecificPages &&
    showPopUp(settings, buyer, order, details)

  useEffect(() => {
    if (forceShow) {
      setOpen(canViewDialogBox)
    } else {
      // Disabled temporarily, will be brought back, to confirm with Product Owner
      const wasAlreadyShown = checkWasAlreadyShown()

      // If it was shown once during session, don't show it again.
      setOpen(wasAlreadyShown ? false : canViewDialogBox)
      setLocalStorageIsShown(canViewDialogBox)
    }
  }, [canViewDialogBox, forceShow])

  const handleClickOnTab = (id) => {
    setActiveTab(id)
  }

  const handleOnClose = () => {
    showAccountSelectionModal && setShowAccountSelection(false)
    hideAccountSelectionModalNotification()
    setOpen(false)
    if (hasChanged) {
      // https://stackoverflow.com/questions/13306492/location-reload-doesnt-reload-the-page-when-im-using-it-in-keydown-event-handl
      setTimeout(() => {
        window.location.reload()
      }, 0)
    }
  }

  const handleCheckboxChange = async (e) => {
    // Use opposite values for - "do NOT show again"
    setIsChecked(!isChecked)
    await updateUserNotToShowPopUp(buyerId, !e.target.checked)
  }

  const handleKeyPress = (e) => {
    if (isEscPress(e)) {
      handleOnClose()
    }
  }

  return (
    <Dialog
      id="accountSelectionDialog"
      open={open || !!showAccountSelectionModal}
      variant="xl"
      onHide={handleOnClose}
      closeOnClickOutside={false}
      onKeyDown={handleKeyPress}
    >
      {canViewDialogBox && (
        <TabList
          tabHeadingList={tabHeadingList}
          clickOnTab={handleClickOnTab}
          activeTabId={activeTab}
        />
      )}

      <div className={styles.accountSelection}>
        <AlertNotificationContainer
          containerId={ACCOUNT_SELECTION_MODAL_CONTAINER_ID}
          maxWidth="auto"
        />
        <MountActiveElement activeStepId={activeTab}>
          <ShipToSelectionTab
            id={SHIP_TO_SELECTION_ID}
            buyer={buyer}
            language={language}
            details={details}
            onCheckboxChange={handleCheckboxChange}
            onClose={handleOnClose}
            isChecked={isChecked}
            onClickFindAccount={() => setActiveTab(FIND_ACCOUNT_TAB_ID)}
          />
          <FindAccountTab
            id={FIND_ACCOUNT_TAB_ID}
            language={language}
            sessionId={sessionId}
            buyerId={buyerId}
            enableFavoriteShipTo={settings.enableFavoriteShipTo}
            isTabActive={activeTab === FIND_ACCOUNT_TAB_ID}
            onClose={handleOnClose}
          />
        </MountActiveElement>
      </div>
    </Dialog>
  )
}

AccountSelection.propTypes = {
  pageName: PropTypes.string,
  buyer: PropTypes.object,
  forceShow: PropTypes.bool,
  language: PropTypes.string,
  settings: PropTypes.object,
  order: PropTypes.object,
  sessionId: PropTypes.string,
}

// Default Props
AccountSelection.defaultProps = {
  language: ENGLISH,
  isLoggedIn: false,
  forceShow: false,
  order: {},
}

function showPopUp(settings, buyer, order, details) {
  const { orderStatusType } = order
  const { isCustomerOnboardingAccount } = details

  const { enableShipToSelection, isPunchOutEnable } = settings
  const { viewShipToSelectionPage } = buyer

  // show pop up if he's not approved for invitee
  const notApprovedForInvitee =
    orderStatusType !== INVITEE_TYPES.APPROVED_FOR_INVITEE

  // show pop up if he's not customer onboarding
  const notCustomerOnBoarding = !isCustomerOnboardingAccount
  // show pop up if he's not group order
  const notGroupOrderFlag = !isGroupOrderFromBuyer(buyer)
  // page show be based on those settings

  const showPopUp =
    enableShipToSelection === SHIP_TO_SELECTION_NO &&
    viewShipToSelectionPage &&
    notApprovedForInvitee &&
    notCustomerOnBoarding &&
    notGroupOrderFlag
  // page should be show either user has a session basket or not but is not a punch user
  const canShow = !isPunchOutEnable

  const canViewPage = showPopUp && canShow
  return canViewPage
}
