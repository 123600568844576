import {
  ACCOUNT_SELECTION_MODAL_CONTAINER_ID,
  SUCCESS,
} from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { sendRequest } from '~/api/apiHelper'
import {
  hideNotification,
  showNotification,
} from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationUtils'
import { getApiUrl } from 'services/utils/configUtils'
import { PAGE_SELECTION_SHOWN } from './AccountSelectionConstants'
import { getCookie, setCookie } from '~/services/cookieManager'

const API_URL = getApiUrl()
export async function updateUserNotToShowPopUp(buyerId, value) {
  const url = `${API_URL}/buyers/${buyerId}`
  const payload = [
    {
      op: 'add',
      path: '/viewShipToSelectionPage',
      value,
    },
  ]
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    method: 'PATCH',
    retry: {
      addPreviousMethodToUrl: true,
      as: 'POST',
    },
  }
  const result = await sendRequest(url, options)
  const { status } = result
  return new Promise((resolve, reject) => {
    if (status === 200) {
      resolve({ ...result })
    } else {
      reject({ ...result })
    }
  })
}

export function setIsShown(canViewDialogBox) {
  setCookie(PAGE_SELECTION_SHOWN, canViewDialogBox)
}

export function checkWasAlreadyShown() {
  return Boolean(getCookie(PAGE_SELECTION_SHOWN))
}

export const showAccountSelectionModalNotification = ({
  notificationMessage,
  type = SUCCESS,
}) => {
  showNotification[type](notificationMessage, {
    showCloseButton: true,
    containerId: ACCOUNT_SELECTION_MODAL_CONTAINER_ID,
    id: ACCOUNT_SELECTION_MODAL_CONTAINER_ID,
  })
}

export const hideAccountSelectionModalNotification = () => {
  hideNotification(ACCOUNT_SELECTION_MODAL_CONTAINER_ID, {
    containerId: ACCOUNT_SELECTION_MODAL_CONTAINER_ID,
  })
}
