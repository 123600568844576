import { SelectBuyersModal } from 'components/shared/SelectBuyersModal/SelectBuyersModal'
import PropTypes from 'prop-types'
import { FIELDS } from 'shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import buyerModalStyles from 'shared/SelectBuyersModal/SelectBuyersModal.module.scss'
import { useFacultyCenter } from '~/api/facultyCenter'
import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import Flex from '~/components/shared/Flex'
import modalMessages from '~/components/shared/ManageAddresses/SaveAddressForm/messages'
import styles from '~/components/shared/PostalCodeEntryForm/PostalCodeEntryForm.module.scss'
import { SelectBuyers } from '~/components/shared/SelectBuyers'
import { useManageAddressesContext } from '~/context/ManageAddressesContext'
import { getAddressById } from '~/context/ManageAddressesContext/ManageAddressesContextUtils'
import { ENGLISH } from '~/redux/constants'
import { showNotification } from '../Alerts'
import Box from '../Box'
import Dialog from '../Dialog'
import { DialogCloseButton } from '../Dialog/DialogCloseButton'
import { DialogConfirmButton } from '../Dialog/DialogConfirmButton'
import Heading from '../Heading'
import { ManageAddressListModal } from '../ManageAddresses/ManageAddressListModal'
import SaveAddressFormWithTitle from '../ManageAddresses/SaveAddressForm/SaveAddressFormWithTitle'
import { useManageAddressList } from '../ManageAddresses/useManageAddressList'
import PostalCodeEntryForm from '../PostalCodeEntryForm'
import Text from '../Text'
import messages from './messages'
import { MODAL_NOTIFICATION_CONTAINER_ID } from '../Dialog/constants'

function WelcomeToEwayModal({
  language = ENGLISH,
  buyerId,
  parentClientNo,
  clientNo,
  onClickContinueManageAddress,
  onClickContinuePostalCodeEntry,
  onClosePostalCodeEntryModal,
  onCloseManageAddressModal,
  showCloseButton,
  open,
  clientId = '',
  addressLevel,
  principalClientId,
  ...rest
}) {
  const translations = messages[language]
  const {
    isLoading: isLoadingAddresses,
    isError: errorLoadingAddresses,
    hasNoAddresses,
    addresses = [],
    setAddresses,
    primaryAddress,
    selectedDeliveryAddressId,
    canCreateGlobalAddress,
    canCreateSharedAddress: canFetchBuyers,
    canAddAddress,
    setSelectedAddress,
  } = useManageAddressesContext()

  const {
    showSaveForm,
    selectedAddressId,
    editingAddress,
    mode,
    handleAddNewAddressClick,
    handleEditAddressClick,
    handleSelectAddressClick,
    handleCloseManageAddressModal,
    handleAfterSubmit,
    handleCancelButtonClick,
    selectBuyersInfo,
  } = useManageAddressList({
    openAddAddressFormInitialValue: false,
    onCloseManageAddressModal,
    defaultSelectedAddressId: selectedDeliveryAddressId,
    language,
    buyerId,
    clientId,
    hasNoAddresses,
    addresses,
    setAddresses,
    primaryAddress,
    canFetchBuyers,
  })

  const showWelcomeToEway = !showSaveForm
  const savedAddressId = selectedAddressId || primaryAddress?.id
  const savedAddress = getAddressById(addresses, savedAddressId)
  const {
    id,
    [FIELDS.POSTAL_CODE]: shippingPostalCode,
    [FIELDS.PROVINCE]: province,
  } = savedAddress
  const { updateSessionAndReload } = useFacultyCenter({ parentClientNo })

  async function handleAddressSelectContinueClick(...args) {
    if (!selectedAddressId) {
      showNotification.error(translations.errorNoAddressSelect, {
        containerId: MODAL_NOTIFICATION_CONTAINER_ID,
        toast: true,
      })
    } else {
      const values = {
        postalCode: shippingPostalCode,
        province,
      }
      updateSessionAndReload(values)
      selectedAddressId && setSelectedAddress(id)
      onClickContinueManageAddress(...args)
    }
  }

  function handleClickContinue(...args) {
    onClickContinuePostalCodeEntry(...args)
  }

  function handleClosePostalCodeEntryModal(e) {
    onClosePostalCodeEntryModal?.(e)
  }

  // Don't render the subtree if the component is not open
  const content = open ? (
    <>
      {hasNoAddresses ? (
        <PostalCodeEntryForm
          language={language}
          parentClientNo={parentClientNo}
          onSubmit={handleClickContinue}
          onClickClose={handleClosePostalCodeEntryModal}
          showCloseButton={showCloseButton}
        />
      ) : (
        <>
          {showSaveForm ? (
            <>
              <SaveAddressFormWithTitle
                variant={mode}
                language={language}
                buyerId={buyerId}
                clientNo={clientNo}
                onAfterSubmit={handleAfterSubmit}
                onClickCancel={handleCancelButtonClick}
                isDisabledSetPrimaryField={true}
                isCheckedSetPrimaryField={hasNoAddresses}
                address={editingAddress || {}}
                selectBuyersInfo={selectBuyersInfo}
                addresses={addresses}
                canCreateSharedAddress={canFetchBuyers}
                canCreateGlobalAddress={canCreateGlobalAddress}
                addressLevel={addressLevel}
                principalClientId={principalClientId}
                addressGroup={ADDRESS_GROUPS.DELIVERY_ADDRESS}
              />
              <SelectBuyersModal
                open={selectBuyersInfo.isOpenSelectedBuyerModal}
                language={language}
                variant="lg"
              >
                <Flex
                  justify="space-between"
                  pt="10px"
                  pr="20px"
                  pb="10px"
                  pl="20px"
                  align="center"
                  className={buyerModalStyles.dialogHeader}
                >
                  <Heading
                    as="h4"
                    text={modalMessages[language].selectBuyers}
                  />
                </Flex>
                <SelectBuyers
                  language={language}
                  selectBuyersInfo={selectBuyersInfo}
                />
              </SelectBuyersModal>
            </>
          ) : (
            <>
              <Text
                text={messages[language].manageAddressDetailsMsg}
                variant="lgText"
                color="secondary"
                as="div"
                className={styles.textMargin}
                textAlign="center"
              />
              <ManageAddressListModal
                onClickAddNew={handleAddNewAddressClick}
                onClickEdit={handleEditAddressClick}
                onClickSelect={handleSelectAddressClick}
                isLoading={isLoadingAddresses}
                isError={errorLoadingAddresses}
                addresses={addresses}
                language={language}
                selectedAddressId={selectedAddressId}
                canAddAddress={canAddAddress}
                buyerId={buyerId}
              >
                {showCloseButton ? (
                  <DialogCloseButton
                    type="button"
                    language={language}
                    onClick={handleCloseManageAddressModal}
                  />
                ) : null}
                <DialogConfirmButton
                  type="submit"
                  language={language}
                  onClick={handleAddressSelectContinueClick}
                />
              </ManageAddressListModal>
            </>
          )}
        </>
      )}
    </>
  ) : null
  const component = showWelcomeToEway ? (
    <Box p="15px 10px">
      <Box className={styles.welcomeModalWrapper}>
        <Heading
          text={translations.ewayWelcomeMsg}
          data-test="welcome-to-eway-select-address-modal"
          as="h1"
          textAlign="center"
        />
        {content}
      </Box>
    </Box>
  ) : (
    content
  )
  return (
    <Dialog
      id="postalCodeEntryDialog"
      data-test="postalCodeEntryDialog"
      closeOnClickOutside={false}
      variant="xxl"
      open={open}
      role="dialog"
      aria-label={translations.ewayWelcomeMsg}
      {...rest}
    >
      {component}
    </Dialog>
  )
}

WelcomeToEwayModal.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string,
  showHeading: PropTypes.bool,
  buyerId: PropTypes.string,
  parentClientNo: PropTypes.number,
  clientNo: PropTypes.number,
  open: PropTypes.bool,
  onClickContinueManageAddress: PropTypes.func,
  onClickContinuePostalCodeEntry: PropTypes.func,
  onClosePostalCodeEntryModal: PropTypes.func,
  onCloseManageAddressModal: PropTypes.func,
  showCloseButton: PropTypes.bool,
  clientId: PropTypes.string,
  saveAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  canCreateSharedAddress: PropTypes.bool,
  canCreateGlobalAddress: PropTypes.bool,
  addressLevel: PropTypes.number,
  principalClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default WelcomeToEwayModal
