import classNames from 'classnames'
import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { useShipToContext } from '~/context/ShipToContext'
import { ENGLISH } from '~/redux/constants'
import messages from './messages'
import styles from './ShipToFindAccount.module.scss'

const ShipToFindAccount = ({ language, readOnly }) => {
  const { setShowAccountSelection } = useShipToContext()
  const { findAccountLabel } = messages[language]

  const findAccountClasses = classNames(styles.findAccountLink, {
    [styles.disabled]: readOnly,
  })

  const handleOnClick = () => {
    setShowAccountSelection(true)
  }

  // TODO: need to confirm readOnly prop with Etienne
  return (
    <Button
      className={findAccountClasses}
      disabled={readOnly}
      variant="link"
      onClick={handleOnClick}
      text={findAccountLabel}
      data-test="findAccountLink"
    />
  )
}

ShipToFindAccount.defaultProps = {
  language: ENGLISH,
  readOnly: false,
}

ShipToFindAccount.propTypes = {
  language: PropTypes.string,
  readOnly: PropTypes.node,
}
export default ShipToFindAccount
