import { noop } from 'components/shared/Utils/userUtils'
import PropTypes from 'prop-types'
import { purifyHtmlString } from 'services/utils/utils'
import ListItem from 'shared/ListItem'
import {
  addCategoryQueryString,
  encodeValues,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import EwayLink from '~/components/shared/EwayLink'
import BannerImg from '../../BannerImage'
import styles from './NavBarChildCategoryList.module.scss'

/**
 * Name:NavBarChildCategoryList
 * Desc: Render sub category list
 * @param {array} categories
 * @param {string} bannerUrl
 * @param {string} categoryName
 */

const NavBarChildCategoryList = ({
  categories = [],
  bannerUrl = '',
  categoryName,
  imageLink = '',
  isBrowseCallingLocation = false,
}) => {
  return (
    <>
      {categories?.length ? (
        <ul className={styles.NavBarChildCategoryList}>
          {categories?.map((item) => {
            const { href, title, className } = itemFormat(
              item,
              categoryName,
              isBrowseCallingLocation
            )
            return (
              <ListItem key={item.Name} className={className}>
                <EwayLink data-test={categories.dataTest} href={href}>
                  {title}
                </EwayLink>
                {item.Dimensions.length ? (
                  <ul>
                    {item.Dimensions.map((subItem) => {
                      const { link, target, subItemTitle } = subItemFormat(
                        subItem,
                        categoryName,
                        item,
                        categories,
                        isBrowseCallingLocation
                      )
                      // TODO: ⬇️ Not tested
                      return (
                        <ListItem key={subItem.Name}>
                          <a href={link} {...target}>
                            {subItemTitle}
                          </a>
                        </ListItem>
                      )
                    })}
                  </ul>
                ) : null}
              </ListItem>
            )
          })}
        </ul>
      ) : null}
      {bannerUrl ? (
        <BannerImg src={bannerUrl} alt={bannerUrl} link={imageLink} />
      ) : null}
    </>
  )
}

NavBarChildCategoryList.defaultProps = {
  changeRightMenu: noop,
}

// Props Validation
NavBarChildCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  bannerUrl: PropTypes.string,
  categoryName: PropTypes.string,
  imageLink: PropTypes.string,
  isBrowseCallingLocation: PropTypes.bool,
}

export default NavBarChildCategoryList

function subItemFormat(
  subItem,
  categoryName,
  item,
  data,
  isBrowseCallingLocation
) {
  const values = encodeValues([categoryName, item.Name, subItem.Name])
  const link = addCategoryQueryString(
    subItem.Url,
    values,
    isBrowseCallingLocation
  )
  const { target: targetValue } = data
  const target = targetValue
    ? {
        target: targetValue,
        rel: 'noopener noreferrer',
      }
    : null
  const subItemTitle = purifyHtmlString(subItem.Name)
  return { link, target, subItemTitle }
}

function itemFormat(item, categoryName, isBrowseCallingLocation) {
  const values = encodeValues([categoryName, item.Name])
  const href = addCategoryQueryString(item.Url, values, isBrowseCallingLocation)
  const className = item.Dimensions.length ? '' : styles.makeLinkActive
  const title = purifyHtmlString(item.Name)
  return { href, title, className }
}
