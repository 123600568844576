import PropTypes from 'prop-types'
import styles from './Tab.module.scss'
import classNames from 'classnames'
import Button from 'shared/Button'

const Tab = ({
  id,
  variant,
  children,
  active,
  onClick,
  className,
  ...rest
}) => {
  const tabStyles = classNames(
    styles.tabStyle,
    {
      [styles.active]: active,
      [styles.link]: variant === 'link',
    },
    className
  )
  return (
    <Button
      role="tab"
      type="button"
      variant="link"
      id={id}
      aria-selected={!!active}
      className={tabStyles}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
}

Tab.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  customActiveEnableProperties: PropTypes.bool,
}
export default Tab
