import { useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import Button from 'shared/Button'
import EwayLink from 'shared/EwayLink'
import { purifyHtmlString } from 'services/utils'

import styles from './HamburgerChildCategory.module.scss'

/**
 * Name:HamburgerChildCategory
 * Desc: Render child menu
 * @param {object} childCategoryItem
 * @param {function} switchMenuListHandler
 */

const HamburgerChildCategory = ({
  childCategoryItem,
  switchMenuListHandler,
}) => {
  const lastMenuListRef = useRef({})
  const [currentMenuList, setCurrentMenuList] = useState(childCategoryItem)

  const getMenuLink = (item) => {
    const link = !item.Dimensions.length ? { link: item.Url } : {}
    return isEmpty(link) ? '#' : link
  }

  const showNextMenuList = (item) => {
    lastMenuListRef.current = { ...currentMenuList }
    setCurrentMenuList({
      ...currentMenuList,
      ...item,
    })
  }
  const showPreviousMenuList = () => {
    const { current = {} } = lastMenuListRef
    if (current.Name) {
      setCurrentMenuList({ ...currentMenuList, ...current })
      lastMenuListRef.current = {
        ...current,
        Name: '',
        Dimensions: [],
      }
    } else {
      switchMenuListHandler(true)
    }
  }
  return (
    <div className={styles.hamburgerNavBarChildCategoryList}>
      <div className={styles.hamburgerNavBarChildCategoryListHeader}>
        <Button
          icon="previous"
          onClick={showPreviousMenuList}
          className={styles.previousIcon}
        />
        <EwayLink
          text={purifyHtmlString(currentMenuList.Name)}
          color="secondary"
          size="xlg"
          href={currentMenuList.Url}
        />
      </div>

      {!!currentMenuList.Dimensions.length && (
        <ul>
          {currentMenuList.Dimensions.map((data) => {
            const link = getMenuLink(data)
            return (
              <ListItem key={data.Name}>
                {/* TODO: Replace <a> with <EwayLink /> */}
                <a
                  href={link}
                  onClick={() => {
                    showNextMenuList(data)
                  }}
                >
                  {purifyHtmlString(data.Name)}
                </a>
              </ListItem>
            )
          })}
        </ul>
      )}
    </div>
  )
}
// Props Validation
HamburgerChildCategory.propTypes = {
  childCategoryItem: PropTypes.object.isRequired,
  switchMenuListHandler: PropTypes.func.isRequired,
}
export default HamburgerChildCategory
