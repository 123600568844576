import PropTypes from 'prop-types'
import { memo, useEffect, useMemo, useState } from 'react'
import Button from 'shared/Button'
import { useShipToContext } from '~/context/ShipToContext/ShipToContext'
import styles from './FindAccountTab.module.scss'
import FindAccountTabFilter from './FindAccountTabFilter'
import { getFilterInitialValues } from './FindAccountTabFilter/FindAccountTabFilterUtils'
import FindAccountTable from './FindAccountTable'
import {
  fetchShipTos,
  getTableData,
  setFavoriteShipTo,
} from './FindAccountTabUtils'
import messages from './messages'

/**
 *Name: FindAccountTab
 *Desc: Render FindAccountTab
 * @param {string} language
 * @param {string} buyerId
 * @param {string} sessionId
 * @param {boolean} isTabActive
 * @param {boolean} enableFavoriteShipTo
 * @param {function} onClose
 */

const FindAccountTab = ({
  language,
  buyerId,
  sessionId,
  isTabActive,
  enableFavoriteShipTo,
  onClose,
}) => {
  const [filterMeta, setFilterMeta] = useState(() => getFilterInitialValues())
  const [accountList, setAccountList] = useState([])
  const { onMarkAccountAsFavorite, isDisabledAccountSelection } =
    useShipToContext()
  const tableData = useMemo(() => {
    return getTableData(filterMeta, accountList)
  }, [accountList, filterMeta])

  useEffect(() => {
    const getAccountList = async () => {
      const shipTos = await fetchShipTos(buyerId, sessionId, {
        includeMax: false,
      })
      setAccountList(shipTos)
    }
    isTabActive && !accountList.length && getAccountList()
  }, [accountList.length, buyerId, isTabActive, language, sessionId])

  const handleMarkFavorite = async (clientId, isFavorite) => {
    await setFavoriteShipTo(language, buyerId, clientId, isFavorite)
    onMarkAccountAsFavorite()
    const updatedFavoriteAccounts = accountList.map((account) => {
      if (clientId === account.clientId) {
        return { ...account, isFavorite: !isFavorite }
      }
      return account
    })
    setAccountList(updatedFavoriteAccounts)
  }

  return (
    <div className={styles.tabWrapper}>
      <FindAccountTabFilter
        language={language}
        totalShowingRecords={tableData?.length}
        setFilterMeta={setFilterMeta}
      />
      <FindAccountTable
        language={language}
        tableData={tableData}
        accountList={accountList}
        handleMarkFavorite={handleMarkFavorite}
        enableFavoriteShipTo={enableFavoriteShipTo}
        isDisabledAccountSelection={isDisabledAccountSelection}
      />

      <div className={styles.tableButton}>
        <Button
          text={messages[language].close}
          variant="primary"
          onClick={onClose}
          data-test="close-button"
        />
      </div>
    </div>
  )
}

// Props Validation
FindAccountTab.propTypes = {
  language: PropTypes.string,
  buyerId: PropTypes.string,
  sessionId: PropTypes.string,
  isTabActive: PropTypes.bool,
  enableFavoriteShipTo: PropTypes.bool,
  onClose: PropTypes.func,
}

export default memo(FindAccountTab)
