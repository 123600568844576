import { useEffect, useRef, useState, useCallback } from 'react'
import { useSelectBuyers } from '~/components/shared/SelectBuyers/SelectBuyersUtils'
import { getAddressById } from '~/context/ManageAddressesContext/ManageAddressesContextUtils'
import { ENGLISH } from '~/redux/constants'
import { somethingWentWrong } from '../Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import { noop } from '../Utils/userUtils'
import { handleNewAddressState } from './SaveAddressForm/SaveAddressFormUtils'

export function useManageAddressList({
  openAddAddressFormInitialValue: init = false,
  defaultSelectedAddressId,
  onCloseManageAddressModal = noop,
  canFetchBuyers,
  language = ENGLISH,
  addresses = [],
  primaryAddress = {},
  hasNoAddresses,
  setAddresses,
  buyerId,
  clientId,
}) {
  const [showSaveForm, setShowSaveForm] = useState(init)
  const [selectedAddressId, setSelectedAddressId] = useState()
  const addressIdRef = useRef('') //capture the address id to delete the address
  const [editingAddress, setEditingAddress] = useState({})
  const selectBuyersInfo = useSelectBuyers({
    buyerId,
    clientId,
    canFetchBuyers,
  })
  // When mounted set the default address as selected
  useEffect(() => {
    setSelectedAddressId(defaultSelectedAddressId || primaryAddress.id)
  }, [defaultSelectedAddressId, primaryAddress.id])
  const { resetSelectedBuyersInfo } = selectBuyersInfo

  function handleAddNewAddressClick() {
    setShowSaveForm(true)
    addressIdRef.current = ''
    resetSelectedBuyersInfo({ isEditMode: false, address: {} })
  }

  function handleCancelButtonClick() {
    setEditingAddress(null)
    setShowSaveForm(false)
    init && hasNoAddresses && onCloseManageAddressModal()
  }

  function handleAfterSubmit(...args) {
    const [isSuccess, ...rest] = args
    if (isSuccess) {
      const newAddressState = handleNewAddressState(addresses, ...rest)
      if (hasNoAddresses) {
        const [address] = newAddressState
        setSelectedAddressId(address.id)
      }
      setAddresses(newAddressState)
    } else {
      somethingWentWrong(language, { toast: true })
    }
    addressIdRef.current = ''
    setEditingAddress(null)
    setShowSaveForm(false)
  }

  function handleEditAddressClick(id) {
    addressIdRef.current = id
    const address = getAddressById(addresses, id)
    setShowSaveForm(true)
    setEditingAddress(address)
    resetSelectedBuyersInfo({ isEditMode: true, address })
  }

  const handleSelectAddressClick = useCallback(
    function handleSelectAddressClick(id) {
      setSelectedAddressId(id)
    },
    []
  )

  const mode = addressIdRef?.current ? 'edit' : 'add'

  return {
    showSaveForm,
    selectedAddressId,
    editingAddress,
    addressIdRef,
    mode,
    selectBuyersInfo,
    handleAddNewAddressClick,
    handleCancelButtonClick,
    handleCloseManageAddressModal: onCloseManageAddressModal,
    handleAfterSubmit,
    handleEditAddressClick,
    handleSelectAddressClick,
  }
}
