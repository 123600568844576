export default {
  en: {
    shipToAccountLabel: 'Ship to location',
    findAccountLabel: 'Find Account',
    tooltipText: `The list contains only your favorite accounts. To see the full list, click on the Find Account Link.`,
    questionMarkText: `You currently have a Group Order in your Shopping Cart; you cannot change the ship-to account for a Group Order. You will be able to access the ship-to account selection after submitting the Group Order or clearing your cart.`,
  },
  fr: {
    shipToAccountLabel: 'Emplacement du sous-compte',
    findAccountLabel: 'Trouver un compte',
    tooltipText: `La liste contient uniquement vos comptes favoris. Pour voir la liste complète, cliquez sur le lien Trouver un compte.`,
    questionMarkText: `Vous avez présentement une commande de groupe dans votre panier d'achat. Vous ne pouvez pas changer de compte pour une commande de groupe. Vous pourrez modifier le compte après avoir soumis la commande de groupe ou après avoir vidé votre panier.`,
  },
}
