import { useCallback, useEffect, useRef } from 'react'
import { defaultCaptureEvents } from '~/services/utils/keyboardUtils/keyboardCodeConstants'
import { useKeyPressEvent } from '../useKeyPressEvent'
import { useMutationObservation } from '../useMutationObservation'
import { getDefaultKeyboardAction } from './useKeyboardNavigationUtils'
import {
  getPreviousFocusableElement,
  getNextFocusableElement,
} from './useKeyBoardNavigationNextElementsUtils'

export const useKeyboardNavigation = function ({
  items,
  isActive,
  selectedItemIndex,
  setSelectedItemIndex,
  mutationObserver,
  observingElement,
  captureEvents = defaultCaptureEvents,
  horizontalArrowHandler = null,
  exitHandler = null,
  getKeyboardAction = getDefaultKeyboardAction,
}) {
  const keyPressEvent = useKeyPressEvent({
    captureEvents,
    isActive,
  })
  const { lastUpdateTime } = keyPressEvent
  const selectedItemIndexRef = useRef(selectedItemIndex)

  const {
    isNextSelection,
    isPreviousSelection,
    rightPress,
    leftPress,
    isExit,
  } = getKeyboardAction(keyPressEvent)

  useMutationObservation({
    observerHandler: mutationObserver,
    observingElement,
  })

  useEffect(() => {
    isExit && exitHandler()
  }, [exitHandler, isExit])

  useEffect(() => {
    if (items.length && isPreviousSelection) {
      selectedItemIndexRef.current = getPreviousFocusableElement(
        selectedItemIndexRef.current,
        items.length
      )

      setSelectedItemIndex(selectedItemIndexRef.current)
    }
  }, [isPreviousSelection, items.length, lastUpdateTime, setSelectedItemIndex])

  useEffect(() => {
    if (items.length && isNextSelection) {
      selectedItemIndexRef.current = getNextFocusableElement(
        selectedItemIndexRef.current,
        items.length
      )
      setSelectedItemIndex(selectedItemIndexRef.current)
    }
  }, [isNextSelection, items.length, lastUpdateTime, setSelectedItemIndex])

  useEffect(() => {
    if (horizontalArrowHandler && rightPress) {
      horizontalArrowHandler(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPress])

  useEffect(() => {
    if (horizontalArrowHandler && leftPress) {
      horizontalArrowHandler(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPress])
  const updateRefIndex = useCallback((updatedIndex) => {
    selectedItemIndexRef.current = updatedIndex
  }, [])
  return { selectedItemIndex, updateRefIndex }
}
