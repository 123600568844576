import PropTypes from 'prop-types'
import compose from 'lodash/fp/compose'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  filterAlternateProducts,
  filterPromoProducts,
  getAndApplyPromoDiscount,
  getSlicedProductItems,
} from 'services/orderService'
import useIsFirstRender from 'shared/CustomHooks/useIsFirstRender'

import Heading from 'shared/Heading'
import Price from 'shared/Price/Price'
import Text from 'shared/Text'
import useWindowDimensions from 'shared/CustomHooks/useWindowDimensions'
import { BREAK_POINTS } from 'shared/Utils/constants'
import {
  SupportMenuItem,
  SupportMenuItemContainer,
  MenuItemContent,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SupportMenuItem'
import CartFooter from './CartFooter'
import CartHeader from './CartHeader'
import CartItemsList from './CartItemsList'
import styles from './CartPanel.module.scss'
import { getOrderList } from './CartPanelUtils'
import messages from './messages'
import { CART } from '../SupportMenuConstants'
import EwayRouter from '~/components/shared/EwayRouter'
import { SHOPPING_CART_ROUTE } from '~/components/shared/EwayRouter/EwayRouterConstants'

const DISPLAY_LIMIT = 5

/**
 * Name: CartPanel
 * Desc: Render header middle section cart panel
 * @param {string} language
 * @param {boolean} isLoggedIn
 * @param {boolean} showYourPriceFlag
 * @param {object} order
 * @param {object} buyer
 * @param {string} sessionId
 * @param {string} cartUpdateTimeStamp
 * @param {func} onMouseEnter
 * @param {object} details
 * @param {object} settings
 */

const CartPanel = ({
  language,
  isLoggedIn,
  showYourPriceFlag,
  order = {},
  sessionId,
  buyer,
  cartUpdateTimeStamp,
  pageName,
  details: { fiftyGreen, minimumOrderAmount = 0, isCustomerOnboardingAccount },
  settings,
}) => {
  const isFirstRender = useIsFirstRender()
  const [orderInfo, setOrderInfo] = useState(order)
  const [isCheckoutDisable, setCheckoutDisable] = useState(false)
  const { buyerId } = buyer
  const {
    cartLabel,
    becomeAMemberLabel,
    moreItemsLabel,
    orderTotalLabel,
    emptyCartLabel,
  } = messages[language]
  const orderProducts =
    compose(
      filterAlternateProducts,
      filterPromoProducts
    )(orderInfo?.orderProducts) || []
  const { enableRecommendationProduct = false } = settings

  useEffect(() => {
    if (!isFirstRender) {
      getOrderList(
        {
          buyerId,
          sessionId,
          fiftyGreen,
          enableRecommendationProduct,
          minimumOrderAmount,
        },
        setOrderInfo,
        setCheckoutDisable
      )
    }
  }, [
    buyerId,
    sessionId,
    fiftyGreen,
    enableRecommendationProduct,
    minimumOrderAmount,
    isFirstRender,
    cartUpdateTimeStamp,
  ])
  const { width } = useWindowDimensions()
  const isClickable = isLoggedIn && width >= BREAK_POINTS.md
  const [shouldCartClickable, setShouldCartClickable] = useState(false)
  useEffect(() => {
    setShouldCartClickable(isClickable)
  }, [isClickable])

  const productItems = getSlicedProductItems(orderInfo, DISPLAY_LIMIT)
  const totalAmount = getAndApplyPromoDiscount(orderInfo)

  const viewCartUrl = EwayRouter.getUrl(SHOPPING_CART_ROUTE)
  const productCount = orderProducts?.length

  const menuItemContent = (
    <MenuItemContent label={cartLabel} icon="cart" color="white">
      {productCount > 0 && (
        <div className={styles.menuCount}>{productCount}</div>
      )}
    </MenuItemContent>
  )

  function redirectToCart() {
    window.location.assign(viewCartUrl)
  }
  const menuItemContentWithLink = shouldCartClickable ? (
    <Text
      role="button"
      onClick={redirectToCart}
      color="white"
      text={menuItemContent}
      dataTest="CartPanel"
    />
  ) : (
    menuItemContent
  )

  return (
    <SupportMenuItemContainer menuName={CART} enableMouseOut>
      <SupportMenuItem
        label={cartLabel}
        icon="cart"
        color="white"
        menuName={CART}
      >
        {menuItemContentWithLink}
      </SupportMenuItem>
      <section className={styles.cartList}>
        <div
          className={`${styles.cartPanel} ${
            isLoggedIn ? styles.cartWrapper : ''
          }`}
        >
          {!isLoggedIn ? (
            <div className={styles.infoWrapper}>
              <Text
                text={becomeAMemberLabel}
                variant="mdText"
                color="secondary"
              />
            </div>
          ) : (
            <>
              <CartHeader
                language={language}
                count={orderProducts?.length}
                order={order}
                buyer={buyer}
              />
              <div className={styles.cartBody}>
                {!!productItems.length && (
                  <CartItemsList items={productItems} />
                )}
                {!orderProducts?.length && (
                  <div className={styles.emptyCard}>
                    <Text
                      text={emptyCartLabel}
                      variant="mdText"
                      color="tertiary"
                    />
                  </div>
                )}
                <div className={styles.totalList}>
                  {orderProducts?.length > DISPLAY_LIMIT && (
                    <Text text={moreItemsLabel} variant="smText" />
                  )}

                  {showYourPriceFlag && totalAmount > 0 && (
                    <div className={styles.totalAmount}>
                      <Text
                        text={`${orderTotalLabel} :`}
                        variant="smText"
                        fontWeight="strong"
                        color="secondary"
                      />
                      <Heading
                        color="primary"
                        as="h3"
                        text={<Price language={language}>{totalAmount}</Price>}
                      />
                    </div>
                  )}
                </div>
              </div>

              <CartFooter
                language={language}
                productCount={orderProducts?.length}
                viewCartUrl={viewCartUrl}
                pageName={pageName}
                isCheckoutDisable={isCheckoutDisable}
                order={order}
                buyer={buyer}
                isCustomerOnboardingAccount={isCustomerOnboardingAccount}
              />
            </>
          )}
        </div>
      </section>
    </SupportMenuItemContainer>
  )
}

// Props Validation
CartPanel.propTypes = {
  language: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  order: PropTypes.object,
  showYourPriceFlag: PropTypes.bool,
  sessionId: PropTypes.string,
  buyer: PropTypes.object,
  cartUpdateTimeStamp: PropTypes.number,
  pageName: PropTypes.string,
  details: PropTypes.object,
  settings: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    cartUpdateTimeStamp: state.state.cartUpdateTimeStamp,
  }
}

export default connect(mapStateToProps, null)(CartPanel)
