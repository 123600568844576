import PropTypes from 'prop-types'
import {
  SupportMenuItem,
  SupportMenuItemContainer,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SupportMenuItem'
import messages from './messages'
import PasswordReminder from './PasswordReminder'
import PostalDetail from './PostalDetail'
import styles from './SignIn.module.scss'
import SignInTopBar from './SignInTopBar'
import UserIDReminder from './UserIDReminder'
import UserLogin, {
  UserLoginPasswordField,
  UserLoginSaveUserIdField,
  UserLoginSignInButton,
  UserLoginUserIdField,
} from './UserLogin'
import { canShowPostalCodeSignIn } from 'services/utils/configUtils'
import { SIGN_IN } from '../SupportMenuConstants'
import { useSignInForms } from './SignInUtils'

const isPostalCodeSignInEnable = canShowPostalCodeSignIn()

/**
 * Name: SignIn
 * Desc: Render sign in panel
 * @param {string} language
 * @param {func} onCloseMenu
 * @param {object} settings
 * @param {object} buyer
 * @param {func} onMouseEnter
 * @param {string} pageName
 * @param {bool} isActive
 */

const SignIn = (props) => {
  const { language, settings, buyer, pageName } = props
  const { signInLabel } = messages[language]

  const {
    currentFormState,
    setCurrentFormState,
    activeFormTitle,
    showUserIdReminderForm,
    showPasswordReminderForm,
    showUserLoginForm,
  } = useSignInForms(language)

  return (
    <SupportMenuItemContainer menuName={SIGN_IN}>
      <SupportMenuItem
        menuName={SIGN_IN}
        label={signInLabel}
        icon="accountRegular"
        color="black"
      />

      <div className={styles.signInList}>
        <div className={styles.signInPanel}>
          <SignInTopBar
            language={language}
            title={activeFormTitle}
            currentState={currentFormState}
            setCurrentState={setCurrentFormState}
          />
          {showUserIdReminderForm ? (
            <UserIDReminder language={language} />
          ) : null}
          {showPasswordReminderForm ? (
            <PasswordReminder language={language} />
          ) : null}
          {showUserLoginForm ? (
            <>
              <UserLogin
                id="userLogin-header"
                language={language}
                setCurrentState={setCurrentFormState}
                settings={settings}
                buyer={buyer}
                pageName={pageName}
                location="header"
              >
                <UserLoginUserIdField id="userId" dataTest="userId" />
                <UserLoginPasswordField id="password" dataTest="password" />
                <UserLoginSaveUserIdField
                  id="rememberMe"
                  dataTest="rememberMe"
                />
                <UserLoginSignInButton dataTest="signInButton" />
              </UserLogin>
              {isPostalCodeSignInEnable ? (
                <PostalDetail language={language} />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </SupportMenuItemContainer>
  )
}

// Props Validations
SignIn.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.object,
  buyer: PropTypes.object,
  pageName: PropTypes.string,
}

export default SignIn
