export default {
  en: {
    ewayWelcomeMsg: 'Welcome to eway.ca',
    manageAddressDetailsMsg:
      'To ensure that we provide you with the most accurate and up-to-date product availability, please select or add the address that your next order will be delivered to.',
    errorNoAddressSelect: `You must select a delivery address in order to proceed. To select an address, simply click on the address card and click 'Continue'.`,
  },
  fr: {
    ewayWelcomeMsg: 'Bienvenue sur eway.ca',
    manageAddressDetailsMsg:
      "Pour nous assurer que nous vous offrons la disponibilité des produits la plus précise et la plus à jour, veuillez sélectionner ou ajouter l'adresse à laquelle votre prochaine commande sera expédiée.",
    errorNoAddressSelect: `Vous devez sélectionner une adresse de livraison pour continuer. Pour sélectionner une adresse, cliquez simplement sur la carte d'adresse et cliquez sur «\u00A0Continuer\u00A0».`,
  },
}
