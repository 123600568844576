import Icon from 'shared/Icon'
import Box from 'shared/Box'
import { getIconVariant } from 'shared/ManageAddresses/ManageAddressesUtils'
import Text from 'shared/Text'
import Flex from '~/components/shared/Flex'
import { AddressActionButtons } from '../AddressActionButtons'
import messages from './messages'
import { ADDRESS_VISIBILITY } from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import FormField from 'shared/FormControl/FormField'
import InfoLabel from 'components/shared/InfoLabel'
import styles from './AddressTable.module.scss'

export const getAddressRows = ({
  language,
  addresses = [],
  onSelectAddressClick,
  selectedAddressId,
  buyerId,
  enableSelectOption,
  ...rest
}) => {
  const translations = messages[language]
  const map = {
    [ADDRESS_VISIBILITY.PRIVATE]: translations.privateHeading,
    [ADDRESS_VISIBILITY.SHARED]: translations.sharedHeading,
    [ADDRESS_VISIBILITY.GLOBAL]: translations.globalHeading,
  }
  return addresses.map((address) => {
    const {
      id,
      buyerId: addressBuyerId = '',
      visibility,
      companyName,
      streetAddress,
      attention,
      building,
      city,
      province,
      postalCode,
      canEditAddress,
      canAssignAddress,
      canDeleteAddress,
      deliveryAddressBillingAddress = [],
      isDefault,
    } = address
    const variant = getIconVariant(visibility)
    const visibilityHeading = map[visibility]
    const fullAddress = `${streetAddress ? `${streetAddress},` : ''} ${
      building ? `${building},` : ''
    } ${city ? `${city},` : ''} ${province ? `${province},` : ''} ${
      postalCode || ''
    }`
    function handleSelectAddress() {
      return onSelectAddressClick?.(id)
    }
    const {
      addressAssignedIds = [],
      showAssignAddress = false,
      showDeleteButton = false,
      showSetAsDefaultBillingAddressButton = false,
      showPrimaryButton,
    } = rest
    const checkedAddress = addressAssignedIds
    const isAssignedToDeliveryAddress =
      (canAssignAddress && deliveryAddressBillingAddress?.length > 0) ||
      (canAssignAddress && checkedAddress?.length > 0)

    const primaryButton = isDefault ? (
      <InfoLabel
        variant="success"
        variantText="smText"
        className={styles.infoLabel}
        data-test="primary-selected"
      >
        <Icon variant="Primary" mr="3px"></Icon>
        <Text text={translations.primary} color="white" />
      </InfoLabel>
    ) : (
      ''
    )
    const selectOption = enableSelectOption
      ? [
          <FormField
            value={id}
            key={`select-address-${id}`}
            defaultChecked={selectedAddressId === id}
            onChange={handleSelectAddress}
            label=""
            type="radio"
            name="selectAddressFormField"
            data-test="select-address-form-filed"
            id={`select-address-${id}`}
            aria-label={companyName}
          />,
        ]
      : [<Box key={`select-address-${id}`}></Box>]
    const showMakePrimaryButton = showPrimaryButton && !isDefault
    return [
      ...selectOption,
      <Text
        text={companyName || ''}
        color="secondary"
        variant="mdText"
        key={`address-name-${id}`}
        data-test="address-company-name"
      />,
      <Flex align="center" key={`address-visibility-${id}`}>
        <Icon
          className={styles.iconStatus}
          variant={variant}
          data-test="cost-center-sorting-icon"
          cursor="default"
        />
        <Text
          text={visibilityHeading}
          color="secondary"
          variant="mdText"
          data-test="cost-center-label-value-2"
        />
      </Flex>,
      <Text
        text={fullAddress}
        color="secondary"
        variant="mdText"
        key={`address-info-${id}`}
        data-test="cost-center-label-value-3"
      />,
      <Text
        text={attention}
        color="secondary"
        variant="mdText"
        key={`address-attention-${id}`}
        data-test="cost-center-label-value-3"
      />,
      primaryButton,
      <AddressActionButtons
        key={`address-action-${id}`}
        id={id}
        language={language}
        showAssignButton={canAssignAddress && showAssignAddress}
        showToggleActionButton={
          canEditAddress ||
          showDeleteButton ||
          showSetAsDefaultBillingAddressButton
        }
        canEditAddress={canEditAddress}
        canDeleteAddress={canDeleteAddress}
        isSharedFromOtherBuyer={
          visibility === ADDRESS_VISIBILITY.SHARED &&
          addressBuyerId.trim() !== buyerId.trim()
        }
        isAssignedToDeliveryAddress={isAssignedToDeliveryAddress}
        isSelected={selectedAddressId === id}
        showMakePrimaryButton={showMakePrimaryButton}
        {...rest}
      />,
    ]
  })
}
