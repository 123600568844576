import PropTypes from 'prop-types'
import { ENGLISH } from '~/redux/constants'
import { getFailedMessage } from '~/services/failedMessageService'
import Box from '~/components/shared/Box'
export function ManageAddressesFetchFailed({ language = ENGLISH }) {
  const message = getFailedMessage(language)
  return <Box data-test="could-not-fetch-addresses-error">{message}</Box>
}

ManageAddressesFetchFailed.propTypes = {
  language: PropTypes.string,
}
