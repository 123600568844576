import PropTypes from 'prop-types'
import ShipToItem from 'shared/ShipToComponent/ShipToListContainer/ShipToItem'

function ShipToList({
  accounts,
  open,
  onSelectItem,
  selectedItemIndex,
  itemSelectedClass,
}) {
  return accounts.map((account, index) => {
    const { label, isFavorite, clientNo, clientId } = account
    const isSelected = selectedItemIndex === index
    const selectedClass = isSelected ? itemSelectedClass : ''
    return (
      <ShipToItem
        key={clientNo}
        id={clientNo}
        guidId={clientId}
        onSelectItem={onSelectItem}
        isSelected={isSelected}
        className={selectedClass}
        isFavorite={isFavorite}
        open={open}
      >
        {label}
      </ShipToItem>
    )
  })
}

ShipToList.propTypes = {
  accounts: PropTypes.array,
  itemSelectedClass: PropTypes.string,
  onSelectItem: PropTypes.func,
  open: PropTypes.bool,
}

ShipToList.defaultProps = {
  accounts: [],
}

export default ShipToList
