export default {
  en: {
    selectBuyerModalDesc:
      'Select the buyers from the list below with whom you wish to share this address.',
    selectBuyers: 'Search buyers',
    cancel: 'Cancel',
    select: 'Select',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    noDataFound: 'Oops, no data found.',
    buyerList: 'Buyer list table',
    search: 'Search',
    buttons: 'Buttons section',
  },
  fr: {
    selectBuyerModalDesc:
      'Sélectionnez les acheteurs dans la liste ci-dessous avec qui vous souhaitez partager cette adresse.',
    selectBuyers: 'Rechercher des acheteurs',
    cancel: 'Annuler',
    select: 'Sélectionner',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    email: 'Courriel',
    noDataFound: 'Oups, aucune donnée disponible.',
    buyerList: 'Tableau de la liste des acheteurs',
    search: 'Chercher',
    buttons: 'Rubrique boutons',
  },
}
