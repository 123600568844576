export default {
  en: {
    recentlyViewedItemsLabel: 'Recently Viewed Items',
    improveResult: 'Help us improve our search results.',
    sendFeedback: 'Send feedback',
    emailSubject: 'Eway Search Feedback',
    emailBody: 'Please note there are no replies from this address.',
    itemsCount: 'Showing _TotalRecord_ items',
    resultsFor: 'Results for:',
    allProducts: 'All Products',
    bestMatch: 'Best Match',
    coreProducts: 'Core Products',
    priceLowToHigh: 'Price (Low to High)',
    priceHightoLow: 'Price (High to Low)',
    mediumDescriptionAtoZ: 'Description (A to Z)',
    mediumDescriptionZtoA: 'Description (Z to A)',
    productNumberAtoZ: 'Product Number (A to Z)',
    productNumberZtoA: 'Product Number (Z to A)',
    manufacturerAtoZ: 'Manufacturer (A to Z)',
    manufacturerZtoA: 'Manufacturer (Z to A)',
    reviewsRatingAtoZ: 'Reviews Rating (A to Z)',
    reviewsRatingZtoA: 'Reviews Rating (Z to A)',
    sortBy: 'Sort by',
    youHaveSelected: `You've Selected`,
    home: 'Home',
    sectionSearchDefValCat: 'CATEGORY',
    sectionSearchDefValBrand: 'BRANDS',
    sectionSearchDefValRate: 'RATING',
    shoppingListDetail: 'Shopping list detail',
    expertSuggestionLabel: 'Our experts suggest',
    filterRemovedMessage:
      'Your initial search did not return any matches from the Standing Offer Products. The following item(s) from the remainder of the on-line catalogue meet your criteria.',
    ratingBox: 'rating',
    shippingBoxFinder: 'Shipping Box Finder',
    shippingBoxFinderText: 'Staples has all the boxes you need to pack & ship',
    boxLength: 'Shipping Box Length',
    boxWidth: 'Shipping Box Width',
    boxHeight: 'Shipping Box Height',
    ShippingBoxHeightAsc: 'Shipping Box Height asc',
    inkAndTonerHeading: 'Ink & Toner Finder',
  },
  fr: {
    recentlyViewedItemsLabel: 'Articles récemment consultés',
    improveResult: 'Aidez-nous à améliorer nos résultats de recherche.',
    sendFeedback: 'Envoyer vos commentaires',
    emailSubject: 'Eway Search Commentaires',
    emailBody: `Veuillez noter qu'il n'y a pas de réponse à cette adresse.`,
    itemsCount: 'Affiche _TotalRecord_ articles',
    resultsFor: 'Résultats pour:',
    allProducts: 'Tous les produits',
    bestMatch: 'Meilleure correspondance',
    coreProducts: 'Produits soumission principale',
    priceLowToHigh: 'Prix (Bas à haut)',
    priceHightoLow: 'Prix (Haut à bas)',
    mediumDescriptionAtoZ: 'Description (A à Z)',
    mediumDescriptionZtoA: 'Description (Z à A)',
    productNumberAtoZ: 'Numéro de produit (A à Z)',
    productNumberZtoA: 'Numéro de produit (Z à A)',
    manufacturerAtoZ: 'Fournisseur (A à Z)',
    manufacturerZtoA: 'Fournisseur (Z à A)',
    reviewsRatingAtoZ: 'Avis de client (A à Z)',
    reviewsRatingZtoA: 'Avis de client (Z à A)',
    sortBy: 'Trier par',
    youHaveSelected: `ÉLÉMENTS CHOISIS`,
    home: 'Accueil',
    sectionSearchDefValCat: 'CATÉGORIE',
    sectionSearchDefValBrand: 'MARQUES',
    sectionSearchDefValRate: 'AVIS',
    shoppingListDetail: `Détails de la liste d'achat`,
    expertSuggestionLabel: 'Nos experts suggèrent',
    filterRemovedMessage:
      "Votre recherche initiale n'a pas retourné de correspondance des produits de l'offre à commandes. Les articles suivants du reste du catalogue en ligne répondent à vos critères.",
    ratingBox: 'avis',
    shippingBoxFinder: "Chercheur de boîtes d'expédition",
    shippingBoxFinderText:
      'Staples a toutes les boîtes dont vous avez besoin pour emballer et expédier',
    boxLength: 'Longueur De La Boîte (Po)',
    boxWidth: 'Largeur De La Boîte (Po)',
    boxHeight: 'Hauteur De La Boîte (Po)',
    ShippingBoxHeightAsc: 'Hauteur de la boîte (po) asc',
    inkAndTonerHeading: `Recherche d'encre et de toner`,
  },
}
