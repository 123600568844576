export default {
  en: {
    enterEmailAddressLabel: 'Enter email address',
    submitLabel: 'Submit',
    noteLabel:
      'Enter your e-mail address in the field above and click on the submit button. You will receive an e-mail containing the User ID(s) associated to your e-mail address.',
    requiredUserIdMsg: 'Please enter a valid email address',
    userIdIsSentMsg: 'The User ID list has been emailed to ',
    invalidAddress: 'The email address you entered is invalid',
  },
  fr: {
    enterEmailAddressLabel: 'Entrez votre adresse courriel',
    submitLabel: 'Soumettre',
    noteLabel:
      "Entrez votre adresse courriel dans le champ ci-dessus et cliquez sur le bouton Envoyer. Vous recevrez un courriel contenant le(s) nom(s) d'utilisateur(s) associé(s) à votre adresse courriel.",
    requiredUserIdMsg: 'Veuillez entrer une adresse courriel valide',
    userIdIsSentMsg: `La liste de(s) nom(s) d'utilisateur(s) a été envoyée par courriel à `,
    invalidAddress: `L'adresse courriel entrée est invalide`,
  },
}
