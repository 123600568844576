import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import useWindowDimensions from 'shared/CustomHooks/useWindowDimensions'
import Icon from 'shared/Icon'
import ListItem from 'shared/ListItem'
import { BREAK_POINTS } from 'shared/Utils/constants'
import EwayLink from '~/components/shared/EwayLink'
import styles from './FooterItemList.module.scss'

/**
 *Name: FooterItem
 *Desc: Render footer item
 * @param {*} data
 * @param {*} isActive
 * @param {*} activeItemHandler
 * @returns Element
 */
const FooterItemList = ({ data, isActive, onClick }) => {
  const { width } = useWindowDimensions()
  const collapseStatus = width > BREAK_POINTS.sm ? true : isActive

  const headingClickHandler = () => {
    const activeItem = isActive ? '' : data.title
    onClick?.(activeItem)
  }

  return (
    <div className={styles.footerItem}>
      <button onClick={headingClickHandler} onKeyPress={headingClickHandler}>
        {data.title}
        <IconArrow isActive={isActive} />
      </button>

      <Collapse isOpened={collapseStatus}>
        <ul className={styles.list}>
          {data.items.map((item, index) => {
            return (
              <ListItem key={`${index}-${item.title}`}>
                <EwayLink
                  href={item.link}
                  target={item.target}
                  data-test={item.dataTest}
                >
                  {item.linkText}
                </EwayLink>
              </ListItem>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

// Props Validation
FooterItemList.propTypes = {
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

export { FooterItemList }

function IconArrow({ isActive }) {
  const activeClass = isActive ? styles.active : ''
  return (
    <Icon className={`${styles.icon} ${activeClass}`} variant="arrowDown" />
  )
}
// Props Validation
IconArrow.propTypes = {
  isActive: PropTypes.bool,
}
