/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import styles from './AutoSuggestionList.module.scss'

/**
 * Name: AutoSuggestionList
 * Decs: Render autoSuggestionList
 * @param {array} suggestions
 * @param {func} onItemClick
 * @param {number} currentIndex
 * @param {fun} onMouseEnter
 */
const AutoSuggestionList = ({
  suggestions,
  onItemClick,
  currentIndex,
  onMouseEnter,
}) => {
  return !suggestions.length ? null : (
    <div className={styles.searchAutoList}>
      <ul>
        {suggestions.map((item, index) => {
          const events = {
            onClick: (e) => onItemClick(e, item),
          }
          // TODO: transform to eway link
          return (
            <ListItem
              key={index}
              className={index === currentIndex ? styles.selected : ''}
            >
              <a href="#" onMouseEnter={() => onMouseEnter(index)} {...events}>
                {item}
              </a>
            </ListItem>
          )
        })}
      </ul>
    </div>
  )
}

// Default Props
AutoSuggestionList.defaultProps = {
  suggestions: [],
}

// Props Validation
AutoSuggestionList.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  currentIndex: PropTypes.number,
  onMouseEnter: PropTypes.func,
}

export default AutoSuggestionList
