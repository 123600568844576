import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ListItem from 'shared/ListItem'
import Container from '~/components/shared/Container'
import { dynamicTranslation } from '~/components/shared/DynamicTranslation/DynamicTranslationUtils'
import EwayLink from '~/components/shared/EwayLink'
import Icon from '~/components/shared/Icon'
import styles from './FooterConnect.module.scss'
import { ACCESSIBILITY_URL } from './FooterConnectConstants'
import messages from './messages'

/**
 *Name: FooterConnect
 *Desc: Render footer item
 * @param {*} language
 * @param {*} enableSocialWidget
 * @param {*} isLoggedIn
 * @returns Element
 */
const FooterConnect = ({ language, enableSocialWidget, isLoggedIn }) => {
  const accessibilityUrl = useMemo(
    () =>
      dynamicTranslation(ACCESSIBILITY_URL, {
        '{lang}': language,
      }),
    [language]
  )
  const translations = messages[language]
  const target = '_blank'

  // Social Icons array
  const socialIcons = [
    {
      target,
      icon: 'linkedin',
      title: translations.linkedInLabel,
      link: 'https://www.linkedin.com/company/staplesprofessional/',
      dataTest: 'linkedin-link',
      id: 'linkedin-link',
    },
    {
      target,
      icon: 'twitter',
      title: translations.twitterLabel,
      link: 'https://twitter.com/StaplesProf',
      dataTest: 'twitter-link',
      id: 'twitter-link',
    },
    {
      target,
      icon: 'facebook',
      title: translations.facebookLabel,
      link: 'https://www.facebook.com/StaplesProfessional',
      dataTest: 'facebook-link',
      id: 'facebook-link',
    },
    {
      target,
      icon: 'youtube',
      title: translations.youTubeLabel,
      link: 'https://www.youtube.com/user/staplesevents/featured',
      dataTest: 'youtube-link',
      id: 'youtube-link',
    },
    {
      target,
      icon: 'instagram',
      title: translations.instagramLabel,
      link: 'https://www.instagram.com/staplesprofessional/',
      dataTest: 'instagram-link',
      id: 'instagram-link',
    },
  ]

  // Logos array
  const logoInfo = [
    {
      target,
      imgUrl: '/static/img/bullfrogpower.svg',
      title: translations.bullFrogPowerLogoLabel,
      link: 'https://www.bullfrogpower.com/',
      longDesc: '/static/long-desc/longdesc.html',
      dataTest: 'bullfrog-link',
      id: 'bullfrog-link',
    },
    {
      target,
      imgUrl: '/static/img/accessibility-logo.svg',
      title: translations.accessibilityLogoLabel,
      link: accessibilityUrl,
      longDesc: '/static/long-desc/longdesc.html',
      dataTest: 'essential-accessibility-link',
      id: 'essential-accessibility-link',
    },
  ]

  const showSocialWidget = !isLoggedIn || (isLoggedIn && enableSocialWidget)

  return (
    <div className={styles.footerConnect}>
      <Container>
        <div className={`${styles.flex}`}>
          <div className={styles.connectBlock}>
            {showSocialWidget && (
              <>
                <p>{translations.joinUsOnLabel}</p>
                <ul className={styles.list}>
                  {socialIcons.map((data) => {
                    return (
                      <ListItem key={data.id}>
                        <EwayLink
                          href={data.link}
                          title={data.title}
                          target={data.target}
                          data-test={data.dataTest}
                          rel="noopener noreferrer"
                        >
                          <span>
                            <Icon variant={data.icon} />
                          </span>
                        </EwayLink>
                      </ListItem>
                    )
                  })}
                </ul>
              </>
            )}
          </div>
          <div className={styles.connectBlock}>
            <ul className={`${styles.list} ${styles.listBlock}`}>
              {logoInfo.map((data) => {
                return (
                  <ListItem key={data.id}>
                    <EwayLink
                      href={data.link}
                      target={data.target}
                      data-test={data.dataTest}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data.imgUrl}
                        title={data.title}
                        alt={data.title}
                        longdesc={data.longDesc}
                      />
                    </EwayLink>
                  </ListItem>
                )
              })}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

// Props Validation
FooterConnect.propTypes = {
  language: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  enableSocialWidget: PropTypes.bool,
}

// Default Props
FooterConnect.defaultProps = {
  isLoggedIn: false,
  enableSocialWidget: true,
}

export default FooterConnect
