import { closeAllNotification } from '~/components/shared/Alerts/AlertNotificationUtils'
import { filterAllMessage } from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationFilteringUtils'

/**
 * Callback that handles what happens after a success add to cart
 * @param {object} cartData
 * @param {function} setCartMessagesStateFn
 * @param {function} clearCartMessages
 */
export const onAddToCartResponse = async (
  cartData,
  setCartMessagesStateFn,
  clearCartMessages // TODO: Does not seem to be used in parent calls, to remove
) => {
  closeAllNotification()
  const messages = cartData?.CartMessages
  const messageDataText = {
    ['data-test']: 'message-text',
  }
  setCartMessagesStateFn?.(filterAllMessage(messages, messageDataText))
  clearCartMessages?.(setCartMessagesStateFn)
}
