import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styles from './MenuList.module.scss'
import MenuListItem from './MenuListItem'
import { isProduction } from '~/config/env/environmentUtils'

const MenuList = forwardRef(
  ({ items, activeIndex, onMouseEnter, activeItemClass = '' }, ref) => {
    return (
      <ul className={styles.accountMenuList} ref={ref}>
        {items.map((item, index) => {
          const { linkText, link } = item
          const handleMouseEnter = () => {
            onMouseEnter(index)
          }
          return (
            <MenuListItem
              key={item.id}
              data-test={linkText}
              isActive={index === activeIndex}
              text={linkText}
              href={link}
              activeItemClass={activeItemClass}
              onMouseEnter={handleMouseEnter}
            />
          )
        })}
      </ul>
    )
  }
)

// Default Props
MenuList.defaultProps = {
  items: [],
}

if (!isProduction()) {
  MenuList.displayName = 'MenuList'
}

MenuList.propTypes = {
  activeIndex: PropTypes.number,
  items: PropTypes.array,
  onMouseEnter: PropTypes.func,
  activeItemClass: PropTypes.string,
}

export default MenuList
