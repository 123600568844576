import classNames from 'classnames'
import PropTypes from 'prop-types'
import ListItem from 'shared/ListItem'
import EwayLink from '../../EwayLink'
import styles from './MenuListItem.module.scss'

export function MenuListItem({
  text,
  href,
  isActive,
  activeItemClass = '',
  onMouseEnter,
  ...rest
}) {
  const classes = classNames(styles.menuListItem)
  const linkClasses = classNames(styles.link, {
    [activeItemClass || styles.linkActive]: isActive,
  })

  return (
    <ListItem {...rest} className={classes}>
      <EwayLink
        className={linkClasses}
        rel="noopener noreferrer"
        href={href}
        onMouseEnter={onMouseEnter}
      >
        {text}
      </EwayLink>
    </ListItem>
  )
}

MenuListItem.propTypes = {
  cursor: PropTypes.string,
  dataTest: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.node,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  activeItemClass: PropTypes.string,
}

export default MenuListItem
