export default {
  en: {
    edit: 'Edit',
    delete: 'Delete',
    makePrimary: 'Make Primary',
    primary: 'Primary',
    select: 'Select',
    privateHeading: 'Private',
    sharedHeading: 'Shared',
    globalHeading: 'Global',
    privateContent: 'Private addresses are visible to you only.',
    sharedContent:
      'Shared addresses are visible to you and the buyers you have shared them with.',
    customSharedContent:
      'This address was created by {fullName} and shared with you',
    globalContent:
      'Global addresses are visible to you and all buyers in your organization.',
    setDefaultBillingAddress: 'Set default billing address',
    assignDelivery: 'Assign to delivery addresses',
    editDelivery: 'Edit delivery address assignment',
  },
  fr: {
    edit: 'Modifier',
    delete: 'Supprimer',
    makePrimary: 'Rendre principale',
    primary: 'Adresse principale',
    select: 'Sélectionner',
    privateHeading: 'Privée',
    sharedHeading: 'Partagée',
    globalHeading: 'Globale',
    privateContent: 'Les adresses privées ne sont visibles que par vous.',
    sharedContent:
      'Les adresses partagées sont visibles pour vous et les acheteurs avec qui vous les avez partagées.',
    customSharedContent:
      'Cette adresse a été créée par {fullName} et partagée avec vous.',
    globalContent:
      'Les adresses globales sont visibles pour vous et tous les acheteurs de votre organisation.',
    setDefaultBillingAddress: "Définir l'adresse de facturation par défaut",
    assignDelivery: `Assigner aux adresses de livraison`,
    editDelivery: `Modifier l'assignation de l'adresse de livraison`,
  },
}
