import { FIELDS } from './CheckoutAddressConstants'
//TODO: field messages will be used from the BillingAddressFields
import { FIELDS as SAVE_ADDRESS_FIELDS } from 'shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'
import messages from 'shared/ManageAddresses/SaveAddressForm/BillingAddressFields/messages'
export default {
  en: {
    [FIELDS.COMPANY_NAME]: messages.en[SAVE_ADDRESS_FIELDS.COMPANY_NAME],
    [FIELDS.ATTENTION]: 'Attention',
    [FIELDS.STREET_ADDRESS]: 'Street Address',
    [FIELDS.BUILDING]: 'Building/Floor/Suite/Room',
    [FIELDS.CITY]: 'City',
    [FIELDS.PROVINCE]: 'Province',
    [FIELDS.POSTAL_CODE]: 'Postal Code',
    requiredMsg: 'This field is required.',
    invalidPostalCode:
      'The postal code entered is invalid. Please ensure the postal code is entered using one of the following formats: A1A1A1 OR A1A 1A1 (where A is a letter and 1 is a digit).',
  },
  fr: {
    [FIELDS.COMPANY_NAME]: messages.fr[SAVE_ADDRESS_FIELDS.COMPANY_NAME],
    [FIELDS.ATTENTION]: 'Attention',
    [FIELDS.STREET_ADDRESS]: 'Adresse civique',
    [FIELDS.BUILDING]: 'Édifice/étage/suite/bureau',
    [FIELDS.CITY]: 'Ville',
    [FIELDS.PROVINCE]: 'Province',
    [FIELDS.POSTAL_CODE]: 'Code postal',
    requiredMsg: 'Ce champ est requis.',
    invalidPostalCode:
      "Le code postal saisi n'est pas valide. Veuillez-vous assurer que le code postal est entré en utilisant l'un des formats suivants : A1A1A1 OU A1A 1A1 (où A est une lettre et 1 est un chiffre).",
  },
}
