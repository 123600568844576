import PropTypes from 'prop-types'
import Dialog from 'shared/Dialog'
import Box from '~/components/shared/Box'
import styles from './SelectBuyersModal.module.scss'
import { SELECT_BUYERS_MODAL_ID } from './SelectBuyersModalConstant'

/**
 *Name: SelectBuyersModal
 *Desc: Render SelectBuyersModal
 * @param {node} children
 * @param {string} variant
 * @param {bool} open
 */

export const SelectBuyersModal = ({ open, variant, children }) => {
  return (
    <Dialog
      id={SELECT_BUYERS_MODAL_ID}
      open={open}
      variant={variant}
      data-test="select-buyer-modal"
    >
      <Box className={styles.selectBuyerModalWrapper}>{children}</Box>
    </Dialog>
  )
}

// Default Props

// PropType validation
SelectBuyersModal.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node,
}
