export async function checkChatServiceAvailable() {
  const STOP_AFTER_10_SECONDS = 10000
  let interval
  let timeout

  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (window.insideFrontInterface) {
        resolve(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 500)

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn(
        'Could not instantiate Live Help chat button after more than 10 seconds, usually means the liveHelp url is not included, check configurations'
      )
      clearInterval(interval)
      resolve(false)
    }, STOP_AFTER_10_SECONDS)
  })
}

export const openLiveChat = () => {
  window.insideFrontInterface.openChatPane()
}
