export const HAMBURGER_NAV = {
  SET_MENU_ACTIVE: 'SET_MENU_ACTIVE',
  SET_MENU_LIST_ACTIVE: 'SET_MENU_LIST_ACTIVE`',
  SET_CHILD_MENU_ITEM: 'SET_CHILD_MENU_ITEM',
}

export const hamburgerInitialState = {
  isMenuActive: false,
  isShowMainList: true,
  childMenuItem: {},
}
