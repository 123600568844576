import PropTypes from 'prop-types'
import Heading from 'shared/Heading'
import MenuClose from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/MenuClose'
import { useSupportMenuContext } from '../../SupportMenuUtils'
import { SHOW_USER_LOGIN } from '../signInConstants'
import styles from './SignInTopBar.module.scss'

/**
 * Name: SignInTopBar
 * Desc: Render top bar of panel
 * @param {string} language
 * @param {string} title
 * @param {number} currentState
 * @param {func} setCurrentState
 * @param {func} onCloseMenu
 */

const SignInTopBar = ({ language, title, currentState, setCurrentState }) => {
  const { closeMenu } = useSupportMenuContext()
  const handleMenuClose = () => {
    if (currentState !== SHOW_USER_LOGIN) {
      setCurrentState(SHOW_USER_LOGIN)
      return
    }
    closeMenu()
  }
  return (
    <div className={styles.SignInTopBarFlex}>
      <Heading text={title} as="h5" />
      <MenuClose onCloseMenu={handleMenuClose} language={language} />
    </div>
  )
}

// Props Validation
SignInTopBar.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentState: PropTypes.number.isRequired,
  setCurrentState: PropTypes.func.isRequired,
}

export default SignInTopBar
