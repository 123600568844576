import ContentLoader from 'react-content-loader'

const TableSkeleton = () => (
  <ContentLoader height="300" style={{ width: '100%' }}>
    <rect x="0" y="5" rx="0" ry="0" width="100%" height="50"></rect>

    <rect x="0" y="75" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="75" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="75" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="75" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="75" rx="0" ry="55" width="18%" height="19"></rect>

    <rect x="0" y="110" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="110" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="110" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="110" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="110" rx="0" ry="55" width="18%" height="19"></rect>

    <rect x="0" y="145" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="145" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="145" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="145" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="145" rx="0" ry="55" width="18%" height="19"></rect>

    <rect x="0" y="180" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="180" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="180" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="180" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="180" rx="0" ry="55" width="18%" height="19"></rect>

    <rect x="0" y="215" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="215" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="215" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="215" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="215" rx="0" ry="55" width="18%" height="19"></rect>

    <rect x="0" y="250" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="20%" y="250" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="41%" y="250" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="62%" y="250" rx="0" ry="55" width="18%" height="19"></rect>
    <rect x="82%" y="250" rx="0" ry="55" width="18%" height="19"></rect>
  </ContentLoader>
)

export default TableSkeleton
