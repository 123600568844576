export default {
  en: {
    youAreShoppingOn: `You are currently shopping under ship to account`,
    close: 'Close',
    doNotShow: 'Do not show this page for future sessions.',
    why: 'Why am I seeing this screen?',
    changeLabel: 'Change ship-to account',
    costCenter: 'Cost Center',
    clickMe: 'click me',
    findAccount: 'Find Account',
    changeShipToSingle: `To change your ship-to account, click on the {link} tab`,
    changeShipToMultiple: `To change your ship-to account, click on the {link} tab OR select an account from the drop down list below`,
    tooltipText: `The ship to address that you have selected can impact the distribution center and availability that is shown during your shopping experience.`,
  },
  fr: {
    youAreShoppingOn: 'Présentement, vous magasinez pour ce compte',
    close: 'Fermer',
    doNotShow: 'Ne pas montrer cette page dans les sessions futures.',
    why: `Pourquoi cet écran s'affiche-t-il?`,
    changeLabel: 'Changez votre compte',
    costCenter: 'Centre de coût',
    clickMe: 'cliquez moi',
    findAccount: 'Trouver un compte',
    changeShipToSingle: `Pour changer votre sous-compte, cliquez sur l’onglet {link}`,
    changeShipToMultiple: `Pour changer votre sous-compte, cliquez sur l’onglet {link} OU sélectionnez un compte de la liste déroulante ci-dessous`,
    tooltipText: `L'adresse du compte sélectionnée peut avoir un impact sur le centre de distribution et la disponibilité affichée pendant votre expérience de magasinage.`,
  },
}
