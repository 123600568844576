import { Fragment } from 'react'
import ContentLoader from 'react-content-loader'
const SelectBuyersSkeleton = () => {
  const TableSkeleton = () => {
    const skeletonArray = Array.from('0123456')
    return skeletonArray.map((index) => (
      <Fragment key={`skeletonKey_${index}`}>
        {skeletonArray.map((childIndex) => (
          <rect
            key={`skeletonKey_${index}_${childIndex}`}
            x={`${26 * childIndex - 2}%`}
            y={70 + 35 * index}
            rx="0"
            ry="55"
            width="25%"
            height="19"
          ></rect>
        ))}
      </Fragment>
    ))
  }
  return (
    <ContentLoader height="300" style={{ width: '100%' }}>
      <rect x="0" y="5" rx="0" ry="0" width="100%" height="50"></rect>
      <TableSkeleton />
    </ContentLoader>
  )
}

export default SelectBuyersSkeleton
