export default {
  en: {
    signInLabel: 'Sign in',
    passwordReminderLabel: 'Password reminder',
    userIdReminderLabel: 'User ID reminder',
  },
  fr: {
    signInLabel: 'Ouvrir une session',
    passwordReminderLabel: 'Rappel du mot de passe',
    userIdReminderLabel: "Rappel du nom d'utilisateur",
  },
}
