import { getCdnUrl } from '~/services/utils/configUtils'
const CDN_URL = getCdnUrl()

export default {
  en: {
    customerServiceLabel: 'Customer Service',
    helpCenterLabel: 'Help Center',
    helpCenterUrl: '/Help/HelpCentre.aspx?HelpCentreSection=0&Language=en-CA',

    contactUsLabel: 'Contact Us',
    contactUsUrl: '/en/Info/Help/ContactUs?from=Footer',

    returnItemLabel: 'Return an Item',
    returnItemUrl: '/en/Orders/Submitted/returns',

    recallInformationLabel: 'Recall Information',
    recallInformationUrl: '/Recalls/RecallInformation.aspx?Language=en-CA',

    safetyDataSheetsLabel: 'Safety Data Sheets',
    safetyDataSheetsUrl: `${CDN_URL}/content/helpcenter/react/{lang}/HelpCentre/Content/Help%20Topics/Safety%20Data%20Sheets%20(SDS).htm`,

    corporateInfoLabel: 'Corporate Info',
    staplesBusinessLabel: 'Staples Professional Overview',
    staplesBusinessUrl: 'https://www.staplesprofessional.ca/',

    industriesServedLabel: 'Industries Served',
    industriesServedUrl:
      'https://www.staplesprofessional.ca/industries-served/',

    blogLabel: 'Blog',
    blogUrl: 'https://www.staplesprofessional.ca/blog/',

    ourBrandsLabel: 'Our Brands',
    ourBrandsUrl: 'https://www.staplesprofessional.ca/our-new-brands/',

    staplesCorporateLabel: 'Staples Corporate Solutions',
    officeProductsLabel: 'Office Products',
    officeProductsUrl: 'https://www.staplesprofessional.ca/office-products/',

    technologyProductsLabel: 'Technology Products',
    technologyProductsUrl:
      'https://www.staplesprofessional.ca/technology-solutions/',

    furnitureLabel: 'Furniture',
    furnitureUrl: 'https://www.staplesprofessional.ca/furniture-products/',

    facilitiesLabel: 'Facilities',
    facilitiesUrl: 'https://www.staplesprofessional.ca/facility-solutions/',

    promotionalProductsLabel: 'Promotional Products',
    promotionalProductsUrl:
      'https://www.staplesprofessional.ca/promotional-products/',

    newCustomerLabel: 'New Customer',
    becomeACustomerLabel: 'Become a Customer',
    becomeACustomerUrl: 'https://www.staplesprofessional.ca/become-a-customer/',

    govtCustomersLabel: 'Govt. Customers',
    govtCustomersUrl:
      'https://www.staplesprofessional.ca/become-a-customer/government-customers/',

    membershipsLabel: 'Membership',
    membershipsUrl: 'https://www.staplesprofessional.ca/premium-next-gen/',

    joinStaplesTeamLabel: 'Join the Staples Team',
    careersLabel: 'Careers',
    careersUrl: 'https://careers.staples.ca/',
  },
  fr: {
    customerServiceLabel: 'Service à la clientèle',
    helpCenterLabel: "Centre d'aide",
    helpCenterUrl: '/Help/HelpCentre.aspx?HelpCentreSection=0&Language=fr-CA',

    contactUsLabel: 'Contactez-nous',
    contactUsUrl: '/fr/Info/Help/ContactUs?from=Footer',

    returnItemLabel: 'Retourner un article',
    returnItemUrl: '/fr/Orders/Submitted/returns',

    recallInformationLabel: 'Informations de rappel',
    recallInformationUrl: '/Recalls/RecallInformation.aspx?Language=fr-CA',

    safetyDataSheetsLabel: 'Fiche de données de sécurité',

    safetyDataSheetsUrl: `${CDN_URL}/content/helpcenter/react/{lang}/HelpCentre/Content/Rubriques%20d_aide/Fiches%20de%20donnees%20de%20securite%20(FDS).htm`,

    corporateInfoLabel: 'Informations corporatives',
    staplesBusinessLabel: 'À propos de Staples Professionnel',
    staplesBusinessUrl: 'https://www.staplesprofessionnel.ca/',

    industriesServedLabel: 'Qui nous servons',
    industriesServedUrl:
      'https://www.staplesprofessionnel.ca/industries-served/',

    blogLabel: 'Blogue',
    blogUrl: 'https://www.staplesprofessionnel.ca/blogue/',

    ourBrandsLabel: 'Nos marques',
    ourBrandsUrl: 'https://www.staplesprofessionnel.ca/our-new-brands/',

    staplesCorporateLabel: 'Solutions corporatives Staples',
    officeProductsLabel: 'Produits de bureau',
    officeProductsUrl: 'https://www.staplesprofessionnel.ca/office-products/',

    technologyProductsLabel: 'Produits de technologie',
    technologyProductsUrl:
      'https://www.staplesprofessionnel.ca/technology-solutions/',

    furnitureLabel: 'Ameublement de bureau',
    furnitureUrl: 'https://www.staplesprofessionnel.ca/furniture-products/',

    facilitiesLabel: "Produits et fournitures d'entretien",
    facilitiesUrl: 'https://www.staplesprofessionnel.ca/facility-solutions/',

    promotionalProductsLabel: 'Articles promotionnels',
    promotionalProductsUrl:
      'https://www.staplesprofessionnel.ca/promotional-products/',

    newCustomerLabel: 'Nouveaux clients',
    becomeACustomerLabel: 'Devenez client',
    becomeACustomerUrl:
      'https://www.staplesprofessionnel.ca/become-a-customer/',

    govtCustomersLabel: 'Clients gouvernementaux',
    govtCustomersUrl:
      'https://www.staplesprofessionnel.ca/become-a-customer/government-customers/',

    membershipsLabel: 'Programme de fidélisation',
    membershipsUrl: 'https://www.staplesprofessionnel.ca/premium-next-gen/',

    joinStaplesTeamLabel: "Joignez-vous à l'équipe Staples",
    careersLabel: 'Carrières',
    careersUrl: 'https://carrieres.bureauengros.ca/',
  },
}
