import { EVENT_TYPES } from 'shared/Utils/formUtilsConstants'
import { EXCEPTION_POSTAL_CODE_PREFIX } from '~/components/shared/PostalCodeEntryForm/PostalCodeEntryFormConstants'
import { isValidPostalCode } from '~/services/utils/regexUtils'

export const onValidate = ({ values, dependencies, eventType }) => {
  const newErrors = {}
  const { fields, language, messages } = dependencies
  Object.keys(values).forEach((fieldName) => {
    const translations = messages[language]
    if (fields[fieldName]?.mandatory && !values[fieldName]) {
      newErrors[fieldName] = translations.requiredMessage
    } else {
      if (eventType === EVENT_TYPES.SUBMIT || eventType === EVENT_TYPES.BLUR) {
        if (
          fields[fieldName].postalCode &&
          !isValidPostalCode(values[fieldName])
        ) {
          newErrors[fieldName] = translations.invalidPostalCode
        }
        if (
          fields[fieldName].validateWhenExceptionsPostalCode &&
          isExceptionsPostalCode(values.postalCode) &&
          !values[fieldName]
        ) {
          newErrors[fieldName] = translations.requiredMessage
        }
      }
    }
  })
  return newErrors
}

export const isExceptionsPostalCode = (postalCode = '') => {
  if (postalCode && isValidPostalCode(postalCode)) {
    const firstThreeDigit = postalCode.substring(0, 3)
    return firstThreeDigit.toUpperCase() === EXCEPTION_POSTAL_CODE_PREFIX
  }
  return false
}
