import isEmpty from 'lodash/isEmpty'
import { SUCCESS_FALSE } from '~/api/apiHelperConstants'
import { mapKeysForApi } from '~/api/apiMappingUtils'
import { getClientInfo } from '~/api/client/clientApiUtils'
import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import {
  mapAddressApiKeyWithFormKey,
  getAddressActions,
} from '~/components/shared/ManageAddresses/ManageAddressesUtils'
import {
  ADDRESS_TYPES_VIEW_LEVEL,
  ADDRESS_VISIBILITY,
  API_MAPPING_TYPE,
  BILLING_API_MAPPING,
  DELIVERY_API_MAPPING,
} from '~/components/shared/ManageAddresses/SaveAddressForm/SaveAddressFormConstants'

import {
  addSelectedBuyersIdOnAddress,
  create,
  get,
  remove,
  update,
  updateAddressLockedFlag,
  assignDeliveryAddress,
  fetchAssociatedBillingAddresses,
  fetchAssociatedCostCenters,
  deleteCostCenters,
} from './ManageAddressesApiUtils'

/**
 *
 * @param {Object} params
 * @param {number} params.buyerId
 * @param {string} params.principalClientId
 * @param {string} params.addressGroup
 */
export async function getAddresses({
  buyerId = '',
  addressLevel = ADDRESS_TYPES_VIEW_LEVEL.PRIVATE,
  principalClientId = '',
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS,
} = {}) {
  // Bail early if no buyerId or principalClientId
  if (!buyerId || !principalClientId) {
    return {
      isSuccess: false,
    }
  }
  const args = [buyerId, principalClientId, addressLevel, addressGroup]
  const { addresses = [], isSuccess } = (await get(...args)) || {}
  const addressesWithPermissions = isSuccess
    ? addresses.map((address) => {
        const { visibility, buyerId: addressBuyerId } = address
        const mainActions = getAddressActions({
          addressLevel,
          addressBuyerId,
          buyerId,
          visibility,
          addressGroup,
        })
        return {
          ...address,
          ...mainActions,
          hasAssignment: !isEmpty(address.deliveryAddressBillingAddress),
        }
      })
    : []

  const filteredAddresses = filterAddressesUsingLevels({
    addresses: addressesWithPermissions,
    addressLevel,
    buyerId,
  })

  const formattedAddresses =
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS
      ? filteredAddresses.map((deliveryAddress) => {
          const {
            defaultBillingAddressValues: [
              {
                defaultBillingAddressId: defaultBillingAddressIdValue = '',
              } = {},
            ] = [],
          } = deliveryAddress
          const { defaultBillingAddressId = defaultBillingAddressIdValue } =
            deliveryAddress
          const finalDefaultBillingAddressId =
            defaultBillingAddressIdValue || defaultBillingAddressId

          const { deliveryAddressBillingAddress } = deliveryAddress
          const assignedBillingIds =
            deliveryAddressBillingAddress?.map(
              ({ billingAddressId }) => billingAddressId
            ) || []

          return {
            ...deliveryAddress,
            finalDefaultBillingAddressId,
            assignedBillingIds,
            hasAssignment: !!assignedBillingIds.length,
          }
        })
      : filteredAddresses

  return {
    addresses: formattedAddresses,
    isSuccess,
  }
}

export async function getAssociatedBillingAddresses(args) {
  const response = await fetchAssociatedBillingAddresses(args)
  const {
    isSuccess = false,
    addresses = [],
    hasAssignedAddresses = false,
  } = response

  const mappedAddresses = addresses.map((address) =>
    mapAddressApiKeyWithFormKey(address, ADDRESS_GROUPS.BILLING_ADDRESS)
  )

  return {
    isSuccess,
    addresses: mappedAddresses,
    hasAssignedAddresses,
  }
}

export async function getAssociatedCostCenters(args) {
  const response = await fetchAssociatedCostCenters(args)
  const {
    isSuccess = false,
    costCenters = [],
    hasAssignedCostCenters = false,
  } = response

  return {
    isSuccess,
    costCenters,
    hasAssignedCostCenters,
  }
}

export async function saveAddress(
  payload,
  checkedBuyers = [],
  addressGroup,
  canUseAsBilling = false
) {
  if (isEmpty(payload)) {
    return SUCCESS_FALSE
  }
  const apiMapping = API_MAPPING_TYPE[addressGroup]
  const finalPayload = [mapKeysForApi({ apiMapping, payload })]

  const { isSuccess, ...restResponse } = await create(
    finalPayload,
    addressGroup,
    canUseAsBilling
  )
  let address = {}
  if (addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS) {
    address = restResponse?.deliveryAddresses?.[0]
  } else {
    address = restResponse?.billingAddresses?.[0]
  }

  const { visibility } = payload
  const isSharedAddress =
    parseInt(visibility) === ADDRESS_VISIBILITY.SHARED &&
    checkedBuyers.length > 0
  if (isSuccess && isSharedAddress) {
    await addSelectedBuyersIdOnAddress(
      address.id,
      createSelectedBuyerPayload(checkedBuyers),
      addressGroup
    )
    if (canUseAsBilling) {
      await addSelectedBuyersIdOnAddress(
        address?.defaultBillingAddressId,
        createSelectedBuyerPayload(checkedBuyers),
        ADDRESS_GROUPS.BILLING_ADDRESS
      )
    }
  }
  const finalAddress = !isSuccess
    ? {}
    : mapAddressApiKeyWithFormKey(address, addressGroup)

  return {
    address: finalAddress,
    isSuccess,
  }
}

export const createSelectedBuyerPayload = (buyerIds) => {
  const selectedBuyerPayload = buyerIds.map((id) => {
    return { BuyerId: id }
  })
  return selectedBuyerPayload
}

const createLockedFieldPayload = (flags) => {
  return Object.entries(flags).map(([key, value]) => {
    return { op: 'replace', path: key, value }
  })
}

export async function deleteAddress(id, addressGroup) {
  if (!id) {
    return SUCCESS_FALSE
  }
  return await remove(id, addressGroup)
}

export async function updateAddress(
  payload,
  checkedBuyers = [],
  addressLockedInfo,
  addressGroup
) {
  if (isEmpty(payload)) {
    return SUCCESS_FALSE
  }
  const apiMapping =
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS
      ? DELIVERY_API_MAPPING
      : BILLING_API_MAPPING
  const updatePayload = mapKeysForApi({ apiMapping, payload })

  const finalPayload = Object.entries(updatePayload)
    .filter((item) => item[0] !== 'id')
    .map((entry) => {
      const [path, value] = entry
      const op = 'add'
      return { op, path, value }
    })
  const { visibility, id } = payload
  const isSharedAddress = parseInt(visibility) === ADDRESS_VISIBILITY.SHARED
  if (isSharedAddress) {
    await addSelectedBuyersIdOnAddress(
      id,
      createSelectedBuyerPayload(checkedBuyers),
      addressGroup
    )
  }
  const isAbleToLock =
    addressGroup === ADDRESS_GROUPS.DELIVERY_ADDRESS &&
    parseInt(visibility) >= ADDRESS_VISIBILITY.SHARED
  if (isAbleToLock) {
    updateAddressLockedFlag(
      id,
      createLockedFieldPayload(addressLockedInfo),
      addressGroup
    )
  }
  const { status, ...address } = await update(
    payload.id,
    finalPayload,
    addressGroup
  )
  const isSuccess = status === 200

  const finalAddress = !isSuccess
    ? {}
    : mapAddressApiKeyWithFormKey(address, addressGroup)
  return {
    address: finalAddress,
    isSuccess,
  }
}

export const getPrincipalClientId = async (principalClientNo) => {
  if (!principalClientNo) {
    return SUCCESS_FALSE
  }
  const { clientId, isSuccess } = await getClientInfo(principalClientNo)
  return {
    principleClientShipToId: clientId,
    isSuccess,
  }
}

export function filterAddressesUsingLevels({
  addresses = [],
  addressLevel,
  buyerId,
}) {
  if (addressLevel > ADDRESS_TYPES_VIEW_LEVEL.SELF) {
    return addresses
  }

  return addresses.filter(
    (address) => address.buyerId?.trim() !== buyerId.trim()
  )
}

export async function assignSelectedDeliveryAddress(assignedIds, addressId) {
  if (isEmpty(assignedIds)) {
    return SUCCESS_FALSE
  }
  const payload = assignedIds.map((deliveryAddressId) => {
    return {
      DeliveryAddress: deliveryAddressId,
      BillingAddressId: addressId,
    }
  })
  return await assignDeliveryAddress(payload, addressId)
}

export async function removeCostCenters(ids) {
  if (isEmpty(ids)) {
    return SUCCESS_FALSE
  }
  return await deleteCostCenters(ids)
}
