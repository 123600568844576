export default {
  en: {
    addNewAddressButton: 'Add new address',
    addNewDeliveryAddress: 'Add new delivery address',
    addNewBillingAddress: 'Add new billing address',
  },
  fr: {
    addNewAddressButton: 'Ajouter une nouvelle adresse',
    addNewDeliveryAddress: 'Ajouter une nouvelle adresse de livraison',
    addNewBillingAddress: 'Ajouter une nouvelle adresse de facturation',
  },
}
