import { sendRequest } from '~/api/apiHelper'
import { ENGLISH } from '~/redux/constants'
import { getHostUrl, getOldApiUrl } from 'services/utils/configUtils'

const OLD_API_URL = getOldApiUrl()
const HOST_URL = getHostUrl()
const MENU_TYPES = {
  PUBLICATION: 4,
  BRAND: 5,
  DEALS: 6,
}

const filterPublicationMenus = getLandingMenusByType(MENU_TYPES.PUBLICATION)
const filterBrandMenus = getLandingMenusByType(MENU_TYPES.BRAND)
const filterDealMenus = getLandingMenusByType(MENU_TYPES.DEALS)
export async function fetchPublicationAndBrandsAndDeals(language = ENGLISH) {
  const formatLandingMapper = getFormattedLandingMenusWithLanguage(language)
  const url = `${OLD_API_URL}/api/menu/Landing?isPublicationsMenu=true`

  try {
    const { SectionMenuLandingItems: landingMenuItems = [], status } =
      await sendRequest(url, {
        method: 'GET',
        headers: {
          'Accept-Language': `${language}-CA`,
          'Content-Type': 'application/json',
        },
      })
    const publications = landingMenuItems
      .filter(filterPublicationMenus)
      .map(formatLandingMapper)
    const brands = landingMenuItems
      .filter(filterBrandMenus)
      .map(formatLandingMapper)
    const deals = landingMenuItems
      .filter(filterDealMenus)
      .map(formatLandingMapper)
    return {
      isSuccess: status === 200,
      status,
      publications,
      brands,
      deals,
    }
  } catch (e) {
    return {
      isSuccess: false,
      status: 400,
      message: e,
      publications: [],
      brands: [],
      deals: [],
    }
  }
}

function getFormattedLandingMenusWithLanguage(language) {
  return function getFormattedLandingMenu(networkLandingMenu, index) {
    const { UrlName, MenuName, ImageName } = networkLandingMenu
    // Add index to prevent key conflicts in case of duplication MenuName
    return {
      id: `${MenuName}-${index}`,
      linkText: MenuName,
      link: `${HOST_URL}/${language}/Landing/${UrlName}`,
      image: ImageName,
    }
  }
}

function getLandingMenusByType(menuType) {
  return function (menu) {
    return menu.ShowInMenu === menuType
  }
}
