import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import {
  classMutationObserver,
  useKeyboardNavigation,
} from '~/components/shared/CustomHooks/useKeyboardNavigation'
import ListItem from '~/components/shared/ListItem'
import MenuClose from '../../../MenuClose'
import { useSupportMenuContext } from '../../../SupportMenuUtils'
import styles from '../AccountMenu.module.scss'

const secondaryAccountMenuMutationObserver = classMutationObserver(
  styles.activeSecondaryMenu
)
const AccountMenusSecondary = ({
  items,
  isMenuActive,
  language,
  onPrimaryActiveMenu,
}) => {
  const secondaryAccountMenusRef = useRef(null)
  const { closeMenu } = useSupportMenuContext()
  const [activeItemIndex, setActiveItemIndex] = useState(null)

  useKeyboardNavigation({
    items,
    isActive: isMenuActive,
    selectedItemIndex: activeItemIndex,
    setSelectedItemIndex: setActiveItemIndex,
    mutationObserver: secondaryAccountMenuMutationObserver,
    observingElement: secondaryAccountMenusRef.current,
    horizontalArrowHandler: onPrimaryActiveMenu,
    exitHandler: closeMenu,
  })

  useEffect(() => {
    isMenuActive && setActiveItemIndex(0)
    !isMenuActive && setActiveItemIndex(null)
  }, [isMenuActive])

  return (
    <ul
      className={`${styles.subList} ${styles.active}`}
      ref={secondaryAccountMenusRef}
    >
      {items.map((subLink, index) => {
        const activeClassName =
          index === activeItemIndex ? styles.activeSecondaryMenu : ''
        return index === 0 ? (
          <ListItem key="CloseMenu">
            <MenuClose
              language={language}
              onCloseMenu={closeMenu}
              className={activeClassName}
            />
          </ListItem>
        ) : (
          <ListItem key={subLink.linkText}>
            <a
              href={subLink.link}
              data-test={subLink.linkText}
              className={activeClassName}
            >
              {subLink.linkText}
            </a>
          </ListItem>
        )
      })}
    </ul>
  )
}

AccountMenusSecondary.propTypes = {
  items: PropTypes.array,
  isMenuActive: PropTypes.bool,
  language: PropTypes.string,
  onPrimaryActiveMenu: PropTypes.func,
}

export default AccountMenusSecondary
