import PropTypes from 'prop-types'
import Box from 'shared/Box'
import { AddNewAddressButton } from '~/components/shared/ManageAddresses/AddNewAddressButton'
import { AddressList } from '~/components/shared/ManageAddresses/AddressList'
import Flex from '~/components/shared/Flex'
import { ENGLISH } from '~/redux/constants'
import Heading from '../../Heading'
import Stack from '../../Stack'
import Text from '../../Text'
import styles from './ManageAddressListModal.module.scss'
import gridTabStyle from '../ManageAddressList/ManageAddressList.module.scss'
import { ADDRESS_GROUPS } from '~/components/containers/ManageAddressesContainer/ManageAddressConstants'
import messages from './messages'
import {
  useSearch,
  addressSearchFields,
} from '~/components/shared/CustomHooks/useSearchBox'
import { SearchBoxItem } from '~/components/shared/ManageAddresses/ManageAddressList/ManageAddressListUtils'
import Button from '~/components/shared/Button'
import {
  TABLE_VIEW,
  GRID_VIEW,
} from '~/components/shared/ProductCard/ProductCardConstants'
import { useState, useEffect } from 'react'
import { setSessionStorage, getSessionStorage } from '~/services/storageManager'
import ScrollBar from 'shared/ScrollBar'

function ManageAddressListModal({
  language = ENGLISH,
  variant = 'delivery',
  showHeaderElement = false,
  onClickAddNew,
  onClickEdit,
  onClickSelect,
  isLoading,
  isError,
  addresses,
  selectedAddressId,
  buyerId,
  canAddAddress = false,
  children,
  addressGroup = ADDRESS_GROUPS.DELIVERY_ADDRESS,
  disableAddNewAddressButton = false,
  addNewBillingAddressTooltip = '',
  ...rest
}) {
  const [viewType, setViewType] = useState(GRID_VIEW)
  const translations = messages[language]
  const heading = `${variant}AddressHeading`
  const savedHeading = `${variant}SavedAddresses`

  const addNewBillingButtonProps = addNewBillingAddressTooltip
    ? {
        'data-for': addNewBillingAddressTooltip,
        'data-tip': true,
      }
    : {}
  const { searchResult: filteredArray, onSearch } = useSearch(
    addresses,
    addressSearchFields
  )
  function handleOnClick({ currentTarget = {} } = {}) {
    setSessionStorage(variant, currentTarget.name)
    return setViewType(currentTarget.name)
  }
  useEffect(() => {
    if (getSessionStorage(variant)) {
      setViewType(getSessionStorage(variant))
    }
  }, [variant])
  return (
    <Box {...rest}>
      <Box className={styles.manageAddressWrapper}>
        {showHeaderElement ? (
          <Heading
            text={translations[heading]}
            as="h2"
            textAlign="center"
            fontSize="20px"
          />
        ) : null}
        <Box className={styles.addAddressButtonSection}>
          <Box display="flex" align="center" flexWrap="wrap" w="100%" pl="6px">
            <Box className={styles.search} mb="10px" mr="20px">
              <SearchBoxItem
                onSearch={onSearch}
                language={language}
                type={variant}
              />
            </Box>
            <Flex
              align="center"
              flexWrap="wrap"
              className={styles.sortingSection}
            >
              {canAddAddress ? (
                <Box
                  as="span"
                  {...addNewBillingButtonProps}
                  mb="10px"
                  mr="10px"
                >
                  <AddNewAddressButton
                    language={language}
                    onClick={onClickAddNew}
                    className={styles.modalButton}
                    addressGroup={addressGroup}
                    disabled={disableAddNewAddressButton}
                  />
                </Box>
              ) : null}
              <Box mb="0px">
                <Box className={gridTabStyle.listGridView} mb="10px">
                  <Button
                    title={translations.gridViewLabel}
                    text=""
                    icon="gridView"
                    name={GRID_VIEW}
                    onClick={handleOnClick}
                    className={
                      viewType === GRID_VIEW ? gridTabStyle.active : ''
                    }
                    data-test="GridViewBtn"
                  />
                  <Button
                    title={translations.tableViewLabel}
                    text=""
                    icon="calculator"
                    onClick={handleOnClick}
                    className={
                      viewType === TABLE_VIEW ? gridTabStyle.active : ''
                    }
                    name={TABLE_VIEW}
                    data-test="tableViewBtn"
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box pl="6px">
          <Text
            text={translations[savedHeading]}
            color="secondary"
            className={styles.modalHeading}
          />
        </Box>
        {filteredArray.length === 0 && addresses.length !== 0 ? (
          <Box p="20px">
            <Text
              variant="lgText"
              data-test="no-address-found-error"
              text={translations.noAddressesFoundMsg}
            />
          </Box>
        ) : null}
        <ScrollBar className={styles.addressListScrollbar}>
          <AddressList
            variant={variant}
            isLoading={isLoading}
            isError={isError}
            buyerId={buyerId}
            addresses={filteredArray}
            language={language}
            mb="20px"
            mt="15px"
            onEditAddressClick={onClickEdit}
            onSelectAddressClick={onClickSelect}
            selectedAddressId={selectedAddressId}
            viewType={viewType}
            className={styles.AddressList}
          />
        </ScrollBar>
        <Stack justify="flex-end" align="center" spacing="15px">
          {children}
        </Stack>
      </Box>
    </Box>
  )
}

ManageAddressListModal.propTypes = {
  addresses: PropTypes.array,
  showHeaderElement: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  language: PropTypes.string,
  buyerId: PropTypes.string,
  onClickAddNew: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickSelect: PropTypes.func,
  onClickAssignAddress: PropTypes.func,
  variant: PropTypes.string,
  selectedAddressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canAddAddress: PropTypes.bool,
  children: PropTypes.node,
  translations: PropTypes.object,
  addressGroup: PropTypes.string,
  disableAddNewAddressButton: PropTypes.bool,
  addNewBillingAddressTooltip: PropTypes.string,
}

export { ManageAddressListModal }
