import PropTypes from 'prop-types'
import Text from 'shared/Text'
import Icon from 'shared/Icon'
import styles from './SignInMessage.module.scss'

/**
 * Name: SignInMessage
 * Desc: Render error message
 * @param {string} text
 * @param {string} type
 * @param {boolean} isHTML
 */
const SignInMessage = (props) => {
  const { text, type, isHTML } = props
  let messageCls = {}
  if (type === 'success') {
    messageCls = {
      icon: 'check',
      class: `${styles.icon}`,
    }
  }
  if (type === 'error') {
    messageCls = {
      icon: 'close',
      class: `${styles.iconDanger} `,
    }
  }

  return (
    <div className={styles.signInMessage}>
      <span className={messageCls.class}>
        <Icon variant={messageCls.icon} />
      </span>
      {isHTML ? (
        <span
          dangerouslySetInnerHTML={{ __html: text }}
          className={styles.loginNotification}
        />
      ) : (
        <Text text={text} />
      )}
    </div>
  )
}

SignInMessage.defaultProps = {
  type: '',
  isHTML: false,
}

// Props validation
SignInMessage.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  isHTML: PropTypes.bool,
}

export default SignInMessage
