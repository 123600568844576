import { useToggler } from '~/components/shared/CustomHooks/useToggler'
import PropTypes from 'prop-types'
import ListItem from '~/components/shared/ListItem'
import NavBarCategoryListItemShow from '../NavBarCategoryListItemShow'
import HamburgerMenuCategoryListItem from '../HamburgerMenuCategoryList/HamburgerMenuCategoryListItem/HamburgerMenuCategoryListItem'
import HamburgerMenuCategoryListToggle from '../HamburgerMenuCategoryList/HamburgerMenuCategoryListToggle'
import styles from './MobileMenuItemList.module.scss'

function MobileMenuItemList({ items = [], heading }) {
  const { isOpen, toggle } = useToggler()
  const classes = isOpen ? styles.active : ''
  const hasItems = !!items.length
  return hasItems ? (
    <ListItem className={classes}>
      <HamburgerMenuCategoryListToggle
        onToggle={toggle}
        text={heading}
        isOpen={isOpen}
      />
      <NavBarCategoryListItemShow isOpen={isOpen} as="ul">
        {items.map((item) => {
          return (
            <HamburgerMenuCategoryListItem
              key={item.id}
              onClick={item.onClick}
              linkText={item.linkText}
              link={item.link}
            />
          )
        })}
      </NavBarCategoryListItemShow>
    </ListItem>
  ) : null
}

MobileMenuItemList.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.array,
}

export default MobileMenuItemList
