import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useRef, useState } from 'react'
import { nextTick } from '~/components/shared/Utils/formUtils'

const CustomPromptsContext = createContext({})
const { Provider } = CustomPromptsContext

export function CustomPromptsContextProvider({ children, productsLength }) {
  const [validateForms, setValidateForms] = useState(false)
  const productsCustomPromptsState = useRef(
    productsCustomPromptsDefaultState
  ).current
  productsCustomPromptsState.totalProducts = productsLength
  const runCustomPromptsValidations = useCallback(() => {
    productsCustomPromptsState.isValid = true
    productsCustomPromptsState.totalSubmittedForms = 0

    setValidateForms(true)
    nextTick(() => {
      setValidateForms(false)
    })
  }, [productsCustomPromptsState])
  return (
    <Provider
      value={{
        productsCustomPromptsState,
        validateForms,
        runCustomPromptsValidations,
      }}
    >
      {children}
    </Provider>
  )
}

CustomPromptsContextProvider.propTypes = {
  children: PropTypes.node,
  productsLength: PropTypes.number,
}

export function useCustomPromptsContext() {
  return useContext(CustomPromptsContext)
}

const productsCustomPromptsDefaultState = {
  isValid: true,
  totalProducts: 0,
  totalSubmittedForms: 0,
}

export default CustomPromptsContext
