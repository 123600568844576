import PropTypes from 'prop-types'
import classNames from 'classnames'
import Box from '~/components/shared/Box'
import styles from './NavBarCategoryListItemShow.module.scss'
import { forwardRef } from 'react'
import { isProduction } from '~/config/env/environmentUtils'

const NavBarCategoryListItemShow = forwardRef((props, ref) => {
  const { children, isOpen, animate = false, className, ...rest } = props
  const classes = classNames(
    className,
    animate ? styles.animateHidden : styles.defaultHidden,
    {
      [styles.animateVisible]: animate && isOpen,
      [styles.defaultVisible]: !animate && isOpen,
    }
  )
  return (
    <Box className={classes} {...rest} ref={ref}>
      {children}
    </Box>
  )
})

if (!isProduction()) {
  NavBarCategoryListItemShow.displayName = 'Button'
}

NavBarCategoryListItemShow.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

export default NavBarCategoryListItemShow
