import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Children, cloneElement } from 'react'
import Box from '~/components/shared/Box'
import { useNavBarContext } from '../NavBarMegaMenuUtils'
import styles from '../NavBarMegaMenu.module.scss'
import { NavBarPrimaryMegaMenuItem } from './NavBarPrimaryMegaMenuItem'

export function NavBarPrimaryMegaMenu({ children, ...props }) {
  const { primaryPanelListRef } = useNavBarContext()
  const { className, ...rest } = props
  const classes = classNames(styles.primaryDefault, className)
  const childrenWithIndex = Children.map(children, (element, index) => {
    return element?.type === NavBarPrimaryMegaMenuItem
      ? cloneElement(element, {
          ...element.props,
          index,
        })
      : element
  })

  return (
    <Box {...rest} ref={primaryPanelListRef} className={classes} as="ul">
      {childrenWithIndex}
    </Box>
  )
}

NavBarPrimaryMegaMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
