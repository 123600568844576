import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// To fix hydration mismatch
//https://github.com/vercel/next.js/issues/7417#issuecomment-509411508

const NoSSR = ({ children, fallback = null }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return <>{isMounted ? children : fallback}</>
}

NoSSR.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  fallback: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
}

export default NoSSR
