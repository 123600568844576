import PropTypes from 'prop-types'
import { purifyHtmlString } from 'services/utils'
import uniqueId from 'lodash/uniqueId'
import { getHostUrl } from 'services/utils/configUtils'
import Image from 'shared/Image'
import ListItem from 'shared/ListItem'
import Box from '~/components/shared/Box'
import CloseButton from '~/components/shared/CloseButton'
import Flex from '~/components/shared/Flex'
import { ENGLISH } from '~/redux/constants'
import messages from '../messages'
import NavBarChildCategoryList from './NavBarChildCategoryList'
import styles from './NavBarDesktop.module.scss'
import NavBarDesktopMenu from './NavBarDesktopMenu'
import {
  NavBarPrimaryMegaMenu,
  NavBarPrimaryMegaMenuItem,
} from './NavBarDesktopMenu/NavBarMegaMenu'
import {
  CATEGORY_SECONDARY_MENUS_SELECTOR,
  CLOSE_BUTTON_SELECTOR,
  getSecondaryFocusableElements,
} from './NavBarDesktopMenu/NavBarMegaMenu/NavBarMegaMenuKeyboardAccessible'
import { NavBarSecondaryMegaMenu } from './NavBarDesktopMenu/NavBarMegaMenu/NavBarMegaMenuSecondary/NavBarMegaMenuSecondary'
import { NavBarSecondaryMegaMenuItem } from './NavBarDesktopMenu/NavBarMegaMenu/NavBarMegaMenuSecondary/NavBarMegaMenuSecondaryItem'
import { useNavBarContext } from './NavBarDesktopMenu/NavBarMegaMenu/NavBarMegaMenuUtils'

const HOST_URL = getHostUrl()
const getCategorySecondaryMenusFocusableElements =
  getSecondaryFocusableElements(CATEGORY_SECONDARY_MENUS_SELECTOR)
const getBrandSecondaryMenusFocusableElements = getSecondaryFocusableElements(
  CLOSE_BUTTON_SELECTOR
)

/**
 * Name: NavBarDesktop
 * Desc: Renders the "Products, Services and Solutions, Publications" Menu
 * @param {string} language
 * @param {array} categories
 * @param {array} deals
 * @param {array} brands
 * @param {array} publications
 * @param {array} topNavBarItems
 */

const NavBarDesktop = ({
  language = ENGLISH,
  categories = [],
  topNavBarItems = [],
  deals = [],
  publications = [],
  brands = [],
  showDeals,
  showPublications,
  showBrands,
}) => {
  return (
    <Flex
      justify="center"
      position="relative"
      className={styles.navbarNav}
      as="ul"
    >
      {/* items that renders the dynamic "Products" and "Services and solution" */}
      {categories.map((category) => {
        const { data = [], Dimensions = [], isBrowseCallingLocation } = category
        return (
          <NavBarDesktopMenu
            id={category.Id}
            key={category.Id}
            buttonText={messages[language][category.Id]}
            data-test={category.Id}
            getSecondaryMenusFocusableElements={
              getCategorySecondaryMenusFocusableElements
            }
          >
            <NavBarPrimaryMegaMenu>
              {data.map((item, index) => (
                <NavBarPrimaryMegaMenuItem
                  key={item.id}
                  href={item.link}
                  text={item.linkText}
                  index={index}
                  data-test="menu-item"
                />
              ))}
            </NavBarPrimaryMegaMenu>

            <NavBarSecondaryMegaMenu>
              {Dimensions.map((item) => {
                const key = item.id || uniqueId()
                return (
                  <NavBarSecondaryMegaMenuItem key={key}>
                    <NavBarCloseButton>
                      <CloseButton
                        aria-label={messages[language].closeNavBar}
                      />
                    </NavBarCloseButton>
                    <NavBarChildCategoryList
                      categories={item.Dimensions}
                      bannerUrl={item.ImageName}
                      categoryName={item.Name}
                      imageLink={item.Url}
                      isBrowseCallingLocation={isBrowseCallingLocation}
                    />
                  </NavBarSecondaryMegaMenuItem>
                )
              })}
            </NavBarSecondaryMegaMenu>
          </NavBarDesktopMenu>
        )
      })}

      {/*List renders the Brands Menu*/}
      {showBrands ? (
        <NavBarDesktopMenu
          id="brands"
          buttonText={messages[language].brands}
          data-test="brands"
          getSecondaryMenusFocusableElements={
            getBrandSecondaryMenusFocusableElements
          }
        >
          <NavBarPrimaryMegaMenu>
            {brands.map((e) => {
              return (
                <NavBarPrimaryMegaMenuItem
                  key={e.id}
                  href={e.link}
                  text={e.linkText}
                  data-test="menu-item"
                />
              )
            })}

            {brands.length > 0 && (
              <NavBarPrimaryMegaMenuItem
                href={`${HOST_URL}/${language}/Shopping/Search/SearchByKeyword`}
                text={messages[language].shopAllBrandsLabel}
              />
            )}
          </NavBarPrimaryMegaMenu>

          <NavBarSecondaryMegaMenu>
            {brands.map((brand) => (
              <NavBarSecondaryMegaMenuItem key={brand.id}>
                <NavBarCloseButton>
                  <CloseButton aria-label={messages[language].closeNavBar} />
                </NavBarCloseButton>
                {brand.image ? (
                  <Image
                    src={brand.image}
                    link={brand.link}
                    alt={brand.linkText}
                    fluid
                  />
                ) : null}
              </NavBarSecondaryMegaMenuItem>
            ))}
          </NavBarSecondaryMegaMenu>
        </NavBarDesktopMenu>
      ) : null}

      {/*renders the Deals Menu*/}
      {showDeals ? (
        <NavBarDesktopMenu
          id="deals"
          buttonText={messages[language].deals}
          data-test="deals"
        >
          <NavBarPrimaryMegaMenu>
            {deals.map((e) => {
              return (
                <NavBarPrimaryMegaMenuItem
                  key={e.id}
                  href={e.link}
                  text={e.linkText}
                />
              )
            })}
          </NavBarPrimaryMegaMenu>
          <NavBarSecondaryMegaMenu>
            {deals.map((deal) => (
              <NavBarSecondaryMegaMenuItem key={deal.id}>
                <NavBarCloseButton>
                  <CloseButton aria-label={messages[language].closeNavBar} />
                </NavBarCloseButton>
                {deal.image ? (
                  <Image
                    src={deal.image}
                    link={deal.link}
                    alt={deal.linkText}
                    fluid
                  />
                ) : null}
              </NavBarSecondaryMegaMenuItem>
            ))}
          </NavBarSecondaryMegaMenu>
        </NavBarDesktopMenu>
      ) : null}

      {/* Renders Publications menu */}
      {showPublications ? (
        <NavBarDesktopMenu
          id="publications"
          buttonText={messages[language].flyerPub}
          data-test="publications"
        >
          <NavBarPrimaryMegaMenu>
            {publications.map((e) => {
              return (
                <NavBarPrimaryMegaMenuItem
                  key={e.id}
                  href={e.link}
                  text={e.linkText}
                />
              )
            })}
          </NavBarPrimaryMegaMenu>
          <NavBarSecondaryMegaMenu>
            {publications.map((publication) => (
              <NavBarSecondaryMegaMenuItem key={publication.id}>
                <NavBarCloseButton>
                  <CloseButton aria-label={messages[language].closeNavBar} />
                </NavBarCloseButton>
                {publication.image ? (
                  <Image
                    src={publication.image}
                    link={publication.link}
                    alt={publication.linkText}
                    fluid
                  />
                ) : null}
              </NavBarSecondaryMegaMenuItem>
            ))}
          </NavBarSecondaryMegaMenu>
        </NavBarDesktopMenu>
      ) : null}

      {/* Renders "Become a customer" and other items */}
      {topNavBarItems.map((item) => {
        const targetWindow = item.target
          ? { target: item.target, rel: 'noopener noreferrer' }
          : null

        return (
          <ListItem key={item.id} data-test="Become-a-customer">
            <a href={item.link} {...targetWindow}>
              {purifyHtmlString(item.linkText)}
            </a>
          </ListItem>
        )
      })}
    </Flex>
  )
}

// Props Validation
NavBarDesktop.propTypes = {
  language: PropTypes.string,
  categories: PropTypes.array.isRequired,
  deals: PropTypes.array,
  publications: PropTypes.array,
  brands: PropTypes.array,
  topNavBarItems: PropTypes.array.isRequired,
  showDeals: PropTypes.bool,
  showPublications: PropTypes.bool,
  showBrands: PropTypes.bool,
}

export default NavBarDesktop

function NavBarCloseButton({ children, ...rest }) {
  const { handleMegaMenuClose } = useNavBarContext()
  return (
    <Box
      display="flex"
      justify="flex-end"
      {...rest}
      onClick={handleMegaMenuClose}
      data-test="navbar-close-button"
    >
      {children}
    </Box>
  )
}

NavBarCloseButton.propTypes = {
  children: PropTypes.node,
}

// Props Validation
NavBarCloseButton.propTypes = {
  language: PropTypes.string,
}
