import ContentLoader from 'react-content-loader'
import styles from './NavBarDesktopSkeleton.module.scss'

const NavBarDesktopSkeleton = () => {
  return (
    <div className={styles.skeletonNavbar}>
      <ContentLoader
        title=""
        viewBox="0 0 660 45"
        preserveAspectRatio="xMidYMid meet"
        uniqueKey="nav-bar-loader"
      >
        <rect x="0" y="8" rx="5" ry="5" width="150px" height="30px" />
        <rect x="170" y="8" rx="5" ry="5" width="150px" height="30px" />
        <rect x="340" y="8" rx="5" ry="5" width="150px" height="30px" />
        <rect x="510" y="8" rx="5" ry="5" width="150px" height="30px" />
      </ContentLoader>
    </div>
  )
}

export default NavBarDesktopSkeleton
