import PropTypes from 'prop-types'
import { createContext, useEffect, useRef } from 'react'
import useOutsideClickHandler from '~/components/shared/CustomHooks/useOutsideClickHandler'
import {
  getCollectionArray,
  getCollectionArrayWithSelector,
  setElementActiveByIndex,
} from './NavBarMegaMenuUtils'
import { useKeyboardMegaMenu } from './NavBarMegaMenuKeyboardAccessible'
import styles from './NavBarMegaMenu.module.scss'
const NavBarMegaMenuContext = createContext()
const { Provider } = NavBarMegaMenuContext
const PRIMARY_ELEMENT_SELECTOR = 'a'
export function NavBarMegaMenuContextProvider({
  children,
  isOpen = false,
  buttonRef,
  onClickOutside,
  close,
  getSecondaryMenusFocusableElements,
}) {
  const navBarRef = useRef()
  const primaryPanelListRef = useRef()
  const secondaryPanelListRef = useRef()

  const primaryKeyboardMenuRef = useRef({
    elements: [],
    selectedItem: 0,
    isActive: false,
    id: 'primary',
  }).current
  const secondaryKeyboardMenuRef = useRef({
    elements: [],
    focusableElements: [],
    selectedItem: 0,
    isActive: false,
    id: 'secondary',
  }).current

  useOutsideClickHandler(navBarRef, (e) => {
    onClickOutside?.(e)
  })

  useEffect(() => {
    /**
     * Grab primary and secondary mega menu items when it is mounted
     *
     */
    const primaryElements = getCollectionArrayWithSelector(
      primaryPanelListRef.current,
      PRIMARY_ELEMENT_SELECTOR
    )
    const secondaryElements = getCollectionArray(secondaryPanelListRef.current)

    primaryKeyboardMenuRef.elements = [...primaryElements]
    secondaryKeyboardMenuRef.elements = secondaryElements
  }, [
    primaryKeyboardMenuRef,
    buttonRef,
    primaryKeyboardMenuRef.elements,
    secondaryKeyboardMenuRef.elements,
    secondaryKeyboardMenuRef,
  ])

  const handlePrimaryMenuMouseEnter = (_e, index) => {
    setElementActiveByIndex({
      primaryElements: primaryKeyboardMenuRef.elements,
      secondaryElements: secondaryKeyboardMenuRef.elements,
      index,
      styles,
      isMouseInteraction: true,
    })

    primaryKeyboardMenuRef.selectedItem = index
  }

  const handleMegaMenuClose = () => {
    close()
    // TODO : need to review Why focus
    //on element is not working without time delay
    setTimeout(() => {
      buttonRef?.current?.focus()
    }, 200)
  }

  useKeyboardMegaMenu({
    primaryPanelListRef,
    secondaryPanelListRef,
    primaryKeyboardMenuRef,
    secondaryKeyboardMenuRef,
    isOpen,
    handleMegaMenuClose,
    getSecondaryMenusFocusableElements,
  })

  const value = {
    navBarRef,
    primaryPanelListRef,
    secondaryPanelListRef,
    isOpen,
    buttonRef,
    close,
    handleOnMouseEnter: handlePrimaryMenuMouseEnter,
    handleMegaMenuClose,
  }
  return <Provider value={value}>{children}</Provider>
}

NavBarMegaMenuContextProvider.propTypes = {
  children: PropTypes.node,
  buttonRef: PropTypes.object,
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
  close: PropTypes.func,
  getSecondaryMenusFocusableElements: PropTypes.func,
}

export default NavBarMegaMenuContext
