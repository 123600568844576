import PropTypes from 'prop-types'
import { POSTAL_CODE_MAX_LENGTH } from 'services/constants'
import Column from 'shared/Column'
import Row from 'shared/Row'
import FormLabel from '~/components/shared/FormControl/FormLabel/FormLabel'
import TextInput from '~/components/shared/FormControl/TextInput'
import FormFieldError from '~/components/shared/FormFieldError'
import { InputWithLockIcon } from '~/components/shared/InputWithLockIcon'
import ProvinceSelect from '~/components/shared/ProvinceSelect'
import QuestionTooltip from '~/components/shared/QuestionTooltip'
import Stack from '~/components/shared/Stack'
import { ENGLISH } from '~/redux/constants'
import {
  FIELDS,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
} from '../SaveAddressFormConstants'
import styles from './BillingAddressFields.module.scss'
import messages from './messages'
import { noop } from '~/components/shared/Utils/userUtils'
import { AddressField } from '../../../AddressField/AddressField'
import { ADDRESS_FORM_FIELD_VARIANTS } from './BillingAddressFieldsConstants'

// TODO: Needs to be renamed (Common name should be provided) as it is being used on Review and confirm step and SaveAddressForm
export function BillingAddressFields({
  language = ENGLISH,
  disabled = false,
  getAddressLockFieldProps,
  onProvinceChange,
  onBlurPostalCode,
  showPostalCodeError,
  values = {},
  dirties = {},
  required = {},
  errors = {},
  onChange = noop,
  onBlur = noop,
  showLockIcons = false,
  id = '',
  ...rest
}) {
  const translations = messages[language]
  const fieldVariants = ADDRESS_FORM_FIELD_VARIANTS[id]
  const common = {
    translations,
    disabled,
    values,
    dirties,
    required,
    errors,
    onChange,
    onBlur,
    minH: '87px',
    fieldVariants,
  }
  return (
    <Row {...rest}>
      <Column>
        <InputWithLockIcon
          showLockIcon={showLockIcons}
          {...getAddressLockFieldProps?.(FIELDS.COMPANY_NAME)}
        >
          <AddressField
            {...common}
            id={FIELDS.COMPANY_NAME}
            name={FIELDS.COMPANY_NAME}
          />
        </InputWithLockIcon>
        <InputWithLockIcon
          showLockIcon={showLockIcons}
          {...getAddressLockFieldProps?.(FIELDS.ATTENTION)}
        >
          <AddressField
            {...common}
            id={FIELDS.ATTENTION}
            name={FIELDS.ATTENTION}
            maxLength={MAX_LENGTH_30}
          />
        </InputWithLockIcon>
        <AddressField
          {...common}
          id={FIELDS.STREET_ADDRESS}
          name={FIELDS.STREET_ADDRESS}
          maxLength={MAX_LENGTH_25}
        />
      </Column>
      <Column>
        <InputWithLockIcon
          showLockIcon={showLockIcons}
          {...getAddressLockFieldProps?.(FIELDS.BUILDING)}
        >
          <AddressField
            {...common}
            id={FIELDS.BUILDING}
            name={FIELDS.BUILDING}
            maxLength={MAX_LENGTH_25}
          />
        </InputWithLockIcon>
        <AddressField {...common} id={FIELDS.CITY} name={FIELDS.CITY} />
        <Row className="noWrap" variant="xsMb20">
          <Column variant="pr10">
            <ProvinceSelect
              id={FIELDS.PROVINCE}
              name={FIELDS.PROVINCE}
              value={values[FIELDS.PROVINCE]}
              required={required[FIELDS.PROVINCE]}
              showError={
                !!errors[FIELDS.PROVINCE] && !!dirties[FIELDS.PROVINCE]
              }
              errorMessage={errors[FIELDS.PROVINCE]}
              language={language}
              onChange={onProvinceChange}
              disabled={disabled}
              data-test={fieldVariants[FIELDS.PROVINCE]['data-test']}
            />
          </Column>

          <Column variant="w50">
            <Stack spacing="10px" direction="row">
              <FormLabel
                isCustomInfoRequired={true}
                className={styles.size}
                fieldLabel={translations[FIELDS.POSTAL_CODE]}
                data-test="save-address-form-postal-code-label"
                htmlFor={FIELDS.POSTAL_CODE}
              />
              <QuestionTooltip
                text={translations.postalCodeTooltip}
                id="save-address-form-tooltip"
                place="top"
              />
            </Stack>
            <TextInput
              aria-required={required[FIELDS.POSTAL_CODE]}
              disabled={disabled}
              onBlur={onBlurPostalCode}
              inputAriaLabel={translations[FIELDS.POSTAL_CODE]}
              id={FIELDS.POSTAL_CODE}
              name={FIELDS.POSTAL_CODE}
              error={showPostalCodeError}
              value={values[FIELDS.POSTAL_CODE] || ''}
              onChange={onChange}
              maxLength={POSTAL_CODE_MAX_LENGTH}
              data-test={fieldVariants[FIELDS.POSTAL_CODE]['data-test']}
            />
            {showPostalCodeError ? (
              <FormFieldError>{errors[FIELDS.POSTAL_CODE]}</FormFieldError>
            ) : null}
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

BillingAddressFields.propTypes = {
  language: PropTypes.string,
  errors: PropTypes.object,
  dirties: PropTypes.object,
  required: PropTypes.object,
  getAddressLockFieldProps: PropTypes.func,
  getProps: PropTypes.func,
  isCheckedIsPrimary: PropTypes.bool,
  isDisabledSetPrimaryField: PropTypes.bool,
  disabled: PropTypes.bool,
  notAllowedStyles: PropTypes.string,
  onBlurPostalCode: PropTypes.func,
  onChangeIsPrimary: PropTypes.func,
  onChange: PropTypes.func,
  onProvinceChange: PropTypes.func,
  showPostalCodeError: PropTypes.bool,
  showLockIcons: PropTypes.bool,
  values: PropTypes.object,
  onBlur: PropTypes.func,
  id: PropTypes.string,
}
