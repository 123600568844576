import PropTypes from 'prop-types'
import MenuListItem from '~/components/shared/MenuList/MenuListItem'
import { useNavBarContext } from '../NavBarMegaMenuUtils'

export function NavBarPrimaryMegaMenuItem(props) {
  const { index, onMouseEnter: onMouseEnterFromProps, ...rest } = props
  const { handleOnMouseEnter } = useNavBarContext()
  const contextMouseEnter = (e) => handleOnMouseEnter(e, index)
  const propsMouseEnter = onMouseEnterFromProps
    ? (e) => onMouseEnterFromProps?.(e, index)
    : null

  // Precede with mouseEnter from props
  const mouseEnter = propsMouseEnter || contextMouseEnter
  const finalProps = {
    ...rest,
    onMouseEnter: mouseEnter,
  }
  return <MenuListItem {...finalProps} />
}

NavBarPrimaryMegaMenuItem.propTypes = {
  index: PropTypes.number,
  onMouseEnter: PropTypes.func,
}
