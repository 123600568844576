import { useEffect } from 'react'
import {
  getKeyboardAction,
  getNextFocusableElement,
  getPreviousFocusableElement,
} from '~/components/shared/CustomHooks/useKeyboardNavigation'
import { useKeyPressEvent } from '~/components/shared/CustomHooks/useKeyPressEvent'
import { useMutationObservation } from '~/components/shared/CustomHooks/useMutationObservation'
import { defaultCaptureEvents } from '~/services/utils/keyboardUtils/keyboardCodeConstants'
import styles from './NavBarMegaMenu.module.scss'
import {
  getCollectionArrayWithSelector,
  setElementActiveByIndex,
} from './NavBarMegaMenuUtils'

export const CLOSE_BUTTON_SELECTOR = 'button'
export const CATEGORY_SECONDARY_MENUS_SELECTOR = `${CLOSE_BUTTON_SELECTOR}, a`

export function useKeyboardMegaMenu({
  primaryPanelListRef,
  secondaryPanelListRef,
  primaryKeyboardMenuRef,
  secondaryKeyboardMenuRef,
  isOpen,
  handleMegaMenuClose,
  getSecondaryMenusFocusableElements,
}) {
  /**
   * When menu open (isOpen === true)
   * Then Enable primary mega menu keyboard listener
   *
   * When menu closed (isOpen === false)
   * Then Disable both primary and secondary
   * mega menu keyboard listener
   */
  useEffect(() => {
    const { elements: primaryElements } = primaryKeyboardMenuRef
    const { elements: secondaryMenuElements } = secondaryKeyboardMenuRef

    primaryKeyboardMenuRef.isActive = isOpen
    secondaryKeyboardMenuRef.isActive = false
    if (isOpen) {
      // TODO : need to review Why focus
      //on element is not working without time delay
      setTimeout(() => {
        handlePrimaryMenuItemSelection(
          0,
          primaryElements,
          secondaryMenuElements
        )
      }, 200)
    }
  }, [isOpen, primaryKeyboardMenuRef, secondaryKeyboardMenuRef])

  const handleKeyPressEvents = ({ key, shiftKey }) => {
    const {
      isNextSelection,
      isPreviousSelection,
      rightPress,
      leftPress,
      isExit,
      isTab,
    } = getKeyboardAction({ key, shiftKey })

    const {
      elements: primaryElements,
      isActive: isActivePrimaryMenu,
      selectedItem: primarySelectedItem,
    } = primaryKeyboardMenuRef

    const {
      selectedItem: secondaryMenuSelectedItem,
      elements: secondaryMenuElements,
      isActive: isActiveSecondaryMenu,
      focusableElements: secondaryFocusableElements,
    } = secondaryKeyboardMenuRef

    isExit && isOpen && handleMegaMenuClose()

    handlePreviousNextSelection({
      isTab,
      isNextSelection,
      isPreviousSelection,
      isActiveMenu: isActivePrimaryMenu,
      focusableElements: primaryElements,
      selectedItem: primarySelectedItem,
      handleMenuItemSelection: handlePrimaryMenuItemSelection,
      secondaryElements: secondaryMenuElements,
      keyboardMenuRef: primaryKeyboardMenuRef,
    })

    handlePreviousNextSelection({
      isTab,
      isNextSelection,
      isPreviousSelection,
      isActiveMenu: isActiveSecondaryMenu,
      focusableElements: secondaryFocusableElements,
      selectedItem: secondaryMenuSelectedItem,
      handleMenuItemSelection: handleSecondaryMenuItemSelection,
      secondaryElements: secondaryMenuElements,
      keyboardMenuRef: secondaryKeyboardMenuRef,
    })

    rightPress &&
      handleRightKeyPress({
        secondaryMenuElements,
        primarySelectedItem,
        primaryKeyboardMenuRef,
        secondaryKeyboardMenuRef,
        getSecondaryMenusFocusableElements,
      })

    leftPress &&
      handleLeftKeyPress({
        primaryElements,
        primarySelectedItem,
        primaryKeyboardMenuRef,
        secondaryKeyboardMenuRef,
      })
  }

  /**
   * Listen for multiple keys all arrows, tab, esc, shift to operate
   * mega menu with keyboard
   *
   * Here we have single key listener/ handler function to take action
   * based on pressed keys.
   * Below are the actions can be taken based on pressed key
   * 1. Can select/focus next item when press tab or arrow down
   * 2. Can select/focus previous item when press shift+tab or arrow up
   * 3. Can move to secondary menu selection when press arrow right
   * 4. Can move back to primary menu and focus last selected element
   * when press arrow up
   * 5. Can close opened menu when press esc
   */
  useKeyPressEvent({
    captureEvents: defaultCaptureEvents,
    isActive: isOpen,
    handleKeyPressEvents,
  })

  /**
   * Use mutation observer in order to simulate arrow and tab key
   * It also enables user to fire native action (Go to link in case of anchor tag)
   * when used with arrow key
   */

  useMutationObservation({
    observingElement: primaryPanelListRef.current,
    observerHandler: mutationObserver,
  })

  useMutationObservation({
    observingElement: secondaryPanelListRef.current,
    observerHandler: mutationObserver,
  })
}

export const getSecondaryFocusableElements = (selector) => {
  return (selectedSecondaryContainer) => {
    const focusableElements = getCollectionArrayWithSelector(
      selectedSecondaryContainer,
      selector
    )

    return [...focusableElements]
  }
}

export const defaultGetSecondaryMenusFocusableElements = () => []

function handleLeftKeyPress({
  primaryElements,
  primarySelectedItem,
  primaryKeyboardMenuRef,
  secondaryKeyboardMenuRef,
}) {
  if (primaryElements.length) {
    primaryKeyboardMenuRef.isActive = true
    secondaryKeyboardMenuRef.isActive = false
    secondaryKeyboardMenuRef.focusableElements = []
    const primarySelectedElement = primaryElements[primarySelectedItem]
    primarySelectedElement?.focus()
  }
}

function handleRightKeyPress({
  secondaryMenuElements,
  primarySelectedItem,
  primaryKeyboardMenuRef,
  secondaryKeyboardMenuRef,
  getSecondaryMenusFocusableElements,
}) {
  if (secondaryMenuElements.length) {
    primaryKeyboardMenuRef.isActive = false
    secondaryKeyboardMenuRef.isActive = true
    secondaryKeyboardMenuRef.selectedItem = 0
    const focusableElements = getSecondaryMenusFocusableElements(
      secondaryMenuElements[primarySelectedItem]
    )
    secondaryKeyboardMenuRef.focusableElements = focusableElements
    handleSecondaryMenuItemSelection(
      secondaryKeyboardMenuRef.selectedItem,
      secondaryKeyboardMenuRef.focusableElements
    )
  }
}

function handlePreviousNextSelection({
  isTab,
  isNextSelection,
  isPreviousSelection,
  isActiveMenu,
  focusableElements,
  selectedItem,
  handleMenuItemSelection,
  secondaryElements,
  keyboardMenuRef,
}) {
  if (!(isActiveMenu && focusableElements.length)) {
    return
  }

  const firstFocusableElement = focusableElements[0]
  const lastFocusableElement = focusableElements[focusableElements.length - 1]

  if (isNextSelection) {
    keyboardMenuRef.selectedItem = getNextFocusableElement(
      selectedItem,
      focusableElements
    )
    handleMenuItemSelection(
      keyboardMenuRef.selectedItem,
      focusableElements,
      secondaryElements
    )
    if (isTab && document.activeElement === lastFocusableElement) {
      firstFocusableElement?.focus()
    }
  }

  if (isPreviousSelection) {
    keyboardMenuRef.selectedItem = getPreviousFocusableElement(
      selectedItem,
      focusableElements
    )
    handleMenuItemSelection(
      keyboardMenuRef.selectedItem,
      focusableElements,
      secondaryElements
    )

    if (isTab && document.activeElement === firstFocusableElement) {
      lastFocusableElement?.focus()
    }
  }
}

const handleSecondaryMenuItemSelection = (index, focusableElements) => {
  setElementActiveByIndex({
    primaryElements: focusableElements,
    secondaryElements: [],
    index,
    styles,
  })
}

const handlePrimaryMenuItemSelection = (
  index,
  focusableElements,
  secondaryElements
) => {
  setElementActiveByIndex({
    primaryElements: focusableElements,
    secondaryElements,
    index,
    styles,
  })
}

const mutationObserver = function (event) {
  event.forEach((mutation) => {
    const { target: selectedElement = {} } = mutation
    const selectedClassName = styles.active
    selectedElement &&
      selectedElement?.classList?.contains(selectedClassName) &&
      selectedElement?.focus()
  })
}
