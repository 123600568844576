import classNames from 'classnames'
import PropTypes from 'prop-types'
import { AddressBox } from '~/components/shared/ManageAddresses/AddressList/AddressBox'
import { ENGLISH } from '~/redux/constants'
import { AddressTable } from './AddressTable'
import Box from '../../Box'
import { splitBoxProps } from '../../Box/BoxUtils'
import Flex from '../../Flex'
import { ManageAddressesFetchFailed } from '../ManageAddressesFetchFailed'
import { GRID_VIEW } from '~/components/shared/ProductCard/ProductCardConstants'
import ManageAddressesSkeleton from '../ManageAddressesSkeleton'
import { ADDRESS_VISIBILITY } from '../SaveAddressForm/SaveAddressFormConstants'
import styles from './AddressList.module.scss'
import messages from './messages'

function AddressList({
  variant = 'delivery',
  buyerId = '',
  addresses = [],
  className,
  isLoading = false,
  isError = false,
  hasNoAddresses,
  canAddAddress,
  language = ENGLISH,
  selectedAddressId,
  showSetAsDefaultBillingAddressButton = false,
  addressAssignedIds = {},
  showPrimaryButton = false,
  showAssignAddress = false,
  hasNoDeliveryAddresses = false,
  viewType = GRID_VIEW,
  enableSelectOption = true,
  ...props
}) {
  const translations = messages[language]
  const noAddressFoundMsg = translations[`${variant}NoAddresses`]
  const { boxProps, rest } = splitBoxProps(props)
  const addressListClasses = classNames(className, styles.addressList)

  return isLoading ? (
    <ManageAddressesSkeleton />
  ) : isError ? (
    <ManageAddressesFetchFailed language={language} />
  ) : hasNoAddresses ? (
    <Box data-test="no-addresses-message" mb="30px">
      {noAddressFoundMsg}
      {canAddAddress ? `${' '}${translations.clickToGetStarted}` : null}
    </Box>
  ) : (
    <>
      {viewType === GRID_VIEW ? (
        <Flex className={addressListClasses} {...boxProps}>
          {addresses.map((address, index) => {
            const id = address.id || index
            const {
              buyerId: addressBuyerId = '',
              visibility,
              canEditAddress,
              canAssignAddress,
              canDeleteAddress,
              deliveryAddressBillingAddress = [],
              isDefault = false,
            } = address
            const checkedAddress = addressAssignedIds[id] || []
            const isAssignedToDeliveryAddress =
              (canAssignAddress && deliveryAddressBillingAddress?.length > 0) ||
              (canAssignAddress && checkedAddress?.length > 0)
            const showMakePrimaryButton = showPrimaryButton && !isDefault
            const isSharedFromOtherBuyer =
              visibility === ADDRESS_VISIBILITY.SHARED &&
              addressBuyerId.trim() !== buyerId.trim()
            return (
              <AddressBox
                key={id}
                id={id}
                address={address}
                language={language}
                showPrimaryButton={showMakePrimaryButton}
                showPrimaryLabel={address.isDefault}
                showAssignButton={canAssignAddress && showAssignAddress}
                showToggleActionButton={
                  canEditAddress ||
                  props.showDeleteButton ||
                  showSetAsDefaultBillingAddressButton
                }
                canEditAddress={canEditAddress}
                canDeleteAddress={canDeleteAddress}
                isSharedFromOtherBuyer={isSharedFromOtherBuyer}
                isAssignedToDeliveryAddress={isAssignedToDeliveryAddress}
                isSelected={selectedAddressId === id}
                showSetAsDefaultBillingAddressButton={
                  showSetAsDefaultBillingAddressButton
                }
                hasNoDeliveryAddresses={hasNoDeliveryAddresses}
                {...rest}
              />
            )
          })}
        </Flex>
      ) : (
        <AddressTable
          addresses={addresses}
          showPrimaryButton={showPrimaryButton}
          language={language}
          showSetAsDefaultBillingAddressButton={
            showSetAsDefaultBillingAddressButton
          }
          selectedAddressId={selectedAddressId}
          buyerId={buyerId}
          showAssignAddress={showAssignAddress}
          enableSelectOption={enableSelectOption}
          {...rest}
        />
      )}
    </>
  )
}

AddressList.propTypes = {
  addresses: PropTypes.array,
  className: PropTypes.string,
  variant: PropTypes.string,
  buyerId: PropTypes.string,
  language: PropTypes.string,
  onEditAddressClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  hasNoAddresses: PropTypes.bool,
  noAddressFoundMsg: PropTypes.node,
  canAddAddress: PropTypes.bool,
  onPrimaryAddressClick: PropTypes.func,
  onDeleteAddressClick: PropTypes.func,
  onSelectAddressClick: PropTypes.func,
  showPrimaryButton: PropTypes.bool,
  disableAllSelectButtons: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showSelectButton: PropTypes.bool,
  selectedAddressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showSetAsDefaultBillingAddressButton: PropTypes.bool,
  addressAssignedIds: PropTypes.object,
  showAssignAddress: PropTypes.bool,
  hasNoDeliveryAddresses: PropTypes.bool,
  viewType: PropTypes.string,
  enableSelectOption: PropTypes.bool,
}

export { AddressList }
