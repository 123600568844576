import PropTypes from 'prop-types'
import { formatOrderNumber } from 'services/utils/orderUtils'
import Text from 'shared/Text'
import { ENGLISH } from '~/redux/constants'
import styles from './CartHeader.module.scss'
import messages from './messages'

/**
 * Name: CartHeader
 * Desc: Render cart header with product count
 * @param {string} language
 * @param {number} count
 * @param {object} order
 * @param {object} buyer
 */
const CartHeader = ({ language, count, buyer, order }) => {
  const cartHeaderLabel =
    count > 1 || !count
      ? messages[language].itemsLabel
      : messages[language].itemLabel

  return (
    <span className={styles.cartCount}>
      {!!count && (
        <Text variant="xxlgText" color="white">
          <span data-test="cart-count">{count}</span>{' '}
          <span data-test="cart-label">{cartHeaderLabel}</span>
        </Text>
      )}
      {order?.ewayOrderNo && (
        <CartHeaderOrderNumber
          order={order}
          language={language}
          buyer={buyer}
        />
      )}
    </span>
  )
}

// Default Props
CartHeader.defaultProps = {
  language: ENGLISH,
}

// Props Validation
CartHeader.propTypes = {
  language: PropTypes.string.isRequired,
  order: PropTypes.object,
  count: PropTypes.number,
  buyer: PropTypes.object,
}

export default CartHeader

// Default Props
CartHeaderOrderNumber.defaultProps = {
  language: ENGLISH,
}

// Props Validation
CartHeaderOrderNumber.propTypes = {
  language: PropTypes.string,
  order: PropTypes.object,
  buyer: PropTypes.object,
}

function CartHeaderOrderNumber({ language, order, buyer }) {
  const { prefix, suffix } = formatOrderNumber({ order, buyer })

  return (
    <Text color="white" variant="smText" className={styles.alignRight}>
      {messages[language].orderNumberLabel}:{' '}
      <span data-test="order-number">{`${prefix}${suffix}`}</span>
    </Text>
  )
}
