export default {
  en: {
    joinUsOnLabel: 'Join us on',
    linkedInLabel: 'LinkedIn',
    twitterLabel: 'Twitter',
    facebookLabel: 'Facebook',
    youTubeLabel: 'Youtube',
    instagramLabel: 'Instagram',
    bullFrogPowerLogoLabel: 'Bullfrogpower',
    accessibilityLogoLabel: 'Accessibility',
  },
  fr: {
    joinUsOnLabel: 'Joignez-vous à nous sur',
    linkedInLabel: 'LinkedIn',
    twitterLabel: 'Twitter',
    facebookLabel: 'Facebook',
    youTubeLabel: 'Youtube',
    instagramLabel: 'Instagram',
    bullFrogPowerLogoLabel: 'Bullfrogpower',
    accessibilityLogoLabel: 'Accessibilité',
  },
}
