import PropTypes from 'prop-types'
import styles from './Star.module.scss'
/**
 * Name: Star
 * Desc: Render the Star
 * @param {string} as
 * @param {string} width
 * @param {string} height
 * @param {string} fill
 */
const Star = ({ as: Element = 'div', width, height, fill, ...props }) => {
  const { className: classNameFromProps, focusable } = props
  return (
    <Element
      className={`${styles['star']} ${classNameFromProps || ''}`.trimRight()}
    >
      <svg
        focusable={focusable}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 51 48"
      >
        <path
          fill={fill}
          stroke="#444444"
          d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
        />
      </svg>
    </Element>
  )
}

Star.defaultProps = {
  width: '14',
  height: '15',
  fill: '#444444',
}
// Props Validation
Star.propTypes = {
  as: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
  focusable: PropTypes.node,
}

export default Star
