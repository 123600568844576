import PropTypes from 'prop-types'
import { useMemo, useState, useRef, useEffect } from 'react'
import { getAccountMenus } from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/Account/AccountMenu/AccountMenuUtils'
import { GROUP_ORDER_PREFIX } from 'services/utils/orderUtils'
import EwayLink from 'shared/EwayLink/EwayLink'
import Text from 'shared/Text'
import { ENGLISH } from '~/redux/constants'
import styles from './AccountMenu.module.scss'
import messages from './messages'
import AccountMenusPrimary from './AccountMenusPrimary'
import AccountMenusSecondary from './AccountMenusSecondary'
/**
 *Name: AccountMenu
 *Desc: Render header account menus
 * @param {string} language
 * @param {object} buyer
 * @param {object} settings
 * @param {string}  createdBy
 * @param {number}  groupOrderId
 * @param {bool}  isGroupOrder
 */

const AccountMenu = ({
  language,
  buyer,
  settings,
  createdBy,
  groupOrderId,
  isGroupOrder,
  details,
  isMenuActive,
}) => {
  const initActiveIndex = isGroupOrder ? -1 : 1
  const [activeIndex, setActiveIndex] = useState(initActiveIndex)
  const [isPrimaryAccountMenuActive, setIsPrimaryAccountMenuActive] =
    useState(true)
  const lastFocusedElement = useRef(null)
  useEffect(() => {
    isMenuActive && setActiveIndex(initActiveIndex)
  }, [initActiveIndex, isMenuActive])
  const handlePrimaryActiveMenu = (isActive) => {
    if (isActive) {
      lastFocusedElement.current?.focus()
    } else {
      lastFocusedElement.current = document.activeElement
    }
    setIsPrimaryAccountMenuActive(isActive)
  }

  const isPrimaryMenuActive = isMenuActive && isPrimaryAccountMenuActive
  const isSecondaryMenuActive = isMenuActive && !isPrimaryAccountMenuActive
  const accountMenus = useMemo(
    () => getAccountMenus({ messages, language, settings, buyer, details }),
    [buyer, details, language, settings]
  )
  const { groupOrderNote, clickHereLabel, groupOrderMsg, groupOrderLink } =
    messages[language]
  let grOrderMsg = ''
  if (isGroupOrder) {
    grOrderMsg = groupOrderMsg
      .replace('GROUP_ORDER', `${GROUP_ORDER_PREFIX}${groupOrderId}`)
      .replace('CREATED_BY', createdBy)
  }
  const secondaryMenuItems = accountMenus[activeIndex]?.subLinks || []
  const secondaryMenuItemsWithButton = [[], ...secondaryMenuItems]
  return (
    <div className={styles.accountMenu}>
      <AccountMenusPrimary
        setActiveIndex={setActiveIndex}
        isMenuActive={isPrimaryMenuActive}
        items={accountMenus}
        activeIndex={activeIndex}
        onPrimaryActiveMenu={handlePrimaryActiveMenu}
      />
      <div className={styles.subListWrapper}>
        <AccountMenusSecondary
          items={secondaryMenuItemsWithButton}
          activeIndex={activeIndex}
          isMenuActive={isSecondaryMenuActive}
          language={language}
          onPrimaryActiveMenu={handlePrimaryActiveMenu}
        />
        {isGroupOrder && (
          <div className={styles.accountDetail}>
            <div className={styles.contentText}>
              {grOrderMsg && (
                <Text variant="mdText" color="tertiary" text={grOrderMsg} />
              )}
              <EwayLink
                text={clickHereLabel}
                textDecoration
                href={groupOrderLink}
              />
            </div>

            <div className={styles.contentNote}>
              <Text text={groupOrderNote} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

AccountMenu.defaultProps = {
  language: ENGLISH,
  createdBy: '',
  groupOrderId: 0,
  isGroupOrder: false,
}

// Props Validation
AccountMenu.propTypes = {
  language: PropTypes.string.isRequired,
  buyer: PropTypes.object,
  settings: PropTypes.object,
  createdBy: PropTypes.string,
  groupOrderId: PropTypes.number,
  isGroupOrder: PropTypes.bool,
  details: PropTypes.object,
  isMenuActive: PropTypes.bool,
}

export default AccountMenu
