import { useContext } from 'react'
import NavBarMegaMenuContext from './NavBarMegaMenuContext'

export function useNavBarContext() {
  return useContext(NavBarMegaMenuContext) || {}
}

export function setElementActiveByIndex({
  primaryElements,
  secondaryElements,
  index,
  styles,
  isMouseInteraction = false,
}) {
  // // Remove all active classes
  primaryElements.forEach((primaryElement, index) => {
    const secondaryElement = secondaryElements[index]
    if (primaryElement) {
      removeClass(primaryElement, styles.active)
      removeClass(primaryElement, styles.activeHover)
    }
    if (secondaryElement) {
      removeClass(secondaryElement, styles.active)
    }
  })
  // // Add the active class to the single item
  const primaryPanelItem = primaryElements[index]
  const secondaryPanelItem = secondaryElements[index]

  const primaryPanelItemActiveClass = isMouseInteraction
    ? styles.activeHover
    : styles.active
  primaryPanelItem && addClass(primaryPanelItem, primaryPanelItemActiveClass)
  secondaryPanelItem && addClass(secondaryPanelItem, styles.active)
}

const addClass = (element, className) => {
  if (!element.classList) {
    return
  }
  element.classList.add(className)
}

const removeClass = (element, className) => {
  if (!element.classList) {
    return
  }
  element.classList.remove(className)
}

export const getCollectionArray = (element, defaultVal = []) => {
  if (!element?.children) {
    return defaultVal
  }
  return [...element.children]
}

export const getCollectionArrayWithSelector = (
  element,
  selector,
  defaultVal = []
) => {
  const elements = element?.querySelectorAll(selector) || defaultVal

  return [...elements]
}
