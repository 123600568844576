import { isDev } from '~/config/env/environmentUtils'
import isEmpty from 'lodash/isEmpty'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import {
  checkTrackingIsReady,
  configureAnalytics,
  handleAdobeProductFindingMethod as addAdobeProductFindingMethodFromQueryVars,
  sendTrackingInformation,
  useAnalyticsDebugFunctions,
} from './AdobeAnalyticsUtils'

/**
 * Just remove old to get the new script
 * From: "/static/scripts/old-analytics-script.js"
 * To:   "/static/scripts/analytics-script.js"
 */
const ANALYTICS_SCRIPT_PATH = '/static/scripts/old-analytics-script.js'

/**
 * Uses Adobe Analytics all documentation can be found here
 * https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/appmeasure-mjs-pagecode.html#section_4351543F2D6049218E18B48769D471E2
 */
function AdobeAnalytics({
  values,
  debug = false,
  sendAnalyticsManually = false,
}) {
  const hasValues = debug ? true : !isEmpty(values)
  useEffect(() => {
    async function initializeAnalytics() {
      const analyticsIsReady = await checkTrackingIsReady()
      if (hasValues && analyticsIsReady) {
        configureAnalytics(values)
        // If analytics are sent manually, don't send the tracking information
        // From the component
        if (sendAnalyticsManually) {
          return
        }
        // Represents the default behavior, if we're not sending the Analytics manually
        // All values sent are first rendered on the server then sent with this function.
        addAdobeProductFindingMethodFromQueryVars()
        sendTrackingInformation()
      } else {
        handlePotentialError()
      }
    }
    hasValues && initializeAnalytics()
  }, [hasValues, values, sendAnalyticsManually])

  useAnalyticsDebugFunctions()

  // Sets the product finding method as soon as the component is ready
  useEffect(() => {
    async function startProductFindingMethod() {
      const analyticsIsReady = await checkTrackingIsReady()
      if (analyticsIsReady) {
        addAdobeProductFindingMethodFromQueryVars()
      }
    }
    startProductFindingMethod()
  }, [])

  // Add the analytics script to page head.
  return (
    <Head>
      <script type="text/javascript" src={ANALYTICS_SCRIPT_PATH} />
    </Head>
  )
}

// Props Validation
AdobeAnalytics.propTypes = {
  values: PropTypes.object.isRequired,
  sendAnalyticsManually: PropTypes.bool,
  debug: PropTypes.bool,
}

export default memo(AdobeAnalytics)

function handlePotentialError() {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.warn(
      'Analytics at this points should exist and have values, check initialize method of the current page'
    )
  }
}
