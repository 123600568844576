import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Button from 'shared/Button'
import Text from 'shared/Text'
import styles from './DialogConfirmation.module.scss'
import { DialogConfirmationFooter } from './DialogConfirmationFooter'
import { DialogConfirmationHeading } from './DialogConfirmationHeading'

/**
 *Name: DialogConfirmation
 *Desc: show Dialog Confirmation
 * @param {func} onConfirm
 * @param {func} onCancel
 * @param {string} headingType
 * @param {string} headingText
 * @param {string} descriptionShort
 * @param {string} descriptionLong
 * @param {string} confirmationBtnText
 * @param {string} cancelText
 * @param {string} closeText
 * @param {string} variantPrimary
 * @param {string} variantSecondary
 * @param {bool} hideCloseLink
 */

const DialogConfirmation = ({
  onConfirm,
  onCancel,
  headingType,
  headingText,
  descriptionShort,
  descriptionLong,
  confirmationBtnText,
  cancelText,
  closeText,
  isLoading = false,
  variantPrimary,
  variantSecondary,
  hideCloseLink = false,
  children,
}) => {
  const proceedBtnRef = useRef(null)
  useEffect(() => {
    if (proceedBtnRef.current) {
      proceedBtnRef.current.focus()
    }
  }, [proceedBtnRef])

  const commonDialogContent = {
    variant: 'xlgText',
    color: variantSecondary,
  }

  const hideConfirmationFooter = !confirmationBtnText && !cancelText

  return (
    <>
      <DialogConfirmationHeading
        headingType={headingType}
        headingText={headingText}
        onClick={onCancel}
        closeText={closeText}
        hideCloseLink={hideCloseLink}
      />
      <div
        className={styles.dialogContent}
        data-test="dialog-confirmation-content"
      >
        {descriptionShort ? (
          <Text text={descriptionShort} {...commonDialogContent} />
        ) : null}
        {descriptionLong ? (
          <Text text={descriptionLong} {...commonDialogContent} />
        ) : null}
      </div>
      {hideConfirmationFooter ? null : (
        <DialogConfirmationFooter>
          {confirmationBtnText ? (
            <Button
              variant={variantPrimary}
              text={confirmationBtnText}
              onClick={onConfirm}
              data-test="Confirm"
              ref={proceedBtnRef}
              aria-label={confirmationBtnText}
              isLoading={isLoading}
            />
          ) : null}
          {cancelText ? (
            <Button
              variant={variantSecondary}
              text={cancelText}
              onClick={onCancel}
              data-test="Cancel"
              aria-label={cancelText}
              isLoading={isLoading}
            />
          ) : null}
        </DialogConfirmationFooter>
      )}

      {children}
    </>
  )
}

DialogConfirmation.defaultProps = {
  variant: 'md',
  headingType: 'h4',
  variantPrimary: 'primary',
  variantSecondary: 'secondary',
}

// PropType validation
DialogConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  headingType: PropTypes.string,
  headingText: PropTypes.string,
  descriptionShort: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  descriptionLong: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  variantPrimary: PropTypes.string,
  variantSecondary: PropTypes.string,
  closeText: PropTypes.string,
  hideCloseLink: PropTypes.bool,
}

export default DialogConfirmation
