import PropTypes from 'prop-types'
import styles from './ErrorBoundaryPage.module.scss'
import Heading from 'shared/Heading'
import Text from 'shared/Text'
import Button from 'shared/Button'

import messages from './messages'
import { getHostUrl } from 'services/utils/configUtils'
import { ENGLISH } from '~/redux/constants'

const HOST_URL = getHostUrl()

/**
 * Name: ErrorBoundaryPage
 * Desc: Render error Page while error found component
 * @param {string} language
 */

const ErrorBoundaryPage = ({ language }) => {
  const { buttons, errorTitle, errorUnderTitle } = messages[language]
  const clickHandler = (data) => {
    window.location.assign(`${HOST_URL}/${language}/${data.link}`)
  }
  return (
    <div className={`${styles.error}`}>
      <div className={`${styles.errorContent}`}>
        <Heading
          text={errorTitle}
          as="h1"
          color="secondary"
          fontWeightNormal="fontWeightNormal"
          data-test="error-title"
        />
        <Text
          text={errorUnderTitle}
          data-test="error-description"
          variant="mdText"
          color="secondary"
        />
        <div className={styles.errorBtn}>
          {buttons.map((data, index) => {
            return (
              <Button
                key={index}
                title={data.title}
                text={data.text}
                aria-label={data.arialabel}
                datatest={data.datatest}
                variant={data.variant}
                onClick={() => clickHandler(data)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

//Default Props
ErrorBoundaryPage.defaultProps = {
  language: ENGLISH,
}

// props validation
ErrorBoundaryPage.propTypes = {
  language: PropTypes.string,
}

export default ErrorBoundaryPage
