import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './MenuClose.module.scss'
import messages from './messages'

/**
 *Name: MenuClose
 *Desc: Render  close button
 * @param {string} language
 * @param {func} onClick
 */
const MenuClose = ({ language, onCloseMenu, className = '' }) => {
  return (
    <div className={styles.btnClose} data-menuclose="close">
      <Button
        variant="link"
        onClick={onCloseMenu}
        text={messages[language].closeLabel}
        data-test="CloseBtn"
        className={className}
      />
    </div>
  )
}

// Props Validation
MenuClose.propTypes = {
  language: PropTypes.string.isRequired,
  onCloseMenu: PropTypes.func,
  className: PropTypes.string,
}

export default MenuClose
