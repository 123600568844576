export default {
  en: {
    productsLabel: 'Products',
    servicesSolutionLabel: 'Services & Solutions',
    flyerPub: 'Publications',
    flyerPubUrl: '/en/Landing/Publications_EN',
    becameCustomer: 'Become a Customer',
    becameCustomerUrl: 'https://www.staplesprofessional.ca/become-a-customer/',
    deals: 'Deals',
    brands: 'Brands',
    flyers: 'Flyers',
    promotions: 'Promotions',
    rebatesOffers: 'Rebates & Offers',
    clearance: 'Clearance',
    noPromotions:
      'There are currently no active promotions available. Please check back regularly',
    clearanceUrl:
      '/Shopping/Search/Category/1327306?categorydescription=Clearance&filter=0&pageName=HOME&callingLocation=nav-bar&category=Category%253AClearance&page=1&sortby=&filtersby=&optionsfilter=CF_ALL',

    shopAllBrandsLabel: 'Shop All Brands',
    closeNavBar: 'Close',
  },
  fr: {
    productsLabel: 'PRODUITS',
    servicesSolutionLabel: 'SERVICES ET SOLUTIONS',
    flyerPub: 'PUBLICATIONS',
    flyerPubUrl: '/fr/Landing/Publications_FR',
    becameCustomer: 'Devenez client',
    becameCustomerUrl: 'https://www.staplesprofessionnel.ca/become-a-customer/',
    deals: 'Offres Spéciales',
    brands: 'Marques',
    flyers: 'Circulaires',
    promotions: 'Promotions',
    rebatesOffers: 'Rabais et Offres',
    clearance: 'Liquidation',
    noPromotions: `Il n'y a actuellement aucune promotion active disponible. Revenez régulièrement vérifier`,
    clearanceUrl:
      '/Shopping/Search/Category/2327306?categorydescription=Liquidation&filter=0&pageName=HOME&callingLocation=nav-bar&category=Category%253ALiquidation&page=1&sortby=&filtersby=&optionsfilter=CF_ALL',
    shopAllBrandsLabel: 'Magasinez toutes les marques',
    closeNavBar: 'Fermer',
  },
}
