export default {
  en: {
    changeLanguage: 'Change language',
    english: 'English',
    french: 'French',
    en: 'EN',
    fr: 'FR',
  },
  fr: {
    changeLanguage: 'Changer de langue',
    english: 'Anglais',
    french: 'Français',
    en: 'EN',
    fr: 'FR',
  },
}
