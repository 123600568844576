import PropTypes from 'prop-types'
import Box from '~/components/shared/Box'
import Button from '~/components/shared/Button'
import Form from '~/components/shared/Form'
import Text from '~/components/shared/Text'
import SearchBox from '~/components/shared/SearchBox'
import Table from '~/components/shared/Table'
import Stack from '~/components/shared/Stack'
import isEmpty from 'lodash/isEmpty'
import { ENGLISH } from '~/redux/constants'
import SelectBuyersSkeleton from './SelectBuyersSkeleton'
import {
  buyerTableColumnsHeading,
  formattedColumns,
  formattedBuyerTableRows,
} from './SelectBuyersUtils'

import styles from './SelectBuyers.module.scss'
import messages from './messages'

/**
 *Name: SelectBuyers
 *Desc: Render SelectBuyers
 * @param {string} language
 */

export const SelectBuyers = ({
  language = ENGLISH,
  selectBuyersInfo,
  subHeading = '',
}) => {
  const {
    buyersList,
    checkedBuyers,
    handleBuyerSelectionEvent,
    selectedBuyerCount,
    handleSearch,
    handleCancelSelectedBuyerModal,
    handleSelectBuyer,
    isLoading,
  } = selectBuyersInfo

  const columns = formattedColumns(buyerTableColumnsHeading(language))
  const rows = formattedBuyerTableRows({
    buyersList,
    checkedBuyers,
    handleBuyerSelectionEvent,
  })
  const subHeadingMsg = isEmpty(subHeading)
    ? messages[language].selectBuyerModalDesc
    : subHeading
  return (
    <Box pt="20px" pr="15px" pb="20px" pl="15px">
      <Form id="select-buyer-form" data-test="select-buyer-form">
        <Box aria-label={messages[language].search} role="search">
          <Text text={subHeadingMsg} />
          <div className={styles.search}>
            <SearchBox
              data-test="search-buyers-filter"
              name="searchBuyers"
              id="searchBuyers"
              aria-label={messages[language].selectBuyers}
              placeholder={messages[language].selectBuyers}
              onChange={handleSearch}
            />
          </div>
        </Box>
        {!isLoading ? (
          <Box
            className={styles.tableWrapper}
            role="complementary"
            aria-label={messages[language].buyerList}
          >
            <Table
              rows={rows}
              columns={columns}
              fontWeight="medium"
              variantText="smText"
              color="secondary"
              scrollClassName={styles.listTableScroll}
              className={styles.listTable}
              language={language}
            />
            {rows.length === 0 ? (
              <Box p="10px 0">
                <Text text={messages[language].noDataFound} />
              </Box>
            ) : null}
          </Box>
        ) : (
          <SelectBuyersSkeleton data-test="select-buyer-skeleton" />
        )}
        <Box
          display="flex"
          justify="flex-end"
          mt="10px"
          role="complementary"
          aria-label={messages[language].buttons}
        >
          <Stack spacing="15px">
            <Button
              data-test="select-buyer-form-cancel-button"
              variant="secondary"
              text={messages[language].cancel}
              onClick={handleCancelSelectedBuyerModal}
            />
            <Button
              data-test="select-buyer-form-select-button"
              type="submit"
              text={`${messages[language].select} (${selectedBuyerCount})`}
              variant="primary"
              onClick={handleSelectBuyer}
              disabled={isLoading}
            />
          </Stack>
        </Box>
      </Form>
    </Box>
  )
}

// PropType validation
SelectBuyers.propTypes = {
  language: PropTypes.string,
  selectBuyersInfo: PropTypes.object,
  subHeading: PropTypes.string,
}
