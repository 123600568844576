import PropTypes from 'prop-types'
import Head from 'next/head'
import { getSegmentSrc } from './SegmentAnalyticsUtils'
const SEGMENT_SRC = getSegmentSrc()

function SegmentAnalytics({ disabled = false }) {
  return disabled ? null : (
    <Head>
      <script async src={SEGMENT_SRC} />
    </Head>
  )
}

SegmentAnalytics.propTypes = {
  disabled: PropTypes.bool,
}

export default SegmentAnalytics
