import { getSession, getSettings } from 'api'
import { COOKIES } from 'services/constants'
import { getAllCookies } from 'services/cookieManager'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { getSearchParamsFromRouter } from '~/components/containers/BrowseProducts/BrowseProductsUtils'
import { STANDING_OFFER_PRODUCTS_TYPE } from '~/components/shared/Layout/Header/HeaderMiddleSection/Search/SearchConstants'
import { getSearchUrl } from '~/components/shared/Layout/Header/HeaderMiddleSection/Search/SearchUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import {
  SUBMITTED_ORDERS_ROUTE,
  HOME_LOCAL_ROUTE,
} from '~/components/shared/EwayRouter/EwayRouterConstants'
import { identify } from '~/components/shared/SegmentAnalytics/SegmentAnalyticsUtils'
import { getBuyer } from '~/api/buyer'
import { windowReload } from './utils/windowUtils'

export const onSignInValidate = ({ values, dependencies }) => {
  const { language, messages } = dependencies
  const newErrors = {}
  if (!values.userId) {
    newErrors.userId = messages[language].requiredUserIdMsg
  }

  if (!values.password) {
    newErrors.password = messages[language].requiredPassMsg
  }

  return newErrors
}

export async function handleSignInSuccess({ redirectUrl, pageName, router }) {
  const { browsProdQuery: query } = getSearchParamsFromRouter(router)

  const settings = await getCCSSetting()
  const { enableWebAnalyticsInstrumentation = false } = settings

  const getLocalRedirectUrl =
    window.location.hostname === 'localhost' ? HOME_LOCAL_ROUTE : redirectUrl
  if (enableWebAnalyticsInstrumentation) {
    segmentAnalyticsIdentifyUser()
  }
  if (isPdpPage(pageName) || isSearchPage(pageName)) {
    //redirect to activation or change password page even if login through search/pdp
    if (
      redirectUrl.includes('AccountManagement/Profile/AccountActivation') ||
      redirectUrl.includes('AccountManagement/Profile/ChangePassword')
    ) {
      window.location.assign(redirectUrl)
      return
    }
    maintainRedirectUrl(redirectUrl, query, settings)
    return
  }
  if (isFindYourOrderPage(pageName)) {
    window.location.assign(getUrl(SUBMITTED_ORDERS_ROUTE))
    return
  }
  handleRedirection(getLocalRedirectUrl, query, settings)
}

function isSearchPage(pageName) {
  return PAGE_NAMES.SEARCH === pageName
}

function isPdpPage(pageName) {
  return PAGE_NAMES.PDP === pageName
}

/**
 * Name: getCCSSetting
 * Desc: get ccs setting
 * return object
 */
export const getCCSSetting = async (shipToClientIdNo = null) => {
  let settings = {}
  let currentShipToClientId = shipToClientIdNo
  const cookies = getAllCookies()
  if (cookies) {
    const tokenHeader = cookies[COOKIES.APIAUTH]
    const buyerId = cookies[COOKIES.BUYER_ID]
    const sessionId = cookies[COOKIES.SESSION_ID]
    if (shipToClientIdNo === null) {
      const { shipToClientId } = await getSession(
        [buyerId, sessionId],
        tokenHeader
      )
      currentShipToClientId = shipToClientId
    }

    settings = (await getSettings(currentShipToClientId, tokenHeader)) || {}
  }
  return settings
}

/**
 * maintainRedirectUrl
 * Desc:maintain  redirect url
 */
const maintainRedirectUrl = async (redirectUrl, query, settings = {}) => {
  const { maintainPreloginResult = false, enablePwgscheckout = false } =
    settings

  if (maintainPreloginResult) {
    //generate the url with pwgs selected option if available
    if (enablePwgscheckout) {
      const finalUrl = getSearchUrl(query, STANDING_OFFER_PRODUCTS_TYPE)
      window.location.assign(finalUrl)
    }
    windowReload()
  } else {
    window.location.assign(redirectUrl)
  }
}

const handleRedirection = async (redirectUrl, query, settings = {}) => {
  const { enablePwgscheckout = false } = settings
  //generate the url with pwgs selected option if redirection url is search
  if (redirectUrl.includes('Search/Search.aspx') && enablePwgscheckout) {
    const finalUrl = getSearchUrl(query, STANDING_OFFER_PRODUCTS_TYPE)
    window.location.assign(finalUrl)
  }
  window.location.assign(redirectUrl)
}

function isFindYourOrderPage(pageName) {
  return pageName === PAGE_NAMES.FIND_YOUR_ORDER
}
async function segmentAnalyticsIdentifyUser() {
  const cookies = getAllCookies()
  const buyerId = cookies[COOKIES.BUYER_ID]
  const { firstName, lastName, logonName, emailAddress } = await getBuyer(
    buyerId
  )
  identify(buyerId, {
    eway_userid: logonName,
    first_name: firstName,
    last_name: lastName,
    email: emailAddress,
  })
}
