import { sendAdobeAnalyticValues } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'

const CHECKOUT_BUTTON_VALUES = {
  pageName: 'Checkout: Checkout ',
}
const EVENTS = 'event4, scCheckout'

/**
 * checkoutButtonAdobeAnalytics
 * call Adobe Analytics on Checkout Button
 * @param {Object} order
 */

export const checkoutButtonAdobeAnalytics = (order, isPunchOutUser) => {
  const products = getProductsId(order)

  CHECKOUT_BUTTON_VALUES.products = products
  CHECKOUT_BUTTON_VALUES.events = isPunchOutUser ? 'scCheckout' : EVENTS
  const data = {
    AnalyticsProperties: CHECKOUT_BUTTON_VALUES,
  }
  sendAdobeAnalyticValues(data)
}

/**
 * Get the shopping cart productId
 * @param {Object} order
 * @return string
 */
const getProductsId = (order) => {
  let productsId = ''
  order?.orderProducts &&
    order.orderProducts.map((data) => {
      productsId += `;${data.productId}`
    })
  return productsId
}
