export default {
  en: {
    errorLoadingAddresses: 'Something went wrong trying to get the addresses',
    defaultBillingSuccess: 'Default billing address was successfully changed.',
  },
  fr: {
    errorLoadingAddresses: `Une erreur s'est produite en tentant d'obtenir les adresses.`,
    defaultBillingSuccess: `L'adresse de facturation par défaut a été modifiée avec succès.`,
  },
}
