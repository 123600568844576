import Box from 'components/shared/Box'
import PropTypes from 'prop-types'
import Table from 'shared/Table'
import Text from 'shared/Text'

import messages from '../messages'
import styles from './AddressTable.module.scss'
import { getAddressRows } from './AddressTableUtils'
import { ENGLISH } from '~/redux/constants'
import classNames from 'classnames'
/**
 * Name: AddressTable
 * Desc: Render AddressTable
 * @param {string} language
 */

const AddressTable = ({
  language = ENGLISH,
  addresses,
  onSelectAddressClick,
  selectedAddressId,
  buyerId,
  enableSelectOption,
  ...rest
}) => {
  const translations = messages[language]
  const listTableClass = classNames(
    { [styles.listTableWithoutSelectAddressOption]: !enableSelectOption },
    {
      [styles.listTable]: enableSelectOption,
    }
  )
  const columns = [
    <Text text="" key="tableHead6" color="secondary" variant="mdText" />,
    <Text
      text={translations.name}
      key="tableHead4"
      color="secondary"
      variant="mdText"
    />,
    <Text
      text={translations.type}
      key="tableHead4"
      color="secondary"
      variant="mdText"
    />,
    <Text
      text={translations.address}
      key="tableHead4"
      color="secondary"
      variant="mdText"
    />,
    <Text
      text={translations.attention}
      key="tableHead4"
      color="secondary"
      variant="mdText"
    />,
    <Text text="" key="tableHead4" color="secondary" variant="mdText" />,
    <Text text="" key="tableHead5" color="secondary" variant="mdText" />,
  ]
  const rows = getAddressRows({
    language,
    addresses,
    onSelectAddressClick,
    selectedAddressId,
    buyerId,
    enableSelectOption,
    ...rest,
  })
  return (
    <Box mt="20px" mb="20px" className={styles.costCenterTableWrapper}>
      <Table
        rows={rows}
        columns={columns}
        variant="tableMd"
        fontWeight="medium"
        variantText="xxlgText"
        color="secondary"
        className={listTableClass}
        variantWrapper={styles.listWrapper}
        scrollClassName={styles.tableDataScroll}
      />
    </Box>
  )
}
// PropType validation
AddressTable.propTypes = {
  language: PropTypes.string,
  addresses: PropTypes.array,
  onSelectAddressClick: PropTypes.func,
  selectedAddressId: PropTypes.number,
  buyerId: PropTypes.string,
  enableSelectOption: PropTypes.bool,
}
export { AddressTable }
