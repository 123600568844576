import { ENGLISH } from '@redux/constants'
import { getApiUrl, getOldApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()

/**
 * Name: fetchSearchByKeywordResult
 * Desc: get search result by keyword search
 * @param {*} language
 * @param {*} type // expected values Cart = 0, FiftyGreen = 1, HomePage = 2, MiniPDP = 3, Product = 4, Search = 5, ShoppingList = 6, ShoppingListProduct = 7
 */
const buildFetchSearchByKeywordResult = async (args) => {
  const [language = ENGLISH, reqParams] = args

  const url = `${OLD_API_URL}/api/Search/1/1/CF_ALL`
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  return sendRequest(url, options)
}

/**
 * Name: buildUpdateSearchOption
 * Desc: updates searchResultType in buyer for pwgs users
 * @param {string} buyerId //guid
 * @param {string} searchType // All Products = 0, Standing Offer Products = 1
 */
const buildUpdateSearchResultType = async (args) => {
  const [searchType, buyerId] = args
  const url = `${API_URL}/buyers/${buyerId}`
  const params = [
    {
      op: 'replace',
      path: 'searchResultType',
      value: searchType,
    },
  ]
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  }

  return sendRequest(url, options)
}
/**
 * Export methods
 */
export const getSearchByKeywordResult = checkArgsEmpty(
  buildFetchSearchByKeywordResult
)
export const updateSearchType = checkArgsEmpty(buildUpdateSearchResultType)
