import { useState } from 'react'
import { useToggler } from '~/components/shared/CustomHooks/useToggler'
import Button from '~/components/shared/Button'
import FormField from '~/components/shared/FormControl/FormField'
import Text from '~/components/shared/Text'
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  IS_CHECKED,
  CHECKED,
  UN_CHECKED,
} from './SelectBuyersConstants'
import messages from './messages'
import styles from './SelectBuyers.module.scss'
import { getBuyersList } from './selectBuyersApiUtils'
export function formattedColumns(column) {
  return column.filter((i) => i !== null)
}
export const useSelectBuyers = ({
  clientId,
  canFetchBuyers = false,
  onSaveSelectedBuyers = () => {},
}) => {
  const [buyersList, setBuyersList] = useState([])
  const [checkedBuyers, setCheckedBuyers] = useState([])
  const [filterInput, setFilterInput] = useState('')
  const [defaultSelectedBuyers, setDefaultSelectedBuyers] = useState([])
  const {
    isOpen: isOpenSelectedBuyerModal,
    open: openSelectedBuyerModal,
    close: closeSelectedBuyerModal,
  } = useToggler()
  const {
    isOpen: isLoading,
    open: setPending,
    close: setResolved,
  } = useToggler(true)
  const setBuyersInfo = async () => {
    setPending()
    const hasBuyerList = buyersList?.length > 0
    if (!hasBuyerList) {
      const { buyers, isSuccess } = await getBuyersList(clientId)
      if (isSuccess) {
        setBuyersList(buyers)
      }
    }
    setResolved()
  }

  const resetSelectedBuyersInfo = async ({
    isEditMode = false,
    address = {},
  }) => {
    canFetchBuyers && (await setBuyersInfo())
    if (isEditMode) {
      const { selectedBuyersList = [] } = address
      const selectedBuyers = selectedBuyersList.map((buyerIds) => {
        return buyerIds.buyerId
      })
      setDefaultSelectedBuyers(selectedBuyers)
      setCheckedBuyers(selectedBuyers)
    } else {
      setDefaultSelectedBuyers([])
      setCheckedBuyers([])
    }
  }
  const handleBuyerSelectionEvent = (event) => {
    const { value, checked } = event.target
    if (checked) {
      setCheckedBuyers([...checkedBuyers, value])
    } else {
      const selectedBuyers = checkedBuyers.filter((buyerId) => {
        return value !== buyerId
      })
      setCheckedBuyers(selectedBuyers)
    }
  }
  const handleSelectBuyer = () => {
    setDefaultSelectedBuyers(checkedBuyers)
    setFilterInput('')
    closeSelectedBuyerModal()
    onSaveSelectedBuyers(checkedBuyers)
  }
  const handleSearch = (e) => {
    setFilterInput(e.target.value)
  }
  const handleCancelSelectedBuyerModal = () => {
    setCheckedBuyers(defaultSelectedBuyers)
    setFilterInput('')
    closeSelectedBuyerModal()
  }
  const mergeCheckedValueWithBuyer = buyersList?.map((buyer) => {
    const isChecked = checkedBuyers.includes(buyer.BuyerId)
      ? CHECKED
      : UN_CHECKED
    return { ...buyer, [IS_CHECKED]: isChecked }
  })

  const firstAddresses = mergeCheckedValueWithBuyer.filter((buyer) =>
    defaultSelectedBuyers.includes(buyer.BuyerId)
  )

  const rest = mergeCheckedValueWithBuyer.filter(
    (buyer) => !defaultSelectedBuyers.includes(buyer.BuyerId)
  )

  const sortedBuyerList = [...firstAddresses, ...rest]

  const filteredBuyerList = getFilteredBuyerList(sortedBuyerList, filterInput)
  return {
    buyersList: filteredBuyerList,
    isOpenSelectedBuyerModal,
    openSelectedBuyerModal,
    handleCancelSelectedBuyerModal,
    handleSelectBuyer,
    checkedBuyers,
    handleBuyerSelectionEvent,
    selectedBuyerCount: checkedBuyers?.length || 0,
    handleSearch,
    resetSelectedBuyersInfo,
    isLoading,
  }
}

export const buyerTableColumnsHeading = (language) => {
  const i18n = messages[language]
  const {
    [FIRST_NAME]: firstName,
    [LAST_NAME]: lastName,
    [EMAIL]: email,
  } = i18n

  function getColumnButtonProps(name) {
    return {
      id: name,
      className: styles.tableSortingButton,
      variant: 'link',
      'data-test': name,
      'aria-label': i18n[name],
    }
  }
  return [
    '',
    <Button {...getColumnButtonProps(FIRST_NAME)} key={FIRST_NAME}>
      {firstName}
    </Button>,
    <Button {...getColumnButtonProps(LAST_NAME)} key={LAST_NAME}>
      {lastName}
    </Button>,
    <Button {...getColumnButtonProps(EMAIL)} key={EMAIL}>
      {email}
    </Button>,
  ]
}

export const formattedBuyerTableRows = ({
  buyersList = [],
  checkedBuyers,
  handleBuyerSelectionEvent,
}) => {
  return buyersList?.map((item) => {
    return Object.values({
      tBody1: (
        <FormField
          data-test="buyer-info"
          type="checkbox"
          name={`select-buyer-${item.BuyerId}`}
          id={`select-buyer-${item.BuyerId}`}
          space="m0"
          value={item.BuyerId}
          onChange={handleBuyerSelectionEvent}
          checked={!!checkedBuyers.includes(item.BuyerId)}
        />
      ),
      tBody2: (
        <Text
          className={styles.tableCell}
          variant="mdText"
          text={item?.FirstName}
          color="secondary"
        />
      ),
      tBody3: (
        <Text
          className={styles.tableCell}
          variant="mdText"
          text={item?.LastName}
          color="secondary"
        />
      ),
      tBody4: (
        <Text
          className={styles.tableCell}
          variant="mdText"
          text={item?.EmailAddress}
          color="secondary"
        />
      ),
    })
  })
}

export function getFilteredBuyerList(buyerList, filterInput) {
  const filteredBuyer = buyerList?.filter((buyer) => {
    const { FirstName = '', LastName = '', EmailAddress = '' } = buyer
    const filterKey = filterInput.toLowerCase()
    return (
      FirstName.toLowerCase().includes(filterKey) ||
      LastName.toLowerCase().includes(filterKey) ||
      EmailAddress.toLowerCase().includes(filterKey)
    )
  })
  return filteredBuyer
}
