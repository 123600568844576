import FormField from 'shared/FormControl/FormField'
import {
  getLabelProps,
  getRequiredProps,
} from 'components/shared/FormControl/FormField/FormFieldUtils'
import PropTypes from 'prop-types'

export const AddressField = ({
  id = '',
  values = {},
  required = {},
  errors = {},
  dirties = {},
  'data-test': dataTest,
  translations,
  fieldVariants,
  ...rest
}) => {
  const addedProps = fieldVariants[id]
  const finalDataTest = dataTest || addedProps['data-test']
  const isRequired = required[id]
  const label = translations[id]
  const requiredProps = getRequiredProps(isRequired)
  const labelFields = getLabelProps(label)

  const errorMessage = errors[id]
  const props = {
    value: values[id] || '',
    showError: !!errorMessage && !!dirties[id],
    errorMessage,
    ...addedProps,
    'data-test': finalDataTest,
    ...labelFields,
    ...requiredProps,
  }
  return <FormField {...props} {...rest} />
}

AddressField.propTypes = {
  dirties: PropTypes.object,
  errors: PropTypes.object,
  'data-test': PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.object,
  translations: PropTypes.object,
  fieldVariants: PropTypes.object,
  values: PropTypes.object,
}
