import {
  getParameterCaseInsensitive,
  hasBoxRequest,
} from 'components/containers/BrowseProducts/BrowseProductsUtils'
import {
  FINDER_TYPE,
  INK_AND_TONER_QUERY,
  VIEW_TYPE,
} from '~/components/containers/BrowseProducts/BrowseProductsConstants'

export function getFinderType(query = {}) {
  const isInkAndTonerFinder = checkIsInAndTonerFinder(query)
  const isBoxFinder = hasBoxRequest(query)
  const finderType = isBoxFinder
    ? FINDER_TYPE.BOX
    : isInkAndTonerFinder
    ? FINDER_TYPE.INK_AND_TONER
    : ''
  return { isBoxFinder, isInkAndTonerFinder, finderType }
}

function checkIsInAndTonerFinder(query) {
  return (
    getParameterCaseInsensitive(query, VIEW_TYPE)?.toLowerCase() ===
    INK_AND_TONER_QUERY.VALUE.toLowerCase()
  )
}
