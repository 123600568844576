import PropTypes from 'prop-types'
import styles from './DialogConfirmation.module.scss'
import classNames from 'classnames'

export function DialogConfirmationFooter({ children, ...rest }) {
  const mergedClasses = classNames(styles.dialogFooter, rest.className)
  return (
    <div {...rest} className={mergedClasses}>
      {children}
    </div>
  )
}

DialogConfirmationFooter.propTypes = {
  children: PropTypes.node,
}
