import {
  FAVORITE_ONLY_FIELD,
  SEARCH_KEY_FIELD,
  SEARCH_ON_FIELD,
  SEARCH_TYPES,
  SEARCH_TYPE_FIELD,
  SORT_BY_FIELD,
  SORT_TYPES,
  SORT_TYPE_FIELD,
} from './FindAccountTabFilterConstant'
import messages from './messages'

export const getFilterInitialValues = () => {
  const initialFilterValues = {
    [SEARCH_KEY_FIELD]: '',
    [SEARCH_TYPE_FIELD]: SEARCH_TYPES.CONTAINS,
    [SEARCH_ON_FIELD]: '',
    [SORT_TYPE_FIELD]: SORT_TYPES.ASCENDING,
    [SORT_BY_FIELD]: '',
    [FAVORITE_ONLY_FIELD]: false,
  }

  return { ...initialFilterValues }
}

export const getSearchTypeButtons = (language) => {
  return [
    { text: messages[language].contains, value: SEARCH_TYPES.CONTAINS },
    { text: messages[language].beginsWith, value: SEARCH_TYPES.BEGINS_WITH },
  ]
}

export const getSortTypeButtons = (language) => {
  return [
    { text: messages[language].ascending, value: SORT_TYPES.ASCENDING },
    { text: messages[language].descending, value: SORT_TYPES.DESCENDING },
  ]
}

export const getSearchOnFields = (language) => {
  const lookInAllFields = 'lookInAllFields'
  const searchOnFields = [lookInAllFields, ...getOperatingItems()]

  return searchOnFields.map((searchOnField) => {
    return {
      name: messages[language][searchOnField],
      value: lookInAllFields === searchOnField ? '' : searchOnField,
    }
  })
}

export const getSortingMenus = (language) => {
  const favoriteShipTo = 'favoriteShipTo'
  const sortingMenus = [favoriteShipTo, ...getOperatingItems()]

  return sortingMenus.map((sortingMenu) => {
    return {
      name: messages[language][sortingMenu],
      value: favoriteShipTo === sortingMenu ? '' : sortingMenu,
    }
  })
}

export const getOperatingItems = () => {
  return [
    'clientNo',
    'costCenter',
    'shippingName', // Customer name
    'address1',
    'address2',
    'address3',
    'shippingPostalCode',
    'shippingContact', // careOf
  ]
}

export const getDefaultSortByFields = () => {
  return ['isFavorite', 'clientNo']
}
