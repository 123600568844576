import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './ButtonPanel.module.scss'

/**
 * Name: ButtonPanel
 * Desc: Render ButtonPanel
 * @param {array} buttonList
 * @param {func} onSelect
 * @param {number} activeButton
 * @param {string} panelName
 */
const ButtonPanel = ({
  buttonList = [],
  onSelect,
  activeButton,
  panelName,
} = {}) => {
  const syntheticEvent = {
    target: {
      name: panelName,
      value: '',
    },
  }
  return (
    <div className={styles.filterButton}>
      {buttonList?.map((button, index) => {
        const handleOnClick = () => {
          syntheticEvent.target.value = button.value
          onSelect(syntheticEvent)
        }
        return (
          <Button
            key={index}
            onClick={handleOnClick}
            className={button.value === activeButton ? styles.selected : ''}
            text={button.text}
            aria-label={button.buttonAriaLabel || button.text}
            variant="light"
          />
        )
      })}
    </div>
  )
}

// Props validation
ButtonPanel.propTypes = {
  buttonList: PropTypes.array,
  onSelect: PropTypes.func,
  activeButton: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  panelName: PropTypes.string.isRequired,
}

export default ButtonPanel
