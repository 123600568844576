export const SHOPPING = 'shopping'
export const ORDERS = 'orders'
export const GROUP_ORDER = 'grouporder'
export const SUBMITTED_ORDER = 'submittedOrder'
export const BACK_ORDER = 'backOrder'
export const TEMPLATES = 'templates'
export const SPECIAL_ORDER = 'specialOrder'
export const RETURNS_URL = 'returnsUrl'
export const ACCOUNT = 'account'
export const EDIT_MY_PROFILE = 'editMyProfile'
export const CHANGE_PASSWORD = 'changePassword'
export const ADMIN = 'admin'
export const APPROVER = 'approver'
export const HELP_MENU = 'Help'
export const CART_SUB_MENU = 'cart'
export const REBATES_AND_OFFERS = 'rebatesAndOffers'
export const FLYERS_CATALOGUES = 'flyersCatalogues'
export const PRINT_ORDER = 'printOrder'
export const ACCOUNT_MANAGEMENT = 'accountManagement'
export const MANAGE_ADDRESSES = 'manageAddresses'
export const MANAGE_USERS = [1, 2]
export const SHOPPING_LIST = 'shoppingList'
