import { ENGLISH } from '@redux/constants'
import { isEdgeBrowser } from 'services/utils'
import { getApiUrl, getHostUrl } from 'services/utils/configUtils'
import EwayRouter from 'shared/EwayRouter'
import { LOGIN_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const HOST_URL = getHostUrl()
const API_URL = getApiUrl()

const authenticateUserCredentials = async (args) => {
  const [userId, password, language] = args

  const bodyContent = `tb-user=${userId}&tb-pass=${password}`
  const url = `${HOST_URL}/${language}/core/Buyers/Authenticate`
  const options = {
    method: 'POST',
    body: bodyContent,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  }

  const response = await sendRequest(url, options, {
    isAddToken: false,
    disableCache: false,
  })
  return response
}

const checkUserLogin = async (args) => {
  const [username, password, language] = args

  const bodyContent = JSON.stringify({
    username,
    password,
  })
  const url = EwayRouter.getUrl(LOGIN_ROUTE)

  const options = {
    method: 'POST',
    body: bodyContent,
    ...addMicrosoftEdgeHeaders(),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options, {
    isAddToken: false,
    disableCache: false,
  })
  const { status, statusCode = 200, redirectUrl, message } = response
  const isSuccess = status === 200 && statusCode === 200
  return { isSuccess, statusCode, redirectUrl, message }
}
/**
 * Methods that conditionally fixes the login issues regarding the edge browser.
 * Based on https://stackoverflow.com/questions/31721250/how-to-target-windows-10-edge-browser-with-javascript
 * The day you don't need edge, simply don't call the method on login.
 */
const addMicrosoftEdgeHeaders = () => {
  return isEdgeBrowser() && { credentials: 'same-origin' }
}

const validateUserCredentials = async (args) => {
  const [userId, password, language] = args
  const bodyContent = `tb-user=${userId}&tb-pass=${password}`
  const url = `${HOST_URL}/${language}/core/Buyers/validateCredentials`
  const options = {
    method: 'POST',
    body: bodyContent,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  }
  const response = await sendRequest(url, options, {
    isAddToken: false,
    disableCache: false,
  })
  return response
}

/**
 * Name: passwordReminder
 * Desc: passwordReminder
 * @param {*} language
 * @param {*} userName
 */
const passwordReminder = async (args) => {
  const [language = ENGLISH, userName] = args
  const url = `${API_URL}/buyers/forgotPassword?username=${userName}`
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  return sendRequest(url, options)
}

/**
 * Name: passwordReminder
 * Desc: passwordReminder
 * @param {*} language
 * @param {*} userName
 */
const userIdReminder = async (args) => {
  const [language = ENGLISH, emailAddress] = args
  const url = `${API_URL}/buyers/forgotUsername?address=${emailAddress}`
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  return sendRequest(url, options)
}

/**
 * Export methods
 */
export const getPasswordReminder = checkArgsEmpty(passwordReminder)
export const getUsernameReminder = checkArgsEmpty(userIdReminder)
export const authenticateCredentials = checkArgsEmpty(
  authenticateUserCredentials
)

export const validateCredentials = checkArgsEmpty(validateUserCredentials)
export const userLogin = checkArgsEmpty(checkUserLogin)
