import PropTypes from 'prop-types'
import { useMemo, useEffect } from 'react'
import { POSTAL_CODE_MAX_LENGTH } from 'services/constants'
import { useFacultyCenter } from '~/api/facultyCenter'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import Box from '~/components/shared/Box'
import Button from '~/components/shared/Button'
import EwayLink from '~/components/shared/EwayLink'
import FormField from '~/components/shared/FormControl/FormField'
import styles from '~/components/shared/PostalCodeEntryForm/PostalCodeEntryForm.module.scss'
import {
  POSTAL_CODE,
  PRE_DEFINE_PROVINCES_OPTIONS,
  PROVINCE,
} from '~/components/shared/PostalCodeEntryForm/PostalCodeEntryFormConstants'
import { postalCodeRegex } from '~/services/utils/regexUtils'
import ProvinceSelect from '~/components/shared/ProvinceSelect'
import Text from '~/components/shared/Text'
import { QUERY_STRING_POSTAL_CODE } from '~/redux/autoLoginService/autoLoginServiceConstants'
import { ENGLISH } from '~/redux/constants'
import { getCookie, removeCookie } from '~/services/cookieManager'
import { getTextWithDynamicComponent } from '~/services/utils/languageUtils'
import { useEffectOnce } from '../CustomHooks/useEffectOnce'
import messages from './messages'
import { isExceptionsPostalCode } from './PostalCodeEntryFormUtils'

function PostalCodeEntryForm({
  language = ENGLISH,
  parentClientNo,
  onSubmit: onSubmitFromProps,
  onClickClose,
  showCloseButton,
}) {
  const { updateSessionAndReload, isLoading } = useFacultyCenter({
    parentClientNo,
  })
  const { requiredMessage, invalidPostalCode } = messages[language]
  const initValues = {
    [POSTAL_CODE]: {
      value: '',
      required: requiredMessage,
      pattern: {
        value: postalCodeRegex,
        message: invalidPostalCode,
      },
    },
  }
  const {
    values = {},
    errors = {},
    dirties = {},
    handleChange,
    handleSubmit,
    triggerChange,
    setValues,
    addField,
    removeField,
  } = useFormControlled(initValues, language)
  const postalCodeValue = values[POSTAL_CODE]
  const showProvinceField = isExceptionsPostalCode(postalCodeValue)
  useEffect(() => {
    if (showProvinceField) {
      addField(PROVINCE, {
        required: requiredMessage,
      })
    } else {
      removeField(PROVINCE)
    }
  }, [addField, removeField, showProvinceField, requiredMessage])
  useEffectOnce(() => {
    const value = getCookie(QUERY_STRING_POSTAL_CODE)
    const upperCasedPostalCode = value?.toUpperCase() || ''
    if (upperCasedPostalCode) {
      setValues({
        [POSTAL_CODE]: upperCasedPostalCode,
      })
    }
    // Once used remove it.
    removeCookie(QUERY_STRING_POSTAL_CODE)
  })

  const handleChangeOrBlur = (event) => {
    handleChange(event)
  }

  function handleProvinceChange(value) {
    triggerChange(PROVINCE, value.toUpperCase())
  }
  const handleSubmitForm = async (event) => {
    const result = handleSubmit(event)
    const { isFormValid } = result
    if (isFormValid) {
      const result = await updateSessionAndReload({
        ...values,
      })
      return { ...result, isFormValid }
    }
    return result
  }

  async function handlePostalCodeSubmit(e) {
    const result = await handleSubmitForm(e)
    onSubmitFromProps?.(e, result)
  }

  const translations = messages[language]
  const { postalCodeHelp, clickHere, postalCodeLookUpLink } = translations

  const findPostalCodeMessage = useMemo(
    () =>
      getTextWithDynamicComponent(postalCodeHelp, {
        '{{CLICK_HERE}}': (
          <EwayLink
            text={clickHere}
            color="primary"
            href={postalCodeLookUpLink}
            textDecoration={true}
            size="lg"
            target="_blank"
            className={styles.viewShoppingList}
            data-test="postal-code-suggestion-link"
          />
        ),
      }),
    [clickHere, postalCodeHelp, postalCodeLookUpLink]
  )

  return (
    <Box className={styles.postalCodeWrapper}>
      <Text
        text={translations.postalCodeDetailsMsg}
        variant="lgText"
        color="secondary"
        as="div"
        className={styles.postalCodeDetailsMsg}
      />
      <Text
        text={findPostalCodeMessage}
        variant="lgText"
        color="secondary"
        as="div"
        className={styles.alignCentre}
        textAlign="center"
      />
      <form
        onSubmit={handlePostalCodeSubmit}
        noValidate
        data-test="postal-code-entry-form"
        id="postal-code-entry-form"
      >
        <Box
          display="flex"
          justify="center"
          align="center"
          m="20px 0"
          className={styles.formWrapper}
        >
          <Box className={styles.formField}>
            <FormField
              placeholder={translations.postalCodePlaceholder}
              type="text"
              name={POSTAL_CODE}
              showError={!!(errors[POSTAL_CODE] && dirties[POSTAL_CODE])}
              errorMessage={errors[POSTAL_CODE]}
              id={POSTAL_CODE}
              data-test="postal-code-form-field"
              defaultValue={values?.[POSTAL_CODE]}
              area-required="true"
              errors={errors}
              onChange={handleChangeOrBlur}
              onBlur={handleChangeOrBlur}
              borderColor="primary"
              dirties={dirties}
              disabled={isLoading}
              maxLength={POSTAL_CODE_MAX_LENGTH}
            />

            {showProvinceField ? (
              <Box mt="10px">
                <ProvinceSelect
                  required={true}
                  disabled={isLoading}
                  label={messages[language].province}
                  language={language}
                  defaultValue={values?.province}
                  value={values?.province}
                  data-test="province"
                  name={PROVINCE}
                  showError={!!(errors[PROVINCE] && dirties[PROVINCE])}
                  id={PROVINCE}
                  errorMessage={errors[PROVINCE]}
                  onChange={handleProvinceChange}
                  defaultProvinceOptions={PRE_DEFINE_PROVINCES_OPTIONS}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box display="flex" justify="center" align="center" m="20px 0">
          {showCloseButton ? (
            <Button
              type="button"
              text={translations.close}
              variant="secondary"
              className={styles.cancelBtn}
              data-test="postal-code-entry-close-button"
              onClick={onClickClose}
            />
          ) : null}
          <Button
            onClick={handlePostalCodeSubmit}
            type="button"
            isLoading={isLoading}
            text={translations.continueBtn}
            variant="primary"
            className={styles.alignCentre}
            name="postal-code-submit-button-continue-button"
            data-test="postal-code-submit-button-continue-button"
          />
        </Box>
      </form>
    </Box>
  )
}

PostalCodeEntryForm.propTypes = {
  form: PropTypes.shape({
    dirties: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
  }),
  language: PropTypes.string,
  parentClientNo: PropTypes.number,
  onSubmit: PropTypes.any,
  onClickClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
}

export default PostalCodeEntryForm
